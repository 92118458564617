import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { Table } from "react-bootstrap";
import { connect } from 'react-redux';
import Loader from "react-loader";
import Pagination from "react-js-pagination";
import * as AllAction from '../Action/AllAction';
import * as InstallAction from '../Action/installAction';
class GetOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            get_order: [],
            loaded: false,
            env: {},
            pageNumber: 1,
            activePage: 1,
            total_product_count: 0,
            // message: "Thank you for your order. Your order has been placed."
        }
    }
    componentWillMount() {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, page: this.state.activePage
        }
        this.props.load_order !== true && this.props.action.AllAction.GetOrderAction(data);
        this.props.reqData && this.setState(() => {
            var { page } = this.props.reqData
            return {
                activePage:page
            }
        })
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        }, () => this.apiCall())
    }
    apiCall = () => {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, page: this.state.activePage
        }
        this.props.action.AllAction.GetOrderAction(data);
    }
    render() {
        var all_order_data = this.props.load_order && this.props.get_order && this.props.get_order.length !== 0
            ? this.props.get_order.map((get_order, i) => {
                return <tr key={i}>
                    <td valign="middle">{get_order.order_no}</td>
                    <td valign="middle">{get_order.service_title}</td>
                    <td valign="middle">{get_order.comment}</td>
                    <td valign="middle">{get_order.no_of_product}</td>
                    <td valign="middle">{get_order.unit_price}</td>
                    <td valign="middle">{get_order.total_amount}</td>
                    <td valign="middle">{get_order.order_date}</td>
                    <td valign="middle">{get_order.order_status}</td>
                </tr>
            }) : <tr><td colSpan="8">No Requested job</td></tr>
        return (
            <div className="bp-txt">
                <div><h4 className="section-title">Your Jobs</h4>
                    {/* {this.state.message && <h4>{this.state.message}</h4>} */}
                </div>
                {this.props.load_order && <><Table bordered responsive className="pro-data-table">
                    <thead>
                        <tr>
                            <th style={{ width: "14%" }}>Job No.</th>
                            <th style={{ width: "14%" }}>Service</th>
                            <th style={{ width: "20%" }}>Niche</th>
                            <th style={{ width: "8%" }}>No. of Product</th>
                            <th style={{ width: "8%" }}>Unit Price</th>
                            <th style={{ width: "8%" }}>Total Price</th>
                            <th style={{ width: "14%" }}>Job Date</th>
                            <th style={{ width: "14%" }}>Job Status </th>
                        </tr>
                    </thead>
                    <tbody>
                        {all_order_data}
                    </tbody>
                </Table>
                    <div>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            pageRangeDisplayed={3}
                            totalItemsCount={this.props.total_record_order}
                            onChange={this.handlePageChange}
                            innerClass="innerClass"
                            activeClass="activeClass"
                            activeLinkClass="activeLinkClass"
                            itemClass="itemClass"
                            itemClassFirst="itemClassFirst"
                            itemClassPrev="itemClassPrev"
                            itemClassNext="itemClassNext"
                            itemClassLast="itemClassLast"
                            linkClass="linkClass"
                            linkClassFirst="linkClassFirst"
                            linkClassPrev="linkClassPrev"
                            linkClassNext="linkClassNext"
                            linkClassLast="linkClassLast"
                        />
                    </div>
                </>}
                <Loader
                    loaded={this.props.load_order}
                    lines={10}
                    length={5}
                    width={5}
                    radius={10}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#1f3893"
                    speed={1}
                    trail={40}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        get_order: state.AllReducer.get_order.data,
        load_order: state.AllReducer.get_order.load_order,
        reqData: state.AllReducer.get_order.reqData,
        total_record_order: state.AllReducer.get_order.total_records,
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        InstallAction: bindActionCreators(InstallAction, dispatch),
        AllAction: bindActionCreators(AllAction, dispatch)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(GetOrder);