import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as InstallAction from '../Action/installAction';
import * as AllAction from '../Action/AllAction';
import { FormGroup } from 'react-bootstrap';
import { Button, FormControl, Modal, FormLabel } from "react-bootstrap";
import { FaSpinner } from "react-icons/fa";
import { GoVerified } from "react-icons/go";

class BespokedReq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected_service: "",
            comment: "",
            no_of_product: 0,
            fieldsErrors: { selected_service: "", comment: "", no_of_product: "" },
            formValid: false,
            placed_order: false
        }
    }
    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value
        }, () => {
            this.setState({
                fieldsErrors: { [key]: "" }
            })
        })
    }
    componentWillMount() {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken,
            shop
        };
        this.props.load_upt !== true && this.props.action.AllAction.getUserPayTypeAction(data);
        this.props.action.AllAction.getShopSubscriptionAction(data);
        this.props.action.AllAction.getCardDeailsByGetShopApi(data);
    }
    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            if (!this.props.show) {
                this.props.action.AllAction.setStripeLoadedOrder();
                this.setState({
                    placed_order: false,
                    selected_service: this.props.get_service && this.props.get_service.length !== 0 && this.props.get_service[0].id,
                    comment: "",
                    no_of_product: 0,
                    fieldsErrors: { selected_service: "", comment: "", no_of_product: "" },
                    formValid: false
                })
            }
            if (this.props.show) {
                this.setState({
                    selected_service: this.props.get_service && this.props.get_service.length !== 0 && this.props.get_service[0].id
                })
            }
        }
    }
    handleSubmitService = () => {

        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from")

        const data_validation = {
            comment: this.state.comment, no_of_product: this.state.no_of_product
        }
        const data = {
            shop, accessToken, user_from,
            nop: this.state.no_of_product,
            up: this.props.get_service[0] && this.props.get_service[0].country_wise_price[0] && this.props.get_service[0].country_wise_price[0].price,
            currency: this.props.get_service[0] && this.props.get_service[0].country_wise_price[0] && this.props.get_service[0].country_wise_price[0].Currency,
            comment: this.state.comment,
            service_id: this.props.get_service[0] && this.props.get_service[0]._id

        }
        // if (data.service_id === "")
        //     this.setState({
        //         fieldsErrors: { selected_service: "* Select Service!" },
        //         formValid: false
        //     });

        if (data_validation.comment === "")
            this.setState({
                fieldsErrors: { comment: "* Category criteria is Required!" },
                formValid: false
            });
        else if (data_validation.no_of_product < 1) {
            this.setState({
                fieldsErrors: { no_of_product: "* Atleast 1 product required!" },
                formValid: false
            });
        } else {
            this.setState({
                placed_order: true,
                formValid: true
            }, () => {
                if (this.props.pay_type && (this.props.pay_type.user_from === "shopify")
                    && (this.props.pay_type.payment_gateway
                        && this.props.pay_type.payment_gateway.gateway_tag &&
                        this.props.pay_type.payment_gateway.gateway_tag === "shopify")) {
                    var data_shopify = {
                        accessToken, shop, service_id: this.props.get_service[0]._id,
                        comment: this.state.comment, no_of_product: this.state.no_of_product,
                        service_tag: "niche_product"
                    }
                    this.props.action.AllAction.PlaceOrderShopifyAction(data_shopify);
                }
                else {
                    this.props.action.AllAction.PlaceOrderAction(data, this.props.history);
                    this.props.onHide();
                }
            });
        }
    }
    render() {
        var {
            card_details
        } = this.props;


        var get_service = this.props.get_service;
        var get_service_option = get_service && get_service.length !== 0 ? get_service.map((get_service) => {
            return <option value={get_service._id}>{get_service.title}</option>
            // return { value: get_service._id, label: get_service.title }
        }) : <option value="0">No service available</option>

        // var selected_service_price = get_service && get_service.length !== 0 && get_service.map((get_service) => {
        //     if (get_service._id === this.state.selected_service) {
        //         return { price: get_service.price, _id: get_service._id }
        //     }
        //     else {
        //         return 0
        //     }
        // })

        // var f_selected_service_price = selected_service_price && selected_service_price.length !== 0 && selected_service_price.filter((selected_service_price) => {
        //     return selected_service_price._id === this.state.selected_service
        // })
        var cw_price = this.props.get_service && this.props.get_service.length !== 0 && this.props.get_service[0] &&
            this.props.get_service[0].country_wise_price && this.props.get_service[0].country_wise_price.length !== 0
            && (this.props.get_service[0].country_wise_price[0].Currency_Symbol + " " + this.props.get_service[0].country_wise_price[0].price);
        var total_price = this.props.get_service && this.props.get_service.length !== 0 && this.props.get_service[0] &&
            this.props.get_service[0].country_wise_price && this.props.get_service[0].country_wise_price.length !== 0
            && (this.props.get_service[0].country_wise_price[0].Currency_Symbol + " " + Number((this.props.get_service[0].country_wise_price[0].price * this.state.no_of_product).toFixed(2)));

        // var is_card_no = this.props.load_shop_sub && this.props.stripe_customer_cards && this.props.stripe_customer_cards.length !== 0 &&
        //     this.props.stripe_customer_cards[0].cardDetails && this.props.stripe_customer_cards[0].cardDetails.length !== 0 &&
        //     this.props.stripe_customer_cards[0].cardDetails[0] && this.props.stripe_customer_cards[0].cardDetails[0].last4 ? true : false;
        // var msg_no = is_card_no ? this.props.load_shop_sub && this.props.stripe_customer_cards && this.props.stripe_customer_cards.length !== 0 &&
        //     this.props.stripe_customer_cards[0].cardDetails && this.props.stripe_customer_cards[0].cardDetails.length !== 0 &&
        //     this.props.stripe_customer_cards[0].cardDetails[0] && this.props.stripe_customer_cards[0].cardDetails[0].last4 : "";

        var is_card_no = this.props.load_cd && card_details && card_details.card_details && Array.isArray(card_details.card_details) &&
            card_details.card_details.length !== 0 && card_details.card_details[0].cardDetails && card_details.card_details[0].cardDetails.length !== 0 &&
            card_details.card_details[0].cardDetails[0] && card_details.card_details[0].cardDetails[0].last4 ? true : false;

        var msg_no = is_card_no ?
            card_details.card_details[0].cardDetails[0] && card_details.card_details[0].cardDetails[0].last4 : "";

        return (
            <>
                <Modal {...this.props} size="lg"
                    dialogClassName="extra-content-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    className="request-bespoked"
                    centered>
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            Request Niche Product
                            </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="bespoked-product">
                            <div className="select-service" >
                                <FormGroup>
                                    <FormControl
                                        as="select"
                                        componentClass="select"
                                        defaultValue={get_service && get_service.length !== 0 && get_service[0]._id}
                                        onChange={e => this.handleOnChange("selected_service", e)}>
                                        <option selected disabled hidden>Choose service</option>
                                        {get_service_option}
                                    </FormControl>
                                    <span style={{ color: "red" }}>{this.state.fieldsErrors.selected_service}</span>
                                </FormGroup>
                            </div>
                            <FormGroup>
                                <FormLabel>
                                    Your niche/category
                        </FormLabel>
                                <FormControl
                                    as="textarea"
                                    placeholder="Enter product criteria"
                                    onChange={e => this.handleOnChange("comment", e)}></FormControl>
                                <span style={{ color: "red" }}>{this.state.fieldsErrors.comment}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>No. of product</FormLabel>
                                <FormControl
                                    as="select"
                                    componentClass="select"
                                    onChange={e => this.handleOnChange("no_of_product", e)}>
                                    <option selected disabled hidden>Choose no. of product</option>
                                    <option>2</option>
                                    <option>4</option>
                                    <option>6</option>
                                    <option>8</option>
                                    <option>10</option>
                                </FormControl>
                                <span style={{ color: "red" }}>{this.state.fieldsErrors.no_of_product}</span>
                            </FormGroup>
                            {/* {(this.state.selected_service !== "" && f_selected_service_price && f_selected_service_price.length !== 0 && (f_selected_service_price[0].price !== ("" || undefined))) && */}
                            <div className="bp-price">
                                <FormLabel>
                                    Service Price: <span className="bp-price-value">{cw_price}</span>
                                </FormLabel>
                                <FormLabel>
                                    Total Price: <span className="bp-price-value">{total_price}</span>
                                </FormLabel>
                            </div>
                            {/* // } */}
                        </div>
                        {this.props.load_cd && is_card_no && < div className="card-no">
                            <span className="green-mark">
                                <GoVerified color="green" />
                            </span>You are paying via card ending with <span className="msg-no">{msg_no}</span>.</div>}
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.placed_order && !this.props.placed_order && <span className="ats-icon">
                            <FaSpinner className="icon-spin" color="#888" height="23px" width="23px" /></span>}
                        <Button disabled={this.state.placed_order && this.props.stripe_loaded_order === false} onClick={() => this.handleSubmitService()}>Place job</Button>
                        <Button onClick={this.props.onHide} variant="danger" className="close-bp">Close</Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        get_service: state.AllReducer.get_service.data,
        pay_type: state.AllReducer.pay_type.message,
        load_upt: state.AllReducer.pay_type.load_upt,
        stripe_loaded_order: state.AllReducer.stripe_loaded_order,
        stripe_customer_cards: state.AllReducer.get_shop_sub.stripe_customer_cards,
        load_shop_sub: state.AllReducer.get_shop_sub.load_shop_sub,
        card_details: state.AllReducer.card_details.message,
        load_cd: state.AllReducer.card_details.load_cd
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
        InstallAction: bindActionCreators(InstallAction, dispatch)
    }
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BespokedReq));