import React, { Component } from "react";
import NavbarHeader from "./NavbarHeader";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Select from "react-select";
import { Button, FormControl, FormGroup, FormLabel, Tooltip, Modal } from "react-bootstrap";
import { FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";
import { TiSocialInstagram } from "react-icons/ti";
import * as AllAction from "../Action/AllAction";
import Header from "./Header";
class Influcer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show__reg: false,
      i_name: "",
      i_email: "",
      i_address: "",
      i_cat: "",
      i_niche: "",
      i_charge: "",
      i_link_fb: "",
      i_link_insta: "",
      i_link_twitter: "",
      i_link_yt: "",
      i_followers_fb: "",
      i_followers_insta: "",
      i_followers_twitter: "",
      i_followers_yt: "",
      formValid: false,
      submit: false,
      inf_state: "",
      inf_country: "",
      inf_city: "",
      selected_cat: [],
      fieldsErrors: {
        i_name: "",
        i_email: "",
        i_address: "",
        i_cat: "",
        i_niche: "",
        i_link_insta: "",
        i_followers_fb: "",
        i_followers_insta: "",
        i_followers_twitter: "",
        i_followers_yt: "",
        inf_state: "",
        inf_country: "",
        inf_city: "",
      }
    };
  }
  componentWillMount() {
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const env_data = {
      secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
    };
    this.props.load_env !== true && this.props.action.AllAction.GetEnvAction(env_data);
    const data_cat = {
      accessToken,
      shop
    };
    this.props.load_cat !== true && this.props.action.AllAction.getCategoryAction(data_cat);
  }
  handleClose = () => {
    this.setState({
      show__reg: false
    });
  };
  handleOnChange = (key, e) => {
    this.setState(
      {
        [key]: e.target.value
      },
      () => {
        this.setState({
          fieldsErrors: { [key]: "" }
        });
      }
    );
  };

  handleRegInfluencer = () => {
    this.setState({
      submit: true
    });
    const {
      i_name,
      i_email,
      i_address,
      i_cat,
      selected_cat,
      i_niche,
      inf_country,
      inf_state,
      inf_city,
      i_charge,
      i_link_fb,
      i_link_insta,
      i_link_twitter,
      i_link_yt,
      i_followers_fb,
      i_followers_insta,
      i_followers_twitter,
      i_followers_yt
    } = this.state;
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const data_influencer = {
      accessToken,
      shop,
      inf_name: i_name,
      inf_email: i_email,
      inf_category: selected_cat.length !== 0 ? selected_cat : [],
      inf_acc_link: {
        link_instagram: i_link_insta,
        link_fb: i_link_fb,
        link_twitter: i_link_twitter,
        link_youtube: i_link_yt
      },
      inf_country: inf_country,
      inf_state: inf_state,
      inf_city: inf_city,
      inf_charge: i_charge,
      inf_address: i_address,
      inf_acc_followers: {
        follower_instagram: i_followers_insta,
        follower_fb: i_followers_fb,
        follower_twitter: i_followers_twitter,
        follower_youtube: i_followers_yt
      }
    };
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;

    if (i_name === "")
      this.setState({
        fieldsErrors: { i_name: "* Name is Required!" },
        formValid: false
      });
    else if (i_email === "")
      this.setState({
        fieldsErrors: { i_email: "* Email is Required!" },
        formValid: false
      });
    else if (!i_email.match(mailformat)) {
      this.setState({
        fieldsErrors: { i_email: "You have entered an invalid email address!" },
        formValid: false
      });
    }
    // else if (i_cat === "")
    //   this.setState({
    //     fieldsErrors: { i_cat: "* Category is Required!" },
    //     formValid: false
    //   });
    else if (selected_cat.length === 0) {
      this.setState({
        fieldsErrors: { selected_cat: "* Category is Required!" },
        formValid: false
      });
    }
    else if (i_link_insta === "")
      this.setState({
        fieldsErrors: { i_link_insta: "* Instagram account ID is Required!" },
        formValid: false
      });
    else if (inf_country === "") {
      this.setState({
        fieldsErrors: { inf_country: "* Select country!" },
        formValid: false
      });
    } else if (inf_state === "") {
      this.setState({
        fieldsErrors: { inf_state: "* State is Required!" },
        formValid: false
      });
    } else if (inf_city === "") {
      this.setState({
        fieldsErrors: { inf_city: "* City is Required!" },
        formValid: false
      });
    }
    else if (i_followers_insta === "")
      this.setState({
        fieldsErrors: {
          i_followers_insta: "* Instagram account follower is Required!"
        },
        formValid: false
      });
    else if (i_followers_fb === "" && i_link_fb !== "")
      this.setState({
        fieldsErrors: {
          i_followers_fb: "* Facebook account follower is Required!"
        },
        formValid: false
      });
    else if (i_followers_twitter === "" && i_link_twitter !== "")
      this.setState({
        fieldsErrors: {
          i_followers_twitter: "* Twitter account follower is Required!"
        },
        formValid: false
      });
    else if (i_followers_yt === "" && i_link_yt !== "")
      this.setState({
        fieldsErrors: {
          i_followers_yt: "* Youtube account follower is Required!"
        },
        formValid: false
      });
    else {
      this.setState(
        {
          formValid: true
        },
        () => {
          this.props.action.AllAction.SaveInfluencerAction(data_influencer);
          //   this.props.onHideCat();
        }
      );
    }
  };
  handleShow = () => {
    this.setState({
      show__reg: true
    });
  };
  renderTooltip = props => {
    return <Tooltip {...props}>{props}</Tooltip>;
  };

  onChangeCat = (value, key) => {
    this.setState({
      [key]: value
    }, () => {
      this.setState({
        fieldsErrors: { [key]: "" }
      })
    })
  };
  render() {
    const img_dir = "secure/appmixo/winning_product/static/";
    var env_path =
      this.props.load_env &&
      this.props.env &&
      this.props.env.CDN_URL !== "" &&
      this.props.env.CDN_URL + img_dir;
    var influencer1 = env_path + "strategy.png";
    var influencer2 = env_path + "trust.png";
    var influencer3 = env_path + "collaboration.png";
    var influencer4 = env_path + "Increases.png";
    var influencermain = env_path + "influ-main.jpg";
    return (
      <div className="root-page">
        <NavbarHeader />
        <div className="main-container sup-holder">
          <Header header_name="Winning Products" history={this.props.history} />
          <div className="section-head">
            <h4 className="section-title">Influencer</h4>
            <div className="influ-info">
              {/* <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Comming soon")}
              > */}
              <button className="find-influ btn btn-primary mt-0 mr-3" onClick={() => this.props.history && this.props.history.push('/influencers/list')}>
                Find Influencer near you
                </button>
              {/* </OverlayTrigger> */}
              {this.props.load_cat && <button
                onClick={() => this.handleShow()}
                className="btn btn-primary">
                Register As Influencer
              </button>}
            </div>
          </div>
          <div className="influ-txt-container mb-5">
            <h1 className="influ-head" style={{ color: "#1f3893" }}>
              Let's get{" "}
              <strong style={{ color: "#f6ab36" }}>Products Go Viral</strong>
            </h1>
            <p className="influ-pre">with your ideal influencers</p>
          </div>
          {this.props.load_env && (
            <div className="about-influ">
              <div className="ab-left">
                <h4 className="ab-title">
                  The benefits of working with influencers
                </h4>
                <p className="ab-pre">
                  Influencers are valuable because they offer instant access to
                  the credibility a growing product needs. 70% of millennials
                  are influenced by recommendations from their peers.
                  Influencers provide a connection point between products and
                  their target market.{" "}
                </p>
                <ul className="influ-list-group">
                  <li className="influ-list-item">
                    <div className="il-img-holder">
                      <img
                        src={influencer1}
                        height="180px"
                        width="180px"
                        alt=""
                      />
                    </div>
                    <div className="il-txt-holder">
                      <h3 className="il-title">Increases brand awareness</h3>
                      <p className="il-details">
                        Gain exposure to your influencer’s existing audience.
                        This market is already engaged and waiting for relevant
                        content.
                      </p>
                    </div>
                  </li>
                  <li className="influ-list-item">
                    <div className="il-img-holder">
                      <img
                        src={influencer2}
                        height="180px"
                        width="180px"
                        alt=""
                      />
                    </div>
                    <div className="il-txt-holder">
                      <h3 className="il-title">Builds trust</h3>
                      <p className="il-details">
                        Consumers already trust the opinions of the influencers
                        they follow. Influencers instantly boost the credibility
                        of your brand.
                      </p>
                    </div>
                  </li>
                  <li className="influ-list-item">
                    <div className="il-img-holder">
                      <img
                        src={influencer3}
                        height="180px"
                        width="180px"
                        alt=""
                      />
                    </div>
                    <div className="il-txt-holder">
                      <h3 className="il-title">
                        Improves customer relationships
                      </h3>
                      <p className="il-details">
                        Strengthen relationships with your customers through the
                        intimate existing connections influencers have with
                        them.
                      </p>
                    </div>
                  </li>
                  <li className="influ-list-item">
                    <div className="il-img-holder">
                      <img
                        src={influencer4}
                        height="180px"
                        width="180px"
                        alt=""
                      />
                    </div>
                    <div className="il-txt-holder">
                      <h3 className="il-title">Improves brand</h3>
                      <p className="il-details">
                        The positive connection influencers already have with
                        customers causes a “halo effect” which strengthens the
                        company in the eyes of the consumer.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="ab-right">
                <img src={influencermain} className="img-fluid" />
              </div>
            </div>
          )}
        </div>
        <Modal
          show={this.state.show__reg}
          onHide={this.handleClose}
          dialogClassName="extra-content-modal"
          className="ragister-influencer"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Register As Influencer</Modal.Title>
          </Modal.Header>
          {this.state.show__reg === true && this.state.formValid === false && (
            <>
              <Modal.Body>
                <div>
                  <form>
                    <FormGroup>
                      <FormLabel>Name *</FormLabel>
                      <FormControl
                        type="text"
                        placeholder="Enter name"
                        onChange={e => this.handleOnChange("i_name", e)}></FormControl>
                      <span style={{ color: "red" }}>{this.state.fieldsErrors.i_name}</span>
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>Email *</FormLabel>
                      <FormControl
                        type="email"
                        placeholder="Enter email"
                        onChange={e => this.handleOnChange("i_email", e)}></FormControl>
                      <span style={{ color: "red" }}>{this.state.fieldsErrors.i_email}
                      </span>
                    </FormGroup>
                    {/* <FormGroup>
                      <FormLabel>
                        Category *
                        <span>
                          {" "}
                          ( Add more than 1 category separated by comma ( , ) )
                        </span>
                      </FormLabel>
                      <FormControl
                        as="textarea"
                        placeholder="Enter category"
                        onChange={e => this.handleOnChange("i_cat", e)}
                      ></FormControl>
                      <span style={{ color: "red" }}>
                        {this.state.fieldsErrors.i_cat}
                      </span>
                    </FormGroup> */}
                    <FormGroup>
                      <FormLabel>Category *</FormLabel>
                      <div className="input-cat-supplier">
                        <Select
                          options={this.props.get_categories}
                          onChange={e => this.onChangeCat(e, "selected_cat")}
                          isMulti={true}
                        />
                      </div>
                      <span style={{ color: "red" }}>{this.state.fieldsErrors.selected_cat}</span>
                    </FormGroup>
                    <FormGroup>
                      <div>Link Account *</div>
                      <div className="social-acc">
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <TiSocialInstagram />
                            </FormLabel>
                            <FormControl
                              type="text"
                              placeholder="Instagram userID"
                              onChange={e =>
                                this.handleOnChange("i_link_insta", e)
                              }
                            ></FormControl>
                          </div>
                          <span className="social-eg">e.g. hello_11</span>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.i_link_insta}
                          </span>
                        </div>
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <FaYoutube />
                            </FormLabel>
                            <FormControl
                              type="text"
                              placeholder="Youtube channel link"
                              onChange={e => this.handleOnChange("i_link_yt", e)}
                            ></FormControl>
                          </div>
                          <span className="social-eg">e.g. www.youtube.com/channel/hiExample</span>
                        </div>
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <FaTwitter />
                            </FormLabel>
                            <FormControl
                              type="text"
                              placeholder="Twitter userID"
                              onChange={e =>
                                this.handleOnChange("i_link_twitter", e)
                              }
                            ></FormControl>
                          </div>
                          <span className="social-eg">e.g. twitter.17</span>
                        </div>
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <FaFacebook />
                            </FormLabel>
                            <FormControl
                              type="text"
                              placeholder="FB URL"
                              onChange={e => this.handleOnChange("i_link_fb", e)}
                            ></FormControl>
                          </div>
                          <span className="social-eg">e.g. www.facebook.com/hi.example</span>
                        </div>
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <span className="charge">
                        <FormLabel>Charge</FormLabel>
                        <FormControl
                          type="text"
                          placeholder="Enter charge "
                          onChange={e => this.handleOnChange("i_charge", e)}
                        ></FormControl>
                      </span>
                    </FormGroup>
                    <div>
                      <FormLabel>Location *</FormLabel>
                      <FormGroup>
                        <div className="is-address">
                          <div className="is-address-inner">
                            <FormControl
                              as="select"
                              componentClass="select"
                              onChange={e => this.handleOnChange("inf_country", e)}>
                              <option value="Afganistan">Afghanistan</option>
                              <option value="Albania">Albania</option>
                              <option value="Algeria">Algeria</option>
                              <option value="American Samoa">American Samoa</option>
                              <option value="Andorra">Andorra</option>
                              <option value="Angola">Angola</option>
                              <option value="Anguilla">Anguilla</option>
                              <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                              <option value="Argentina">Argentina</option>
                              <option value="Armenia">Armenia</option>
                              <option value="Aruba">Aruba</option>
                              <option value="Australia">Australia</option>
                              <option value="Austria">Austria</option>
                              <option value="Azerbaijan">Azerbaijan</option>
                              <option value="Bahamas">Bahamas</option>
                              <option value="Bahrain">Bahrain</option>
                              <option value="Bangladesh">Bangladesh</option>
                              <option value="Barbados">Barbados</option>
                              <option value="Belarus">Belarus</option>
                              <option value="Belgium">Belgium</option>
                              <option value="Belize">Belize</option>
                              <option value="Benin">Benin</option>
                              <option value="Bermuda">Bermuda</option>
                              <option value="Bhutan">Bhutan</option>
                              <option value="Bolivia">Bolivia</option>
                              <option value="Bonaire">Bonaire</option>
                              <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                              <option value="Botswana">Botswana</option>
                              <option value="Brazil">Brazil</option>
                              <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                              <option value="Brunei">Brunei</option>
                              <option value="Bulgaria">Bulgaria</option>
                              <option value="Burkina Faso">Burkina Faso</option>
                              <option value="Burundi">Burundi</option>
                              <option value="Cambodia">Cambodia</option>
                              <option value="Cameroon">Cameroon</option>
                              <option value="Canada">Canada</option>
                              <option value="Canary Islands">Canary Islands</option>
                              <option value="Cape Verde">Cape Verde</option>
                              <option value="Cayman Islands">Cayman Islands</option>
                              <option value="Central African Republic">Central African Republic</option>
                              <option value="Chad">Chad</option>
                              <option value="Channel Islands">Channel Islands</option>
                              <option value="Chile">Chile</option>
                              <option value="China">China</option>
                              <option value="Christmas Island">Christmas Island</option>
                              <option value="Cocos Island">Cocos Island</option>
                              <option value="Colombia">Colombia</option>
                              <option value="Comoros">Comoros</option>
                              <option value="Congo">Congo</option>
                              <option value="Cook Islands">Cook Islands</option>
                              <option value="Costa Rica">Costa Rica</option>
                              <option value="Cote DIvoire">Cote DIvoire</option>
                              <option value="Croatia">Croatia</option>
                              <option value="Cuba">Cuba</option>
                              <option value="Curaco">Curacao</option>
                              <option value="Cyprus">Cyprus</option>
                              <option value="Czech Republic">Czech Republic</option>
                              <option value="Denmark">Denmark</option>
                              <option value="Djibouti">Djibouti</option>
                              <option value="Dominica">Dominica</option>
                              <option value="Dominican Republic">Dominican Republic</option>
                              <option value="East Timor">East Timor</option>
                              <option value="Ecuador">Ecuador</option>
                              <option value="Egypt">Egypt</option>
                              <option value="El Salvador">El Salvador</option>
                              <option value="Equatorial Guinea">Equatorial Guinea</option>
                              <option value="Eritrea">Eritrea</option>
                              <option value="Estonia">Estonia</option>
                              <option value="Ethiopia">Ethiopia</option>
                              <option value="Falkland Islands">Falkland Islands</option>
                              <option value="Faroe Islands">Faroe Islands</option>
                              <option value="Fiji">Fiji</option>
                              <option value="Finland">Finland</option>
                              <option value="France">France</option>
                              <option value="French Guiana">French Guiana</option>
                              <option value="French Polynesia">French Polynesia</option>
                              <option value="French Southern Ter">French Southern Ter</option>
                              <option value="Gabon">Gabon</option>
                              <option value="Gambia">Gambia</option>
                              <option value="Georgia">Georgia</option>
                              <option value="Germany">Germany</option>
                              <option value="Ghana">Ghana</option>
                              <option value="Gibraltar">Gibraltar</option>
                              <option value="Great Britain">Great Britain</option>
                              <option value="Greece">Greece</option>
                              <option value="Greenland">Greenland</option>
                              <option value="Grenada">Grenada</option>
                              <option value="Guadeloupe">Guadeloupe</option>
                              <option value="Guam">Guam</option>
                              <option value="Guatemala">Guatemala</option>
                              <option value="Guinea">Guinea</option>
                              <option value="Guyana">Guyana</option>
                              <option value="Haiti">Haiti</option>
                              <option value="Hawaii">Hawaii</option>
                              <option value="Honduras">Honduras</option>
                              <option value="Hong Kong">Hong Kong</option>
                              <option value="Hungary">Hungary</option>
                              <option value="Iceland">Iceland</option>
                              <option value="Indonesia">Indonesia</option>
                              <option value="India">India</option>
                              <option value="Iran">Iran</option>
                              <option value="Iraq">Iraq</option>
                              <option value="Ireland">Ireland</option>
                              <option value="Isle of Man">Isle of Man</option>
                              <option value="Israel">Israel</option>
                              <option value="Italy">Italy</option>
                              <option value="Jamaica">Jamaica</option>
                              <option value="Japan">Japan</option>
                              <option value="Jordan">Jordan</option>
                              <option value="Kazakhstan">Kazakhstan</option>
                              <option value="Kenya">Kenya</option>
                              <option value="Kiribati">Kiribati</option>
                              <option value="Korea North">Korea North</option>
                              <option value="Korea Sout">Korea South</option>
                              <option value="Kuwait">Kuwait</option>
                              <option value="Kyrgyzstan">Kyrgyzstan</option>
                              <option value="Laos">Laos</option>
                              <option value="Latvia">Latvia</option>
                              <option value="Lebanon">Lebanon</option>
                              <option value="Lesotho">Lesotho</option>
                              <option value="Liberia">Liberia</option>
                              <option value="Libya">Libya</option>
                              <option value="Liechtenstein">Liechtenstein</option>
                              <option value="Lithuania">Lithuania</option>
                              <option value="Luxembourg">Luxembourg</option>
                              <option value="Macau">Macau</option>
                              <option value="Macedonia">Macedonia</option>
                              <option value="Madagascar">Madagascar</option>
                              <option value="Malaysia">Malaysia</option>
                              <option value="Malawi">Malawi</option>
                              <option value="Maldives">Maldives</option>
                              <option value="Mali">Mali</option>
                              <option value="Malta">Malta</option>
                              <option value="Marshall Islands">Marshall Islands</option>
                              <option value="Martinique">Martinique</option>
                              <option value="Mauritania">Mauritania</option>
                              <option value="Mauritius">Mauritius</option>
                              <option value="Mayotte">Mayotte</option>
                              <option value="Mexico">Mexico</option>
                              <option value="Midway Islands">Midway Islands</option>
                              <option value="Moldova">Moldova</option>
                              <option value="Monaco">Monaco</option>
                              <option value="Mongolia">Mongolia</option>
                              <option value="Montserrat">Montserrat</option>
                              <option value="Morocco">Morocco</option>
                              <option value="Mozambique">Mozambique</option>
                              <option value="Myanmar">Myanmar</option>
                              <option value="Nambia">Nambia</option>
                              <option value="Nauru">Nauru</option>
                              <option value="Nepal">Nepal</option>
                              <option value="Netherland Antilles">Netherland Antilles</option>
                              <option value="Netherlands">Netherlands (Holland, Europe)</option>
                              <option value="Nevis">Nevis</option>
                              <option value="New Caledonia">New Caledonia</option>
                              <option value="New Zealand">New Zealand</option>
                              <option value="Nicaragua">Nicaragua</option>
                              <option value="Niger">Niger</option>
                              <option value="Nigeria">Nigeria</option>
                              <option value="Niue">Niue</option>
                              <option value="Norfolk Island">Norfolk Island</option>
                              <option value="Norway">Norway</option>
                              <option value="Oman">Oman</option>
                              <option value="Pakistan">Pakistan</option>
                              <option value="Palau Island">Palau Island</option>
                              <option value="Palestine">Palestine</option>
                              <option value="Panama">Panama</option>
                              <option value="Papua New Guinea">Papua New Guinea</option>
                              <option value="Paraguay">Paraguay</option>
                              <option value="Peru">Peru</option>
                              <option value="Phillipines">Philippines</option>
                              <option value="Pitcairn Island">Pitcairn Island</option>
                              <option value="Poland">Poland</option>
                              <option value="Portugal">Portugal</option>
                              <option value="Puerto Rico">Puerto Rico</option>
                              <option value="Qatar">Qatar</option>
                              <option value="Republic of Montenegro">Republic of Montenegro</option>
                              <option value="Republic of Serbia">Republic of Serbia</option>
                              <option value="Reunion">Reunion</option>
                              <option value="Romania">Romania</option>
                              <option value="Russia">Russia</option>
                              <option value="Rwanda">Rwanda</option>
                              <option value="St Barthelemy">St Barthelemy</option>
                              <option value="St Eustatius">St Eustatius</option>
                              <option value="St Helena">St Helena</option>
                              <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                              <option value="St Lucia">St Lucia</option>
                              <option value="St Maarten">St Maarten</option>
                              <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                              <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                              <option value="Saipan">Saipan</option>
                              <option value="Samoa">Samoa</option>
                              <option value="Samoa American">Samoa American</option>
                              <option value="San Marino">San Marino</option>
                              <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                              <option value="Saudi Arabia">Saudi Arabia</option>
                              <option value="Senegal">Senegal</option>
                              <option value="Seychelles">Seychelles</option>
                              <option value="Sierra Leone">Sierra Leone</option>
                              <option value="Singapore">Singapore</option>
                              <option value="Slovakia">Slovakia</option>
                              <option value="Slovenia">Slovenia</option>
                              <option value="Solomon Islands">Solomon Islands</option>
                              <option value="Somalia">Somalia</option>
                              <option value="South Africa">South Africa</option>
                              <option value="Spain">Spain</option>
                              <option value="Sri Lanka">Sri Lanka</option>
                              <option value="Sudan">Sudan</option>
                              <option value="Suriname">Suriname</option>
                              <option value="Swaziland">Swaziland</option>
                              <option value="Sweden">Sweden</option>
                              <option value="Switzerland">Switzerland</option>
                              <option value="Syria">Syria</option>
                              <option value="Tahiti">Tahiti</option>
                              <option value="Taiwan">Taiwan</option>
                              <option value="Tajikistan">Tajikistan</option>
                              <option value="Tanzania">Tanzania</option>
                              <option value="Thailand">Thailand</option>
                              <option value="Togo">Togo</option>
                              <option value="Tokelau">Tokelau</option>
                              <option value="Tonga">Tonga</option>
                              <option value="Trinidad & Tobago">Trinidad&Tobago</option>
                              <option value="Tunisia">Tunisia</option>
                              <option value="Turkey">Turkey</option>
                              <option value="Turkmenistan">Turkmenistan</option>
                              <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                              <option value="Tuvalu">Tuvalu</option>
                              <option value="Uganda">Uganda</option>
                              <option value="United Kingdom">United Kingdom</option>
                              <option value="Ukraine">Ukraine</option>
                              <option value="United Arab Erimates">United Arab Emirates</option>
                              <option value="United States of America">United States of America</option>
                              <option value="Uraguay">Uruguay</option>
                              <option value="Uzbekistan">Uzbekistan</option>
                              <option value="Vanuatu">Vanuatu</option>
                              <option value="Vatican City State">Vatican City State</option>
                              <option value="Venezuela">Venezuela</option>
                              <option value="Vietnam">Vietnam</option>
                              <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                              <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                              <option value="Wake Island">Wake Island</option>
                              <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                              <option value="Yemen">Yemen</option>
                              <option value="Zaire">Zaire</option>
                              <option value="Zambia">Zambia</option>
                              <option value="Zimbabwe">Zimbabwe</option>
                            </FormControl>
                            <span style={{ color: "red" }}>
                              {this.state.fieldsErrors.inf_country}
                            </span>
                          </div>
                          <div className="is-address-inner">
                            <FormControl
                              type="text"
                              placeholder="Enter state"
                              onChange={e => this.handleOnChange("inf_state", e)}
                            ></FormControl>
                            <span style={{ color: "red" }}>
                              {this.state.fieldsErrors.inf_state}
                            </span>
                          </div>
                          <div className="is-address-inner">
                            <FormControl
                              type="text"
                              placeholder="Enter city"
                              onChange={e => this.handleOnChange("inf_city", e)}
                            ></FormControl>
                            <span style={{ color: "red" }}>
                              {this.state.fieldsErrors.inf_city}
                            </span>
                          </div>
                        </div>
                      </FormGroup>
                    </div>
                    <FormGroup>
                      <div>Followers *</div>
                      <div className="social-acc-followers">
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <TiSocialInstagram />{" "}
                            </FormLabel>
                            <FormControl
                              type="number"
                              placeholder="Instagram Followers"
                              onChange={e =>
                                this.handleOnChange("i_followers_insta", e)
                              }
                            ></FormControl>
                          </div>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.i_followers_insta}
                          </span>
                        </div>
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <FaYoutube />
                            </FormLabel>
                            <FormControl
                              type="number"
                              placeholder="Youtube Followers"
                              onChange={e =>
                                this.handleOnChange("i_followers_yt", e)
                              }
                            ></FormControl>
                          </div>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.i_followers_yt}
                          </span>
                        </div>
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <FaTwitter />
                            </FormLabel>
                            <FormControl
                              type="number"
                              placeholder="Twitter Followers"
                              onChange={e =>
                                this.handleOnChange("i_followers_twitter", e)
                              }
                            ></FormControl>
                          </div>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.i_followers_twitter}
                          </span>
                        </div>
                        <div className="m-social-group-item">
                          <div className='in-social'>
                            <FormLabel>
                              <FaFacebook />
                            </FormLabel>
                            <FormControl
                              type="number"
                              placeholder="Fb Followers"
                              onChange={e =>
                                this.handleOnChange("i_followers_fb", e)
                              }
                            ></FormControl>
                          </div>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.i_followers_fb}
                          </span>
                        </div>
                      </div>
                    </FormGroup>
                  </form>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={() => this.handleRegInfluencer()}>Save</Button>
                <Button onClick={this.handleClose} variant="danger">
                  Close
                </Button>
              </Modal.Footer>
            </>
          )}
          {this.state.submit === true && this.state.formValid === true && (
            <>
              <Modal.Body>
                <div>
                  Thank you for your registration. We will contact you soon.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose} variant="danger">
                  Close
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    env: state.AllReducer.env.data,
    load_env: state.AllReducer.env.load_env,
    get_categories: state.AllReducer.get_categories.data,
    load_cat: state.AllReducer.get_categories.load_cat,
  };
};
const mapDispatchToProps = dispatch => ({
  action: {
    AllAction: bindActionCreators(AllAction, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Influcer);
