import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import Home from "./component/Home";
import Products from "./component/Products";
// import InstallPage from "./component/InstallPage";
import PrivateRouter from "./component/PrivateRouter";
import SingleProduct from "./component/SingleProduct";
import GetSupport from "./component/GetSupport";
import Help from "./component/Help";
import Account from './component/Account';
import PageNotFound from "./component/PageNotFound";
import PrivacyPolicy from "./component/PrivacyPolicy";
import Influcer from "./component/Influcer";
import Supplier from "./component/Supplier";
import HomeSingleProduct from "./component/HomeSingleProduct";
import InfLIst from "./component/InfLIst";
import SupplierList from "./component/SupplierList";
import * as AllAction from '../src/Action/AllAction';
import { connect } from 'react-redux';
import Messages from "./component/Messages/Messages";
import BillPayments from "./component/BillPayments";
import "./component/CSS/Styles.css";
import "./App.css";
import ForgotPwd from "./component/ForgotPwd";
import SupPlans from "./component/SupPlans";
import SignupSetPwd from "./component/SignupSetPwd";

class App extends Component {

  componentWillMount() {

    this.props.action.AllAction.getCountryAction();

    const script = document.createElement("script");
    if (this.props.location.pathname.includes("/messages") === false) {
      script.src = "//code.tidio.co/gukvhuwvcxago94nbe8gyfhkdp8fkis6.js";
      script.id = "tidio1"
      document.body.appendChild(script);
    }
  }

  componentDidUpdate(prevProps) {

    if (prevProps.location.pathname !== this.props.location.pathname) {

      var elem = document.getElementById("tidio-chat");

      if (this.props.location.pathname.includes("/messages") === true) {
        if (elem) {
          elem.style["display"] = "none";
        }
      } else {
        if (elem) {
          elem.style["display"] = "block";
        } else {
          const script = document.createElement("script");
          script.src = "//code.tidio.co/gukvhuwvcxago94nbe8gyfhkdp8fkis6.js";
          script.id = "tidio1"
          document.body.appendChild(script);
        }
      }
    }
  }

  render() {
    var hostname = window.location.hostname;

    return (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/home/:id" component={HomeSingleProduct} />
        <PrivateRouter path="/products" component={Products} />
        <PrivateRouter path="/product/:id" component={SingleProduct} />
        <PrivateRouter path="/getsupport" component={GetSupport} />
        <PrivateRouter path="/helps" component={Help} />
        <PrivateRouter path="/influencer" component={Influcer} />
        <PrivateRouter path="/influencers/list" component={InfLIst} />
        <PrivateRouter path="/supplier" component={Supplier} />
        <PrivateRouter path="/messages" component={Messages} />
        <PrivateRouter path="/suppliers/list" component={SupplierList} />
        <PrivateRouter path="/bill-payments" component={BillPayments} />
        {/* <Route path="/installpage" component={InstallPage} /> */}
        <PrivateRouter path='/account' component={Account} />
        <PrivateRouter path='/supplier-plans' component={hostname !== "live.appmixo.com" ? SupPlans : PageNotFound} />
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/createnewpass" component={ForgotPwd} />
        <Route path="/set_password" component={SignupSetPwd} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    env: state.AllReducer.env.data
  }
};
const mapDispatchToProps = (dispatch) => ({
  action: {
    AllAction: bindActionCreators(AllAction, dispatch)
  }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));