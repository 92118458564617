import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as InstallAction from '../Action/installAction';
import * as AllAction from '../Action/AllAction';
import { withRouter } from "react-router";
import Help from './Help';
import Home from './Home';
import GetSupport from './GetSupport';
import SingleProduct from './SingleProduct';
import Products from './Products';
// import InstallPage from './InstallPage';
import Influcer from './Influcer';
import Supplier from './Supplier';
import Account from './Account';
import InfLIst from './InfLIst';
import SupplierList from './SupplierList';

// import OrderResponse from './OrderResponse';

class Loder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            store_match: { data: "no data" },
            on_refresh_data: { data: "no data" },
            valid: "",
            env: {}
        }
    }
    componentWillMount() {
        const shop = localStorage.getItem("shop_wp");
        this.props.action.InstallAction.getTokenOnRefreshPage(shop);
    }
    componentDidUpdate(prevProps) {
        if ((this.props.on_refresh_data !== prevProps.on_refresh_data)) {
            this.setState({
                on_refresh_data: this.props.on_refresh_data
            }, () => {
                this.validOnRefreshPAge();
            })
        }
    }

    validOnRefreshPAge = () => {
        if (localStorage.getItem("token_wp") === ("" || undefined) && (localStorage.getItem("shop_wp") === ("" || undefined))) {
            this.setState({
                valid: false
            })
            this.props.history.push('/')
        }
        else {
            const token = localStorage.getItem("token_wp");
            const shop = localStorage.getItem("shop_wp");

            // if (localStorage.getItem("user_from") !== "website") {
                const matchtoken = this.props.on_refresh_data.token
                const matchshop = this.props.on_refresh_data.shop
                if (token !== matchtoken || shop !== matchshop) {
                    this.props.history.push('/');
                    this.setState({
                        valid: false
                    })
                }
                else {
                    this.setState({
                        valid: true
                    })
                }
            // }
            // else {
            //     this.setState({
            //         valid: true
            //     })
            // }

        }
    }

    render() {
        const token = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        if ((token === "") || (token === undefined) || (token === null) || (shop === "") || (shop === undefined) || (shop === null)) {
            this.props.history.push('/');
        }
        return (
            <div>
                {this.props.location.pathname === "/products" && this.state.valid === true && <Products />}
                {this.props.location.pathname === "/helps" && this.state.valid === true && <Help />}
                {this.props.location.pathname === "/getsupport" && this.state.valid === true && <GetSupport />}
                {this.props.location.pathname === "/" && <Home />}
                {this.props.location.pathname === "/account" && this.state.valid === true && <Account />}
                {this.props.location.pathname.match(/product\//g) && this.state.valid === true && <SingleProduct />}
                {this.props.location.pathname === "/supplier" && this.state.valid === true && <Supplier />}
                {this.props.location.pathname === "/influencer" && this.state.valid === true && <Influcer />}
                {this.props.location.pathname === "/influencers/list" && this.state.valid === true && <InfLIst />}
                {this.props.location.pathname === "/suppliers/list" && this.state.valid === true && <SupplierList />}
                {this.props.location.pathname === "/bill-payments" && this.state.valid === true && <SupplierList />}

                {/* {this.props.location.pathname === "/order-response" && this.state.valid === true && <OrderResponse />} */}
                {/* {this.state.valid === false && <InstallPage />} */}
                {/* {this.props.location.pathname === "/installPage" && <InstallPage />} */}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        store_data: state.InstallReducer.store_data,
        store_match: state.InstallReducer.store_match,
        on_refresh_data: state.InstallReducer.on_refresh_data,
        env: state.AllReducer.env.data
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        InstallAction: bindActionCreators(InstallAction, dispatch),
        AllAction: bindActionCreators(AllAction, dispatch),

    }
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Loder));