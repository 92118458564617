import React, { Component } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import * as AllAction from '../Action/AllAction';
import { FaSpinner } from "react-icons/fa";

class RefEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            u_name: "",
            u_email: "",
            email1: "",
            email2: "",
            email3: "",
            email4: "",
            email5: "",
            fieldsErrors: {
                u_name: "",
                u_email: "",
                email1: "",
                email2: "",
                email3: "",
                email4: "",
                email5: ""
            },
            ref_code_step: 1,
            gen_code_click: false
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.showRefEmail !== prevProps.showRefEmail) {
            if (this.props.showRefEmail === false) {
                this.setState({
                    ref_code_step: 1,
                    gen_code_click: false,
                    u_name: "",
                    u_email: "",
                    email1: "",
                    email2: "",
                    email3: "",
                    email4: "",
                    email5: "",
                    fieldsErrors: {
                        u_name: "",
                        u_email: "",
                        email1: "",
                        email2: "",
                        email3: "",
                        email4: "",
                        email5: ""
                    }
                })
            } else {
                const shop = localStorage.getItem("shop_wp");
                this.setState({
                    u_email: shop
                })
            }
        }
    }
    componentWillMount() {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from");
        const data = {
            accessToken, user_from, shop
        }
        this.props.action.AllAction.getRefCodeAction(data);

    }
    handleSubmitEmails = () => {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from");
        var { u_name, u_email, email1, email2, email3, email4, email5 } = this.state;
        var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,15}$/;
        var u_email = u_email.toLowerCase();
        var email1 = email1.toLowerCase();
        var email2 = email2.toLowerCase();
        var email3 = email3.toLowerCase();
        var email4 = email4.toLowerCase();
        var email5 = email5.toLowerCase();
        if (u_email === "") {
            this.setState({
                u_email: this.props.data.email
            })
        }
        if (u_name === "") {
            this.setState({
                fieldsErrors: { u_name: "Enter your name!" }
            })
        }

        else if (u_email !== "" && pattern.test(u_email) === false) {
            this.setState({
                fieldsErrors: { u_email: "Invalid Email-ID!" }
            })
        }

        else if (email1 === "") {
            this.setState({
                fieldsErrors: { email1: "Enter Email-ID!" }
            })
        }
        else if (pattern.test(email1) === false) {
            this.setState({
                fieldsErrors: { email1: "Invalid Email-ID!" }
            })
        }

        else if (email1 === shop) {
            this.setState({
                fieldsErrors: { email1: "You can not enter your own Email-ID!" }
            })
        }
        else if (email2 === "") {
            this.setState({
                fieldsErrors: { email2: "Enter Email-ID!" }
            })
        }
        else if (pattern.test(email2) === false) {
            this.setState({
                fieldsErrors: { email2: "Invalid Email-ID!" }
            })
        }

        else if (email2 === shop) {
            this.setState({
                fieldsErrors: { email2: "You can not enter your own Email-ID!" }
            })
        }
        else if (email3 === "") {
            this.setState({
                fieldsErrors: { email3: "Enter Email-ID!" }
            })
        }
        else if (pattern.test(email3) === false) {
            this.setState({
                fieldsErrors: { email3: "Invalid Email-ID!" }
            })
        }
        else if (email3 === shop) {
            this.setState({
                fieldsErrors: { email3: "You can not enter your own Email-ID!" }
            })
        }
        else if (email4 === "") {
            this.setState({
                fieldsErrors: { email4: "Enter Email-ID!" }
            })
        }
        else if (pattern.test(email4) === false) {
            this.setState({
                fieldsErrors: { email4: "Invalid Email-ID!" }
            })
        }

        else if (email4 === shop) {
            this.setState({
                fieldsErrors: { email4: "You can not enter your own Email-ID!" }
            })
        }
        else if (email5 === "") {
            this.setState({
                fieldsErrors: { email5: "Enter Email-ID!" }
            })
        }
        else if (pattern.test(email5) === false) {
            this.setState({
                fieldsErrors: { email5: "Invalid Email-ID!" }
            })
        }
        else if (email5 === shop) {
            this.setState({
                fieldsErrors: { email5: "You can not enter your own Email-ID!" }
            })
        }
        else if (email5 === email1 || email5 === email2 || email5 === email3 || email5 === email4) {
            this.setState({
                fieldsErrors: { email5: "You can not repeat Email-ID!" }
            })
        }
        else if (email4 === email1 || email4 === email2 || email4 === email3 || email4 === email5) {
            this.setState({
                fieldsErrors: { email4: "You can not repeat Email-ID!" }
            })
        }
        else if (email3 === email1 || email3 === email2 || email3 === email4 || email3 === email5) {
            this.setState({
                fieldsErrors: { email3: "You can not repeat Email-ID!" }
            })
        }
        else if (email2 === email1 || email2 === email3 || email2 === email4 || email2 === email5) {
            this.setState({
                fieldsErrors: { email2: "You can not repeat Email-ID!" }
            })
        }
        else if (email1 === email2 || email1 === email3 || email1 === email4 || email1 === email5) {
            this.setState({
                fieldsErrors: { email1: "You can not repeat Email-ID!" }
            })
        }

        else {
            var f_emails = [email1, email2, email3, email4, email5]
            var data = {
                accessToken, shop, user_from, u_name, u_email: u_email !== "" ? u_email : this.props.email, f_emails
            }
            this.props.action.AllAction.submitFriendEmailsAction(data);
            this.props.handleCloseRefEmail();
        }
    }

    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value
        }, () => {
            this.setState({
                fieldsErrors: { [key]: "" }
            })
        })
    }

    goToNextStep = (no) => {
        this.setState({
            ref_code_step: no,
            fieldsErrors: {
                u_name: "",
                u_email: "",
                email1: "",
                email2: "",
                email3: "",
                email4: "",
                email5: ""
            }
        })
    }
    generateRefCode = () => {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from");
        const data = {
            accessToken, user_from, shop
        }
        this.props.action.AllAction.generateRefCodeAction(data);
        this.setState({
            gen_code_click: true
        })
    }
    render() {
        const { ref_code_step } = this.state;
        var has_code = this.props.load_getrc && this.props.get_ref_code && this.props.get_ref_code.success === true ? true : false
        var code_class = has_code || (this.state.gen_code_click && this.props.load_grc === true && this.props.generate_ref_code && this.props.generate_ref_code.success) ? true : false
        return (
            <Modal show={this.props.showRefEmail} onHide={this.props.handleCloseRefEmail} className="ref-email">
                <Modal.Header closeButton>
                    <Modal.Title>Extend Trial Days</Modal.Title>
                </Modal.Header>

                {ref_code_step === 1 &&
                    <Modal.Body className="gen-code-body-container">
                        <div className="gen-code-container">
                            <div className="text-info-ref">Generate Referral code and Share it with your friends.Your trial periods will be extended when your friend add your referral code. (1 day / 1 Friend)</div>
                            <Button disabled={code_class} onClick={() => this.generateRefCode()} className={code_class && "disable-btn"}>Generate Referral Code</Button>
                            {/* <div class="next-step" onClick={() => this.goToNextStep()}>Next</div> */}
                            {this.state.gen_code_click && this.props.load_grc !== true &&
                                <span className="mar-code"><FaSpinner className="icon-spin" color="#888" height="18px" width="18px" /></span>}
                            {/* {this.props.load_grc === true && this.props.generate_ref_code && this.props.generate_ref_code.success &&
                                this.props.generate_ref_code.code && <div className="container-ref">
                                    <div className="sub-text-ref">Your Referral code is:</div>
                                    <div className="ref-code-display">{this.props.generate_ref_code.code.toUpperCase()}</div>
                                </div>} */}
                            {has_code &&
                                <div className="container-ref">
                                    <div className="sub-text-ref">Your Referral code is:</div>
                                    <div className="ref-code-display">{this.props.get_ref_code.code.toUpperCase()}</div>
                                </div>}
                        </div>
                    </Modal.Body >}
                {ref_code_step === 2 &&
                    <Modal.Body>
                        <Form.Group>
                            <span className="email-ref">{this.props.data && this.props.data.email}</span>
                            <Form.Label>Your Name:</Form.Label>
                            <Form.Control type="text" placeholder="Enter your name" onChange={(e) => this.handleOnChange("u_name", e)} value={this.state.u_name} />
                            <span className="err-val-msg">{this.state.fieldsErrors.u_name}</span>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Your Email-ID:</Form.Label>
                            <Form.Control type="email" placeholder="Enter your email-id" onChange={(e) => this.handleOnChange("u_email", e)} value={this.state.u_email} />
                            <span className="err-val-msg">{this.state.fieldsErrors.u_email}</span>
                        </Form.Group>
                        <Form.Group >
                            <Form.Label>Friend's Email-ID:</Form.Label>
                            <div className="f-emails">
                                <Form.Control type="email" placeholder="Enter friend's email-id" onChange={(e) => this.handleOnChange("email1", e)} value={this.state.email1} />
                                <span className="err-val-msg">{this.state.fieldsErrors.email1}</span>
                            </div>
                            <div className="f-emails">
                                <Form.Control type="email" placeholder="Enter friend's email-id" onChange={(e) => this.handleOnChange("email2", e)} value={this.state.email2} />
                                <span className="err-val-msg">{this.state.fieldsErrors.email2}</span>
                            </div>
                            <div className="f-emails">
                                <Form.Control type="email" placeholder="Enter friend's email-id" onChange={(e) => this.handleOnChange("email3", e)} value={this.state.email3} />
                                <span className="err-val-msg">{this.state.fieldsErrors.email3}</span>
                            </div>
                            <div className="f-emails">
                                <Form.Control type="email" placeholder="Enter friend's email-id" onChange={(e) => this.handleOnChange("email4", e)} value={this.state.email4} />
                                <span className="err-val-msg">{this.state.fieldsErrors.email4}</span>
                            </div>
                            <div className="f-emails">
                                <Form.Control type="email" placeholder="Enter friend's email-id" onChange={(e) => this.handleOnChange("email5", e)} value={this.state.email5} />
                                <span className="err-val-msg">{this.state.fieldsErrors.email5}</span>
                            </div>
                        </Form.Group>
                    </Modal.Body >}
                {ref_code_step === 1 && <Modal.Footer className="step-footer">
                    <Button className="next" onClick={() => this.goToNextStep(2)}>Next &raquo;</Button>
                </Modal.Footer>}
                {ref_code_step === 2 && <Modal.Footer className="step-footer">
                    <div className="prev-btns">
                        <div className="previous" onClick={() => this.goToNextStep(1)}>&laquo; Previous</div>
                    </div>
                    <div className="sub-close-step">
                        <Button variant="primary" onClick={() => this.handleSubmitEmails()}>
                            Send
                    </Button>
                    </div>
                </Modal.Footer>}
            </Modal >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        get_ref_code: state.AllReducer.get_ref_code.message,
        generate_ref_code: state.AllReducer.generate_ref_code.message,
        load_grc: state.AllReducer.generate_ref_code.load_grc,
        load_getrc: state.AllReducer.get_ref_code.load_getrc
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(RefEmail);