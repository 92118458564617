import React, { Component } from 'react';
import { FormControl, FormLabel, Button, FormGroup } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import * as AllAction from '../Action/AllAction';
var logo = require("../img/app-logo.png");

class SignupSetPwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_pwd: "",
            confirm_pwd: "",
            fieldsErrors: {
                new_pwd: "",
                confirm_pwd: "",
            }
        }
    }
    componentWillMount() {
        this.props.action.AllAction.getCountryAction();
        const env_data = {
            secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
        }
        this.props.action.AllAction.GetEnvAction(env_data);
    }
    submitChangePwd = () => {
        const { new_pwd, confirm_pwd } = this.state;
        if (new_pwd === "") {
            this.setState({
                fieldsErrors: { new_pwd: "Enter passsword!" }
            })
        }
        else if (new_pwd.length < 8) {
            this.setState({
                fieldsErrors: { new_pwd: "Password length is too short provide at least 8 characters!" }
            })
        }
        else if (confirm_pwd === "") {
            this.setState({
                fieldsErrors: { confirm_pwd: "Enter password again!" }
            })
        }
        else if (confirm_pwd !== new_pwd) {
            this.setState({
                fieldsErrors: { confirm_pwd: "Password did not match. Please try again..!" }
            })
        }
        else {
            var token = window.location.href.split('token=');
            var data = {
                token: token[1] !== undefined && token[1] !== null ? token[1] : "", new_password: new_pwd, confirm_password: confirm_pwd
            }
            token[1] !== undefined && token[1] !== null && this.props.action.AllAction.signupSetPasswordAction(data);
        }
    }
    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value,
        }, () => {
            this.setState({
                fieldsErrors: { [key]: "" },
            })
        })
    };
    render() {
        let country_value = localStorage.getItem("country_wp") && JSON.parse(localStorage.getItem("country_wp"));
        var token = window.location.href.split('token=');

        return (
            <div className="root-page-forgot-pwd">
                {
                    this.props.load_env &&
                    <div className="main-container">
                        <div className="container-header">
                            <span className="icon-logo">
                                <img src={logo} height="30px" width="30px" alt="NoLogo" />
                            </span>
                            <span className="header-logo" onClick={this.handleOnclickLogo}>
                                Winning Products for {country_value && country_value.Country_Name}
                                {this.props.load_env && country_value && <span className="cou-flag">
                                    <img src={this.props.env.CDN_URL + country_value.Country_Flag}></img></span>}
                            </span>
                        </div>
                        <div><img src={logo} alt="" className="logo-pwd" /></div>
                        {
                            token[1] !== undefined &&
                                token[1] !== null &&
                                window.location.href.includes("expired") === false ?
                                <div className="forgot-pwd-container">
                                    <div className="change-pwd-text">Set Password</div>
                                    <FormGroup >
                                        <FormLabel>Password</FormLabel>
                                        <FormControl type="password" placeholder="Enter password"
                                            onChange={(e) => this.handleOnChange("new_pwd", e)} />
                                        <span className="err-val-msg">{this.state.fieldsErrors.new_pwd}</span>
                                    </FormGroup>
                                    <FormGroup >
                                        <FormLabel>Confirm password</FormLabel>
                                        <FormControl type="password" placeholder="Enter password again"
                                            onChange={(e) => this.handleOnChange("confirm_pwd", e)} />
                                        <span className="err-val-msg">{this.state.fieldsErrors.confirm_pwd}</span>
                                    </FormGroup>
                                    <Button variant="primary" onClick={() => this.submitChangePwd()}>Submit</Button>
                                </div> :
                                <div className="class-expired">The Link You Followed Has Expired.{" "}
                                    <span className="try-again"
                                        onClick={() => window.open("/winningproducts", "_self")}>Please try again.</span>
                                </div>
                        }
                    </div>
                }
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        env: state.AllReducer.env.data,
        load_env: state.AllReducer.env.load_env,
    };
};
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(SignupSetPwd);