import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { FaSpinner } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { Button } from 'react-bootstrap';
import { IoMdCloseCircle } from "react-icons/io";
import NavbarHeader from './NavbarHeader';
import Header from './Header';
import RefEmail from './RefEmail';
import * as AllAction from '../Action/AllAction';
import ChangePwd from './ChangePwd';
import Loader from "react-loader";
import swal from 'sweetalert';
import moment from 'moment';
var paper = require("../img/paper-plane.png");
var plane = require("../img/plane.png")
class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop_data: [],
            loaded: false,
            env: {},
            upgrade_click: false,
            loaded_sub_plan: false,
            get_all_sub: [],
            show_change_pwd: false,
            shop_data: {},
            load_sd: false,
            plan_type: "",
            loaded_PT: false,
            showRefEmail: false
        }
    }
    componentWillMount() {
        this.setState({
            upgrade_click: false
        })
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from");
        const data = {
            accessToken, shop, tag: 'premium_plan'
        }
        this.props.action.AllAction.getSubscriptionPlanAction(data);
        const data_pay = {
            accessToken, shop
        }
        const data_btn = {
            accessToken, shop, user_from
        }
        this.props.action.AllAction.getAllSubAction(data_pay);
        this.props.action.AllAction.getUserPayTypeAction(data_pay);
        this.props.action.AllAction.getShopSubscriptionAction(data_pay);
        this.props.action.AllAction.isDisplayRefBtnAction(data_btn);
        this.props.action.AllAction.getStripCustomerMessagesAction(data_pay);
        this.props.action.AllAction.getRefCodeAction(data_btn);
    }
    componentDidUpdate(prevProps) {
        if (this.props.shop_data !== (prevProps.shop_data)) {
            this.setState({
                shop_data: this.props.shop_data,
            }, () => {
                this.setState({
                    loaded: true
                })
            })
        }

        if (JSON.stringify(this.state.get_all_sub) !== JSON.stringify(this.props.get_all_sub)) {
            if (this.props.load_sub === true) {
                this.setState({
                    get_all_sub: this.props.get_all_sub
                }, () => {
                    var supp_arr = this.props.get_all_sub && this.props.get_all_sub.length !== 0 ?
                        this.props.get_all_sub.filter((supp) => {
                            return supp.subtag === 'winning_products'
                        }) : []
                    var plan_type = supp_arr && supp_arr.length !== 0 && supp_arr[0] && supp_arr[0].shop_plan_name;
                    this.setState({ plan_type }, () => {
                        this.setState({
                            loaded_PT: true
                        })
                    });
                    const accessToken = localStorage.getItem("token_wp");
                    const shop = localStorage.getItem("shop_wp");
                    const data = {
                        accessToken, shop, plan_type
                    }
                    this.props.action.AllAction.GetShopDetailsAction(data);

                })
            }
        }
        if (JSON.stringify(this.state.shop_data) !== JSON.stringify(this.props.shop_data)) {
            this.setState({
                shop_data: this.props.shop_data,
                load_sd: true
            })
        }
    }
    handleDowngrade = () => {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        var { plan_type } = this.state;
        const data = {
            accessToken, shop, plan_type
        }
        this.props.action.AllAction.PlanDowngradeAction(data);
    }
    handleUpgrade = (upgrade, up_down, is_free) => {
        var subscriptionItemID = "";
        if (upgrade === "yes_premium") {
            var filter_data = this.props.load_shop_sub && this.props.get_shop_sub &&
                this.props.get_shop_sub.plan_data && this.props.get_shop_sub.plan_data.length !== 0 ?
                this.props.get_shop_sub.plan_data.filter((plan_data, i) => {
                    return plan_data.sub_tag === "winning_products" && plan_data.plan_tag === 'premium_plan'
                })
                : []
            subscriptionItemID = filter_data && filter_data.length !== 0 && filter_data[0].subscriptionItemID
        }
        this.setState({
            upgrade_click: true
        })
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from");
        var { plan_type } = this.state;
        var { load_scm, get_stripe_cus_msg } = this.props
        const data = {
            accessToken,
            shop,
            user_from,
            price: this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                && (this.props.get_sub_plan[0].country_wise_price[0].price),
            currency: this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                && (this.props.get_sub_plan[0].country_wise_price[0].Currency),
            plan_tag: "premium_plan",
            subscriptionItemID,
            is_basic_plan: is_free === "free" ? true : false,
            plan_type,
            plan_name: up_down === "down" ? "basic_plan" : "premium_plan",
            chargeFailureMessage: load_scm && get_stripe_cus_msg !== null && Array.isArray(get_stripe_cus_msg) && get_stripe_cus_msg.length !== 0 ? true : false
        };

        if (this.props.pay_type
            && (this.props.pay_type.user_from === "shopify")
            && (this.props.pay_type.payment_gateway
                && this.props.pay_type.payment_gateway.gateway_tag
                && this.props.pay_type.payment_gateway.gateway_tag === "shopify")) {
            var data_shopify = {
                accessToken,
                shop,
                plan_tag: "premium_plan"
            }
            if (up_down === 'up') {
                this.props.action.AllAction.PlanUpdradeShopifyAction(data_shopify);
            }
            else if (up_down === 'down') {
                this.handleDowngrade();
            }
        }
        else {
            const { plan, plan_shopify_id } = this.state.shop_data;
            if (plan === 'premium_plan' && plan_shopify_id !== null && user_from === 'shopify' && up_down === 'up') {
                var price = this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                    this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                    && (this.props.get_sub_plan[0].country_wise_price[0].price);
                var currency = this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                    this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                    && (this.props.get_sub_plan[0].country_wise_price[0].Currency);
                const datas2s = {
                    accessToken,
                    shop,
                    user_from,
                    price,
                    currency,
                    plan_tag: "premium_plan",
                    plan_shopify_id,
                    plan_type,
                    plan_name: up_down === "down" ? "basic_plan" : "premium_plan",
                    win_product_plan_tag: plan,
                    win_product_price: price
                };
                this.props.action.AllAction.planUpgradeShopifyToStripe(datas2s);
            }
            else if ((plan === 'premium_plan' && plan_shopify_id !== null && user_from === 'shopify' && up_down === 'down')) {
                this.handleDowngrade();
            }
            else {
                this.props.action.AllAction.PlanUpdradeAction(data);
            }
        }
    }
    showChangePwd = () => {
        this.setState({
            show_change_pwd: true
        })
    }
    closeChangePwd = () => {
        this.setState({
            show_change_pwd: false
        })
    }
    confirmBox = (upgrade, up_down, is_free) => {
        if (up_down === 'down') {
            swal({
                text: "Are you sure you want to update your subscription?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(value => {
                if (value) {
                    this.handleUpgrade(upgrade, up_down, is_free)
                }
            });
        }
        else {
            this.handleUpgrade(upgrade, up_down, is_free)
        }
    }
    handleShowRefEmail = () => {
        this.setState({
            showRefEmail: true
        });
    }
    handleCloseRefEmail = () => {
        this.setState({
            showRefEmail: false
        });
    }
    handleRefCode = () => {
        if (this.props.load_ref_btn && this.props.ref_btn.added_ref_code === true) {
            swal("It looks, You have already entered referral code " + this.props.ref_btn.code + " for the Shop : " + this.props.ref_btn.shop + "\nYou can generate and send your code to friends, though.")
        } else {
            this.props.action.AllAction.handleRefCodeSubmit();
        }
    }
    render() {
        var { get_stripe_cus_msg, load_scm } = this.props;
        var user = localStorage.getItem("user_from")
        var is_show_shop = localStorage.getItem("user_from") === "shopify" ? true : false
        const { shop, domain, email, store_plan, app_plan, trial_days } = this.state.shop_data
        var cw_price = this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
            this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
            && (this.props.get_sub_plan[0].country_wise_price[0].Currency_Symbol + this.props.get_sub_plan[0].country_wise_price[0].price);
        var upgrade_premium = app_plan === 'premium_plan' ? "yes_premium" : "not_premium";
        var ref_data = { email, shop };
        return (
            <div className="root-page">
                <NavbarHeader />
                <div className="main-container acc-plan">
                    <Header header_name="Winning Products" history={this.props.history} />
                    {this.state.load_sd && load_scm ?
                        <>
                            <div className="acc-info">
                                <div className="acc-info-container">
                                    <ul className="ul-acc-info account-details-list">
                                        {is_show_shop === true && <li>Store< span className="li-info-field">{shop}</span></li>}
                                        <li>Email-ID<span><span className="li-info-field">{email}</span>
                                            {is_show_shop === false &&
                                                <span className="change-pwd" onClick={() => this.showChangePwd()}>
                                                    Change password?
                                            </span>}
                                        </span></li>
                                    </ul>
                                    <ul className="ul-paln-info account-details-list">
                                        <li>Winning products plan<span className="li-info-field">{app_plan == "basic_plan" ? "Basic Plan" : app_plan == "premium_plan" ? "Premium Plan" : ""}</span></li>


                                        {app_plan === "basic_plan" && <li>Remaining trial days<span className="li-info-field">{trial_days}</span></li>}
                                        {
                                            // (window.location.hostname === "localhost" || window.location.hostname === "stage.appmixo.com" || window.location.hostname === "reena.appmixo.com") &&
                                            <span className="ref-code-container">
                                                {app_plan === "basic_plan" && user === "website" &&
                                                    <div className="ref-code-gen">
                                                        <Button className="share-btn" onClick={() => this.handleShowRefEmail()}>Extend trial days</Button>
                                                        {/* {this.props.load_getrc && this.props.get_ref_code && this.props.get_ref_code.success === true &&
                                                            <div className="container-ref-acc">
                                                                <span className="sub-text-ref-acc">Your Referral code is: </span>
                                                                <span className="ref-code-display-acc">{this.props.get_ref_code.code.toUpperCase()}</span>
                                                            </div>} */}
                                                    </div>}
                                                {user === "website" && <div className="single-btn"><Button onClick={() => this.handleRefCode()}>Enter friend's code</Button></div>}
                                            </span>}

                                    </ul>
                                </div>
                            </div>
                            <div className="background">
                                <div className="acc-info-container">
                                    <div className="panel pricing-table">
                                        <div className="pricing-plan">
                                            <img src={paper} alt="" className="pricing-img" />
                                            <h2 className="pricing-header">Basic Plan</h2>
                                            <ul className="pricing-features">
                                                <li className="pricing-features-item">All the features</li>
                                                <li className="pricing-features-item">7 days delayed products</li>
                                            </ul>
                                            <span className="pricing-price">Free</span>

                                            {/* block for Downgrade plan */}

                                            {this.props.load_sp && this.props.load_sub && this.state.loaded_PT ?
                                                <>
                                                    {app_plan === "premium_plan" &&
                                                        <div className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ? 'not-allow-btns' : "plan-btns"}>
                                                            <div
                                                                className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ?
                                                                    'pricing-button-down is-featured no-event' : "pricing-button-down is-featured"}
                                                                onClick={() => this.confirmBox('yes_premium', "down", 'free')}>
                                                                {" "}Downgrade{" "}
                                                                {this.state.upgrade_click && this.props.stripe_loaded === false && <span className="ats-icon">
                                                                    <FaSpinner className="icon-spin" color="#888" height="18px" width="18px" /></span>
                                                                }
                                                            </div>
                                                        </div>}
                                                    {app_plan === "basic_plan" && <div className="current-plan">Current Plan{" "}<span><GoVerified /></span></div>}</> :
                                                <FaSpinner className="icon-spin" color="#558cee" height="23px" width="23px" />}
                                        </div>
                                        <div className="pricing-plan">
                                            <img src={plane} alt="" className="pricing-img" />
                                            <h2 className="pricing-header">Premium Plan</h2>
                                            <ul className="pricing-features">
                                                <li className="pricing-features-item">All the features</li>
                                                <li className="pricing-features-item">Daily new products</li>
                                                <li className="pricing-features-item">All Links Unlocked</li>
                                            </ul>
                                            {this.props.load_sp && this.props.load_sub && this.state.loaded_PT ?
                                                <>
                                                    <>{app_plan === "basic_plan" && trial_days > 0 ?
                                                        <span className="trial-days">
                                                            {trial_days}{" "}{trial_days == 1 ? " day " : " days "}free
                                                            <div className="trial-days-price">Then {cw_price} per month</div>
                                                        </span>
                                                        : <span className="pricing-price">
                                                            {cw_price}
                                                            <span className="pricing-price-subtext">{" "}/ {" "}month</span>
                                                        </span>}</>

                                                    {/* block for updrade plan */}

                                                    {app_plan === "basic_plan" &&
                                                        <div className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ? 'not-allow-btns' : "plan-btns"}>
                                                            <div
                                                                className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ?
                                                                    'pricing-button is-featured no-event' : "pricing-button is-featured"}
                                                                onClick={() => this.confirmBox('not_premium', "up", 'no')}>{" "}Upgrade{" "}
                                                                {this.state.upgrade_click && this.props.stripe_loaded === false &&
                                                                    <span className="ats-icon">
                                                                        <FaSpinner className="icon-spin" color="#fff" height="23px" width="23px" /></span>
                                                                }
                                                            </div>
                                                        </div>}
                                                    {app_plan === "premium_plan" && <div className="current-plan">Current Plan{" "}<span><GoVerified /></span></div>}
                                                </> : <FaSpinner className="icon-spin" color="#558cee" height="23px" width="23px" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.load_shop_sub && this.props.next_billing_date &&
                                <div className="next-date-acc"> Your account is billed monthly and the next payment is due on{" "}
                                    <span className="due-date">{moment(this.props.next_billing_date).format("MMM DD, YYYY")}</span>.
                                    {" "}<span className="active-sub" onClick={() => this.props.history.push("/bill-payments")}>See active subscription</span></div>}
                            {load_scm && get_stripe_cus_msg !== null && get_stripe_cus_msg !== undefined && Array.isArray(get_stripe_cus_msg) && get_stripe_cus_msg.length !== 0
                                && <div className="card-no-fail"><span className="red-mark">
                                    <IoMdCloseCircle color="red" />
                                </span>{get_stripe_cus_msg[0].displayMessage}</div>}
                            <RefEmail
                                showRefEmail={this.state.showRefEmail}
                                handleCloseRefEmail={this.handleCloseRefEmail}
                                data={ref_data}
                            />
                        </> : <div className="loader-container">
                            <Loader
                                loaded={this.state.load_sd && load_scm}
                                lines={10}
                                length={5}
                                width={5}
                                radius={10}
                                corners={1}
                                rotate={0}
                                direction={1}
                                color="#1f3893"
                                speed={1}
                                trail={40}
                                shadow={false}
                                hwaccel={false}
                                className="spinner"
                                zIndex={2e9}
                                top="50%"
                                left="50%"
                                scale={1.0}
                                loadedClassName="loadedContent"
                            />
                        </div>
                    }
                </div>
                <ChangePwd show={this.state.show_change_pwd} onHide={() => this.closeChangePwd()} />
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        shop_data: state.AllReducer.shop_data.data,
        load_sd: state.AllReducer.shop_data.load_sd,
        plan_downgrade: state.AllReducer.plan_downgrade,
        get_sub_plan: state.AllReducer.get_sub_plan.data,
        pay_type: state.AllReducer.pay_type.message,
        load_sp: state.AllReducer.get_sub_plan.load_sp,
        get_all_sub: state.AllReducer.get_all_sub.message,
        load_sub: state.AllReducer.get_all_sub.load_sub,
        stripe_loaded: state.AllReducer.stripe_loaded,
        get_shop_sub: state.AllReducer.get_shop_sub.message,
        load_shop_sub: state.AllReducer.get_shop_sub.load_shop_sub,
        next_billing_date: state.AllReducer.get_shop_sub.next_billing_date,
        ref_btn: state.AllReducer.ref_btn.message,
        load_ref_btn: state.AllReducer.ref_btn.load_ref_btn,
        get_stripe_cus_msg: state.AllReducer.get_stripe_cus_msg.message,
        load_scm: state.AllReducer.get_stripe_cus_msg.load_scm,
        get_ref_code: state.AllReducer.get_ref_code.message,
        load_getrc: state.AllReducer.get_ref_code.load_getrc
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(Account);