import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBZTCu3gyRXKulP1q8TdS6L8lUvsJ5z8hg",
    authDomain: "appmixo-chat.firebaseapp.com",
    databaseURL: "https://appmixo-chat.firebaseio.com",
    projectId: "appmixo-chat",
    storageBucket: "appmixo-chat.appspot.com",
    messagingSenderId: "470891872273",
    appId: "1:470891872273:web:c1bb53571668dc444bb1b0",
    measurementId: "G-01EK0K9FNM"
  };

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;