import {
    PRODUCT_DATA, FAILED, GET_CATEGORY, GET_SUPPORT, ADD_TO_SHOPIFY, SINGLE_PRODUCT_DATA, GET_SHOP, GET_ENV,
    SAVE_MARKETING, SAVE_SEO, SAVE_SUPPLIER, SAVE_INFLUENCER, GET_SERVICE, PLACE_ORDER, GET_ORDER, PLAN_DOWNGRADE,
    PLAN_UPDRADE, PRODUCTS_BY_JOB, HOME_SINGLE_PRODUCT, HOME_ALL_PRODUCT, GET_SUPPLIER, GET_INFLUENCER, SIGN_UP,
    SIGN_IN, GET_COUNTRY, GET_SUB_PLAN, IS_ANY_DONE_JOBS, USER_PAY_TYPE, SHOPIFY_MONEY_FORMATE, PRODUCTS_BY_CAT,
    PRODUCTS_BY_CAT_REMOVE, GET_SHOP_SUB, GET_SUP_PLAN_GOLD, STRIPE_LOADED, GET_SUP_PLAN_SILVER, GET_ALL_SUB, HIDE_LINK, HIDE_ATS,
    HIDE_LINK_LOGOUT, IS_REF_BTN, GET_STRIPE_CUS_MSG, GET_FIREBASE_CREDENTIALS, STRIPE_LOADED_ORDER, GENERATE_REF_CODE, GET_REF_CODE,
    GET_USERNAME, CHECK_USERNAME, CARD_DETAILS, SEND_OFFLINE_USER_EMAIL
} from "../Action/AllAction"
const INITIAL_STATE = {
    all_product_data: {
        data: [], total_records: "0", load_pro: false,
        reqData: {
            page: "1",
            sort: "",
            search: {
                by_product: "",
                by_category: "",
                by_interest: "",
            }
        }
    },
    get_categories: { data: [], load_cat: false },
    sign_up: [],
    sign_in: [],
    get_firebase_credentials: [],
    send_offline_user_email: [],
    get_username: [],
    check_username: [],
    get_support: [],
    res_add_to_shopify: {},
    single_product: [],
    shop_data: { data: {}, load_sd: false },
    get_seo: {},
    save_seo: {},
    get_marketing: {},
    save_marketing: {},
    save_influencer: {},
    save_supplier: {},
    get_service: { data: [], load_serv: false },
    place_order: {},
    get_order: { data: [], load_order: false },
    plan_upgrade: {},
    plan_downgrade: {},
    get_influencer: { data: [], total_records: 0, load_inf: false },
    get_supplier: { data: [], total_records: 0, load_sup: false },
    home_all_product_data: [],
    home_single_product: [],
    products_by_job: {
        data: [], load_pbj: false,
        reqData: { search: { by_product: "", by_interest: "" }, sort: "", page: "1" }
    },
    env: { data: { CDN_URL: "" }, load_env: false },
    get_country: [],
    get_sub_plan: { data: [], load_sp: false },
    get_sup_plan_gold: { data: [], load_spg: false },
    get_sup_plan_silver: { data: [], load_shop_sps: false },
    any_done_jobs: { message: {}, load_adj: false },
    pay_type: { message: {}, load_upt: false },
    shopify_MF: { message: {}, load_smf: false },
    pro_by_cat: { data: { _id: "0", title: "All" }, is_cat_selected: false },
    get_shop_sub: { message: {}, load_shop_sub: false },
    stripe_loaded: false,
    stripe_loaded_order: false,
    get_all_sub: { message: [], load_sub: false },
    hide_link: { message: {}, load_hl: false },
    hide_ats: { message: {}, load_ats: false },
    hide_link_logout: { message: {}, load_hl_lo: false },
    ref_btn: { message: {}, load_ref_btn: false },
    get_stripe_cus_msg: { message: null, load_scm: false },
    generate_ref_code: { message: {}, load_grc: false },
    get_ref_code: { message: {}, load_getrc: false },
    card_details: { message: {}, load_cd: false }
}
const handle_all_product_data = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case PRODUCT_DATA:
            {
                return Object.assign({}, state,
                    {
                        all_product_data:
                        {
                            data: action.data.data,
                            total_records: action.data.total_records,
                            load_pro: true,
                            reqData: action.reqData
                        }
                    })
            }
        case GET_CATEGORY:
            {
                if (action.data.data && action.data.data.length !== 0) {
                    var categories = [];
                    categories =
                        action.data.data.map(get_categories => {
                            return { label: get_categories.title, value: get_categories._id };
                        });
                    return Object.assign({}, state, { get_categories: { data: categories, load_cat: true } })
                }
                else {
                    return Object.assign({}, state, { get_categories: { data: [], load_cat: true } })
                }
            }
        case SIGN_UP:
            {
                return Object.assign({}, state, { sign_up: action.data })
            }
        case SIGN_IN:
            {
                return Object.assign({}, state, { sign_in: action.data })
            }

        case GET_FIREBASE_CREDENTIALS:
            {
                return Object.assign({}, state, { get_firebase_credentials: action.data })
            }
        case SEND_OFFLINE_USER_EMAIL:
            {
                return Object.assign({}, state, { send_offline_user_email: action.data })
            }
        case GET_USERNAME:
            {
                return Object.assign({}, state, { get_username: action.data })
            }

        case CHECK_USERNAME:
            {
                return Object.assign({}, state, { check_username: action.data })
            }
        case GET_SUPPORT:
            {
                return Object.assign({}, state, { get_support: action.data })
            }
        case SINGLE_PRODUCT_DATA:
            {
                if (action.data.data) {
                    var sp_data = [];
                    if (sp_data.length === 0) {
                        sp_data.push(action.data.data)
                    }
                    return Object.assign({}, state, { single_product: sp_data })
                }
                else
                    return Object.assign({}, state, { single_product: action.data.message })
            }
        case ADD_TO_SHOPIFY:
            {
                if (action.data.data) {
                    return Object.assign({}, state, { res_add_to_shopify: action.data.data })
                }
                else
                    return Object.assign({}, state, { res_add_to_shopify: action.data.message })
            }
        case GET_SHOP:
            {
                if (action.data.data) {
                    return Object.assign({}, state, { shop_data: { data: action.data.data, load_sd: true } })
                }
                else
                    return Object.assign({}, state, { shop_data: { data: action.data.message, load_sd: true } })
            }

        case GET_ENV: {
            return Object.assign({}, state, { env: { data: action.data.data, load_env: true } })
        }
        case SAVE_SEO: {
            return Object.assign({}, state, { save_seo: action.data })
        }
        case SAVE_MARKETING: {
            return Object.assign({}, state, { save_marketing: action.data })
        }
        case SAVE_INFLUENCER: {
            return Object.assign({}, state, { save_influencer: action.data })
        }
        case SAVE_SUPPLIER: {
            return Object.assign({}, state, { save_supplier: action.data })
        }
        case GET_SERVICE: {
            return Object.assign({}, state, { get_service: { data: action.data.data, load_serv: true } })
        }
        case PLACE_ORDER: {
            return Object.assign({}, state, { place_order: action.data })
        }
        case GET_ORDER: {
            return Object.assign({}, state,
                {
                    get_order:
                    {
                        data: action.data.data, total_records: action.data.total_records, load_order: true,
                        total_pp_records: action.data.total_pp_records, reqData: action.reqData
                    }
                })
        }
        case PLAN_UPDRADE: {
            return Object.assign({}, state, { plan_upgrade: action.data })
        }
        case PLAN_DOWNGRADE: {
            return Object.assign({}, state, { plan_downgrade: action.data })
        }
        case PRODUCTS_BY_JOB: {
            return Object.assign({}, state,
                {
                    products_by_job:
                    {
                        data: action.data.data, total_records: action.data.total_records, load_pbj: true,
                        reqData: action.reqData
                    }

                })
        }
        case HOME_ALL_PRODUCT: {
            return Object.assign({}, state, { home_all_product_data: action.data })
        }
        case HOME_SINGLE_PRODUCT: {
            if (action.data.data) {
                var sp_data = [];
                if (sp_data.length === 0) {
                    sp_data.push(action.data.data)
                }
                return Object.assign({}, state, { home_single_product: sp_data })
            }
            else
                return Object.assign({}, state, { home_single_product: action.data.message })
        }
        case GET_INFLUENCER: {
            return Object.assign({}, state, {
                get_influencer:
                    { data: action.data.data, total_records: action.data.total_records, load_inf: true }
            })
        }
        case GET_SUPPLIER: {
            return Object.assign({}, state, {
                get_supplier:
                    { data: action.data.data, total_records: action.data.total_records, load_sup: true }
            })
        }
        case GET_COUNTRY: {
            return Object.assign({}, state, { get_country: action.data })
        }
        case GET_SUB_PLAN: {
            return Object.assign({}, state, { get_sub_plan: { data: action.data.data, load_sp: true } })
        }
        case GET_SUP_PLAN_GOLD: {
            return Object.assign({}, state, { get_sup_plan_gold: { data: action.data.data, load_spg: true } })
        }
        case GET_SUP_PLAN_SILVER: {
            return Object.assign({}, state, { get_sup_plan_silver: { data: action.data.data, load_sps: true } })
        }
        case IS_ANY_DONE_JOBS: {
            return Object.assign({}, state, { any_done_jobs: { message: action.data.message, load_adj: true } })
        }
        case USER_PAY_TYPE: {
            return Object.assign({}, state, { pay_type: { message: action.data.message, load_upt: true } })
        }
        case SHOPIFY_MONEY_FORMATE: {
            return Object.assign({}, state, { shopify_MF: { message: action.data.message, load_smf: true } })
        }
        case PRODUCTS_BY_CAT: {
            return Object.assign({}, state, {
                pro_by_cat: {
                    data: action.data, is_cat_selected: true
                }
            })
        }
        case PRODUCTS_BY_CAT_REMOVE: {
            return Object.assign({}, state, {
                pro_by_cat: {
                    data: { _id: "0", title: "All" }, is_cat_selected: false
                }
            })
        }
        case GET_SHOP_SUB: {
            return Object.assign({}, state, {
                get_shop_sub: {
                    message: action.data.message,
                    next_billing_date: action.data.next_billing_date,
                    load_shop_sub: true,
                    stripe_customer_cards: action.data.stripe_customer_cards
                }
            })
        }
        case STRIPE_LOADED: {
            return Object.assign({}, state, { stripe_loaded: action.data })
        }
        case STRIPE_LOADED_ORDER: {
            return Object.assign({}, state, { stripe_loaded_order: action.data })
        }

        case GET_ALL_SUB: {
            return Object.assign({}, state, {
                get_all_sub: {
                    message: action.data.message, load_sub: true
                }
            })
        }
        case HIDE_LINK: {
            return Object.assign({}, state, {
                hide_link: {
                    message: action.data.message, load_hl: true
                }
            })
        }
        case HIDE_ATS: {
            return Object.assign({}, state, {
                hide_ats: {
                    message: action.data.message, load_ats: true
                }
            })
        }
        case HIDE_LINK_LOGOUT: {
            return Object.assign({}, state, {
                hide_link_logout: {
                    message: action.data.message, load_hl_lo: true
                }
            })
        }
        case IS_REF_BTN: {
            return Object.assign({}, state, {
                ref_btn: {
                    message: action.data.message, load_ref_btn: true
                }
            })
        }
        case GET_STRIPE_CUS_MSG: {
            return Object.assign({}, state, {
                get_stripe_cus_msg: {
                    message: action.data.message, load_scm: true
                }
            })
        }

        case GENERATE_REF_CODE: {
            return Object.assign({}, state, {
                generate_ref_code: {
                    message: action.data.message, load_grc: true
                }
            })
        }

        case GET_REF_CODE: {
            return Object.assign({}, state, {
                get_ref_code: {
                    message: action.data.message, load_getrc: true
                }
            })
        }
        case CARD_DETAILS: {
            return Object.assign({}, state, {
                card_details: {
                    message: action.data.message, load_cd: true
                }
            })
        }

        case FAILED: {
            return Object.assign({}, state, { error_msg: action.data.error_msg });
        }

        default:
            return state;
    }
}
export default handle_all_product_data;