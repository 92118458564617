import React, { Component } from 'react';

class PageNotFound extends Component {
    render() {
        return (
            <div>
                <div id="colorlib-notfound">
                    <div className="colorlib-notfound">
                        <div className="colorlib-notfound-404">
                            <h1>4<span>0</span>4</h1>
                        </div>
                        <p id="colorlib_404_customizer_page_heading">OOPS! Page Not Found</p>
                        <button id="colorlib_404_customizer_button_text" onClick={() => this.props.history.push("/products")}>Back to products</button>
                    </div>
                </div>
                <div></div>
            </div>
        );
    }
}

export default PageNotFound;