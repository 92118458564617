import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as AllAction from "../Action/AllAction";

class SeoNmarketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      seo_done: false,
      marketing_done: false
    };
  }
  handleOnChange = (key, e) => {
    this.setState({
      [key]: e.target.value
    });
  };
  handlesubmitSeo = () => {
    // this.props.onHide();
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const data = {
      accessToken,
      shop
    };
    this.props.action.AllAction.SaveSeoAction(data);
    this.setState({
      seo_done: true
    });
  };
  handlesubmitMArketing = () => {
    // this.props.onHide();
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const data = {
      accessToken,
      shop
    };
    this.props.action.AllAction.SaveMarketingAction(data);
    this.setState({
      marketing_done: true
    });
  };
  render() {
    const img_dir = "secure/appmixo/winning_product/static/";
    var env_path =
      this.props.env &&
      this.props.env.CDN_URL !== "" &&
      this.props.env.CDN_URL + img_dir;
    var consulting = env_path + "consulting.png";
    var consulting_seo = env_path + "seo.jpg";
    return (
      <div>
        {this.props.modal_for_snm === "seo" && (
          <Modal
            {...this.props}
            size="lg"
            dialogClassName="extra-content-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                SEO Request
              </Modal.Title>
            </Modal.Header>
            {this.state.seo_done === false && (
              <>
                <div className="mm-data mm-seo">
                  <span className="mm-img">
                    <img src={consulting_seo} alt="" className="img-fluid" />
                  </span>
                  <div className="mm-txt">Get 15 mins free<strong> SEO consultation </strong>via call.</div>
                </div>
                <Modal.Footer>
                  <Button onClick={() => this.handlesubmitSeo()}>
                    Request us
                  </Button>
                </Modal.Footer>
              </>
            )}
            {this.state.seo_done === true && (
              <>
                <Modal.Body>
                  <div>Will contact you within 5-7 business days.</div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.props.onHide} variant="danger">
                    Close
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>

        )}
        {this.props.modal_for_snm === "marketing" && (
          <Modal
            {...this.props}
            size="lg"
            dialogClassName="extra-content-modal"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Marketing Requests
              </Modal.Title>
            </Modal.Header>
            {this.state.marketing_done === false && (
              <>
                <Modal.Body className="mm-modal">
                  <div className="mm-data mm-marketing">
                    <span className="mm-img">
                      <img src={consulting} alt="" className="img-fluid" />
                    </span>
                    <div className="mm-txt">Get 15 mins free<strong> PPC Marketing consultation </strong>via call.</div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.handlesubmitMArketing()}>
                    Request us
                  </Button>
                </Modal.Footer>
              </>
            )}
            {this.state.marketing_done === true && (
              <>
                <Modal.Body>
                  <div>Will contact you within 5-7 business days.</div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.props.onHide} variant="danger">
                    Close
                  </Button>
                </Modal.Footer>
              </>
            )}
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    env: state.AllReducer.env.data
  };
};
const mapDispatchToProps = dispatch => ({
  action: {
    AllAction: bindActionCreators(AllAction, dispatch),
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(SeoNmarketing);