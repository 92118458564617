import React, { Component } from 'react';
import BespokedReq from './BespokedReq';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as InstallAction from '../Action/installAction';
import * as AllAction from '../Action/AllAction';
import { Table, Button, InputGroup, FormControl, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { TiArrowUnsorted } from "react-icons/ti";
import AllImages from "./AllImages";
// import Select from "react-select";
import Pagination from "react-js-pagination";
import ReactStars from "react-rating-stars-component";
import YouTube from "react-youtube";
import AddToShopify from "./AddToShopify";
import Loader from "react-loader";
import "./CSS/productStyle.scss";
const pdf_icon = require("../img/pdf-image.png");
const fb_ads = require("../img/fb-ads.png");
const comments = require("../img/Comment-89.png");
const likes = require("../img/Like-18.png");
const reactions = require("../img/Smiles.png");
const share = require("../img/Share-91.png");
var youtube_icon = require("../img/youtube-icon.png");
var view = require("../img/view-42.png");

class BespokedList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalShowsService: false,
            get_service: [],
            products_by_job: [],
            loaded_job_products: false,
            modalShow: false,
            loaded: false,
            show_youtube: false,
            product_image_id: "",
            pageNumber: 1,
            activePage: 1,
            total_product_count: 0,
            sort: "",
            display_youtube_video_id: "",
            apiCall: false,
            selected_category: "",
            by_interest: "",
            by_product: "",
            modal_for: "",
            route_id: "",
            loading: true,
            youtube_url: "",
            get_categories: [],
            show_add_to_shopify: false,
            shopify_p_data: {},
            modal_for_snm: "",
            modal_snm: false,
            get_seo: {},
            get_marketing: {},
            loaded_marketing: false,
            loaded_seo: false,
            loaded_env: false,
            loaded_order: false,
            get_order: [],
            shop_data: [],
            loaded_shop: false
        }
    }
    componentWillMount() {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, tag: "niche_product"
        }
        // const env_data = {
        //     secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
        // };
        // this.props.action.AllAction.GetEnvAction(env_data);
        this.props.load_serv !== true && this.props.action.AllAction.GetServiceAction(data);
        const data_jobs = {
            accessToken, shop,
            page: this.state.activePage,
            sort: this.state.sort,
            search: { by_product: "", by_category: "0", by_interest: "" },
        }
        this.props.reqData && this.setState(() => {
            var { search, page, sort } = this.props.reqData
            return {
                by_product: search.by_product,
                by_interest: search.by_interest, activePage:page, sort
            }
        })
        this.props.load_pbj !== true && this.props.action.AllAction.ProductsByJobAction(data_jobs);
        this.props.load_adj !== true && this.props.action.AllAction.isAnyDoneJobsAction(data);
    }

    toggleBP = () => {
        this.setState({
            modalShowsService: !this.state.modalShowsService,
        }, () => {
            if (this.state.modalShowsService === false)
                this.setState({ get_service: [] })
        })
    }
    handleCloseAddToShopify = () => {
        this.setState({
            show_add_to_shopify: false
        });
    };
    handleshowAddToShopify = data => {
        this.setState(
            {
                shopify_p_data: data
            },
            () => {
                this.setState({
                    show_add_to_shopify: true
                });
            }
        );
    };
    toggle = (id, key) => {
        this.setState({
            modalShow: !this.state.modalShow,
            product_image_id: id,
            modal_for: key
        });
    };

    _onReady(event) {
        event.target.pauseVideo();
    }

    handleOnChange = (key, e) => {
        this.setState(
            {
                [key]: e.target.value
            },
            () => {
                if (this.state[key] === "") {
                    this.apiCall();
                }
            }
        );
    };

    apiCall = () => {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data_jobs = {
            page: this.state.activePage,
            sort: this.state.sort,
            search: {
                by_product: this.state.by_product,
                // by_category: this.state.selected_category.value,
                by_category: "0",
                by_interest: this.state.by_interest
            },
            accessToken,
            shop
        }
        this.props.action.AllAction.ProductsByJobAction(data_jobs);
    };

    handlePageChange = pageNumber => {
        this.setState(
            {
                activePage: pageNumber
            },
            () => this.apiCall()
        );
    };

    handleSort = () => {
        if (this.state.sort === "" || this.state.sort === "desc")
            this.setState(
                {
                    sort: "asc"
                },
                () => {
                    this.apiCall();
                }
            );
        else if (this.state.sort === "asc") {
            this.setState(
                {
                    sort: "desc"
                },
                () => {
                    this.apiCall();
                }
            );
        }
    };

    handleLinkClick = link => {
        window.open(link, "_blank");
    };

    handleSinglePageRouter = id => {
        this.props.history.push("/product/" + id);
    };
    onChangeCat = value => {
        this.setState(
            {
                selected_category: value
            },
            () => {
                this.apiCall();
            }
        );
    };
    renderTooltip = props => {
        return <Tooltip {...props}>{props}</Tooltip>;
    };
    handleClose = () => {
        this.setState(
            {
                show_youtube: false
            },
            () => {
                this.setState({
                    display_youtube_video_id: "",
                    youtube_url: ""
                });
            }
        );
    };

    handleShow = video => {
        var index = video && video.length !== 0 && video[0].indexOf("=");
        var arr = [
            video && video.length !== 0 && video[0].slice(0, index),
            video && video.length !== 0 && video[0].slice(index + 1)
        ];
        this.setState(
            {
                display_youtube_video_id: arr[1],
                youtube_url: video[0]
            },
            () => {
                this.setState({
                    show_youtube: true
                });
            }
        );
    };
    api_call_with_search = e => {
        var code = e.keyCode || e.which;
        if (code === 13) {
            //13 is the enter keycode
            this.apiCall();
        }
    };
    toggle_snm = key => {
        this.setState({
            modal_for_snm: key,
            modal_snm: !this.state.modal_snm
        });
    };

    render() {
        const img_dir = "secure/appmixo/winning_product/static/";
        var env_path =
            this.props.env &&
            this.props.env.CDN_URL !== "" &&
            this.props.env.CDN_URL + img_dir;
        var niche = env_path + "niche.jpg";
        const opts = {
            playerVars: {
                autoplay: 0,
                controls: 0,
                forceSSL: 0,
                origin: "https://" + window.location.hostname
            }
        };
        const all_data = this.props.load_pbj &&
            this.props.products_by_job && this.props.products_by_job.length !== 0
            ? this.props.products_by_job.map((products_by_job, i) => {
                const links =
                    this.props.load_env &&
                    products_by_job.product_link &&
                    products_by_job.product_link.length !== 0 &&
                    products_by_job.product_link.map(product_link => {
                        return (
                            <OverlayTrigger
                                placement="auto"
                                delay={{ show: 250, hide: 400 }}
                                overlay={this.renderTooltip(product_link.title)}
                            >
                                <div
                                    className="t-link-icon"
                                    onClick={() =>
                                        this.handleLinkClick(product_link.link_name)
                                    }
                                >
                                    <img
                                        src={(typeof (product_link.icon) === "string" && (product_link.icon.includes("http") ? product_link.icon :
                                            this.props.env.CDN_URL + product_link.icon.replace("images", "thumb_images")))}
                                        height="50px"
                                        width="50px"
                                        alt={product_link.title}
                                    ></img>
                                </div>
                            </OverlayTrigger>
                        );
                    });

                const get_id_youtube1 = products_by_job.product_youtube_video &&
                    products_by_job.product_youtube_video.length !== 0 && (
                        <div key={i}>
                            <div
                                className="y-video-container"
                                onClick={() =>
                                    this.handleShow(products_by_job.product_youtube_video)
                                }
                            >
                                <div className="t-product-img-container-youtube">
                                    <img src={youtube_icon.toString()} alt="NoImage"></img>
                                </div>
                            </div>
                            {products_by_job.product_youtube_video.length > 1 && (
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(
                                            products_by_job._id,
                                            "product_youtube_viedo"
                                        )
                                    }
                                >
                                    View all
                                </div>
                            )}
                        </div>
                    );
                const fb_doc1 = this.props.load_env && products_by_job.product_fb_ads &&
                    products_by_job.product_fb_ads.document &&
                    products_by_job.product_fb_ads.document.length !== 0 && (
                        <div>
                            <div
                                className="fb-ads-img"
                                onClick={() =>
                                    this.handleLinkClick(
                                        products_by_job.product_fb_ads.document[0].includes("http") ? products_by_job.product_fb_ads.document[0] :
                                            this.props.env.CDN_URL + products_by_job.product_fb_ads.document[0]
                                    )
                                }
                            >
                                <img
                                    src={pdf_icon}
                                    width="85px"
                                    height="85px"
                                    alt="No document"
                                ></img>
                            </div>
                            {products_by_job.product_fb_ads.document.length > 1 && (
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(
                                            products_by_job._id,
                                            "product_fb_documents"
                                        )
                                    }
                                >
                                    All documents
                                </div>
                            )}
                        </div>
                    );

                const fb_image1 = this.props.load_env && products_by_job.product_fb_ads &&
                    products_by_job.product_fb_ads.image &&
                    products_by_job.product_fb_ads.image.length !== 0 && (
                        <div>
                            <div
                                className="t-product-img-container"
                                onClick={() =>
                                    this.handleLinkClick(
                                        products_by_job.product_fb_ads.image[0].includes("http") ? products_by_job.product_fb_ads.image[0] :
                                            this.props.env.CDN_URL + products_by_job.product_fb_ads.image[0]
                                    )
                                }
                            >
                                <img
                                    src={(typeof (products_by_job.product_fb_ads.image[0]) === "string" && (products_by_job.product_fb_ads.image[0].includes("http") ? products_by_job.product_fb_ads.image[0] :
                                        this.props.env.CDN_URL + products_by_job.product_fb_ads.image[0].replace("images", "thumb_images")))}
                                    alt="NoImage"
                                ></img>
                            </div>
                            {products_by_job.product_fb_ads.image.length > 1 && (
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(products_by_job._id, "product_fb_images")
                                    }
                                >
                                    All images
                                </div>
                            )}
                        </div>
                    );

                const fb_video1 = products_by_job.product_fb_ads &&
                    products_by_job.product_fb_ads.video &&
                    products_by_job.product_fb_ads.video.length !== 0 && (
                        <div>
                            <div
                                className="fb-ads-img"
                                onClick={() =>
                                    this.handleLinkClick(
                                        products_by_job.product_fb_ads.video[0]
                                    )
                                }
                            >
                                <img
                                    src={fb_ads}
                                    width="100px"
                                    height="60px"
                                    alt="No document"
                                ></img>
                            </div>
                            {products_by_job.product_fb_ads.video.length > 1 && (
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(products_by_job._id, "product_fb_viedo")
                                    }
                                >
                                    All videos
                                </div>
                            )}
                        </div>
                    );

                const engage_doc1 = this.props.load_env && products_by_job.product_engagement &&
                    products_by_job.product_engagement.document &&
                    products_by_job.product_engagement.document.length !== 0 && (
                        <div>
                            <div
                                className="fb-ads-img"
                                onClick={() =>
                                    this.handleLinkClick(
                                        products_by_job.product_engagement.document[0].includes("http") ? products_by_job.product_engagement.document[0] :
                                            this.props.env.CDN_URL + products_by_job.product_engagement.document[0]
                                    )
                                }
                            >
                                {" "}
                                <img
                                    src={pdf_icon}
                                    width="85px"
                                    height="85px"
                                    alt="No document"
                                ></img>
                            </div>
                            {products_by_job.product_engagement.document.length > 1 && (
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(
                                            products_by_job._id,
                                            "product_engage_documents"
                                        )
                                    }
                                >
                                    All documents
                                </div>
                            )}
                        </div>
                    );

                const engage_image1 = this.props.load_env && products_by_job.product_engagement &&
                    products_by_job.product_engagement.image &&
                    products_by_job.product_engagement.image.length !== 0 && (
                        <div>
                            <div
                                className="t-product-img-container"
                                onClick={() =>
                                    this.handleLinkClick(
                                        products_by_job.product_engagement.image[0].includes("http") ? products_by_job.product_engagement.image[0] :
                                            this.props.env.CDN_URL + products_by_job.product_engagement.image[0]
                                    )
                                }
                            >
                                <img
                                    src={(typeof (products_by_job.product_engagement.image[0]) === "string" && (products_by_job.product_engagement.image[0].includes("http") ? products_by_job.product_engagement.image[0] :
                                        this.props.env.CDN_URL + products_by_job.product_engagement.image[0].replace("images", "thumb_images")))}
                                    alt="NoImage"
                                ></img>
                            </div>
                            {products_by_job.product_engagement.image.length > 1 && (
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(
                                            products_by_job._id,
                                            "product_engage_images"
                                        )
                                    }
                                >
                                    All images
                                </div>
                            )}
                        </div>
                    );
                const engage_video1 = products_by_job.product_engagement.video &&
                    products_by_job.product_engagement.video.length !== 0 && (
                        <div>
                            <div
                                className="fb-ads-img"
                                onClick={() =>
                                    this.handleLinkClick(
                                        products_by_job.product_engagement.video[0]
                                    )
                                }
                            >
                                <img
                                    src={fb_ads}
                                    width="100px"
                                    height="60px"
                                    alt="No document"
                                ></img>
                            </div>
                            {products_by_job.product_engagement.video.length > 1 && (
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(
                                            products_by_job._id,
                                            "product_engage_viedo"
                                        )
                                    }
                                >
                                    All videos
                                </div>
                            )}
                        </div>
                    );
                const engage_txt =
                    products_by_job.product_engagement.text !== "" &&
                    products_by_job.product_engagement.text.replace(/\s/g, "");
                const engage = engage_txt && engage_txt.split(",");
                const filter_enage_like =
                    engage &&
                    engage.length !== 0 &&
                    engage.filter(engage => {
                        return (
                            engage.includes("like") ||
                            engage.includes("likes") ||
                            engage.includes("LIKES") ||
                            engage.includes("LIKE") ||
                            engage.includes("Likes") ||
                            engage.includes("Like")
                        );
                    });
                const filter_enage_comment =
                    engage &&
                    engage.length !== 0 &&
                    engage.filter(engage => {
                        return (
                            engage.includes("comment") ||
                            engage.includes("comments") ||
                            engage.includes("COMMENT") ||
                            engage.includes("COMMENTS") ||
                            engage.includes("Comments") ||
                            engage.includes("Comment")
                        );
                    });
                const filter_enage_reactions =
                    engage &&
                    engage.length !== 0 &&
                    engage.filter(engage => {
                        return (
                            engage.includes("reaction") ||
                            engage.includes("reactions") ||
                            engage.includes("REACTIONS") ||
                            engage.includes("REACTION") ||
                            engage.includes("Reaction") ||
                            engage.includes("Reactions")
                        );
                    });
                const filter_enage_share =
                    engage &&
                    engage.length !== 0 &&
                    engage.filter(engage => {
                        return (
                            engage.includes("share") ||
                            engage.includes("shares") ||
                            engage.includes("SHARE") ||
                            engage.includes("SHARES") ||
                            engage.includes("Shares") ||
                            engage.includes("Share")
                        );
                    });
                const filter_enage_view =
                    engage &&
                    engage.length !== 0 &&
                    engage.filter(engage => {
                        return (
                            engage.includes("view") ||
                            engage.includes("views") ||
                            engage.includes("VIEW") ||
                            engage.includes("VIWES") ||
                            engage.includes("Views") ||
                            engage.includes("View")
                        );
                    });
                const filter_enage_comment_val =
                    filter_enage_comment &&
                    filter_enage_comment.length !== 0 &&
                    filter_enage_comment[0].split("-")[0];
                const filter_enage_like_val =
                    filter_enage_like &&
                    filter_enage_like.length !== 0 &&
                    filter_enage_like[0].split("-")[0];
                const filter_enage_reactions_val =
                    filter_enage_reactions &&
                    filter_enage_reactions.length !== 0 &&
                    filter_enage_reactions[0].split("-")[0];
                const filter_enage_share_val =
                    filter_enage_share &&
                    filter_enage_share.length !== 0 &&
                    filter_enage_share[0].split("-")[0];
                const filter_enage_view_val =
                    filter_enage_view &&
                    filter_enage_view.length !== 0 &&
                    filter_enage_view[0].split("-")[0];

                const engage_text = (
                    <div className="engage_text">
                        <div className="p-engage-field">
                            {/* {filter_enage_like && filter_enage_like.length !== 0 && <div><img src={likes} width="25px" height="25px" alt="Likes" ></img><span>{filter_enage_like_val}</span></div>}
                            {filter_enage_comment && filter_enage_comment.length !== 0 && <div><img src={comments} width="25px" height="25px" alt="Comments" ></img><span>{filter_enage_comment_val}</span></div>}
                            {filter_enage_share && filter_enage_share.length !== 0 && <div><img src={share} width="25px" height="25px" alt="Share" ></img><span>{filter_enage_share_val}</span></div>}
                            {filter_enage_reactions && filter_enage_reactions.length !== 0 && <div><img src={reactions} width="25px" height="25px" alt="Reactions" ></img><span>{filter_enage_reactions_val}</span></div>} */}
                            {filter_enage_like && filter_enage_like.length !== 0 && (
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip("Like")}
                                >
                                    <div className="p-engage-data">
                                        <img
                                            src={likes}
                                            width="25px"
                                            height="25px"
                                            alt="Likes"
                                        ></img>
                                        <span>{filter_enage_like_val}</span>
                                    </div>
                                </OverlayTrigger>
                            )}
                            {filter_enage_comment && filter_enage_comment.length !== 0 && (
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip("Comment")}
                                >
                                    <div className="p-engage-data">
                                        <img
                                            src={comments}
                                            width="25px"
                                            height="25px"
                                            alt="Comments"
                                        ></img>
                                        <span>{filter_enage_comment_val}</span>
                                    </div>
                                </OverlayTrigger>
                            )}
                            {filter_enage_share && filter_enage_share.length !== 0 && (
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip("Share")}
                                >
                                    <div className="p-engage-data">
                                        <img
                                            src={share}
                                            width="25px"
                                            height="25px"
                                            alt="Share"
                                        ></img>
                                        <span>{filter_enage_share_val}</span>
                                    </div>
                                </OverlayTrigger>
                            )}
                            {filter_enage_reactions &&
                                filter_enage_reactions.length !== 0 && (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.renderTooltip("Reaction")}
                                    >
                                        <div className="p-engage-data">
                                            <img
                                                src={reactions}
                                                width="25px"
                                                height="25px"
                                                alt="Reactions"
                                            ></img>
                                            <span>{filter_enage_reactions_val}</span>
                                        </div>
                                    </OverlayTrigger>
                                )}
                            {filter_enage_view && filter_enage_view.length !== 0 && (
                                <OverlayTrigger
                                    placement="left"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip("View")}
                                >
                                    <div className="p-engage-data">
                                        <img
                                            src={view}
                                            width="25px"
                                            height="25px"
                                            alt="Views"
                                        ></img>
                                        <span>{filter_enage_view_val}</span>
                                    </div>
                                </OverlayTrigger>
                            )}
                        </div>
                    </div>
                );

                const fb = (
                    <div className="pro-small-txt">
                        {products_by_job.product_fb_ads.text !== "" && (
                            <div className="t-block-discription">
                                {" "}
                                {products_by_job.product_fb_ads.text}{" "}
                            </div>
                        )}
                        {products_by_job.product_fb_ads.document.length !== 0 && (
                            <>{fb_doc1}</>
                        )}
                        {products_by_job.product_fb_ads.image.length !== 0 && (
                            <div>{fb_image1} </div>
                        )}
                        {products_by_job.product_fb_ads.video.length !== 0 && (
                            <>{fb_video1}</>
                        )}
                    </div>
                );
                const engage_f = (
                    <div className="pro-small-txt-enage">
                        {products_by_job.product_engagement.text !== "" &&
                            // <div className="t-block-discription"> {products_by_job.product_engagement.text} </div>}
                            engage_text}
                        {products_by_job.product_engagement.document.length !== 0 && (
                            <>{engage_doc1}</>
                        )}
                        {products_by_job.product_engagement.image.length !== 0 && (
                            <div>{engage_image1}</div>
                        )}
                        {products_by_job.product_engagement.video.length !== 0 && (
                            <>{engage_video1}</>
                        )}
                    </div>
                );
                const analytics = (
                    <div className="t-product-analytics">
                        <div>
                            <span>Source:</span>{" "}
                            {products_by_job.product_analytics_source.toString()}
                        </div>
                        <div>
                            <span>Orders:</span>{" "}
                            {products_by_job.product_orders_count.toString()}
                        </div>
                        <div>
                            <span>Reviews:</span>{" "}
                            {products_by_job.product_reviews.toString()}
                        </div>
                        <div>
                            <div className="pro-rating-group">
                                <ReactStars
                                    count={5}
                                    size={35}
                                    edit={false}
                                    value={products_by_job.product_rating.toString()}
                                    half={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    fullIcon={<i className="fa fa-star"></i>}
                                    color2={"#ffd700"}
                                />
                                <div className="pro-rating-value">
                                    {products_by_job.product_rating.toString()}
                                </div>
                            </div>
                        </div>
                    </div>
                );
                var date_ver =
                    products_by_job.product_display_date &&
                    products_by_job.product_display_date.toString() &&
                    products_by_job.product_display_date.slice(0, -5);
                var date_api =
                    products_by_job.product_display_date &&
                    products_by_job.product_display_date.toString();
                var final_date =
                    new Date(date_api && date_api).toDateString() ===
                        new Date().toDateString()
                        ? "Today"
                        : new Date(date_api && date_api).toDateString() ===
                            new Date(
                                new Date().setDate(new Date().getDate() - 1)
                            ).toDateString()
                            ? "Yesterday"
                            : date_ver;
                var f_main_image = this.props.load_env && products_by_job.product_main_image !== "" && products_by_job.product_main_image !== {} &&
                    (typeof (products_by_job.product_main_image) === "string" && (products_by_job.product_main_image.includes("http") ? products_by_job.product_main_image :
                        this.props.env.CDN_URL + products_by_job.product_main_image.replace("images", "thumb_images")));

                var f_main_image_onclick = this.props.load_env && products_by_job.product_main_image !== "" && products_by_job.product_main_image !== {} &&
                    (typeof (products_by_job.product_main_image) === "string" && products_by_job.product_main_image.includes("http") ? products_by_job.product_main_image :
                        this.props.env.CDN_URL + products_by_job.product_main_image);

                function titleCase(str) {
                    var splitStr = str.toLowerCase().split(' ');
                    for (var i = 0; i < splitStr.length; i++) {
                        // You do not need to check if i is larger than splitStr length, as your for does that for you
                        // Assign it back to the array
                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                    }
                    // Directly return the joined string
                    return splitStr.join(' ');
                }

                let tempName = titleCase(products_by_job.product_name);
                var user_from = localStorage.getItem("user_from")

                return (
                    <tr key={i}>
                        <td valign="middle">
                            <div className="product-cell">
                                <div className="cell-date-holder">
                                    <span className="date-in-vertical">
                                        {final_date && final_date.toString()}
                                    </span>
                                </div>
                                <div className="t-pro-info-container">
                                    <div className="t-product-img-container">
                                        <img
                                            src={f_main_image}
                                            alt="NoImage"
                                            onClick={() =>
                                                this.handleLinkClick(
                                                    f_main_image_onclick
                                                )
                                            }
                                        ></img>
                                    </div>
                                    <div className="t-product-title">
                                        {products_by_job.product_name &&
                                            tempName}
                                    </div>
                                </div>
                                <div
                                    className="pro-img-viewmore-btn"
                                    onClick={() =>
                                        this.toggle(products_by_job._id, "product_images")
                                    }
                                >
                                    All Images
                                            </div>
                            </div>
                        </td>
                        <td valign="middle">
                            <div className="profit-cost-inner">
                                <div>
                                    <span>Price:</span>{" "}
                                    <span>
                                        {products_by_job.product_price}
                                        <div className="base-price">
                                            {(products_by_job.product_price !== products_by_job.base_product_price) && products_by_job.base_product_price}</div>
                                    </span>
                                </div>
                                <div>
                                    <span>Sale price:</span>{" "}
                                    <span>
                                        {products_by_job.product_sale_price}
                                        <div className="base-price">
                                            {(products_by_job.product_sale_price !== products_by_job.base_product_sale_price) && products_by_job.base_product_sale_price}</div>
                                    </span>
                                </div>
                                <div>
                                    <span>Profit:</span>{" "}
                                    <span>
                                        {products_by_job.product_profit_margin}
                                        <div className="base-price">
                                            {(products_by_job.product_profit_margin !== products_by_job.base_product_profit_margin) && products_by_job.base_product_profit_margin}</div>
                                    </span>
                                </div>
                            </div>
                        </td>
                        <td valign="middle">{analytics}</td>
                        <td valign="middle">
                            {" "}
                            <div className="t-pro-links">{links}</div>
                        </td>
                        <td valign="middle">{get_id_youtube1}</td>
                        <td valign="middle">{fb}</td>
                        <td valign="middle">{engage_f}</td>
                        <td valign="middle">
                            <div className="pro-small-txt">
                                <div className="block_discription">
                                    {products_by_job.product_interest}
                                </div>
                            </div>
                        </td>
                        <td
                            valign="middle"
                            style={{ width: "8%" }}
                            className="table-action-buttons"
                        >
                            <Button
                                onClick={() =>
                                    this.handleSinglePageRouter(products_by_job._id)
                                }
                            >
                                {" "}
                                    View Details{" "}
                            </Button>
                            <br />
                            {user_from === "shopify" && <Button className="add-to-shopify-btn"
                                onClick={() => this.handleshowAddToShopify(products_by_job)}>Add To Shopify</Button>}
                        </td>
                    </tr>
                );
            })
            : this.props.load_pbj && this.props.products_by_job &&
            this.props.products_by_job.length === 0 && (
                <tr>
                    <td colSpan="9">No Products</td>
                </tr>
            );
        var is_table_display = this.props.load_adj && this.props.any_done_jobs && this.props.any_done_jobs.done_jobs &&
            this.props.any_done_jobs.done_jobs === true ? true : false

        return (
            <div className="bp-txt">
                {this.props.load_adj && is_table_display === false && <div>
                    <div className="bp-img">
                        <img src={niche} alt="" className="img-fluid" />
                    </div>
                    <Button onClick={() => this.toggleBP()}>Request Niche Product</Button>
                    <h4 className="bes-msg">You can request us to find products just for your niche and your store.</h4>
                    <h6>Delivering tens of hundreds of products daily to merchants!</h6>
                </div>
                }
                {this.props.load_pbj && this.props.load_adj && is_table_display === true && <div className="bp-img-btn">
                    <Button onClick={() => this.toggleBP()}>Request Niche Product</Button>
                </div>
                }
                {this.props.load_serv && <BespokedReq
                    show={this.state.modalShowsService}
                    onHide={() => this.toggleBP()}
                    get_service={this.state.modalShowsService === false ? [] : this.state.get_service} />}
                {this.state.show_add_to_shopify && this.props.load_smf && (
                    <AddToShopify
                        show={this.state.show_add_to_shopify}
                        onHide={() => this.handleCloseAddToShopify()}
                        shopify_p_data={this.state.shopify_p_data}
                        CDN_URL={this.props.env.CDN_URL}
                        shopify_MF={this.props.shopify_MF}
                    />
                )}
                {this.props.load_env && <AllImages
                    show={this.state.modalShow}
                    onHide={() => this.toggle()}
                    product_image_id={this.state.product_image_id}
                    all_product_data={this.props.products_by_job}
                    modal_for={this.state.modal_for}
                    CDN_URL={this.props.env.CDN_URL}
                />}
                {this.props.load_pbj && this.props.load_adj && is_table_display === true && <>
                    <div className="search-fields">
                        <InputGroup>
                            <GoSearch />
                            <FormControl
                                placeholder="Search product"
                                aria-label="Search product"
                                aria-describedby="basic-addon2"
                                onKeyPress={this.api_call_with_search.bind(this)}
                                onChange={e => this.handleOnChange("by_product", e)}
                                value={this.state.by_product}
                            />
                        </InputGroup>
                        <InputGroup>
                            <GoSearch />
                            <FormControl
                                placeholder="Search interest"
                                aria-label="Search interest"
                                aria-describedby="basic-addon2"
                                onKeyPress={this.api_call_with_search.bind(this)}
                                onChange={e => this.handleOnChange("by_interest", e)}
                                value={this.state.by_interest}
                            />
                        </InputGroup>
                    </div>
                    <Table bordered responsive className="pro-data-table">
                        <thead>
                            <tr>
                                <th style={{ width: "20%" }}>
                                    Product{" "}
                                    <span className="sort-by-product">
                                        <TiArrowUnsorted
                                            onClick={this.handleSort}
                                            size={15}
                                        />
                                    </span>
                                </th>
                                <th style={{ width: "12%" }}>Profits</th>
                                <th style={{ width: "12%" }}>Analytics</th>
                                <th style={{ width: "11%" }}>Links</th>
                                <th style={{ width: "8%" }}>Youtube</th>
                                <th style={{ width: "10%" }}>FB Ads</th>
                                <th style={{ width: "10%" }}>Engagement </th>
                                <th style={{ width: "10%" }}>Interest</th>
                                <th style={{ width: "7%" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>{all_data}</tbody>
                    </Table>
                    <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        pageRangeDisplayed={3}
                        totalItemsCount={this.props.total_records_pro}
                        onChange={this.handlePageChange}
                        innerClass="innerClass"
                        activeClass="activeClass"
                        activeLinkClass="activeLinkClass"
                        itemClass="itemClass"
                        itemClassFirst="itemClassFirst"
                        itemClassPrev="itemClassPrev"
                        itemClassNext="itemClassNext"
                        itemClassLast="itemClassLast"
                        linkClass="linkClass"
                        linkClassFirst="linkClassFirst"
                        linkClassPrev="linkClassPrev"
                        linkClassNext="linkClassNext"
                        linkClassLast="linkClassLast"
                    />
                </>}
                <Modal
                    show={this.state.show_youtube}
                    onHide={this.handleClose}
                    dialogClassName="extra-content-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Youtube Video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="product-youtube-video-modal">
                            <YouTube
                                videoId={this.state.display_youtube_video_id}
                                opts={opts}
                                onReady={this._onReady}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() =>
                                this.handleLinkClick(
                                    this.state.youtube_url && this.state.youtube_url
                                )
                            }>See in full screen</Button>
                    </Modal.Footer>
                </Modal>
                <Loader
                    loaded={this.props.load_pbj && this.props.load_adj}
                    lines={10}
                    length={5}
                    width={5}
                    radius={10}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#1f3893"
                    speed={1}
                    trail={40}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        get_service: state.AllReducer.get_service.data,
        load_serv: state.AllReducer.get_service.load_serv,
        products_by_job: state.AllReducer.products_by_job.data,
        reqData: state.AllReducer.products_by_job.reqData,
        load_pbj: state.AllReducer.products_by_job.load_pbj,
        total_records_pro: state.AllReducer.products_by_job.total_records,
        get_order: state.AllReducer.get_order.data,
        env: state.AllReducer.env.data,
        load_env: state.AllReducer.env.load_env,
        get_categories: state.AllReducer.get_categories.data,
        any_done_jobs: state.AllReducer.any_done_jobs.message,
        load_adj: state.AllReducer.any_done_jobs.load_adj,
        shopify_MF: state.AllReducer.shopify_MF.message,
        load_smf: state.AllReducer.shopify_MF.load_smf
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
        InstallAction: bindActionCreators(InstallAction, dispatch)
    }
})
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BespokedList));