import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import Cookies from "universal-cookie";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as InstallAction from "../Action/installAction";
import * as AllAction from "../Action/AllAction";
import { Button, Modal, Form } from "react-bootstrap";
import swal from 'sweetalert';
import firebase from "../firebase";
var logo = require("../img/app-logo.png");
const cookies = new Cookies();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cookie: "",
      showUsernameModal: false,
      username: "",
      fieldsErrors: { username: "" },
      isUsernameExist: false,
      overAllUnreadMessages: 0,
      play: false,
      pause: true,
      // audio: new Audio('https://notificationsounds.com/soundfiles/d7a728a67d909e714c0774e22cb806f2/file-sounds-1150-pristine.mp3')
      audio: new Audio(`https://${window.location.hostname}/secure/appmixo/winning_product/static/notification.mp3`)

    };
  }

  componentWillMount() {
    const env_data = {
      secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
    };
    this.props.load_env !== true && this.props.action.AllAction.GetEnvAction(env_data);

    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const data = {
      accessToken, shop
    }
    this.props.action.AllAction.getUsername(data);

    if (shop) {
      this.getCurrentUser(shop);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.get_username !== (prevProps.get_username)) {
      if (this.props.get_username.isUserExist === true && this.props.get_username.username) {
        this.setState({
          isUsernameExist: true,
          username: this.props.get_username.username
        })
      }
    }

    if (this.props.check_username !== (prevProps.check_username)) {
      if (this.props.check_username.isUsernameExist === true) {
        swal({
          text: "This username is already exist! Please try another username.",
          icon: "warning",
          dangerMode: true,
        })
        this.setState({
          isUsernameExist: false,
          username: ""
        })
      }
      else if (this.props.check_username.isUsernameExist === false && this.props.check_username.isUserGenerated === true) {
        if (this.state.username !== '') {
          this.props.history.push({
            pathname: '/messages',
            username: this.state.username
          });
        }
      }
    }
  }

  play = () => {
    this.setState({ play: true, pause: false })
    this.state.audio.play();
  }

  pause = () => {
    this.setState({ play: false, pause: true })
    this.state.audio.pause();
  }

  getCurrentUser(currentUser) {
    // console.log(currentUser);
    const ref = firebase.database().ref("users");
    let that = this;

    ref.orderByChild("email").equalTo(currentUser).on("value", async function (snapshot) {
      if (snapshot.val()) {
        snapshot.forEach(async function (data) {
          const result = data.val();
          if (result.overAllUnreadMessage) {
            that.setState({ overAllUnreadMessages: result.overAllUnreadMessage - 1 }, async () => {
              that.play()
            });
          }
        })
      }
    })
  }

  handleOnclickLogo = () => {
    this.props.history.push("/products");
  };

  handleOnChange = (key, e) => {
    this.setState(
      {
        [key]: e.target.value
      },
      () => {
        if (this.state[key] === "") {
          this.apiCall();
        }
      }
    );
  };

  clickForRatings = () => {
    const expires = new Date();
    expires.setDate(expires.getTime() + 24 * 60 * 60 * 1000);
    cookies.set("winning_products_review_cookie", "review_done", {
      path: "/",
      expires,
      domain: window.location.origin,
      secure: true,
      sameSite: "none",
      httpOnly: true
    });
  };

  sendMessage = () => {
    if (this.state.isUsernameExist === false) {
      this.setState({ showUsernameModal: true });
    }
    else if (this.state.isUsernameExist === true && this.state.username !== "") {
      this.props.history.push({
        pathname: '/messages',
        username: this.state.username
      });
    }
  };

  saveUserName = (e) => {
    e.preventDefault();
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const data = {
      accessToken, shop, username: this.state.username
    }
    this.props.action.AllAction.checkUsername(data);
    this.setState({ showUsernameModal: false })
  }

  render() {
    // const host = window.location.hostname;
    // let isLive = false;
    // if (host === 'winmixo.com' || host === 'live.appmixo.com') {
    //   isLive = true;
    // }

    //Add ?milan-testing at URL for message btn

    var showMsgBtn = false;

    if (window.location.search === "?milan-testing") {
      showMsgBtn = true;
    }

    var get_cookie = cookies.get("winning_products_review_cookie");
    var get_cookie = "";
    let country_value = localStorage.getItem("country_wp") && JSON.parse(localStorage.getItem("country_wp"));
    return (
      <div className="header-in-all">
        <div className="container-header">
          <span className="icon-logo">
            <img src={logo} height="30px" width="30px" alt="NoLogo" />
          </span>
          <span className="header-logo" onClick={this.handleOnclickLogo}>
            Winning Products for {country_value && country_value.Country_Name}
            {this.props.load_env && country_value && <span className="cou-flag">
              <img src={this.props.env.CDN_URL + country_value.Country_Flag}></img></span>}
          </span>
          {!(
            get_cookie != "" &&
            get_cookie != undefined &&
            get_cookie == "review_done"
          ) ? (
              <span
                className="rating-container"
                onClick={() => this.clickForRatings()}
              >
                <a
                  href="https://apps.shopify.com/winning-products#modal-show=ReviewListingModal"
                  target="_blank"
                  title="Click here to place a review"
                  rel="noopener noreferrer"
                >
                  <span className="ratings-in-header">
                    <span className="rate-text">Like this app? Review us </span>
                    <div className="stars-ltr">
                      <div className="stars-ltr-outline">
                        <div className="stars-ltr-full">
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                          <span>★</span>
                        </div>
                        <span>☆</span>
                        <span>☆</span>
                        <span>☆</span>
                        <span>☆</span>
                        <span>☆</span>
                      </div>
                    </div>
                  </span>
                </a>
              </span>
            ) : (
              <span className="rate-text">
                <span>★</span>Thanks for reviewing our work.
              </span>
            )}
          <span className="header-fields">
            {
              // isLive ? null : (
              showMsgBtn !== true ? null : (
                <span
                  className="header-field-text"
                  onClick={
                    () => this.sendMessage()
                  }
                >
                  Messages
                  {this.state.overAllUnreadMessages > 0 ? <label className="overall-unread-message-count">+{this.state.overAllUnreadMessages}</label> : null}
                </span>
              )
            }

            <span
              className="header-field-text"
              onClick={() =>
                this.props.history && this.props.history.push("/supplier")
              }
            >
              Supplier
            </span>
            <span
              className="header-field-text"
              onClick={() =>
                this.props.history && this.props.history.push("/influencer")
              }
            >
              Influencer
            </span>
          </span>
        </div>

        <Modal show={this.state.showUsernameModal} onHide={() => this.setState({ showUsernameModal: false })}>
          <form onSubmit={this.saveUserName} className="signup-btn">
            <Modal.Header closeButton>
              <Modal.Title>Add Username!</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form.Group controlId="formBasicEmail" role="form">
                <Form.Label>Username</Form.Label>
                <Form.Control type="text" placeholder="Enter Username" onChange={(e) => this.handleOnChange("username", e)} value={this.state.username} />
                <span className="err-val-msg">{this.state.fieldsErrors.username}</span>
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" type="submit">
                Submit
                                    </Button>
              <Button variant="secondary" onClick={() => this.setState({ showUsernameModal: false })}>
                Close
                                    </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    env: state.AllReducer.env.data,
    load_env: state.AllReducer.env.load_env,
    get_username: state.AllReducer.get_username,
    check_username: state.AllReducer.check_username
  };
};
const mapDispatchToProps = dispatch => ({
  action: {
    AllAction: bindActionCreators(AllAction, dispatch),
    InstallAction: bindActionCreators(InstallAction, dispatch)
  }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
