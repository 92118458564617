import axios from 'axios';
var host = window.location.hostname;
var baseUrl = "https://" + host + "/"
 var tempURL = "http://localhost:8081/";
 //var tempURL = 'https://reena.appmixo.com/'
var url = host.includes("localhost") ? tempURL : baseUrl
const BaseService = axios.create(
    {
        baseURL: url
    }
);
export default BaseService;
