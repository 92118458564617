import React, { Component } from 'react';
import { Button, Modal, Form } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import * as AllAction from '../Action/AllAction';
class SignUpModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            fieldsErrors: { email: "" }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.showSignUpModal !== prevProps.showSignUpModal) {
            if (this.props.showSignUpModal === false) {
                this.setState({
                    email: "",
                    fieldsErrors: { email: "" }
                })
            }
        }
    }
    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value,
        }, () => {
            this.setState({
                fieldsErrors: { [key]: "" },
                formValid: true
            })
        })
    };

    signUp = (e) => {
        e.preventDefault();
        var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-zA-Z0-9]+(\-[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+(\-[a-zA-Z0-9]+)*)*\.[a-zA-Z]{2,15}$/;
        if (this.state.email === "") {
            this.setState({
                fieldsErrors: {
                    email: "Enter Email-ID !",
                }
            })
        }
        else if (pattern.test(this.state.email) === false) {
            this.setState({
                fieldsErrors: {
                    email: "Enter valid Email-ID !",
                }
            })
        }
        else {
            this.props.handleCloseSignUp();
            let result = {
                username: this.state.email.toLowerCase(),
            }
            this.props.action.AllAction.sendSignupEmailAction(result);
        }

    }
    render() {
        return (
            <Modal show={this.props.showSignUpModal} onHide={this.props.handleCloseSignUp}>
                <form onSubmit={this.signUp} className="signup-btn">
                    <Modal.Header closeButton>
                        <Modal.Title>Sign Up!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="formBasicEmail" role="form">
                            <Form.Label>Email-ID</Form.Label>
                            <Form.Control type="email" placeholder="Enter email-id" onChange={(e) => this.handleOnChange("email", e)} />
                            <span className="err-val-msg">{this.state.fieldsErrors.email}</span>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" type="submit">
                            SignUp
                        </Button>
                        <Button variant="secondary" onClick={this.props.handleCloseSignUp}>
                            Close
                        </Button>

                    </Modal.Footer>
                    <div className="login-msg">Login if you have already signed up.</div>
                </form>
            </Modal>
        );
    }
}


const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
    }
});
export default connect(null, mapDispatchToProps)(SignUpModal);