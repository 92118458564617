import React, { Component } from 'react';
import SideNav, { NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { withRouter } from 'react-router-dom';
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import { MdAccountCircle } from "react-icons/md";
import { FaProductHunt, FaHeadphones } from 'react-icons/fa'
import { AiFillDashboard } from "react-icons/ai";
import { IoMdHelpCircle, IoMdLogOut } from "react-icons/io";
var logo = require("../img/app-logo.png");

class NavbarHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "/",
            toggle: false,
        };
    };
    toggleNav = () => {
        this.setState({
            toggle: !this.state.toggle
        })
    }
    logOut = () => {
        localStorage.clear();
        this.props.history.push("/")
    }
    render() {

        const user_from = localStorage.getItem("user_from");
        return (
            <SideNav
                onSelect={(selected) => {
                    this.setState({
                        selected: selected
                    })
                    if (selected === "logout") {

                        const asyncLocalStorage = {
                            setItem: function () {
                                return Promise.resolve().then(function () {
                                    localStorage.clear();
                                });
                            }
                        };
                        asyncLocalStorage.setItem()
                            .then(() => {
                                window.open("/winningproducts", "_self")
                            })
                    }
                    else {
                        this.props.history.push("/" + selected)
                    }
                }}>
                <SideNav.Toggle onClick={() => this.toggleNav()}></SideNav.Toggle>
                {this.state.toggle &&
                    <><span className="shopname-nav"></span>
                        <div className="nav-logo-sidebar"><span></span><img src={logo} height="120px" width="120px"></img></div></>}
                <SideNav.Nav >
                    <NavItem eventKey="">
                        <NavIcon>
                            <AiFillDashboard />
                        </NavIcon>
                        <NavText >
                            Dashboard
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="products">
                        <NavIcon>
                            <FaProductHunt />
                        </NavIcon>
                        <NavText >
                            Products
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="getsupport">
                        <NavIcon>
                            <FaHeadphones />
                        </NavIcon>
                        <NavText >
                            Get Support
                        </NavText>
                    </NavItem>
                    <NavItem eventKey="helps">
                        <NavIcon>
                            <IoMdHelpCircle />
                        </NavIcon>
                        <NavText >
                            FAQ
                        </NavText>
                    </NavItem>
                    {/* <NavItem eventKey="account">
                        <NavIcon>
                            <MdAccountCircle />
                        </NavIcon>
                        <NavText >
                            Account
                        </NavText>
                    </NavItem> */}
                    <NavItem eventKey={user_from !== "website" ? "account" : "bill-payments"}>
                        <NavIcon>
                            <MdAccountCircle />
                        </NavIcon>
                        <NavText >
                            Account
                        </NavText>
                    </NavItem>

                    {user_from === "website" &&
                        <NavItem className="logout-sidebar" eventKey="logout">
                            <NavIcon>
                                <IoMdLogOut />
                            </NavIcon>
                            <NavText >
                                Log out
                            </NavText>
                        </NavItem>}
                </SideNav.Nav>
            </SideNav>
        );
    }
}
export default withRouter(NavbarHeader);