import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from './Loader';

function PrivateRouter({ component: Component, ...rest }) {
    const localshop = localStorage.getItem("shop_wp");
    const localtoken = localStorage.getItem("token_wp");
    return (
        <Route {...rest} render={props => (
            localtoken !== ("undefined" || "") && localshop !== ("undefined" || "") && localtoken === rest.on_refresh_data.token && localshop === rest.on_refresh_data.shop
                ? <Component {...props} />
                : <Loader {...props} history={rest.history} />
        )} />
    )
}
const mapStateToProps = (state) => {
    return {
        on_refresh_data: state.InstallReducer.on_refresh_data
    }
}

export default connect(mapStateToProps)(PrivateRouter);