import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { Button, Card, Accordion } from "react-bootstrap";
import * as InstallAction from '../Action/installAction';
import NavbarHeader from './NavbarHeader';
import Header from './Header';
class Help extends Component {
    render() {
        return (
            <div className="root-page">
                <NavbarHeader />
                <div className="main-container">
                    <Header header_name="Winning Products" history={this.props.history} CDN_URL={this.props.env.CDN_URL} />
                    <h4 className="section-title">FAQ</h4>
                    <Accordion>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                    What are the winning products?
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body>Our experts keep on eye on products those are unsaturated, monitor them for a few days and if find them on potential and problem-solving approach, they list them here as winning products.
                                    </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    What is the use of the application?
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body>Using this app merchants should not have to search for the winning products elsewhere. Merchant can find products following their niche, review content and edit if they want and simply add to their Shopify store with just one click.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    What Service does winning products provide?
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body>We list new products on a daily bases. Our specialists hand-pick the products and upload them. The product data listed in Winning products include profit, Analytics, links, Facebook ads, product videos, and offer types. This valuable content we put unitedly so that the user can determine their winning products.</Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                    What are the Niche Products?
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>
                                   <span> Some times you may not find suitable products for your store. Not to worry, our experts' team is here to help you. You can request us to find products just for your niche and your store.
We are Delivering tens of hundreds of products daily to merchants!</span>
<div className="faq-btn"><Button onClick={() => this.props.history.push("/products#niche")}>Request us</Button></div>
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        {/* <Card>
                            <Card.Header>
                                <Accordion.Toggle as={Button} variant="link" eventKey="5">
                                    What are the costs of using this app?
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body>Winning products listed delay by 7 days is free. to see the latest winning products we charge a small fee of $9.99/month. There may be variable charges on custom jobs.</Card.Body>
                            </Accordion.Collapse>
                        </Card> */}
                    </Accordion>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        store_data: state.InstallReducer.store_data,
        env: state.AllReducer.env.data
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        InstallAction: bindActionCreators(InstallAction, dispatch)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(Help);