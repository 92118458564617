import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import NavbarHeader from "./NavbarHeader";
import Header from "./Header";
import { Table, InputGroup, FormControl, Button, OverlayTrigger, Modal, Tooltip, Form } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { BsFillChatQuoteFill } from "react-icons/bs";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import Loader from "react-loader";
import Select from "react-select";
import * as AllAction from '../Action/AllAction';
import * as InstallAction from '../Action/installAction';
import swal from 'sweetalert';
const pdf_icon = require("../img/pdf-image.png");

class SupplierList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            get_supplier: [],
            pageNumber: 1,
            activePage: 1,
            selected_category: "",
            by_location: "",
            showUsernameModal: false,
            username: "",
            fieldsErrors: { username: "" },
            isUsernameExist: false
        }
    }
    componentWillMount() {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, page: this.state.activePage
        }
        this.props.action.AllAction.GetSupplier(data);
        this.props.action.AllAction.getUsername(data);
        const env_data = {
            secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
        };
        this.props.load_env !== true && this.props.action.AllAction.GetEnvAction(env_data);
        const data_cat = {
            accessToken,
            shop
        };
        this.props.load_cat !== true && this.props.action.AllAction.getCategoryAction(data_cat);
    }

    componentDidUpdate(prevProps) {
        if (this.props.get_username !== (prevProps.get_username)) {
            if (this.props.get_username.isUserExist === true && this.props.get_username.username) {
                this.setState({
                    isUsernameExist: true,
                    username: this.props.get_username.username
                }, () => {
                    // console.log(this.state.isUsernameExist, this.state.username)
                })
            }
        }

        if (this.props.check_username !== (prevProps.check_username)) {
            if (this.props.check_username.isUsernameExist === true) {
                //username exist, get another username
                swal({
                    text: "This username is already exist! Please try another username.",
                    icon: "warning",
                    dangerMode: true,
                })
                this.setState({
                    isUsernameExist: false,
                    username: "",
                    sendMessageData: null
                })
            }
            else if (this.props.check_username.isUsernameExist === false && this.props.check_username.isUserGenerated === true) {
                if (this.state.sendMessageData) {
                    this.state.sendMessageData.userType = 'supplier';
                    this.state.sendMessageData.username = this.state.username;
                    // console.log(this.state.sendMessageData)
                    this.props.history.push({
                        pathname: '/messages',
                        userData: this.state.sendMessageData // your data array of objects
                    });
                }
            }
        }
    }

    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        }, () => this.apiCall())
    }
    apiCall = () => {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, page: this.state.activePage,
            search: {
                by_location: this.state.by_location,
                by_category: this.state.selected_category.value ? this.state.selected_category.value : "",
            }
        }
        this.props.action.AllAction.GetSupplier(data);
    }
    handleLinkClick = link => {
        window.open(link, "_blank");
    };
    api_call_with_search = e => {
        var code = e.keyCode || e.which;
        if (code === 13) {
            //13 is the enter keycode
            this.apiCall();
        }
    };
    handleOnChange = (key, e) => {
        this.setState(
            {
                [key]: e.target.value
            },
            () => {
                if (this.state[key] === "") {
                    this.apiCall();
                }
            }
        );
    };
    onChangeCat = value => {
        this.setState(
            {
                selected_category: value
            },
            () => {
                this.apiCall();
            }
        );
    };

    sendMessage = (data) => {
        this.setState({ sendMessageData: data });
        // console.log(this.state.isUsernameExist, this.state.username)
        if (this.state.isUsernameExist === false) {
            // console.log('username is not exist, get username from user');
            this.setState({ showUsernameModal: true });
        }
        else if (this.state.isUsernameExist === true && this.state.username !== "") {
            // console.log('username exist, land user to messaging page');
            data.userType = 'supplier';
            data.username = this.state.username;
            // console.log(data)
            this.props.history.push({
                pathname: '/messages',
                userData: data // your data array of objects
            });
        }
    };

    saveUserName = (e) => {
        e.preventDefault();
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, username: this.state.username
        }
        this.props.action.AllAction.checkUsername(data);
        this.setState({ showUsernameModal: false })
    }

    render() {

        // const host = window.location.hostname;
        // let isLive = false;
        // if (host === 'winmixo.com' || host === 'live.appmixo.com') {
        //     isLive = true;
        // }

        //Add ?milan-testing at URL for message btn

        var showMsgBtn = false;

        if (window.location.search === "?milan-testing") {
            showMsgBtn = true;
        }

        var all_supplier_data = this.props.load_sup && this.props.load_env && this.props.get_supplier && this.props.get_supplier.length !== 0 ?
            this.props.get_supplier.map((get_supplier, i) => {
                var cat_f = typeof (get_supplier.store_category) === 'string' ?
                    get_supplier.store_category :
                    (Array.isArray(get_supplier.store_category) && get_supplier.store_category.length !== 0) ?
                        get_supplier.store_category.map(u => u.label).join(', ') : ""


                var location =
                    (get_supplier.supplier_city !== '' && (get_supplier.supplier_state === "" && get_supplier.supplier_country === "")) ? get_supplier.supplier_city
                        : (get_supplier.supplier_state !== '' && (get_supplier.supplier_city === "" && get_supplier.supplier_country === "")) ? get_supplier.supplier_state
                            : (get_supplier.supplier_country !== '' && (get_supplier.supplier_city === "" && get_supplier.supplier_state === "")) ? get_supplier.supplier_country
                                : (get_supplier.supplier_city !== '' && get_supplier.supplier_state !== "" && get_supplier.supplier_country === "") ? get_supplier.supplier_city + ", " + get_supplier.supplier_state
                                    : (get_supplier.supplier_country !== '' && get_supplier.supplier_city !== "" && get_supplier.supplier_state === "") ? get_supplier.supplier_city + ", " + get_supplier.supplier_country
                                        : (get_supplier.supplier_country !== '' && get_supplier.supplier_state !== "" && get_supplier.supplier_city === "") ? get_supplier.supplier_state + ", " + get_supplier.supplier_country
                                            : (get_supplier.supplier_country !== '' && get_supplier.supplier_state !== "" && get_supplier.supplier_city !== "") ? get_supplier.supplier_city + ", " + get_supplier.supplier_state + ", " + get_supplier.supplier_country
                                                : (get_supplier.supplier_country === '' && get_supplier.supplier_state === "" && get_supplier.supplier_city === "") ? "" : ""
                return <tr key={i}>
                    <td valign="middle">{get_supplier.store_link}

                        {/* { isLive ? null : */}

                        {showMsgBtn !== true ? null :
                            <BsFillChatQuoteFill onClick={() => this.sendMessage(get_supplier)} />}
                    </td>
                    <td valign="middle">{get_supplier.supplier_email}</td>
                    <td valign="middle">{get_supplier.supplier_mobile}</td>
                    <td valign="middle">{location}</td>
                    <td valign="middle" className="supplier-product"><div className="supplier-product-inn">{cat_f}</div></td>
                    <td valign="middle" className="supplier-product"><div className="supplier-product-inn">{get_supplier.supplier_product}</div></td>
                    <td valign="middle">{get_supplier.supplier_product_catalog && get_supplier.supplier_product_catalog !== "" &&
                        <div
                            className="pdf-sup"
                            onClick={() => this.handleLinkClick(this.props.env.CDN_URL + get_supplier.supplier_product_catalog)}>
                            <img src={pdf_icon} width="40px" height="40px" alt=""></img>
                        </div>}</td>
                </tr>
            }) : <tr><td colSpan="7">No any supplier.</td></tr>

        return (
            <div className="root-page">
                <NavbarHeader />
                <div className="main-container">
                    <Header
                        header_name="Winning Products"
                        history={this.props.history}
                    />
                    <div className="page-container">
                        <div>
                            <h4 className="section-title">Supplier</h4>
                        </div>
                        {this.props.load_sup === false && <div className="loader-container">
                            <Loader
                                loaded={this.props.load_sup && this.props.load_env}
                                lines={10}
                                length={5}
                                width={5}
                                radius={10}
                                corners={1}
                                rotate={0}
                                direction={1}
                                color="#1f3893"
                                speed={1}
                                trail={40}
                                shadow={false}
                                hwaccel={false}
                                className="spinner"
                                zIndex={2e9}
                                top="50%"
                                left="50%"
                                scale={1.0}
                                loadedClassName="loadedContent"
                            />
                        </div>}
                        {this.props.load_sup &&
                            <>
                                {/* <div className="search-fields supplier">
                                    <InputGroup>
                                        <GoSearch />
                                        <FormControl
                                            placeholder="Search by location"
                                            aria-label="Search by location"
                                            aria-describedby="basic-addon2"
                                            onKeyPress={this.api_call_with_search.bind(this)}
                                            onChange={e => this.handleOnChange("by_location", e)}
                                            value={this.state.by_location}
                                        />
                                    </InputGroup>
                                    <InputGroup className="cat-pro">
                                        <div className="container">
                                            <Select
                                                options={this.props.get_categories}
                                                onChange={this.onChangeCat}
                                                value={this.state.selected_category}
                                                placeholder="Search category"
                                                isSearchable
                                                classNamePrefix="select_pro_category"
                                                autoFocus={false}
                                            />
                                        </div>
                                    </InputGroup>
                                </div> */}

                                <Table bordered responsive className="pro-data-table supplier--list">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "15%" }}>URL/Name</th>
                                            <th style={{ width: "11%" }}>Email-ID</th>
                                            <th style={{ width: "11%" }}>Contact no.</th>
                                            <th style={{ width: "14%" }}>Location</th>
                                            <th style={{ width: "20%" }}>Category</th>
                                            <th style={{ width: "20%" }}>Product</th>
                                            <th style={{ width: "11%" }}>Product catalog</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {all_supplier_data}
                                    </tbody>
                                </Table></>}
                    </div>
                    {this.props.load_sup && <div>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            pageRangeDisplayed={3}
                            totalItemsCount={this.props.total_records}
                            onChange={this.handlePageChange}
                            innerClass="innerClass"
                            activeClass="activeClass"
                            activeLinkClass="activeLinkClass"
                            itemClass="itemClass"
                            itemClassFirst="itemClassFirst"
                            itemClassPrev="itemClassPrev"
                            itemClassNext="itemClassNext"
                            itemClassLast="itemClassLast"
                            linkClass="linkClass"
                            linkClassFirst="linkClassFirst"
                            linkClassPrev="linkClassPrev"
                            linkClassNext="linkClassNext"
                            linkClassLast="linkClassLast"
                        />
                    </div>}
                </div>

                <Modal show={this.state.showUsernameModal} onHide={() => this.setState({ showUsernameModal: false })}>
                    <form onSubmit={this.saveUserName} className="signup-btn">
                        <Modal.Header closeButton>
                            <Modal.Title>Add Username!</Modal.Title>
                        </Modal.Header>

                        <Modal.Body>
                            <Form.Group controlId="formBasicEmail" role="form">
                                <Form.Label>Username</Form.Label>
                                <Form.Control type="text" placeholder="Enter Username" onChange={(e) => this.handleOnChange("username", e)} value={this.state.username} />
                                <span className="err-val-msg">{this.state.fieldsErrors.username}</span>
                            </Form.Group>
                        </Modal.Body>

                        <Modal.Footer>
                            <Button variant="primary" type="submit">
                                Submit
                                    </Button>
                            <Button variant="secondary" onClick={() => this.setState({ showUsernameModal: false })}>
                                Close
                                    </Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        get_supplier: state.AllReducer.get_supplier.data,
        load_sup: state.AllReducer.get_supplier.load_sup,
        total_records: state.AllReducer.get_supplier.total_records,
        env: state.AllReducer.env.data,
        load_env: state.AllReducer.env.load_env,
        get_categories: state.AllReducer.get_categories.data,
        load_cat: state.AllReducer.get_categories.load_cat,
        get_username: state.AllReducer.get_username,
        check_username: state.AllReducer.check_username
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        InstallAction: bindActionCreators(InstallAction, dispatch),
        AllAction: bindActionCreators(AllAction, dispatch)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(SupplierList);