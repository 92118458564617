import * as authService from "../services/authService";
export const INSTALL_IN_STORE = "INSTALL_IN_STORE";
export const MATCH_STORE = "MATCH_STORE";
export const ON_REFRESH = "ON_REFRESH"
export const FAILED = "FAILED";

export const getTokenApp = (shop, history) => {
    return (dispatch) => {
        authService.getInstall(shop)
            .then((response) => {
                if (response.status === 200) {
                    localStorage.setItem("token_wp", response.data.token);
                    localStorage.setItem("shop_wp", response.data.shop);
                    localStorage.setItem("user_from", response.data.user_from);
                    if (response.data.token && response.data.shop) {
                        history.push("/products")
                    }
                    dispatch({
                        type: INSTALL_IN_STORE,
                        data: response.data,
                    });
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch({ type: FAILED, data: { error_msg: 'error.response.data.error ' } });
                }
            })
    }
}

export const getTokenOnRefreshPage = (shop, data) => {
    return (dispatch) => {
        authService.getInstall(shop)
            .then((response) => {
                if (response.status === 200) {
                    dispatch({
                        type: ON_REFRESH,
                        data: response.data,
                    });
                    var shop_wp = localStorage.getItem("shop_wp")
                    var token_wp = localStorage.getItem("token_wp")
                    if (response.data.token && response.data.shop) {
                        if ((shop_wp === response.data.shop) && (token_wp === response.data.token)) {
                            data.history.push("/products")
                        }
                    }
                }
            })
            .catch((error) => {
                if (error) {
                    dispatch({ type: FAILED, data: { error_msg: 'error.response.data.error ' } });
                }
            })
    }
}

export const getTokenMatchApp = () => {
    return (dispatch) => {
        dispatch({
            type: MATCH_STORE,
        });
    }
}




