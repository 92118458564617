import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { Table, InputGroup, FormControl, OverlayTrigger, Tooltip } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { connect } from 'react-redux';
import Pagination from "react-js-pagination";
import Select from "react-select";
import NavbarHeader from "./NavbarHeader";
import Loader from "react-loader";
import Header from "./Header";
import * as AllAction from '../Action/AllAction';
import * as InstallAction from '../Action/installAction';
import { FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";
import { BsFillChatQuoteFill } from "react-icons/bs";
import { TiSocialInstagram } from "react-icons/ti";
class InfList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            get_influencer: [],
            pageNumber: 1,
            activePage: 1,
            selected_category: "",
            by_location: ""
        }
    }
    componentWillMount() {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, page: this.state.activePage
        }
        this.props.action.AllAction.GetInfluencerAction(data);
        const data_cat = {
            accessToken,
            shop
        };
        this.props.load_cat !== true && this.props.action.AllAction.getCategoryAction(data_cat);
    }
    handlePageChange = (pageNumber) => {
        this.setState({
            activePage: pageNumber
        }, () => this.apiCall())
    }
    apiCall = () => {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, page: this.state.activePage,
            search: {
                by_location: this.state.by_location,
                by_category: this.state.selected_category.value ? this.state.selected_category.value : "",
            }
        }
        this.props.action.AllAction.GetInfluencerAction(data);
    }
    handleAccClick = (key, value) => {
        if (key === "yt" || key === "fb") {
            var c = value.includes("http");
            window.open(c === true ? value : "https://" + value, "_blank");
        }
        else if (key === "insta") {
            window.open("https://www.instagram.com/" + value, "_blank");
        }
        else if (key === "twit") {
            window.open("https://twitter.com/" + value, "_blank");
        }
    }
    renderTooltip = props => {
        return <Tooltip {...props}>{props}</Tooltip>;
    };

    api_call_with_search = e => {
        var code = e.keyCode || e.which;
        if (code === 13) {
            //13 is the enter keycode
            this.apiCall();
        }
    };
    handleOnChange = (key, e) => {
        this.setState(
            {
                [key]: e.target.value
            },
            () => {
                if (this.state[key] === "") {
                    this.apiCall();
                }
            }
        );
    };
    onChangeCat = value => {
        this.setState(
            {
                selected_category: value
            },
            () => {
                this.apiCall();
            }
        );
    };

    sendMessage = (data) => {
        console.log(data)
        data.userType = 'influencer';
        this.props.history.push({
            pathname: '/messages',
            data: data // your data array of objects
        });
    };

    render() {
        var all_order_data = this.props.load_inf && this.props.get_influencer && this.props.get_influencer.length !== 0 ?
            this.props.get_influencer.map((get_influencer, i) => {
                var social_acc = <div className="s-acc-main">
                    {get_influencer.inf_acc_link && get_influencer.inf_acc_link.link_instagram !== "" &&
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip("Click to view Instagram account")}>
                            <span className="social-acc-value" onClick={() => this.handleAccClick("insta", get_influencer.inf_acc_link.link_instagram)}><TiSocialInstagram size={25} color="#808080" />
                                <span>{get_influencer.inf_acc_link && get_influencer.inf_acc_followers.follower_instagram}</span></span>
                        </OverlayTrigger>
                    }
                    {get_influencer.inf_acc_link && get_influencer.inf_acc_link.link_fb !== "" &&
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip("Click to view Facebook account")}>
                            <span className="social-acc-value" onClick={() => this.handleAccClick("fb", get_influencer.inf_acc_link.link_fb)}><FaFacebook size={25} color="#808080" />
                                <span>{get_influencer.inf_acc_followers && get_influencer.inf_acc_followers.follower_fb}</span></span>
                        </OverlayTrigger>}
                    {get_influencer.inf_acc_link && get_influencer.inf_acc_link.link_twitter !== "" &&
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip("Click to view Twitter account")}>
                            <span className="social-acc-value" onClick={() => this.handleAccClick("twit", get_influencer.inf_acc_link.link_twitter)}><FaTwitter size={25} color="#808080" />
                                <span>{get_influencer.inf_acc_followers && get_influencer.inf_acc_followers.follower_twitter}</span></span>
                        </OverlayTrigger>}
                    {get_influencer.inf_acc_link && get_influencer.inf_acc_link.link_youtube !== "" &&
                        <OverlayTrigger
                            placement="bottom"
                            delay={{ show: 250, hide: 400 }}
                            overlay={this.renderTooltip("Click to view Youtube channel")}>
                            <span className="social-acc-value" onClick={() => this.handleAccClick("yt", get_influencer.inf_acc_link.link_youtube)}><FaYoutube size={25} color="#808080" />
                                <span>{get_influencer.inf_acc_followers && get_influencer.inf_acc_followers.follower_youtube}</span></span>
                        </OverlayTrigger>}
                </div>
                var location =
                    (get_influencer.inf_city !== '' && (get_influencer.inf_state === "" && get_influencer.inf_country === "")) ? get_influencer.inf_city
                        : (get_influencer.inf_state !== '' && (get_influencer.inf_city === "" && get_influencer.inf_country === "")) ? get_influencer.inf_state
                            : (get_influencer.inf_country !== '' && (get_influencer.inf_city === "" && get_influencer.inf_state === "")) ? get_influencer.inf_country
                                : (get_influencer.inf_city !== '' && get_influencer.inf_state !== "" && get_influencer.inf_country === "") ? get_influencer.inf_city + ", " + get_influencer.inf_state
                                    : (get_influencer.inf_country !== '' && get_influencer.inf_city !== "" && get_influencer.inf_state === "") ? get_influencer.inf_city + ", " + get_influencer.inf_country
                                        : (get_influencer.inf_country !== '' && get_influencer.inf_state !== "" && get_influencer.inf_city === "") ? get_influencer.inf_state + ", " + get_influencer.inf_country
                                            : (get_influencer.inf_country !== '' && get_influencer.inf_state !== "" && get_influencer.inf_city !== "") ? get_influencer.inf_city + ", " + get_influencer.inf_state + ", " + get_influencer.inf_country
                                                : (get_influencer.inf_country === '' && get_influencer.inf_state === "" && get_influencer.inf_city === "") ? "" : ""

                var cat_f = typeof (get_influencer.inf_category) === 'string' ?
                    get_influencer.inf_category :
                    (Array.isArray(get_influencer.inf_category) && get_influencer.inf_category.length !== 0) ?
                        get_influencer.inf_category.map(u => u.label).join(', ') : ""

                return <tr key={i}>
                    <td valign="middle">{get_influencer.inf_name}
                        {/* <BsFillChatQuoteFill onClick={() => this.sendMessage(get_influencer)} /> */}
                    </td>
                    <td valign="middle">{get_influencer.inf_email}</td>
                    <td valign="middle">{cat_f}</td>
                    <td valign="middle">{social_acc}</td>
                    <td valign="middle">{location}</td>
                </tr>
            }) : <tr><td colSpan="6">No any Influencer</td></tr>

        return (
            <div className="root-page" >
                <NavbarHeader />
                <div className="main-container">
                    <Header
                        header_name="Winning Products"
                        history={this.props.history}
                    />
                    <div className="page-container">
                        <div>
                            <h4 className="section-title">Influencer</h4>
                        </div>
                        {this.props.load_inf == false && <div className="loader-container">
                            <Loader
                                loaded={this.props.load_inf}
                                lines={10}
                                length={5}
                                width={5}
                                radius={10}
                                corners={1}
                                rotate={0}
                                direction={1}
                                color="#1f3893"
                                speed={1}
                                trail={40}
                                shadow={false}
                                hwaccel={false}
                                className="spinner"
                                zIndex={2e9}
                                top="50%"
                                left="50%"
                                scale={1.0}
                                loadedClassName="loadedContent"
                            />
                        </div>}
                        {this.props.load_inf &&
                            <>
                                {/* <div className="search-fields supplier">
                                    <InputGroup>
                                        <GoSearch />
                                        <FormControl
                                            placeholder="Search by location"
                                            aria-label="Search by location"
                                            aria-describedby="basic-addon2"
                                            onKeyPress={this.api_call_with_search.bind(this)}
                                            onChange={e => this.handleOnChange("by_location", e)}
                                            value={this.state.by_location}
                                        />
                                    </InputGroup>
                                    <InputGroup className="cat-pro">
                                        <div className="container">
                                            <Select
                                                options={this.props.get_categories}
                                                onChange={this.onChangeCat}
                                                value={this.state.selected_category}
                                                placeholder="Search category"
                                                isSearchable
                                                classNamePrefix="select_pro_category"
                                                autoFocus={false}
                                            />
                                        </div>
                                    </InputGroup>
                                </div> */}

                                <Table bordered responsive className="pro-data-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "20%" }}>Name</th>
                                            <th style={{ width: "20%" }}>Email-ID</th>
                                            <th style={{ width: "20%" }}>Category</th>
                                            <th style={{ width: "20%" }}>Account Link</th>
                                            <th style={{ width: "20%" }}>Location</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {all_order_data}
                                    </tbody>
                                </Table>
                            </>
                        }
                    </div>
                    {this.props.load_inf && <div>
                        <Pagination
                            activePage={this.state.activePage}
                            itemsCountPerPage={10}
                            pageRangeDisplayed={3}
                            totalItemsCount={this.props.total_records}
                            onChange={this.handlePageChange}
                            innerClass="innerClass"
                            activeClass="activeClass"
                            activeLinkClass="activeLinkClass"
                            itemClass="itemClass"
                            itemClassFirst="itemClassFirst"
                            itemClassPrev="itemClassPrev"
                            itemClassNext="itemClassNext"
                            itemClassLast="itemClassLast"
                            linkClass="linkClass"
                            linkClassFirst="linkClassFirst"
                            linkClassPrev="linkClassPrev"
                            linkClassNext="linkClassNext"
                            linkClassLast="linkClassLast"
                        />
                    </div>}
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        get_influencer: state.AllReducer.get_influencer.data,
        total_records: state.AllReducer.get_influencer.total_records,
        load_inf: state.AllReducer.get_influencer.load_inf,
        get_categories: state.AllReducer.get_categories.data,
        load_cat: state.AllReducer.get_categories.load_cat
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        InstallAction: bindActionCreators(InstallAction, dispatch),
        AllAction: bindActionCreators(AllAction, dispatch)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(InfList);