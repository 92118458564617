import BaseService from './baseService';
import qs from 'qs';

export function signUp(data) {
    return BaseService.post('/winningproducts/signup_website', qs.stringify(data));
}

export function signIn(data) {
    return BaseService.post('/winningproducts/login_website', qs.stringify(data));
}

export function getFirebaseCredentials(data) {
    return BaseService.post('/winningproducts/get_firebase_credentials', qs.stringify(data));
}

export function sendOfflineUserEmail(data) {
    return BaseService.post('/winningproducts/send_offline_user_email', qs.stringify(data));
}

export function getUsername(data) {
    return BaseService.post('/winningproducts/get_username', qs.stringify(data));
}

export function checkUsername(data) {
    return BaseService.post('/winningproducts/check_username', qs.stringify(data));
}

export function getInstall(shop) {
    return BaseService.get('/winningproducts/get_token?shop=' + shop)
}
export function getProducts(data) {
    return BaseService.post('/winningproducts/get_products', qs.stringify(data))
}
export function getCategory(data) {
    return BaseService.post('/winningproducts/get_categories', qs.stringify(data))
}
export function getSupport(data) {
    return BaseService.post('/winningproducts/get_support', qs.stringify(data))
}
export function addToShopify(data) {
    return BaseService.post('/winningproducts/add_to_shopify', qs.stringify(data))
}
export function singleProduct(data) {
    return BaseService.post('/winningproducts/get_product_by_id', qs.stringify(data))
}
export function getShop(data) {
    return BaseService.post('/winningproducts/get_shop', qs.stringify(data))
}
export function getEnv(data) {
    return BaseService.post('/winningproducts/get_s3_uri', qs.stringify(data))
}
export function saveSeo(data) {
    return BaseService.post('/winningproducts/save_seo', qs.stringify(data))
}
export function saveMarketing(data) {
    return BaseService.post('/winningproducts/save_marketing', qs.stringify(data))
}
export function saveSupplier(data) {
    return BaseService.post('/winningproducts/save_supplier', qs.stringify(data))
}
export function saveInfluencer(data) {
    return BaseService.post('/winningproducts/save_influencer', qs.stringify(data))
}
export function getServices(data) {
    return BaseService.post('/winningproducts/get_services', qs.stringify(data))
}
export function placeOrder(data) {
    return BaseService.post('/winningproducts/create-checkout-session-single', qs.stringify(data))
}
export function placeOrderShopify(data) {
    return BaseService.post('/winningproducts/placeOrder', qs.stringify(data))
}

export function getOrder(data) {
    return BaseService.post('/winningproducts/getorders', qs.stringify(data))
}
export function planUpgrade(data) {
    return BaseService.post('/winningproducts/create-checkout-session-subscription', qs.stringify(data))
}

export function planUpgradeShopifyToStripe(data) {
    return BaseService.post('/winningproducts/create-checkout-shopify-user', qs.stringify(data))
}

export function planUpgradeShopify(data) {
    return BaseService.post('/winningproducts/planUpgrade', qs.stringify(data))
}

export function planDowngrade(data) {
    return BaseService.post('/winningproducts/planDowngrade', qs.stringify(data))
}
export function productsByJob(data) {
    return BaseService.post('/winningproducts/products_by_job', qs.stringify(data))
}
export function homeAllProducts(data) {
    return BaseService.post('/winningproducts/getproducts', qs.stringify(data))
}
export function homeProductById(data) {
    return BaseService.post('/winningproducts/gethomeproductbyid', qs.stringify(data))
}
export function getInfluencer(data) {
    return BaseService.post('/winningproducts/search_influencer', qs.stringify(data))
}
export function getSupplier(data) {
    return BaseService.post('/winningproducts/search_supplier', qs.stringify(data))
}
export function publicKey() {
    return BaseService.get('/winningproducts/public-key')
}
export function getCountry() {
    return BaseService.get('/winningproducts/getCountry')
}
export function getSubscriptionPlan(data) {
    return BaseService.post('/winningproducts/get_subscription_plan', qs.stringify(data))
}
export function getUserPayType(data) {
    return BaseService.post('/winningproducts/get_user_pay_type', qs.stringify(data))
}
export function isAnyDoneJobs(data) {
    return BaseService.post('/winningproducts/is_any_done_jobs', qs.stringify(data))
}
export function getShopoifyMF(data) {
    return BaseService.post('/winningproducts/get_money_formate', qs.stringify(data))
}
export function getShopSubscription(data) {
    return BaseService.post('/winningproducts/get_shop_subscription', qs.stringify(data))
}
export function forgotPassword(data) {
    return BaseService.post('/winningproducts/forgotpass_website', qs.stringify(data))
}
export function changePassword(data) {
    return BaseService.post('/winningproducts/changepassword', qs.stringify(data))
}
export function updateForgotPassword(data) {
    return BaseService.post('/winningproducts/updateforgotpassword', qs.stringify(data))
}
export function getAllSub(data) {
    return BaseService.post('/winningproducts/get_all_subscription', qs.stringify(data))
}

export function setsSignupPass(data) {
    return BaseService.post('/winningproducts/setsignup_password', qs.stringify(data))
}
export function sendSignupemail(data) {
    return BaseService.post('/winningproducts/send_signup_email', qs.stringify(data))
}
export function hideLink(data) {
    return BaseService.post('/winningproducts/hide_link_status', qs.stringify(data))
}
export function hideATS(data) {
    return BaseService.post('/winningproducts/hide_ATS_status', qs.stringify(data))
}
export function hideLinkLogout(data) {
    return BaseService.post('/winningproducts/hide_link_status_logout', qs.stringify(data))
}
export function submitFriendEmails(data) {
    return BaseService.post('/winningproducts/submit_ref_emails', qs.stringify(data))
}
export function addRefCode(data) {
    return BaseService.post('/winningproducts/add_ref_code', qs.stringify(data))
}
export function isDisplayRefBtn(data) {
    return BaseService.post('/winningproducts/is_display_ref_btn', qs.stringify(data))
}
export function getStripCustomerMessages(data) {
    return BaseService.post('/winningproducts/get_stripe_customer_messages', qs.stringify(data))
}
export function generateRefCode(data) {
    return BaseService.post('/winningproducts/generate_ref_code', qs.stringify(data))
}
export function getRefCode(data) {
    return BaseService.post('/winningproducts/get_ref_code', qs.stringify(data))
}
export function sendEmailOfResponseTime(data) {
    return BaseService.post('/winningproducts/res_time_email', qs.stringify(data))
}
export function getCustomerCardDeails(data) {
    return BaseService.post('/winningproducts/get_customer_card_deails', qs.stringify(data))
}
