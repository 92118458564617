import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import YouTube from 'react-youtube';
import swal from "sweetalert";
const pdf_icon1 = require("../img/pdf-image.png");
const fb_ads1 = require("../img/fb-ads.png");
class AllImages extends Component {
    handleLinkClick = (link) => {
        if (this.props.hide_link && this.props.hide_link.is_hide_status === true &&
            (this.props.modal_for === "product_fb_images" || this.props.modal_for === 'product_fb_documents'
                || this.props.modal_for === 'product_fb_viedo')) {
            this.props.onHide();
            this.handleSwal()
        }
        else {
            window.open(link, "_blank");
        }
    }
    handleSwal = () => {
        swal({
            text: "To Unlock this link, you need Premium. that's how we are paying for winning product experts.",
            dangerMode: false,
            button: 'Get premium!'
        }).then((value) => {
            if (value)
                this.props.history.push("/account");
        });
    }
    onReady(event) {
        event.target.pauseVideo();
    }
    render() {
        const opts = {
            height: '200',
            width: '150',
            playerVars: {
                autoplay: 0
            }
        };
        const id = this.props.product_image_id
        const filter_image = this.props.all_product_data && this.props.all_product_data.length !== 0 && this.props.all_product_data.filter(all_product_data => {
            return all_product_data._id === id
        })
        // const main_image = filter_image && filter_image.length !== 0 && filter_image[0].product_main_image;
        var f_main_image = filter_image && filter_image.length !== 0 && filter_image[0]
            && (typeof (filter_image[0].product_main_image) === "string") && (filter_image[0].product_main_image.includes("http") ?
                filter_image[0].product_main_image :
                this.props.CDN_URL + filter_image[0].product_main_image.replace("images", "thumb_images"));

        var f_main_image_onclick = filter_image && filter_image.length !== 0 && filter_image[0] && (typeof (filter_image[0].product_main_image) === "string" && filter_image[0].product_main_image.includes("http") ?
            filter_image[0].product_main_image :
            this.props.CDN_URL + filter_image[0].product_main_image);

        const other_images = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_images.map((product_images, i) => {
            return <div key={i}><img src={(typeof product_images === "string") && (product_images.includes("http") ? product_images :
                this.props.CDN_URL + product_images.replace("images", "thumb_images"))}
                width="120px" height="120px" alt="NoImage"
                onClick={() => this.handleLinkClick(product_images.includes("http") ? product_images :
                    this.props.CDN_URL + product_images)}></img></div>
        })
        const product_name = filter_image && filter_image.length !== 0 && filter_image[0].product_name;

        const fb_ads_images = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_fb_ads.image && filter_image[0].product_fb_ads.image.length !== 0 &&
            filter_image[0].product_fb_ads.image.map((product_fb_images, i) => {
                return <div key={i} ><img src={(typeof product_fb_images === "string") && (product_fb_images.includes("http") ?
                    product_fb_images : this.props.CDN_URL + product_fb_images.replace("images", "thumb_images"))}
                    width="120px" height="120px" alt="NoImage"
                    onClick={() => this.handleLinkClick(product_fb_images.includes("http") ? product_fb_images : this.props.CDN_URL + product_fb_images)}></img></div>
            })

        const fb_ads_documents = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_fb_ads.document && filter_image[0].product_fb_ads.document.length !== 0 &&
            filter_image[0].product_fb_ads.document.map((product_fb_doc, i) => {
                return <div key={i} ><img src={pdf_icon1} width="120px" height="120px" alt="NoImage"
                    onClick={() => this.handleLinkClick(product_fb_doc.includes("http") ? product_fb_doc : this.props.CDN_URL + product_fb_doc)}></img>
                </div>
            })

        const fb_ads_videos = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_fb_ads.video && filter_image[0].product_fb_ads.video.length !== 0 &&
            filter_image[0].product_fb_ads.video.map((video, i) => {
                return <div key={i} ><img src={fb_ads1} width="130px" height="90px" alt="NoImage" onClick={() => this.handleLinkClick(video.toString())}></img></div>
            })

        const engage_images = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_engagement.image && filter_image[0].product_engagement.image.length !== 0 &&
            filter_image[0].product_engagement.image.map((product_engage_images, i) => {
                return <div key={i} ><img src={(typeof product_engage_images === "string") && (product_engage_images.includes("http") ?
                    product_engage_images : this.props.CDN_URL + product_engage_images.replace("images", "thumb_images"))} width="120px" height="120px" alt="NoImage"
                    onClick={() => this.handleLinkClick(product_engage_images.includes("http") ? product_engage_images : this.props.CDN_URL + product_engage_images)}></img></div>
            })
        const engage_documents = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_engagement.document && filter_image[0].product_engagement.document.length !== 0 &&
            filter_image[0].product_engagement.document.map((product_fb_doc, i) => {
                return <div key={i} ><img src={pdf_icon1} width="100px" height="100px" alt="NoImage"
                    onClick={() => this.handleLinkClick(product_fb_doc.includes("http") ? product_fb_doc : this.props.CDN_URL + product_fb_doc)}></img>
                    <div className="document-view">View</div>
                </div>
            })

        const engage_videos = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_engagement.video && filter_image[0].product_engagement.video.length !== 0 &&
            filter_image[0].product_engagement.video.map((video, i) => {
                return <div key={i} ><img src={fb_ads1} width="130px" height="90px" alt="NoImage" onClick={() => this.handleLinkClick(video.toString())}></img></div>
            })

        const all_youtube_video = filter_image && filter_image.length !== 0 && filter_image[0] && filter_image[0].product_youtube_video && filter_image[0].product_youtube_video.length !== 0 &&
            filter_image[0].product_youtube_video.map((product_youtube_video, i) => {
                var index = product_youtube_video.indexOf('=');
                var arr = [product_youtube_video.slice(0, index), product_youtube_video.slice(index + 1)];
                return <div className="e-video-box"><YouTube
                    className="extra-video-group"
                    videoId={arr[1]}
                    opts={opts}
                    onReady={this._onReady} />
                    <div onClick={() => this.handleLinkClick(product_youtube_video.toString())} className="link-name">See in full screen</div>
                </div>
            })
        return (
            <div>
                {
                    this.props.modal_for === "product_images" && <Modal {...this.props} size="lg"
                        dialogClassName="extra-pro-images"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Images of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="e-pro-main-img"> <img src={f_main_image} alt="NoImage" onClick={() => this.handleLinkClick(f_main_image_onclick)}></img></div>
                            <div className="images-modal">
                                {other_images}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    this.props.modal_for === "product_fb_images" &&
                    <Modal {...this.props} size="lg" dialogClassName="extra-content-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Fb Ads Images of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="images-modal">
                                {fb_ads_images}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>

                }
                {
                    this.props.modal_for === "product_fb_documents" && <Modal {...this.props} size="lg"
                        dialogClassName="extra-content-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Fb Ads Documents of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div>Click to see fb ads documents</div>
                                <div className="images-modal">
                                    {fb_ads_documents}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    this.props.modal_for === "product_youtube_viedo" && <Modal {...this.props} size="lg"
                        dialogClassName="extra-video-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Youtube Videos of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="images-modal">
                                {all_youtube_video}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    this.props.modal_for === "product_fb_viedo" && <Modal {...this.props} size="lg"
                        dialogClassName="extra-content-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Fb Ads Videos of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>Click to see fb ads video</div>
                            <div className="images-modal">
                                {fb_ads_videos}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    this.props.modal_for === "product_engage_images" && <div className="extra-content-modal-container"><Modal {...this.props} size="lg"
                        dialogClassName="extra-content-modal"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Engagement Images of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="images-modal">
                                {engage_images}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    </div>
                }
                {
                    this.props.modal_for === "product_engage_documents" && <Modal {...this.props} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="extra-content-modal"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Engagement Documents of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <div>Click to see engagement documents</div>
                                <div className="images-modal">
                                    {engage_documents}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                }
                {
                    this.props.modal_for === "product_engage_viedo" && <Modal {...this.props} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        dialogClassName="extra-content-modal"
                        centered>
                        <Modal.Header closeButton>
                            <Modal.Title id="contained-modal-title-vcenter">
                                All Engagement Videos of {product_name}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div>Click to see engagement video</div>
                            <div className="images-modal">
                                {engage_videos}
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.props.onHide}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </div >

        );
    }
}

export default AllImages;