import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Table, Button, InputGroup, FormControl, OverlayTrigger, Tooltip, Modal, Tab, Tabs } from "react-bootstrap";
import { GoSearch } from "react-icons/go";
import { TiArrowUnsorted } from "react-icons/ti";
import * as InstallAction from "../Action/installAction";
import * as AllAction from "../Action/AllAction";
import AllImages from "./AllImages";
import Select from "react-select";
import Pagination from "react-js-pagination";
import ReactStars from "react-rating-stars-component";
import YouTube from "react-youtube";
import AddToShopify from "./AddToShopify";
import NavbarHeader from "./NavbarHeader";
import Header from "./Header";
import Loader from "react-loader";
import SeoNmarketing from "./SeoNmarketing";
import "./CSS/productStyle.scss";
import BespokedList from "./BespokedList";
import GetOrder from "./GetOrder";
import BespokedReq from './BespokedReq';
import swal from "sweetalert";
import AddToStore from "./AddToStore";
const pdf_icon = require("../img/pdf-image.png");
const fb_ads = require("../img/fb-ads.png");
const comments = require("../img/Comment-89.png");
const likes = require("../img/Like-18.png");
const reactions = require("../img/Smiles.png");
const share = require("../img/Share-91.png");
var youtube_icon = require("../img/youtube-icon.png");
var view = require("../img/view-42.png");

class Products extends Component {
  constructor(props) {
    super(props);
    this.state = {
      all_product_data: [],
      modalShow: false,
      loaded: false,
      show_youtube: false,
      product_image_id: "",
      pageNumber: 1,
      activePage: 1,
      total_product_count: 0,
      sort: "",
      display_youtube_video_id: "",
      apiCall: false,
      selected_category: "",
      by_interest: "",
      by_product: "",
      modal_for: "",
      route_id: "",
      loading: true,
      youtube_url: "",
      get_categories: [],
      show_add_to_shopify: false,
      show_add_to_store: false,
      shopify_p_data: {},
      store_p_data: {},
      modal_for_snm: "",
      modal_snm: false,
      loaded_env: false,
      loaded_order: false,
      get_order: [],
      shop_data: [],
      loaded_shop: false,
      modalShowsService: false,
      get_all_sub: [],
      default_tab: "",
      key: ""
    };
  }
  handleCloseAddToShopify = () => {
    this.setState({
      show_add_to_shopify: false
    });
  };
  handleCloseAddToStore = () => {
    this.setState({
      show_add_to_store: false
    });
  };
  handleshowAddToShopify = data => {
    this.setState(
      {
        shopify_p_data: data
      },
      () => {
        this.setState({
          show_add_to_shopify: true
        });
      }
    );
  };

  componentWillMount() {
    var defaultTab =
      window.location.href && (
        window.location.href.includes("#jobs")
          ? "getorder"
          : window.location.href.includes("#niche") ? "handpicked" : "general");
    this.setState({
      key: defaultTab
    })
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    var user_from = localStorage.getItem("user_from")
    const data = {
      accessToken,
      shop
    };
    const data_service = {
      accessToken, shop, tag: "niche_product"
    }
    const data_order = {
      accessToken, shop, page: "1"
    }
    this.props.reqData && this.setState(() => {
      var { search, page, sort } = this.props.reqData
      return {
        by_product: search.by_product,
        by_interest: search.by_interest,
        by_category: search.by_category, activePage: page, sort
      }
    });

    // this.props.action.AllAction.GetShopDetailsAction(data);
    this.props.action.AllAction.getAllSubAction(data);
    this.props.load_order !== true && this.props.action.AllAction.GetOrderAction(data_order);
    this.props.load_serv !== true && this.props.action.AllAction.GetServiceAction(data_service);
    // this.props.load_sd !== true && this.props.action.AllAction.GetShopDetailsAction(data);
    this.props.load_smf !== true && user_from === "shopify" && this.props.action.AllAction.getShopoifyMFAction(data);
  }
  componentDidMount() {
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    if (this.props.pro_by_cat.is_cat_selected) {
      this.setState({
        selected_category: { label: this.props.pro_by_cat.data.title, value: this.props.pro_by_cat.data._id }
      })
    }
    const data = {
      page: this.state.activePage,
      sort: this.state.sort,
      search: {
        by_product: "", by_category: "0", by_interest: ""
      },
      accessToken,
      shop
    };
    this.props.load_pro === true && this.setState((previousState) => ({ by_product: previousState.by_product }))
    this.props.load_pro !== true && this.props.action.AllAction.getAllProduct(data);
    const data_cat = {
      accessToken,
      shop
    };
    this.props.load_cat !== true && this.props.action.AllAction.getCategoryAction(data_cat);
    const hide_link = {
      accessToken,
      shop,
      tag: "link_hide",
      key: "link_hide"
    };
    const hide_ats = {
      accessToken,
      shop,
      tag: "add_to_shopify_hide",
      key: "add_to_shopify_hide"
    };
    this.props.load_hl !== true && this.props.action.AllAction.hideLinkAction(hide_link);
    this.props.load_ats !== true && this.props.action.AllAction.hideATSAction(hide_ats);
    this.props.history.listen((location, action) => {
      if (location.hash.includes("#jobs")) {
        this.setState({
          key: "getorder"
        })
      }
    });
  }
  componentDidUpdate(prevProps) {

    if (JSON.stringify(this.state.get_all_sub) !== JSON.stringify(this.props.get_all_sub)) {
      if (this.props.load_sub === true) {
        this.setState({
          get_all_sub: this.props.get_all_sub
        }, () => {
          var supp_arr = this.props.get_all_sub && this.props.get_all_sub.length !== 0 ?
            this.props.get_all_sub.filter((supp) => {
              return supp.subtag === 'winning_products'
            }) : []
          var plan_type = supp_arr && supp_arr.length !== 0 && supp_arr[0] && supp_arr[0].shop_plan_name;
          this.setState({ plan_type }, () => {
            this.setState({
              loaded_PT: true
            })
          });
          const accessToken = localStorage.getItem("token_wp");
          const shop = localStorage.getItem("shop_wp");
          const data = {
            accessToken, shop, plan_type
          }
          this.props.action.AllAction.GetShopDetailsAction(data);

        })
      }
    }
  }

  toggle = (id, key) => {
    this.setState({
      modalShow: !this.state.modalShow,
      product_image_id: id,
      modal_for: key
    });
  };

  _onReady(event) {
    event.target.pauseVideo();
  }

  handleOnChange = (key, e) => {
    this.setState(
      {
        [key]: e.target.value
      },
      () => {
        if (this.state[key] === "") {
          this.apiCall();
        }
      }
    );
  };

  apiCall = (filter_by) => {
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const data = {
      page: this.state.activePage,
      sort: this.state.sort,
      search: {
        by_product: this.state.by_product,
        by_category: this.state.selected_category.value,
        by_interest: this.state.by_interest
      },
      accessToken,
      shop
    };
    this.props.action.AllAction.getAllProduct(data);
  };

  handlePageChange = pageNumber => {
    this.setState(
      {
        activePage: pageNumber
      },
      () => this.apiCall()
    );
  };

  handleSort = () => {
    if (this.state.sort === "" || this.state.sort === "desc")
      this.setState(
        {
          sort: "asc"
        },
        () => {
          this.apiCall();
        }
      );
    else if (this.state.sort === "asc") {
      this.setState(
        {
          sort: "desc"
        },
        () => {
          this.apiCall();
        }
      );
    }
  };

  handleLinkClick = (link, is_show) => {
    if (this.props.load_hl && this.props.hide_link && this.props.hide_link.is_hide_status === true
      && is_show !== 'show') {
      this.handleSwal();
    }
    else {
      window.open(link, "_blank");
    }
  };
  handleSwal = () => {
    swal({
      text: "To Unlock this link, you need Premium. that's how we are paying for winning product experts.",
      dangerMode: false,
      button: 'Get Premium!',
      className: "btn-links"
    }).then((value) => {
      if (value)
        this.props.history.push("/account");
    });
  }
  handleSinglePageRouter = id => {
    this.props.history.push("/product/" + id);
  };
  onChangeCat = value => {
    this.setState(
      {
        selected_category: value
      },
      () => {
        this.props.action.AllAction.proByCatRemoveAction();
        this.apiCall();
      }
    );
  };
  renderTooltip = props => {
    return <Tooltip {...props}>{props}</Tooltip>;
  };
  handleClose = () => {
    this.setState(
      {
        show_youtube: false
      },
      () => {
        this.setState({
          display_youtube_video_id: "",
          youtube_url: ""
        });
      }
    );
  };
  toggleBP = () => {
    this.setState({
      modalShowsService: !this.state.modalShowsService,
    }, () => {
      if (this.state.modalShowsService === false)
        this.setState({ get_service: [] })
    })
  }
  handleShow = video => {
    if (this.props.load_hl && this.props.hide_link.is_hide_status === true) {
      this.handleSwal();
    }
    else {
      var index = video && video.length !== 0 && video[0].indexOf("=");
      var arr = [
        video && video.length !== 0 && video[0].slice(0, index),
        video && video.length !== 0 && video[0].slice(index + 1)
      ];
      this.setState(
        {
          display_youtube_video_id: arr[1],
          youtube_url: video[0]
        },
        () => {
          this.setState({
            show_youtube: true
          });
        }
      );
    }

  };
  api_call_with_search = e => {
    var code = e.keyCode || e.which;
    if (code === 13) {
      //13 is the enter keycode
      this.apiCall();
    }
  };
  toggle_snm = key => {
    this.setState({
      modal_for_snm: key,
      modal_snm: !this.state.modal_snm
    });
  };
  handleUpgrade = () => {
    // const accessToken = localStorage.getItem("token_wp");
    // const shop = localStorage.getItem("shop_wp");
    // const data = {
    //   accessToken,
    //   shop
    // };
    // this.props.action.AllAction.PlanUpdradeAction(data);
    this.props.history.push("/account")
  };
  setKey = (key) => {
    this.setState({
      key
    })
  }

  handleshowAddToStore = (data) => {
    this.setState({
      store_p_data: data
    },
      () => {
        this.setState({
          show_add_to_store: true
        });
      }
    );
  }

  render() {
    var user_from = localStorage.getItem("user_from")
    const opts = {
      playerVars: {
        autoplay: 0,
        controls: 0,
        forceSSL: 0,
        origin: "https://" + window.location.hostname
      }
    };
    const all_data =
      this.props.load_pro && this.props.all_product_data && this.props.all_product_data.length !== 0
        ? this.props.all_product_data.map((all_product_data, i) => {
          const links =
            this.props.load_env &&
            all_product_data.product_link &&
            all_product_data.product_link.length !== 0 &&
            all_product_data.product_link.map((product_link, i) => {
              return (
                <OverlayTrigger
                  key={i}
                  placement="auto"
                  delay={{ show: 250, hide: 400 }}
                  overlay={this.renderTooltip(product_link.title)}
                >
                  <div
                    className="t-link-icon"
                    onClick={() =>
                      this.handleLinkClick(product_link.link_name)
                    }
                  >
                    <img
                      src={product_link.icon.includes("http") ? product_link.icon :
                        product_link.icon && this.props.env.CDN_URL + product_link.icon.replace("images", "thumb_images")}
                      height="50px"
                      width="50px"
                      alt={product_link.title}
                    ></img>
                  </div>
                </OverlayTrigger>
              );
            });

          const get_id_youtube1 = all_product_data.product_youtube_video &&
            all_product_data.product_youtube_video.length !== 0 && (
              <div key={i}>
                <div
                  className="y-video-container"
                  onClick={() =>
                    this.handleShow(all_product_data.product_youtube_video)
                  }
                >
                  <div className="t-product-img-container-youtube">
                    <img src={youtube_icon.toString()} alt="NoImage"></img>
                  </div>
                </div>
                {all_product_data.product_youtube_video.length > 1 && (
                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() => this.props.load_hl && this.props.hide_link.is_hide_status === true
                      ? this.handleSwal()
                      : this.toggle(
                        all_product_data._id,
                        "product_youtube_viedo")}>
                    View all
                  </div>
                )}
              </div>
            );
          const fb_doc1 = this.props.load_env && all_product_data.product_fb_ads &&
            all_product_data.product_fb_ads.document &&
            all_product_data.product_fb_ads.document.length !== 0 && (
              <div>
                <div
                  className="fb-ads-img"
                  onClick={() =>
                    this.handleLinkClick(
                      all_product_data.product_fb_ads.document[0].includes("http") ? all_product_data.product_fb_ads.document[0] :
                        this.props.env.CDN_URL + all_product_data.product_fb_ads.document[0]
                    )
                  }
                >
                  <img
                    src={pdf_icon}
                    width="85px"
                    height="85px"
                    alt="No document"
                  ></img>
                </div>
                {all_product_data.product_fb_ads.document.length > 1 && (
                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() =>
                      this.toggle(
                        all_product_data._id,
                        "product_fb_documents"
                      )
                    }
                  >
                    All documents
                  </div>
                )}
              </div>
            );

          const fb_image1 = this.props.load_env && all_product_data.product_fb_ads &&
            all_product_data.product_fb_ads.image &&
            all_product_data.product_fb_ads.image.length !== 0 && (
              <div>
                <div
                  className="t-product-img-container"
                  onClick={() =>
                    this.handleLinkClick(
                      all_product_data.product_fb_ads.image[0].includes("http") ? all_product_data.product_fb_ads.image[0] :
                        (this.props.env.CDN_URL + all_product_data.product_fb_ads.image[0])
                    )
                  }
                >
                  <img
                    src={all_product_data.product_fb_ads.image[0].includes("http") ? all_product_data.product_fb_ads.image[0] :
                      this.props.env.CDN_URL + all_product_data.product_fb_ads.image[0].replace("images", "thumb_images")}
                    alt="NoImage"
                  ></img>
                </div>
                {all_product_data.product_fb_ads.image.length > 1 && (
                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() =>
                      this.toggle(all_product_data._id, "product_fb_images")
                    }
                  >
                    All images
                  </div>
                )}
              </div>
            );

          const fb_video1 = all_product_data.product_fb_ads &&
            all_product_data.product_fb_ads.video &&
            all_product_data.product_fb_ads.video.length !== 0 && (
              <div>
                <div
                  className="fb-ads-img"
                  onClick={() =>
                    this.handleLinkClick(
                      all_product_data.product_fb_ads.video[0]
                    )
                  }
                >
                  <img
                    src={fb_ads}
                    width="100px"
                    height="60px"
                    alt="No document"
                  ></img>
                </div>
                {all_product_data.product_fb_ads.video.length > 1 && (
                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() =>
                      this.toggle(all_product_data._id, "product_fb_viedo")
                    }
                  >
                    All videos
                  </div>
                )}
              </div>
            );

          const engage_doc1 = this.props.load_env && all_product_data.product_engagement &&
            all_product_data.product_engagement.document &&
            all_product_data.product_engagement.document.length !== 0 && (
              <div>
                <div
                  className="fb-ads-img"
                  onClick={() =>
                    this.handleLinkClick(
                      all_product_data.product_engagement.document[0].includes("http") ? all_product_data.product_engagement.document[0] :
                        this.props.env.CDN_URL + all_product_data.product_engagement.document[0], "show"
                    )
                  }
                >
                  {" "}
                  <img
                    src={pdf_icon}
                    width="85px"
                    height="85px"
                    alt="No document"
                  ></img>
                </div>
                {all_product_data.product_engagement.document.length > 1 && (
                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() =>
                      this.toggle(
                        all_product_data._id,
                        "product_engage_documents"
                      )
                    }
                  >
                    All documents
                  </div>
                )}
              </div>
            );

          const engage_image1 = this.props.load_env && all_product_data.product_engagement &&
            all_product_data.product_engagement.image &&
            all_product_data.product_engagement.image.length !== 0 && (
              <div>
                <div
                  className="t-product-img-container"
                  onClick={() =>
                    this.handleLinkClick(
                      all_product_data.product_engagement.image[0].includes("http") ? all_product_data.product_engagement.image[0] :
                        this.props.env.CDN_URL + all_product_data.product_engagement.image[0], 'show'
                    )}>
                  <img
                    src={all_product_data.product_engagement.image[0].includes("http") ? all_product_data.product_engagement.image[0] :
                      ((typeof (all_product_data.product_engagement.image[0]) === "string") &&
                        this.props.env.CDN_URL + all_product_data.product_engagement.image[0].replace("images", "thumb_images"))}
                    alt="NoImage"></img>
                </div>
                {all_product_data.product_engagement.image.length > 1 && (
                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() =>
                      this.toggle(
                        all_product_data._id,
                        "product_engage_images")}>
                    All images
                  </div>
                )}
              </div>
            );
          const engage_video1 = all_product_data.product_engagement && all_product_data.product_engagement.video &&
            all_product_data.product_engagement.video.length !== 0 && (
              <div>
                <div
                  className="fb-ads-img"
                  onClick={() =>
                    this.handleLinkClick(
                      all_product_data.product_engagement.video[0], 'show'
                    )}>
                  <img
                    src={fb_ads}
                    width="100px"
                    height="60px"
                    alt="No document"></img>
                </div>
                {all_product_data.product_engagement.video.length > 1 && (
                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() =>
                      this.toggle(all_product_data._id, "product_engage_viedo")}>
                    All videos
                  </div>
                )}
              </div>
            );
          const engage_txt =
            all_product_data.product_engagement && all_product_data.product_engagement.text !== "" &&
            all_product_data.product_engagement.text.replace(/\s/g, "");
          const engage = engage_txt && engage_txt.split(",");
          const filter_enage_like =
            engage &&
            engage.length !== 0 &&
            engage.filter(engage => {
              return (
                engage.includes("like") ||
                engage.includes("likes") ||
                engage.includes("LIKES") ||
                engage.includes("LIKE") ||
                engage.includes("Likes") ||
                engage.includes("Like")
              );
            });
          const filter_enage_comment =
            engage &&
            engage.length !== 0 &&
            engage.filter(engage => {
              return (
                engage.includes("comment") ||
                engage.includes("comments") ||
                engage.includes("COMMENT") ||
                engage.includes("COMMENTS") ||
                engage.includes("Comments") ||
                engage.includes("Comment")
              );
            });
          const filter_enage_reactions =
            engage &&
            engage.length !== 0 &&
            engage.filter(engage => {
              return (
                engage.includes("reaction") ||
                engage.includes("reactions") ||
                engage.includes("REACTIONS") ||
                engage.includes("REACTION") ||
                engage.includes("Reaction") ||
                engage.includes("Reactions")
              );
            });
          const filter_enage_share =
            engage &&
            engage.length !== 0 &&
            engage.filter(engage => {
              return (
                engage.includes("share") ||
                engage.includes("shares") ||
                engage.includes("SHARE") ||
                engage.includes("SHARES") ||
                engage.includes("Shares") ||
                engage.includes("Share")
              );
            });
          const filter_enage_view =
            engage &&
            engage.length !== 0 &&
            engage.filter(engage => {
              return (
                engage.includes("view") ||
                engage.includes("views") ||
                engage.includes("VIEW") ||
                engage.includes("VIWES") ||
                engage.includes("Views") ||
                engage.includes("View")
              );
            });
          const filter_enage_comment_val =
            filter_enage_comment &&
            filter_enage_comment.length !== 0 &&
            filter_enage_comment[0].split("-")[0];
          const filter_enage_like_val =
            filter_enage_like &&
            filter_enage_like.length !== 0 &&
            filter_enage_like[0].split("-")[0];
          const filter_enage_reactions_val =
            filter_enage_reactions &&
            filter_enage_reactions.length !== 0 &&
            filter_enage_reactions[0].split("-")[0];
          const filter_enage_share_val =
            filter_enage_share &&
            filter_enage_share.length !== 0 &&
            filter_enage_share[0].split("-")[0];
          const filter_enage_view_val =
            filter_enage_view &&
            filter_enage_view.length !== 0 &&
            filter_enage_view[0].split("-")[0];

          const engage_text = (
            <div className="engage_text">
              <div className="p-engage-field">
                {filter_enage_like && filter_enage_like.length !== 0 && (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("Like")}>
                    <div className="p-engage-data">
                      <img
                        src={likes}
                        width="25px"
                        height="25px"
                        alt="Likes"></img>
                      <span>{filter_enage_like_val}</span>
                    </div>
                  </OverlayTrigger>
                )}
                {filter_enage_comment && filter_enage_comment.length !== 0 && (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("Comment")}>
                    <div className="p-engage-data">
                      <img
                        src={comments}
                        width="25px"
                        height="25px"
                        alt="Comments"></img>
                      <span>{filter_enage_comment_val}</span>
                    </div>
                  </OverlayTrigger>
                )}
                {filter_enage_share && filter_enage_share.length !== 0 && (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("Share")}>
                    <div className="p-engage-data">
                      <img
                        src={share}
                        width="25px"
                        height="25px"
                        alt="Share"></img>
                      <span>{filter_enage_share_val}</span>
                    </div>
                  </OverlayTrigger>
                )}
                {filter_enage_reactions &&
                  filter_enage_reactions.length !== 0 && (
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      overlay={this.renderTooltip("Reaction")}>
                      <div className="p-engage-data">
                        <img
                          src={reactions}
                          width="25px"
                          height="25px"
                          alt="Reactions"></img>
                        <span>{filter_enage_reactions_val}</span>
                      </div>
                    </OverlayTrigger>
                  )}
                {filter_enage_view && filter_enage_view.length !== 0 && (
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 250, hide: 400 }}
                    overlay={this.renderTooltip("View")}>
                    <div className="p-engage-data">
                      <img
                        src={view}
                        width="25px"
                        height="25px"
                        alt="Views"
                      ></img>
                      <span>{filter_enage_view_val}</span>
                    </div>
                  </OverlayTrigger>
                )}
              </div>
            </div>
          );

          const fb = (
            <div className="pro-small-txt">
              {all_product_data.product_fb_ads && all_product_data.product_fb_ads.text !== "" && (
                <div className="t-block-discription">
                  {" "}
                  {all_product_data.product_fb_ads.text}{" "}
                </div>
              )}
              {all_product_data.product_fb_ads.document && all_product_data.product_fb_ads.document.length !== 0 && (
                <>{fb_doc1}</>
              )}
              {all_product_data.product_fb_ads.image && all_product_data.product_fb_ads.image.length !== 0 && (
                <div>{fb_image1} </div>
              )}
              {all_product_data.product_fb_ads.video && all_product_data.product_fb_ads.video.length !== 0 && (
                <>{fb_video1}</>
              )}
            </div>
          );
          const engage_f = (
            <div className="pro-small-txt-engage">
              {all_product_data.product_engagement && all_product_data.product_engagement.text !== "" &&
                // <div className="t-block-discription"> {all_product_data.product_engagement.text} </div>}
                engage_text}
              {all_product_data.product_engagement && all_product_data.product_engagement.document && all_product_data.product_engagement.document.length !== 0 && (
                <>{engage_doc1}</>
              )}
              {all_product_data.product_engagement && all_product_data.product_engagement.image && all_product_data.product_engagement.image.length !== 0 && (
                <div>{engage_image1}</div>
              )}
              {all_product_data.product_engagement && all_product_data.product_engagement.video && all_product_data.product_engagement.video.length !== 0 && (
                <>{engage_video1}</>
              )}
            </div>
          );
          const analytics = (
            <div className="t-product-analytics">
              <div>
                <span>Source:</span>{" "}
                {all_product_data.product_analytics_source.toString()}
              </div>
              <div>
                <span>Orders:</span>{" "}
                {all_product_data.product_orders_count.toString()}
              </div>
              <div>
                <span>Reviews:</span>{" "}
                {all_product_data.product_reviews.toString()}
              </div>
              <div>
                <div className="pro-rating-group">
                  <ReactStars
                    count={5}
                    size={35}
                    edit={false}
                    value={all_product_data.product_rating}
                    half={true}
                    emptyIcon={<i className="far fa-star"></i>}
                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                    fullIcon={<i className="fa fa-star"></i>}
                    color2={"#ffd700"}
                  />
                  <div className="pro-rating-value">
                    {all_product_data.product_rating.toString()}
                  </div>
                </div>
              </div>
            </div>
          );
          var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
          ];
          function dateFormat(d) {
            var t = new Date(d);
            return t.getDate() + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear();
          }
          function dateFormat2(d) {
            var t = new Date(d);
            return t.getDate() - 1 + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear();
          }
          var dd = dateFormat(new Date());
          var dd_yest = dateFormat2(new Date());
          var date_ver =
            all_product_data.product_display_date &&
            all_product_data.product_display_date.toString() &&
            all_product_data.product_display_date.slice(0, -5);
          var date_api =
            all_product_data.product_display_date &&
            all_product_data.product_display_date.toString();
          var final_date =
            dd === date_api
              ? "Today"
              : dd_yest === date_api
                ? "Yesterday"
                : date_ver;
          var f_main_image = this.props.load_env && all_product_data.product_main_image !== "" &&
            (typeof (all_product_data.product_main_image) === "string" && all_product_data.product_main_image.includes("http") ? all_product_data.product_main_image :
              this.props.env.CDN_URL + all_product_data.product_main_image.replace("images", "thumb_images")
            );
          var f_main_image_onclick = this.props.load_env && all_product_data.product_main_image !== "" &&
            (typeof (all_product_data.product_main_image) === "string" && all_product_data.product_main_image.includes("http") ? all_product_data.product_main_image :
              this.props.env.CDN_URL + all_product_data.product_main_image
            );


          function titleCase(str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
              // You do not need to check if i is larger than splitStr length, as your for does that for you
              // Assign it back to the array
              splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            // Directly return the joined string
            return splitStr.join(' ');
          }

          let tempName = titleCase(all_product_data.product_name);
          return (
            <tr key={i}>
              <td valign="middle">
                <div className="product-cell">
                  <div className="cell-date-holder">
                    <span className="date-in-vertical">
                      {final_date && final_date.toString()}
                    </span>
                  </div>
                  <div className="t-pro-info-container">
                    <div className="t-product-img-container">
                      <img
                        src={f_main_image}
                        alt="NoImage"
                        onClick={() =>
                          this.handleLinkClick(f_main_image_onclick, 'show')}></img>
                    </div>
                    <div className="t-product-title">
                      {all_product_data.product_name ?
                        (tempName)
                        : all_product_data.product_name.toString()}
                    </div>
                  </div>

                  <div
                    className="pro-img-viewmore-btn"
                    onClick={() =>
                      this.toggle(all_product_data._id, "product_images")}>
                    All Images
                    </div>
                  {/* mobile view   */}
                  <div className="mobile-view_ m-t-15">
                    <div className="mob-profit">
                      <span>Profit:</span>{" "}
                      <span>
                        {all_product_data.product_profit_margin}
                        <div className="base-price">
                          {(all_product_data.product_profit_margin !== all_product_data.base_product_profit_margin) && all_product_data.base_product_profit_margin}</div>
                      </span>
                    </div>
                    <div className="mob-view-details">
                      <Button
                        onClick={() =>
                          this.handleSinglePageRouter(all_product_data._id)}>{" "}View Details{" "}
                      </Button>
                    </div>
                  </div>
                  {/* mobile view   */}

                </div>
              </td>
              <td valign="middle">
                <div className="profit-cost-inner">
                  <div className="price-table">
                    <span >Price:</span>{" "}
                    <span>{all_product_data.product_price}
                      <div className="base-price">
                        {(all_product_data.product_price !== all_product_data.base_product_price) && all_product_data.base_product_price}
                      </div>
                    </span>
                  </div>
                  <div>
                    <span>Sale price:</span>{" "}
                    <span>{all_product_data.product_sale_price}
                      <div className="base-price">
                        {(all_product_data.product_sale_price !== all_product_data.base_product_sale_price) && all_product_data.base_product_sale_price}</div>
                    </span>
                  </div>
                  <div>
                    <span>Profit:</span>{" "}
                    <span>{all_product_data.product_profit_margin}
                      <div className="base-price">
                        {(all_product_data.product_profit_margin !== all_product_data.base_product_profit_margin) && all_product_data.base_product_profit_margin}</div>
                    </span>
                  </div>
                </div>
              </td>
              <td valign="middle">{analytics}</td>
              <td valign="middle">
                {" "}
                <div className="t-pro-links">{links}</div>
              </td>
              <td valign="middle">{get_id_youtube1}</td>
              <td valign="middle">{fb}</td>
              <td valign="middle">{engage_f}</td>
              <td valign="middle">
                <div className="pro-small-txt">
                  <div className="block_discription">
                    {all_product_data.product_interest}
                  </div>
                </div>
              </td>
              <td
                valign="middle"
                style={{ width: "8%" }}
                className="table-action-buttons">
                <Button
                  onClick={() =>
                    this.handleSinglePageRouter(all_product_data._id)}>
                  {" "}View Details{" "}
                </Button>
                <br />
                {user_from === "shopify" && <Button
                  className="add-to-shopify-btn"
                  onClick={() =>
                    this.props.load_ats && this.props.hide_ats.is_hide_status === true
                      ? this.handleSwal() :
                      this.handleshowAddToShopify(all_product_data)}>
                  Add To Shopify
                  </Button>}
                {user_from === "website" && <Button
                  className="add-to-shopify-btn"
                  onClick={() => this.handleshowAddToStore(all_product_data)}>
                  Add To Store
                  </Button>}
              </td>
            </tr >
          );
        })
        : this.props.load_pro && this.props.all_product_data && this.props.all_product_data.length === 0 && (
          <tr>
            <td colSpan="9">No Products</td>
          </tr>
        );
    var job_counts =
      this.props.load_order &&
      this.props.get_order &&
      this.props.get_order.total_pp_records;
    var defaultTab =
      window.location.href && (
        window.location.href.includes("#jobs")
          ? "getorder"
          : window.location.href.includes("#niche") ? "handpicked" : "general");
    var job_title = job_counts ? (
      <span>
        Jobs <span className="job-count">{job_counts}</span>
      </span>
    ) : (
        <span>
          Jobs <span className="job-count">0</span>
        </span>
      );
    const { app_plan, trial_days } = this.props.shop_data;
    var plan_alert_msg = (
      <div className="plan-msg">
        <p className="plan-msg-value">
          You have {trial_days} Day(s) trial left for the latest winning
          products. After the trial, products will be delayed by 7 days.
          <span className="plan-redirect" onClick={() => this.handleUpgrade()}>
            {" "}Get latest always.{" "}
          </span>
        </p>
      </div>
    );


    var expire_plan_alert_msg = (
      <div className="plan-msg">
        <p className="plan-msg-value">
          Your trial period has been expired. you are seeing winning products by
          7 days delayed.
          <span className="plan-redirect" onClick={() => this.handleUpgrade()}>
            {" "}Get latest always.{" "}
          </span>
        </p>
      </div>
    );

    return (
      <div className="root-page">
        <NavbarHeader />
        <div className="main-container">
          <Header
            header_name="Winning Products"
            history={this.props.history}
            CDN_URL={this.props.env.CDN_URL}
          />
          {this.state.show_add_to_shopify && this.props.load_smf && (
            <AddToShopify
              show={this.state.show_add_to_shopify}
              onHide={() => this.handleCloseAddToShopify()}
              shopify_p_data={this.state.shopify_p_data}
              CDN_URL={this.props.env.CDN_URL}
              shopify_MF={this.props.shopify_MF}
            />
          )}
          {this.state.show_add_to_store && (
            <AddToStore
              show={this.state.show_add_to_store}
              onHide={() => this.handleCloseAddToStore()}
              store_p_data={this.state.store_p_data}
              CDN_URL={this.props.env.CDN_URL}
            />
          )}
          {this.props.load_env && this.props.load_hl && <AllImages
            show={this.state.modalShow}
            onHide={() => this.toggle()}
            product_image_id={this.state.product_image_id}
            all_product_data={this.props.all_product_data}
            modal_for={this.state.modal_for}
            CDN_URL={this.props.env.CDN_URL}
            hide_link={this.props.hide_link}
          />}
          {this.props.load_env && <SeoNmarketing
            show={this.state.modal_snm}
            onHide={() => this.toggle_snm()}
            modal_for_snm={this.state.modal_for_snm}
            env={this.props.env}
          />}
          <div className="page-container">
            {window.location.search === "?milan-testing" &&
              <div className="header-snm">
                <span>Get sales using</span>
                <span>
                  <Button
                    className="header-field-text-t"
                    onClick={() => this.toggle_snm("seo")}>
                    SEO
                </Button>
                  <Button
                    className="header-field-text-t"
                    onClick={() => this.toggle_snm("marketing")}>
                    PPC Marketing
                </Button>
                </span>
              </div>}

            <div className="nav-products">
              <Tabs id="uncontrolled-tab-example" unmountOnExit activeKey={this.state.key}
                onSelect={(k) => this.setKey(k)} >
                <Tab eventKey="general" title="General Products">
                  <div className="search-fields">
                    <InputGroup>
                      <GoSearch />
                      <FormControl
                        placeholder="Search product"
                        aria-label="Search product"
                        aria-describedby="basic-addon2"
                        onKeyPress={this.api_call_with_search.bind(this)}
                        onChange={e => this.handleOnChange("by_product", e)}
                        value={this.state.by_product}
                      />
                    </InputGroup>
                    <InputGroup>
                      <GoSearch />
                      <FormControl
                        placeholder="Search interest"
                        aria-label="Search interest"
                        aria-describedby="basic-addon2"
                        onKeyPress={this.api_call_with_search.bind(this)}
                        onChange={e => this.handleOnChange("by_interest", e)}
                        value={this.state.by_interest}
                      />
                    </InputGroup>
                    <div className="search-cat-msg">
                      <div className="pandemic-msg">Pandemic special category is now available in search!</div>
                      <InputGroup className="cat-pro">
                        <div className={this.props.pro_by_cat.is_cat_selected ? "rainbow" : "container"}>
                          <Select
                            options={this.props.get_categories}
                            onChange={this.onChangeCat}
                            value={this.state.selected_category}
                            placeholder="Search category"
                            isSearchable
                            classNamePrefix="select_pro_category"
                            autoFocus={false}
                          />
                        </div>
                      </InputGroup>
                    </div>
                  </div>
                  {this.props.load_sd &&
                    app_plan &&
                    trial_days != 0 &&
                    app_plan === "basic_plan" &&
                    plan_alert_msg}
                  {this.props.load_sd &&
                    trial_days == 0 &&
                    app_plan &&
                    app_plan === "basic_plan" &&
                    expire_plan_alert_msg}
                  <Loader
                    loaded={this.props.load_pro}
                    lines={10}
                    length={5}
                    width={5}
                    radius={10}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#1f3893"
                    speed={1}
                    trail={40}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                  />
                  {this.props.load_pro && <Table bordered responsive className="pro-data-table hide-on-desktop">
                    <thead>
                      <tr>
                        <th style={{ width: "20%" }}>
                          Product{" "}
                          <span className="sort-by-product">
                            <TiArrowUnsorted
                              onClick={this.handleSort}
                              size={15}
                            />
                          </span>
                        </th>
                        <th style={{ width: "12%" }}>Profits</th>
                        <th style={{ width: "12%" }}>Analytics</th>
                        <th style={{ width: "11%" }}>Links</th>
                        <th style={{ width: "8%" }}>Youtube</th>
                        <th style={{ width: "10%" }}>FB Ads</th>
                        <th style={{ width: "10%" }}>Engagement </th>
                        <th style={{ width: "10%" }}>Interest</th>
                        <th style={{ width: "7%" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>{all_data}</tbody>
                  </Table>}
                  <div>
                    {this.props.load_pro && <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={10}
                      pageRangeDisplayed={3}
                      totalItemsCount={this.props.total_record_pro}
                      onChange={this.handlePageChange}
                      innerClass="innerClass"
                      activeClass="activeClass"
                      activeLinkClass="activeLinkClass"
                      itemClass="itemClass"
                      itemClassFirst="itemClassFirst"
                      itemClassPrev="itemClassPrev"
                      itemClassNext="itemClassNext"
                      itemClassLast="itemClassLast"
                      linkClass="linkClass"
                      linkClassFirst="linkClassFirst"
                      linkClassPrev="linkClassPrev"
                      linkClassNext="linkClassNext"
                      linkClassLast="linkClassLast"
                    />}
                  </div>
                  {this.props.load_pro &&
                    <div className="product-niche-msg">
                      <h5 className="bes-msg color-bp">You can request us to find products just for your niche and your store.</h5>
                      <h6>Delivering tens of hundreds of products daily to merchants!</h6>
                      <Button onClick={() => this.toggleBP()}>Request Niche Product</Button>
                    </div>}
                </Tab>
                <Tab eventKey="handpicked" title="Niche Products">
                  <div className="bp-data">
                    <BespokedList />
                  </div>
                </Tab>
                <Tab eventKey="getorder" title={job_title}>
                  <div className="req-products">
                    <GetOrder />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
        <Modal
          show={this.state.show_youtube}
          onHide={this.handleClose}
          dialogClassName="extra-content-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Youtube Video</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="product-youtube-video-modal">
              <YouTube
                videoId={this.state.display_youtube_video_id}
                opts={opts}
                onReady={this._onReady}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.handleLinkClick(
                  this.state.youtube_url && this.state.youtube_url)}>
              See in full screen
            </Button>
          </Modal.Footer>
        </Modal>
        {
          this.props.load_serv && <BespokedReq
            show={this.state.modalShowsService}
            onHide={() => this.toggleBP()}
            get_service={this.state.modalShowsService === false ? [] : this.state.get_service} />
        }
      </div >
    );
  }
}
const mapStateToProps = state => {
  return {
    all_product_data: state.AllReducer.all_product_data.data,
    reqData: state.AllReducer.all_product_data.reqData,
    total_record_pro: state.AllReducer.all_product_data.total_records,
    load_pro: state.AllReducer.all_product_data.load_pro,
    store_data: state.InstallReducer.store_data,
    get_categories: state.AllReducer.get_categories.data,
    load_cat: state.AllReducer.get_categories.load_cat,
    get_order: state.AllReducer.get_order,
    env: state.AllReducer.env.data,
    load_env: state.AllReducer.env.load_env,
    shop_data: state.AllReducer.shop_data.data,
    load_sd: state.AllReducer.shop_data.load_sd,
    get_service: state.AllReducer.get_service.data,
    load_serv: state.AllReducer.get_service.load_serv,
    shopify_MF: state.AllReducer.shopify_MF.message,
    load_smf: state.AllReducer.shopify_MF.load_smf,
    load_order: state.AllReducer.get_order.load_order,
    pro_by_cat: state.AllReducer.pro_by_cat,
    get_all_sub: state.AllReducer.get_all_sub.message,
    load_sub: state.AllReducer.get_all_sub.load_sub,
    hide_link: state.AllReducer.hide_link.message,
    load_hl: state.AllReducer.hide_link.load_hl,
    hide_ats: state.AllReducer.hide_ats.message,
    load_ats: state.AllReducer.hide_ats.load_ats
  };
};
const mapDispatchToProps = dispatch => ({
  action: {
    AllAction: bindActionCreators(AllAction, dispatch),
    InstallAction: bindActionCreators(InstallAction, dispatch)
  }
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Products)
);