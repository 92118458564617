import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { FaSpinner } from "react-icons/fa";
import NavbarHeader from './NavbarHeader';
import Header from './Header';
import * as AllAction from '../Action/AllAction';
import ChangePwd from './ChangePwd';
import swal from 'sweetalert';
import Loader from "react-loader";
import { GoVerified } from "react-icons/go";
import { IoMdCloseCircle } from "react-icons/io";
import moment from 'moment';
var paper = require("../img/paper-plane.png");
var plane = require("../img/plane.png");

class SupPlans extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shop_data: [],
            loaded: false,
            env: {},
            did_plan_downgrade: false,
            downgrade_click: false,
            upgrade_click: false,
            upgrade_click_gold: false,
            loaded_sub_plan: false,
            get_sub_plan: [],
            show_change_pwd: false,
            upgrade_clickfree: false,
            get_all_sub: [],
            load_sd: false,
            shop_data: {},
            plan_type: "",
            loaded_PT: false
        }
    }
    componentWillMount() {
        this.setState({
            upgrade_click: false
        })
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop, tag: 'sup_silver'
        }
        this.props.action.AllAction.getSubscriptionPlanAction(data, 'sup_silver');
        const data_gold = {
            accessToken, shop, tag: 'sup_gold'
        }
        this.props.action.AllAction.getSubscriptionPlanAction(data_gold, "sup_gold");
        const data_wp = {
            accessToken, shop, tag: 'premium_plan'
        }
        this.props.action.AllAction.getSubscriptionPlanAction(data_wp);

        const data_pay = {
            accessToken, shop
        }
        this.props.action.AllAction.getAllSubAction(data_pay);
        this.props.action.AllAction.getUserPayTypeAction(data_pay);
        this.props.action.AllAction.getShopSubscriptionAction(data_pay);
        this.props.action.AllAction.getStripCustomerMessagesAction(data_pay);

    }
    componentDidUpdate(prevProps) {
        if (prevProps.plan_downgrade !== this.props.plan_downgrade) {
            if (this.props.plan_downgrade && this.props.plan_downgrade.message && this.props.plan_downgrade.message == "Plan has been Downgraded successfully !") {
                this.setState({
                    did_plan_downgrade: true
                })
            }
        }
        if (JSON.stringify(this.state.get_all_sub) !== JSON.stringify(this.props.get_all_sub)) {
            if (this.props.load_sub === true) {
                this.setState({
                    get_all_sub: this.props.get_all_sub
                }, () => {
                    var supp_arr = this.props.get_all_sub && this.props.get_all_sub.length !== 0 ?
                        this.props.get_all_sub.filter((supp) => {
                            return supp.subtag === 'supplier'
                        }) : []
                    var plan_type = supp_arr && supp_arr.length !== 0 && supp_arr[0] && supp_arr[0].shop_plan_name;
                    this.setState({ plan_type }, () => {
                        this.setState({
                            loaded_PT: true
                        })
                    });
                    const accessToken = localStorage.getItem("token_wp");
                    const shop = localStorage.getItem("shop_wp");
                    const data = {
                        accessToken, shop, plan_type
                    }
                    this.props.action.AllAction.GetShopDetailsAction(data);
                })
            }
        }
        if (JSON.stringify(this.state.shop_data) !== JSON.stringify(this.props.shop_data)) {
            this.setState({
                shop_data: this.props.shop_data,
                load_sd: true
            })
        }
    }
    // handleDowngrade = () => {
    //     const accessToken = localStorage.getItem("token_wp");
    //     const shop = localStorage.getItem("shop_wp");
    //     const data = {
    //         accessToken, shop
    //     }
    //     this.props.action.AllAction.PlanDowngradeAction(data);
    //     this.setState({
    //         downgrade_click: true
    //     })

    // }
    confirmBox = (upgrade, upgrade_filer, up_down, is_free) => {
        if (up_down === "down") {
            swal({
                text: "Are you sure you want to update your subscription?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(value => {
                if (value) {
                    this.handleUpgrade(upgrade, upgrade_filer, up_down, is_free)
                }
            });
        }
        else {
            this.handleUpgrade(upgrade, upgrade_filer, up_down, is_free)
        }
    }
    handleUpgrade = (upgrade, upgrade_filer, up_down, is_free) => {
        var subscriptionItemID = "";
        if (upgrade === "yes_gold") {
            var sub = upgrade_filer && upgrade_filer.length !== 0 && upgrade_filer.filter(upgrade_filer => {
                return upgrade_filer.plan_tag === "sup_gold"
            })

            if (sub.length > 0) {
                subscriptionItemID = sub[0].subscriptionItemID
            }
        }
        else if (upgrade === "yes_silver") {
            var sub = upgrade_filer && upgrade_filer.length !== 0 && upgrade_filer.filter(upgrade_filer => {
                return upgrade_filer.plan_tag === "sup_silver"
            })

            if (sub.length > 0) {
                subscriptionItemID = sub[0].subscriptionItemID
            }
        }
        if (is_free === "free") {
            this.setState({
                upgrade_clickfree: true
            })
        }
        else {
            this.setState({
                upgrade_click: true
            })
        }
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from");
        // var { plan_type } = this.state;
        var supp_arr = this.props.get_all_sub && this.props.get_all_sub.length !== 0 ?
            this.props.get_all_sub.filter((supp) => {
                return supp.subtag === 'supplier'
            }) : []
        var plan_type = supp_arr && supp_arr.length !== 0 && supp_arr[0] && supp_arr[0].shop_plan_name;
        var { get_stripe_cus_msg, load_scm } = this.props;
        const data = {
            accessToken,
            shop,
            user_from,
            price: this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                && (this.props.get_sub_plan[0].country_wise_price[0].price),
            currency: this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                && (this.props.get_sub_plan[0].country_wise_price[0].Currency),
            plan_tag: "sup_silver",
            subscriptionItemID: subscriptionItemID,
            is_basic_plan: up_down === "down" && is_free === "free" ? true : false,
            plan_type,
            plan_name: up_down === "down" && is_free === "free" ? "basic_plan" : "sup_silver",
            chargeFailureMessage: load_scm && get_stripe_cus_msg !== null && Array.isArray(get_stripe_cus_msg) && get_stripe_cus_msg.length !== 0 ? true : false
        };

        if (this.props.pay_type && (this.props.pay_type.user_from === "shopify")
            && (this.props.pay_type.payment_gateway
                && this.props.pay_type.payment_gateway.gateway_tag &&
                this.props.pay_type.payment_gateway.gateway_tag === "shopify")) {
            var data_shopify = {
                accessToken,
                shop,
                plan_tag: "sup_silver"
            }
            this.props.action.AllAction.PlanUpdradeShopifyAction(data_shopify);
        }
        else {
            const { plan, plan_shopify_id } = this.state.shop_data;
            if (plan === 'premium_plan' && plan_shopify_id !== null && user_from === 'shopify') {
                var price = this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                    this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                    && (this.props.get_sub_plan[0].country_wise_price[0].price);
                var currency = this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
                    this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
                    && (this.props.get_sub_plan[0].country_wise_price[0].Currency);
                const datas2s = {
                    accessToken,
                    shop,
                    user_from,
                    price,
                    currency,
                    plan_tag: "sup_silver",
                    plan_type,
                    plan_name: up_down === "down" && is_free === "free" ? "basic_plan" : "sup_silver",
                    plan_shopify_id,
                    win_product_plan_tag: plan,
                    win_product_price: this.props.get_sub_plan_wp && this.props.get_sub_plan_wp.length !== 0 && this.props.get_sub_plan_wp[0] &&
                        this.props.get_sub_plan_wp[0].country_wise_price && this.props.get_sub_plan_wp[0].country_wise_price.length !== 0
                        && (this.props.get_sub_plan_wp[0].country_wise_price[0].price)
                };
                this.props.action.AllAction.planUpgradeShopifyToStripe(datas2s);
            } else {
                this.props.action.AllAction.PlanUpdradeAction(data);
            }
        }
    }
    confirmBoxGold = (upgrade, upgrade_filer, up_down, is_free) => {
        if (up_down === "down") {
            swal({
                text: "Are you sure you want to update your subscription?",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            }).then(value => {
                if (value) {
                    this.handleUpgradeGold(upgrade, upgrade_filer, up_down, is_free)
                }
            });
        }
        else {
            this.handleUpgradeGold(upgrade, upgrade_filer, up_down, is_free)
        }
    }
    handleUpgradeGold = (upgrade, upgrade_filer, up_down, is_free) => {
        var subscriptionItemID = "";
        if (upgrade === "yes_gold") {
            var sub = upgrade_filer && upgrade_filer.length !== 0 && upgrade_filer.filter(upgrade_filer => {
                return upgrade_filer.plan_tag === "sup_gold"
            })

            if (sub.length > 0) {
                subscriptionItemID = sub[0].subscriptionItemID
            }
        }
        else if (upgrade === "yes_silver") {
            var sub = upgrade_filer && upgrade_filer.length !== 0 && upgrade_filer.filter(upgrade_filer => {
                return upgrade_filer.plan_tag === "sup_silver"
            })
            if (sub.length > 0) {
                subscriptionItemID = sub[0].subscriptionItemID
            }
        }
        this.setState({
            upgrade_click_gold: true
        })
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const user_from = localStorage.getItem("user_from");
        // var plan_type = this.state;
        var supp_arr = this.props.get_all_sub && this.props.get_all_sub.length !== 0 ?
            this.props.get_all_sub.filter((supp) => {
                return supp.subtag === 'supplier'
            }) : []
        var plan_type = supp_arr && supp_arr.length !== 0 && supp_arr[0] && supp_arr[0].shop_plan_name;
        var { get_stripe_cus_msg, load_scm } = this.props;

        const data = {
            accessToken,
            shop,
            user_from,
            price: this.props.get_sup_plan_gold && this.props.get_sup_plan_gold.length !== 0 && this.props.get_sup_plan_gold[0] &&
                this.props.get_sup_plan_gold[0].country_wise_price && this.props.get_sup_plan_gold[0].country_wise_price.length !== 0
                && (this.props.get_sup_plan_gold[0].country_wise_price[0].price),
            currency: this.props.get_sup_plan_gold && this.props.get_sup_plan_gold.length !== 0 && this.props.get_sup_plan_gold[0] &&
                this.props.get_sup_plan_gold[0].country_wise_price && this.props.get_sup_plan_gold[0].country_wise_price.length !== 0
                && (this.props.get_sup_plan_gold[0].country_wise_price[0].Currency),
            plan_tag: "sup_gold",
            subscriptionItemID: up_down === "up" ? subscriptionItemID : "",
            is_basic_plan: up_down === "down" && is_free === "free" ? true : false,
            plan_type,
            plan_name: up_down === "down" ? "basic_plan" : "sup_gold",
            chargeFailureMessage: load_scm && get_stripe_cus_msg !== null && Array.isArray(get_stripe_cus_msg) && get_stripe_cus_msg.length !== 0 ? true : false

        };

        if (this.props.pay_type && (this.props.pay_type.user_from === "shopify")
            && (this.props.pay_type.payment_gateway
                && this.props.pay_type.payment_gateway.gateway_tag &&
                this.props.pay_type.payment_gateway.gateway_tag === "shopify")) {
            var data_shopify = {
                accessToken,
                shop,
                plan_tag: "sup_gold"
            }
            this.props.action.AllAction.PlanUpdradeShopifyAction(data_shopify);
        }
        else {
            const { plan, plan_shopify_id } = this.state.shop_data;
            if (plan === 'premium_plan' && plan_shopify_id !== null && user_from === 'shopify') {
                var price = this.props.get_sup_plan_gold && this.props.get_sup_plan_gold.length !== 0 && this.props.get_sup_plan_gold[0] &&
                    this.props.get_sup_plan_gold[0].country_wise_price && this.props.get_sup_plan_gold[0].country_wise_price.length !== 0
                    && (this.props.get_sup_plan_gold[0].country_wise_price[0].price);
                var currency = this.props.get_sup_plan_gold && this.props.get_sup_plan_gold.length !== 0 && this.props.get_sup_plan_gold[0] &&
                    this.props.get_sup_plan_gold[0].country_wise_price && this.props.get_sup_plan_gold[0].country_wise_price.length !== 0
                    && (this.props.get_sup_plan_gold[0].country_wise_price[0].Currency);
                const datas2s = {
                    accessToken,
                    shop,
                    user_from,
                    price,
                    currency,
                    plan_tag: "sup_gold",
                    plan_type,
                    plan_name: up_down === "down" ? "basic_plan" : "sup_gold",
                    win_product_plan_tag: plan,
                    win_product_price: this.props.get_sub_plan_wp && this.props.get_sub_plan_wp.length !== 0 && this.props.get_sub_plan_wp[0] &&
                        this.props.get_sub_plan_wp[0].country_wise_price && this.props.get_sub_plan_wp[0].country_wise_price.length !== 0
                        && (this.props.get_sub_plan_wp[0].country_wise_price[0].price),
                    plan_shopify_id
                };
                this.props.action.AllAction.planUpgradeShopifyToStripe(datas2s);
            } else {
                this.props.action.AllAction.PlanUpdradeAction(data);
            }
        }
    }
    showChangePwd = () => {
        this.setState({
            show_change_pwd: true
        })
    }
    closeChangePwd = () => {
        this.setState({
            show_change_pwd: false
        })
    }
    render() {
        var { get_stripe_cus_msg, load_scm } = this.props;
        const { shop, email, app_plan } = this.state.shop_data
        var upgrade_silver_filter =
            this.props.load_shop_sub && this.props.get_shop_sub && this.props.get_shop_sub.plan_data && this.props.get_shop_sub.plan_data.length !== 0 ?
                this.props.get_shop_sub.plan_data.filter((plan_data, i) => {
                    return plan_data.sub_tag === "supplier" && plan_data.plan_tag === 'sup_silver'
                })
                : []
        var upgrade_silver = this.state.load_sd ? (app_plan === 'sup_silver' ? "yes_silver" : "not_silver") : "";
        var upgrade_gold_filter =
            this.props.load_shop_sub && this.props.get_shop_sub && this.props.get_shop_sub.plan_data && this.props.get_shop_sub.plan_data.length !== 0 ?
                this.props.get_shop_sub.plan_data.filter((plan_data, i) => {
                    return plan_data.sub_tag === "supplier" && plan_data.plan_tag === 'sup_gold'
                })
                : []
        var upgrade_gold = this.state.load_sd ? (app_plan === 'sup_gold' ? "yes_gold" : "not_gold") : "";

        var is_show_shop = localStorage.getItem("user_from") === "shopify" ? true : false
        var cw_price = this.props.load_sps && this.props.get_sub_plan && this.props.get_sub_plan.length !== 0 && this.props.get_sub_plan[0] &&
            this.props.get_sub_plan[0].country_wise_price && this.props.get_sub_plan[0].country_wise_price.length !== 0
            && (this.props.get_sub_plan[0].country_wise_price[0].Currency_Symbol + this.props.get_sub_plan[0].country_wise_price[0].price);
        var cw_price_gold = this.props.load_spg && this.props.get_sup_plan_gold && this.props.get_sup_plan_gold.length !== 0 && this.props.get_sup_plan_gold[0] &&
            this.props.get_sup_plan_gold[0].country_wise_price && this.props.get_sup_plan_gold[0].country_wise_price.length !== 0
            && (this.props.get_sup_plan_gold[0].country_wise_price[0].Currency_Symbol + this.props.get_sup_plan_gold[0].country_wise_price[0].price);
        var plan_name = (upgrade_silver === "not_silver" && upgrade_gold === "not_gold") ? "Basic" : upgrade_silver === "yes_silver" ? "Silver plan" : upgrade_gold === "yes_gold" ? "Gold plan" : "Basic"
        return (
            <div className="root-page">
                <NavbarHeader />
                <div className="main-container acc-plan">
                    <Header header_name="Winning Products" history={this.props.history} />
                    {this.state.load_sd && load_scm ?
                        <>
                            <div className="acc-info">
                                <div className="acc-info-container">
                                    <ul className="ul-acc-info account-details-list">
                                        {is_show_shop === true && <li>Store< span className="li-info-field">{shop}</span></li>}
                                        <li>Email-ID<span><span className="li-info-field">{email}</span>
                                            {is_show_shop === false &&
                                                <span className="change-pwd" onClick={() => this.showChangePwd()}>
                                                    Change password?
                                            </span>}
                                        </span></li>
                                    </ul>
                                    <ul className="ul-paln-info account-details-list">
                                        <li>Supplier plan<span className="li-info-field">{plan_name}</span></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="background">
                                <div className="acc-info-container">
                                    <div className="panel pricing-table">
                                        <div className="pricing-plan">
                                            <img src={paper} alt="" className="pricing-img" />
                                            <h2 className="pricing-header">Basic Plan</h2>
                                            <span className="pricing-price">Free</span>

                                            {/* block for Downgrade plan */}
                                            {(app_plan === "sup_gold" || app_plan === "sup_silver") &&
                                                <div className={(this.state.upgrade_clickfree && (this.props.stripe_loaded === false)) ? 'not-allow-btns' : "plan-btns"}>
                                                    <div
                                                        className={(this.state.upgrade_clickfree && (this.props.stripe_loaded === false)) ?
                                                            'pricing-button-down is-featured no-event' : "pricing-button-down is-featured"}
                                                        onClick={() =>
                                                            this.confirmBox(
                                                                app_plan === "sup_gold" ? upgrade_gold :
                                                                    app_plan === "sup_silver" ? upgrade_silver : "",
                                                                app_plan === "sup_gold" ? upgrade_gold_filter :
                                                                    app_plan === "sup_silver" ? upgrade_silver_filter : "", "down", 'free')}
                                                    >{" "}Downgrade{" "}
                                                        {this.state.upgrade_clickfree && (this.props.stripe_loaded === false) &&
                                                            <span className="ats-icon">
                                                                <FaSpinner className="icon-spin" color="#888" height="18px" width="18px" />
                                                            </span>}
                                                    </div>
                                                </div>
                                            }
                                            {app_plan === "basic_plan" &&
                                                <div className="current-plan">Current Plan{" "}<span><GoVerified /></span></div>}
                                        </div>
                                        <div className="pricing-plan">
                                            <img src={plane} alt="" className="pricing-img" />
                                            <h2 className="pricing-header">Silver Plan</h2>
                                            {this.props.load_sps && this.props.load_sub && this.state.loaded_PT ?
                                                <>
                                                    <span className="pricing-price">
                                                        {cw_price}
                                                        <span className="pricing-price-subtext">{" "}/ {" "}month</span>
                                                    </span>

                                                    {/* Block for upgrade silver plan */}
                                                    {upgrade_silver === "not_silver" && upgrade_gold === "not_gold" &&
                                                        <div className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ? 'not-allow-btns' : "plan-btns"}>

                                                            <div
                                                                className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ?
                                                                    'pricing-button is-featured no-event' : "pricing-button is-featured"}
                                                                onClick={() => this.confirmBox(upgrade_gold, upgrade_gold_filter, "up", 'no')}>
                                                                {" "}Upgrade{" "}
                                                                {this.state.upgrade_click && (this.props.stripe_loaded === false) &&
                                                                    <span className="ats-icon">
                                                                        <FaSpinner className="icon-spin" color="#fff" height="23px" width="23px" /></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    }

                                                    {/* Block for Downgrade to silver plan */}
                                                    {upgrade_gold === "yes_gold" &&
                                                        <div className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ? 'not-allow-btns' : "plan-btns"}>

                                                            <div
                                                                className={(this.state.upgrade_click && (this.props.stripe_loaded === false)) ?
                                                                    'pricing-button-down is-featured no-event' : "pricing-button-down is-featured"}
                                                                onClick={() => this.confirmBox(upgrade_gold, upgrade_gold_filter, "down", 'no')}>
                                                                {" "}Downgrade{" "}
                                                                {this.state.upgrade_click && (this.props.stripe_loaded === false) &&
                                                                    <span className="ats-icon">
                                                                        <FaSpinner className="icon-spin" color="#888" height="18px" width="18px" /></span>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {app_plan === "sup_silver" &&
                                                        <div className="current-plan">Current Plan{" "}<span><GoVerified /></span></div>}
                                                </> : <FaSpinner className="icon-spin" color="#558cee" height="23px" width="23px" />}

                                        </div>
                                        <div className="pricing-plan">
                                            <img src={plane} alt="" className="pricing-img" />
                                            <h2 className="pricing-header">Gold Plan</h2>
                                            {this.props.load_spg && this.props.load_sub && this.state.loaded_PT ?
                                                <>
                                                    <span className="pricing-price">
                                                        {cw_price_gold}
                                                        <span className="pricing-price-subtext">{" "}/ {" "}month</span>
                                                    </span>

                                                    {/* Block for upgrade gold plan */}
                                                    {upgrade_gold === "not_gold" &&
                                                        <div className={(this.state.upgrade_click_gold && (this.props.stripe_loaded === false)) ? 'not-allow-btns' : "plan-btns"}>

                                                            <div
                                                                className={(this.state.upgrade_click_gold && (this.props.stripe_loaded === false)) ?
                                                                    'pricing-button is-featured no-event' : "pricing-button is-featured"}

                                                                onClick={() => this.confirmBoxGold(upgrade_silver, upgrade_silver_filter, "up", 'no')}>
                                                                {" "}Upgrade{" "}
                                                                {(this.state.upgrade_click_gold && (this.props.stripe_loaded === false)) &&
                                                                    <span className="ats-icon">
                                                                        <FaSpinner className="icon-spin" color="#fff" height="23px" width="23px" />
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    }
                                                    {app_plan === "sup_gold" &&
                                                        <div className="current-plan">Current Plan{" "}<span><GoVerified /></span></div>}
                                                </> : <FaSpinner className="icon-spin" color="#558cee" height="23px" width="23px" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {this.props.load_shop_sub && this.props.next_billing_date &&
                                <div className="next-date-acc"> Your account is billed monthly and the next payment is due on{" "}
                                    <span className="due-date">{moment(this.props.next_billing_date).format("MMM DD, YYYY")}</span>.
                                    {" "}<span className="active-sub" onClick={() => this.props.history.push("/bill-payments")}>See active subscription.</span></div>}
                            {load_scm && get_stripe_cus_msg !== null && get_stripe_cus_msg !== undefined && Array.isArray(get_stripe_cus_msg) && get_stripe_cus_msg.length !== 0
                                && <div className="card-no-fail"><span className="red-mark">
                                    <IoMdCloseCircle color="red" />
                                </span>{get_stripe_cus_msg[0].displayMessage}</div>}
                        </> : <div className="loader-container">
                            <Loader
                                loaded={this.state.load_sd && load_scm}
                                lines={10}
                                length={5}
                                width={5}
                                radius={10}
                                corners={1}
                                rotate={0}
                                direction={1}
                                color="#1f3893"
                                speed={1}
                                trail={40}
                                shadow={false}
                                hwaccel={false}
                                className="spinner"
                                zIndex={2e9}
                                top="50%"
                                left="50%"
                                scale={1.0}
                                loadedClassName="loadedContent"
                            />
                        </div>}
                </div>
                <ChangePwd show={this.state.show_change_pwd} onHide={() => this.closeChangePwd()} />
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        shop_data: state.AllReducer.shop_data.data,
        load_sd: state.AllReducer.shop_data.load_sd,
        plan_downgrade: state.AllReducer.plan_downgrade,
        get_sub_plan: state.AllReducer.get_sup_plan_silver.data,
        load_sps: state.AllReducer.get_sup_plan_silver.load_sps,
        get_shop_sub: state.AllReducer.get_shop_sub.message,
        get_sup_plan_gold: state.AllReducer.get_sup_plan_gold.data,
        load_spg: state.AllReducer.get_sup_plan_gold.load_spg,
        pay_type: state.AllReducer.pay_type.message,
        stripe_loaded: state.AllReducer.stripe_loaded,
        load_shop_sub: state.AllReducer.get_shop_sub.load_shop_sub,
        get_all_sub: state.AllReducer.get_all_sub.message,
        load_sub: state.AllReducer.get_all_sub.load_sub,
        get_sub_plan_wp: state.AllReducer.get_sub_plan.data,
        load_sp: state.AllReducer.get_sub_plan.load_sp,
        load_shop_sub: state.AllReducer.get_shop_sub.load_shop_sub,
        next_billing_date: state.AllReducer.get_shop_sub.next_billing_date,
        get_stripe_cus_msg: state.AllReducer.get_stripe_cus_msg.message,
        load_scm: state.AllReducer.get_stripe_cus_msg.load_scm
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(SupPlans);