import React, { Component } from 'react';
import { Button, FormLabel, FormControl, FormGroup, Alert } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import NavbarHeader from './NavbarHeader';
import Header from './Header';
import * as AllAction from '../Action/AllAction';
import * as InstallAction from '../Action/installAction';

class GetSupport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wp_support_name: "",
            wp_support_email: "",
            wp_support_msg: "",
            formValid: false,
            show: false,
            fieldsErrors: {
                wp_support_name: "",
                wp_support_email: "",
                wp_support_msg: ""
            }
        }
    }

    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value,
        }, () => { this.validateField(key) })
    }
    validateField(fieldName) {
        this.setState({
            fieldsErrors: { fieldName: "" },
        })
    }
    setShowToggele = () => {
        this.setState({
            show: !this.state.show
        }, () => {
            if (this.state.show === false) {
                this.setState({
                    wp_support_name: "",
                    wp_support_email: "",
                    wp_support_msg: "",
                })
            }
        })
    }
    handleLinkClick = (link) => {
        window.open(link, "_blank")
    }
    handleSubmitGetSupport = () => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
        const { wp_support_name, wp_support_email, wp_support_msg } = this.state
        const shop = localStorage.getItem("shop_wp")
        const data = { wp_support_name, wp_support_email, wp_support_msg, shop }
        if (this.state.wp_support_name === "")
            this.setState({
                fieldsErrors: { wp_support_name: "* Name is required" },
                formValid: false
            })
        else if (this.state.wp_support_email === "") {
            this.setState({
                fieldsErrors: { wp_support_email: "* Email id is required" },
                formValid: false
            })
        }
        else if (!(this.state.wp_support_email.match(mailformat))) {
            this.setState({
                fieldsErrors: { wp_support_email: "You have entered an invalid email address!" },
                formValid: false
            })
        }
        else if (this.state.wp_support_msg === "")
            this.setState({
                fieldsErrors: { wp_support_msg: "* Message is Required" },
                formValid: false
            })

        else {
            this.setState({
                formValid: true
            }, () => {
                this.props.action.AllAction.getSupportAction(data);
                this.setShowToggele()
            })
        }
    }
    handleCancelGetSupport = () => {
        this.setState({
            wp_support_name: "",
            wp_support_email: "",
            wp_support_msg: "",
        })
    }
    render() {
        return (
            <div className="root-page">
                <NavbarHeader />
                <div className="main-container">
                    <Header header_name="Winning Products" history={this.props.history} CDN_URL={this.props.env.CDN_URL}/>
                    <form className="app-support-form">
                        <h4 className="section-title">Get Support</h4>
                        <div className="app-support-form-inner">
                            <FormGroup>
                                <FormLabel>Full Name *</FormLabel >
                                <FormControl type="text" placeholder="Enter full name" onChange={(e) => this.handleOnChange("wp_support_name", e)} value={this.state.wp_support_name} ></FormControl>
                                <span style={{ color: "red" }}>{this.state.fieldsErrors.wp_support_name}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Email - ID *</FormLabel >
                                <FormControl type="email" placeholder="Enter email id" onChange={(e) => this.handleOnChange("wp_support_email", e)} value={this.state.wp_support_email} ></FormControl>
                                <span style={{ color: "red" }}>{this.state.fieldsErrors.wp_support_email}</span>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel>Your Message *</FormLabel >
                                <FormControl as="textarea" rows="4" placeholder="Enter your message" onChange={(e) => this.handleOnChange("wp_support_msg", e)} value={this.state.wp_support_msg}></FormControl>
                                <span style={{ color: "red" }}>{this.state.fieldsErrors.wp_support_msg}</span>
                            </FormGroup>
                            <div className="app-support-btn-group">
                                <Button variant="primary" onClick={() => this.handleSubmitGetSupport()}>
                                    Submit
                            </Button>
                                <Button variant="danger" onClick={() => this.handleCancelGetSupport()} >
                                    Cancel
                            </Button>
                            </div>
                        </div>
                    </form>
                    <>
                        <Alert show={this.state.show} variant="success" onClose={() => this.setShowToggele()} dismissible>
                            <Alert.Heading>Email has been sent successfully!</Alert.Heading>
                            <p>
                                Our team will contact you soon :)
                            </p>
                        </Alert>
                    </>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        store_data: state.InstallReducer.store_data,
        env: state.AllReducer.env.data
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        InstallAction: bindActionCreators(InstallAction, dispatch),
        AllAction: bindActionCreators(AllAction, dispatch),

    }
})
export default connect(mapStateToProps, mapDispatchToProps)(GetSupport);