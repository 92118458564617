import React, { Component } from 'react';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import * as AllAction from '../Action/AllAction';
import * as InstallAction from '../Action/installAction';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import ReactStars from "react-rating-stars-component";
import { Table, Button, OverlayTrigger, Modal, Tooltip, Form } from "react-bootstrap";
import { withRouter } from "react-router";
import AllImages from "./AllImages";
import YouTube from "react-youtube";
import Loader from "react-loader";
import SweetAlert from 'sweetalert2-react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import swal from "sweetalert";
import SignUpModal from './SignUpModal';
const pdf_icon = require("../img/pdf-image.png");
const fb_ads = require("../img/fb-ads.png");
const comments = require("../img/Comment-89.png");
const reactions = require("../img/Smiles.png");
var youtube_icon = require("../img/youtube-icon.png");

const likes = require("../img/Like-18.png");
const share = require("../img/Share-91.png");
var view = require("../img/view-42.png");

var u1 = require("../img/u1.png");
var u2 = require("../img/u2.png");
var u3 = require("../img/u3.png");
var u4 = require("../img/u4.png");
var u5 = require("../img/u5.png");
var u6 = require("../img/u6.png");
var u7 = require("../img/u7.png");
var u8 = require("../img/u8.png");
var ebayimg = require("../img/m-ebay.png");
var amazonimg = require("../img/m-amazon.png");
var etsyimg = require("../img/m-etsy.png");
var flipkartimg = require("../img/m-flipkart.png");
var shopifyimg = require("../img/p-shopify.png");
var magentoimg = require("../img/p-magento.png");
var wooimg = require("../img/p-woo.png");
var imageInstall = require("../img/app-logo.png");

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            env: { data: { CDN_URL: "" } },
            loaded_val: false,
            home_all_product_data: [],
            modalShow: false,
            loaded: false,
            show_youtube: false,
            product_image_id: "",
            pageNumber: 1,
            activePage: 1,
            total_product_count: 0,
            sort: "",
            display_youtube_video_id: "",
            apiCall: false,
            selected_category: "",
            by_interest: "",
            by_product: "",
            modal_for: "",
            youtube_url: "",
            on_refresh_data: [],
            get_categories: [],
            show_add_to_shopify: false,
            shopify_p_data: {},
            modal_for_snm: "",
            modal_snm: false,
            loaded_env: false,
            loaded_order: false,
            get_order: [],
            shop_data: [],
            count: 0,
            store_data: [],
            showSignUpModal: false,
            loaded_signup: false,
            showSignUpSuccess: false,
            showSignInModal: false,
            loaded_signin: false,
            showSignInSuccess: false,
            signin_email: "", signin_password: "", email: "",
            fieldsErrors: { signin_email: "", signin_password: "", email: "" },
            formValid: false
        }
    }

    componentWillMount() {
        const params = new URLSearchParams(window.location.search)
        var shop_a = params.get('shop_a');
        var token_a = params.get('token_a');
        var shop_params = params.get('shop');

        //if user from direct admin login
        if (window.location.href.includes("shop_a") &&
            window.location.href.includes("token_a")) {
            const params = new URLSearchParams(window.location.search)
            var shop_a = params.get('shop_a');
            var token_a = params.get('token_a');
            const Cryptr = require('cryptr');
            const cryptr = new Cryptr('myTotalySecretKey');
            const dec_token = cryptr.decrypt(token_a);
            var user_from_a = params.get('user_from_a');
            const asyncLocalStorage = {
                setItem: function (key, value) {
                    return Promise.resolve().then(function () {
                        localStorage.setItem(key, value);
                    });
                }
            };
            asyncLocalStorage.setItem('shop_wp', shop_a)
                .then(() => asyncLocalStorage.setItem('token_wp', dec_token))
                .then(() => asyncLocalStorage.setItem('user_from', user_from_a))
                .then(() => {
                    this.props.history.push("/products")
                })
        }

        //if user is already logged-in
        var shop_wp = localStorage.getItem("shop_wp")
        var token_wp = localStorage.getItem("token_wp")
        if (shop_wp && token_wp) {
            if (shop_params && shop_params.includes(".myshopify.com")) {
                //if user is not from website and URL includes shop
                this.props.action.InstallAction.getTokenApp(shop_params, this.props.history);
            } else {
                var data = {
                    shop: shop_wp, history: this.props.history
                }
                this.props.action.InstallAction.getTokenOnRefreshPage(shop_wp, data);
            }
        }
        else if (shop_params && shop_params.includes(".myshopify.com")) {
            //if user is not from website and URL includes shop
            this.props.action.InstallAction.getTokenApp(shop_params, this.props.history);
        }
        else {
            this.setState({ loaded_val: true })
        }

        const env_data = {
            secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
        }

        this.props.action.AllAction.GetEnvAction(env_data);
        const data_all_home_product = {
            page: 1
        }
        this.props.action.AllAction.HomeAllProductAction(data_all_home_product);
        const hide_link_logout = {
            tag: "link_hide",
            key: "link_hide"
        };
        this.props.load_hl_lo !== true && this.props.action.AllAction.hideLinkLogoutAction(hide_link_logout);
    }

    componentDidUpdate() {
        var user_from = localStorage.getItem("user_from")
        if ((JSON.stringify(this.state.store_data) !== JSON.stringify(this.props.store_data))) {
            this.setState({
                store_data: this.props.store_data,
            }, () => {
                const token = localStorage.getItem("token_wp");
                const shop = localStorage.getItem("shop_wp");
                const matchtoken = this.props.store_data.token;
                const matchshop = this.props.store_data.shop;
                if (token !== matchtoken || shop !== matchshop) {
                    this.setState({
                        loaded_val: true,
                    })
                }
                else {
                    this.props.history.push("/products");
                }
            })
        }
        if ((JSON.stringify(this.state.on_refresh_data)) != (JSON.stringify(this.props.on_refresh_data))) {

            this.setState({
                on_refresh_data: this.props.on_refresh_data,
            }, () => {
                const token = localStorage.getItem("token_wp");
                const shop = localStorage.getItem("shop_wp");
                const matchtoken = this.props.on_refresh_data.token;
                const matchshop = this.props.on_refresh_data.shop;
                if (token !== matchtoken || shop !== matchshop) {
                    this.setState({
                        loaded_val: true,
                    })
                }
                else {
                    this.props.history.push("/products");
                }
            })
        }
        if (this.state.env !== this.props.env) {
            this.setState(
                {
                    env: this.props.env
                },
                () => {
                    this.setState({
                        loaded_env: true
                    });
                }
            );
        }

        if (this.state.sign_up !== this.props.sign_up) {
            this.setState(
                {
                    sign_up: this.props.sign_up
                },
                () => {
                    this.setState({
                        loaded_signup: true
                    });
                }
            );
        }

        if (this.state.sign_in !== this.props.sign_in) {
            this.setState(
                {
                    sign_in: this.props.sign_in
                },
                () => {
                    if (this.state.sign_in.message === "Invalid user name or password !") {
                        this.setState({
                            loaded_signin: true
                        });
                    }
                }
            );
        }

        if (this.props.home_all_product_data !== this.state.home_all_product_data) {
            this.setState(
                {
                    home_all_product_data: this.props.home_all_product_data,
                },
                () => {
                    this.setState({
                        loaded: true
                    });
                }
            );
        }
    };

    handleSinglePageRouter = id => {
        this.props.history.push("/home/" + id);
    };
    toggle = (id, key) => {
        this.setState({
            modalShow: !this.state.modalShow,
            product_image_id: id,
            modal_for: key
        });
    };
    handleLinkClick = (link, is_show) => {
        if (this.props.load_hl_lo && this.props.hide_link_logout && this.props.hide_link_logout.is_hide_status === true
            && is_show === 'show') {
            this.handleSwal();
        }
        else {
            window.open(link, "_blank");
        }
    };
    handleSwal = () => {
        this.setState({
            showSignUpModal: true
        })
    }
    renderTooltip = props => {
        return <Tooltip {...props}>{props}</Tooltip>;
    };
    handleForgotPwd = () => {
        this.handleCloseSignIn();
        this.props.action.AllAction.forgotPasswordAction();
    }
    handleClose = () => {
        this.setState(
            {
                show_youtube: false
            },
            () => {
                this.setState({
                    display_youtube_video_id: "",
                    youtube_url: ""
                });
            }
        );
    };
    handleShow = video => {
        if (this.props.load_hl_lo && this.props.hide_link_logout.is_hide_status === true) {
            this.handleSwal();
        }
        else {
            var index = video && video.length !== 0 && video[0].indexOf("=");
            var arr = [
                video && video.length !== 0 && video[0].slice(0, index),
                video && video.length !== 0 && video[0].slice(index + 1)
            ];
            this.setState(
                {
                    display_youtube_video_id: arr[1],
                    youtube_url: video[0]
                },
                () => {
                    this.setState({
                        show_youtube: true
                    });
                }
            );
        }
    };

    // signUp = (e) => {
    //     e.preventDefault();
    //     var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,15}$/;
    //     if (this.state.email === "") {
    //         this.setState({
    //             fieldsErrors: {
    //                 email: "Enter Email-ID !",
    //             }
    //         })
    //     }
    //     else if (pattern.test(this.state.email) === false) {
    //         this.setState({
    //             fieldsErrors: {
    //                 email: "Enter valid Email-ID !",
    //             }
    //         })
    //     }
    //     else {
    //         this.handleCloseSignUp();
    //         let result = {
    //             username: this.state.email,
    //         }
    //         this.props.action.AllAction.sendSignupEmailAction(result);
    //     }

    // }

    signIn = (e) => {
        e.preventDefault();
        if (this.state.signin_email === "") {
            this.setState({
                fieldsErrors: {
                    signin_email: "Enter Email-ID !",
                }
            })
        }
        else if (this.state.signin_password === "") {
            this.setState({
                fieldsErrors: {
                    signin_password: "Enter Password !"
                }
            })
        }
        else {
            this.handleCloseSignIn();
            let result = {
                username: this.state.signin_email,
                password: this.state.signin_password
            }
            this.props.action.AllAction.signInAction(result, this.props.history);
        }
    }

    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value,
        }, () => {
            this.setState({
                fieldsErrors: { [key]: "" },
                formValid: true
            })
        })
    };
    handleCloseSignUp = () => this.setState({
        showSignUpModal: false
    });
    handleShowSignUp = () => this.setState({ showSignUpModal: true });
    handleCloseSignIn = () => this.setState({
        showSignInModal: false, showSignInSuccess: true, signin_email: "", signin_password: "",
        fieldsErrors: { signin_email: "", signin_password: "" }
    });
    handleShowSignIn = () => this.setState({ showSignInModal: true });

    render() {

        let country_value = JSON.parse(localStorage.getItem("country_wp"));

        var settings = {
            dots: false,
            pauseOnHover: true,
            pauseOnFocus: true,
            infinite: false,
            speed: 800,
            slidesToShow: 10,
            slidesToScroll: 1,
            autoplay: window.location.hostname !== 'localhost' ? true : false,
            autoplaySpeed: 6000,
            swipe: false,
            arrows: false,
            draggable: false,
            centerMode: false,
            adaptiveHeight: false,
            pauseOnHover: true,
            vertical: true
        };
        var Hsettings = {
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            draggable: true,
            autoplaySpeed: 5000,
            arrows: false
        };
        const opts = {
            playerVars: {
                autoplay: 0,
                controls: 0,
                forceSSL: 0,
                origin: "https://" + window.location.hostname
            }
        };

        const all_data =
            this.state.home_all_product_data && this.state.home_all_product_data.length !== 0
                ? this.state.home_all_product_data.map((home_all_product_data, i) => {
                    const links =
                        this.state.loaded_env &&
                        home_all_product_data.product_link &&
                        home_all_product_data.product_link.length !== 0 &&
                        home_all_product_data.product_link.map(product_link => {
                            return (
                                <OverlayTrigger
                                    placement="auto"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={this.renderTooltip(product_link.title)}
                                >
                                    <div
                                        className="t-link-icon"
                                        onClick={() =>
                                            this.handleLinkClick(product_link.link_name, "show")
                                        }
                                    >
                                        <img
                                            src={(typeof (product_link.icon) === "string" &&
                                                (product_link.icon.includes("http") ? product_link.icon :
                                                    this.state.env.CDN_URL + product_link.icon.replace("images", "thumb_images")))}
                                            height="50px"
                                            width="50px"
                                            alt={product_link.title}
                                        ></img>
                                    </div>
                                </OverlayTrigger>
                            );
                        });

                    const get_id_youtube1 = home_all_product_data.product_youtube_video &&
                        home_all_product_data.product_youtube_video.length !== 0 && (
                            <div key={i}>
                                <div
                                    className="y-video-container"
                                    onClick={() =>
                                        this.handleShow(home_all_product_data.product_youtube_video)
                                    }
                                >
                                    <div className="t-product-img-container-youtube">
                                        <img src={youtube_icon.toString()} alt="NoImage"></img>
                                    </div>
                                </div>
                                {home_all_product_data.product_youtube_video.length > 1 && (
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() =>
                                            this.toggle(
                                                this.props.load_hl_lo && this.props.hide_link_logout.is_hide_status === true
                                                    ? this.handleSwal()
                                                    : home_all_product_data._id,
                                                "product_youtube_viedo"
                                            )
                                        }
                                    >
                                        View all
                                    </div>
                                )}
                            </div>
                        );
                    const fb_doc1 = this.state.loaded_env && home_all_product_data.product_fb_ads &&
                        home_all_product_data.product_fb_ads.document &&
                        home_all_product_data.product_fb_ads.document.length !== 0 && (
                            <div>
                                <div
                                    className="fb-ads-img"
                                    onClick={() =>
                                        this.handleLinkClick(
                                            home_all_product_data.product_fb_ads.document[0].includes("http") ? home_all_product_data.product_fb_ads.document[0] :
                                                this.state.env.CDN_URL + home_all_product_data.product_fb_ads.document[0],
                                            "show"
                                        )
                                    }
                                >
                                    <img
                                        src={pdf_icon}
                                        width="85px"
                                        height="85px"
                                        alt="No document"
                                    ></img>
                                </div>
                                {home_all_product_data.product_fb_ads.document.length > 1 && (
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() =>
                                            this.toggle(
                                                home_all_product_data._id,
                                                "product_fb_documents"
                                            )
                                        }
                                    >
                                        All documents
                                    </div>
                                )}
                            </div>
                        );

                    const fb_image1 = this.state.loaded_env && home_all_product_data.product_fb_ads &&
                        home_all_product_data.product_fb_ads.image &&
                        home_all_product_data.product_fb_ads.image.length !== 0 && (
                            <div>
                                <div
                                    className="t-product-img-container"
                                    onClick={() =>
                                        this.handleLinkClick(
                                            home_all_product_data.product_fb_ads.image[0] && home_all_product_data.product_fb_ads.image[0].includes("http") ? home_all_product_data.product_fb_ads.image[0] :
                                                this.state.env.CDN_URL + home_all_product_data.product_fb_ads.image[0],
                                            "show"
                                        )
                                    }
                                >
                                    <img
                                        src={(typeof (home_all_product_data.product_fb_ads.image[0]) === "string" &&
                                            (home_all_product_data.product_fb_ads.image[0] && home_all_product_data.product_fb_ads.image[0].includes("http")
                                                ? home_all_product_data.product_fb_ads.image[0] :
                                                this.state.env.CDN_URL + home_all_product_data.product_fb_ads.image[0].replace("images", "thumb_images")))}
                                        alt="NoImage"
                                    ></img>
                                </div>
                                {home_all_product_data.product_fb_ads.image.length > 1 && (
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() =>
                                            this.toggle(home_all_product_data._id, "product_fb_images")
                                        }
                                    >
                                        All images
                                    </div>
                                )}
                            </div>
                        );

                    const fb_video1 = home_all_product_data.product_fb_ads &&
                        home_all_product_data.product_fb_ads.video &&
                        home_all_product_data.product_fb_ads.video.length !== 0 && (
                            <div>
                                <div
                                    className="fb-ads-img"
                                    onClick={() =>
                                        this.handleLinkClick(
                                            home_all_product_data.product_fb_ads.video[0], "show"
                                        )
                                    }
                                >
                                    <img
                                        src={fb_ads}
                                        width="100px"
                                        height="60px"
                                        alt="No document"
                                    ></img>
                                </div>
                                {home_all_product_data.product_fb_ads.video.length > 1 && (
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() =>
                                            this.toggle(home_all_product_data._id, "product_fb_viedo")
                                        }
                                    >
                                        All videos
                                    </div>
                                )}
                            </div>
                        );

                    const engage_doc1 = this.state.loaded_env && home_all_product_data.product_engagement &&
                        home_all_product_data.product_engagement.document &&
                        home_all_product_data.product_engagement.document.length !== 0 && (
                            <div>
                                <div
                                    className="fb-ads-img"
                                    onClick={() =>
                                        this.handleLinkClick(
                                            home_all_product_data.product_engagement.document[0].includes("http") ? home_all_product_data.product_engagement.document[0] :
                                                this.state.env.CDN_URL + home_all_product_data.product_engagement.document[0]
                                        )
                                    }
                                >
                                    {" "}
                                    <img
                                        src={pdf_icon}
                                        width="85px"
                                        height="85px"
                                        alt="No document"
                                    ></img>
                                </div>
                                {home_all_product_data.product_engagement.document.length > 1 && (
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() =>
                                            this.toggle(
                                                home_all_product_data._id,
                                                "product_engage_documents"
                                            )
                                        }
                                    >
                                        All documents
                                    </div>
                                )}
                            </div>
                        );

                    const engage_image1 = this.state.loaded_env && home_all_product_data.product_engagement &&
                        home_all_product_data.product_engagement.image &&
                        home_all_product_data.product_engagement.image.length !== 0 && (
                            <div>
                                <div
                                    className="t-product-img-container"
                                    onClick={() =>
                                        this.handleLinkClick(
                                            home_all_product_data.product_engagement.image[0].includes("http") ? home_all_product_data.product_engagement.image[0] :
                                                this.state.env.CDN_URL + home_all_product_data.product_engagement.image[0]
                                        )
                                    }
                                >
                                    <img
                                        src={(typeof (home_all_product_data.product_engagement.image[0]) === "string" &&
                                            (home_all_product_data.product_engagement.image[0].includes("http") ? home_all_product_data.product_engagement.image[0] :
                                                this.state.env.CDN_URL + home_all_product_data.product_engagement.image[0].replace("images", "thumb_images")))}
                                        alt="NoImage"
                                    ></img>
                                </div>
                                {home_all_product_data.product_engagement.image.length > 1 && (
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() =>
                                            this.toggle(
                                                home_all_product_data._id,
                                                "product_engage_images"
                                            )
                                        }
                                    >
                                        All images
                                    </div>
                                )}
                            </div>
                        );
                    const engage_video1 = home_all_product_data.product_engagement.video &&
                        home_all_product_data.product_engagement.video.length !== 0 && (
                            <div>
                                <div
                                    className="fb-ads-img"
                                    onClick={() =>
                                        this.handleLinkClick(
                                            home_all_product_data.product_engagement.video[0]
                                        )}>
                                    <img
                                        src={fb_ads}
                                        width="100px"
                                        height="60px"
                                        alt="No document"></img>
                                </div>
                                {home_all_product_data.product_engagement.video.length > 1 && (
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() =>
                                            this.toggle(
                                                home_all_product_data._id,
                                                "product_engage_viedo"
                                            )}>
                                        All videos
                                    </div>
                                )}
                            </div>
                        );
                    const engage_txt =
                        home_all_product_data.product_engagement.text !== "" &&
                        home_all_product_data.product_engagement.text.replace(/\s/g, "");
                    const engage = engage_txt && engage_txt.split(",");
                    const filter_enage_like =
                        engage &&
                        engage.length !== 0 &&
                        engage.filter(engage => {
                            return (
                                engage.includes("like") ||
                                engage.includes("likes") ||
                                engage.includes("LIKES") ||
                                engage.includes("LIKE") ||
                                engage.includes("Likes") ||
                                engage.includes("Like")
                            );
                        });

                    const filter_enage_comment =
                        engage &&
                        engage.length !== 0 &&
                        engage.filter(engage => {
                            return (
                                engage.includes("comment") ||
                                engage.includes("comments") ||
                                engage.includes("COMMENT") ||
                                engage.includes("COMMENTS") ||
                                engage.includes("Comments") ||
                                engage.includes("Comment")
                            );
                        });
                    const filter_enage_reactions =
                        engage &&
                        engage.length !== 0 &&
                        engage.filter(engage => {
                            return (
                                engage.includes("reaction") ||
                                engage.includes("reactions") ||
                                engage.includes("REACTIONS") ||
                                engage.includes("REACTION") ||
                                engage.includes("Reaction") ||
                                engage.includes("Reactions")
                            );
                        });
                    const filter_enage_share =
                        engage &&
                        engage.length !== 0 &&
                        engage.filter(engage => {
                            return (
                                engage.includes("share") ||
                                engage.includes("shares") ||
                                engage.includes("SHARE") ||
                                engage.includes("SHARES") ||
                                engage.includes("Shares") ||
                                engage.includes("Share")
                            );
                        });
                    const filter_enage_view =
                        engage &&
                        engage.length !== 0 &&
                        engage.filter(engage => {
                            return (
                                engage.includes("view") ||
                                engage.includes("views") ||
                                engage.includes("VIEW") ||
                                engage.includes("VIWES") ||
                                engage.includes("Views") ||
                                engage.includes("View")
                            );
                        });
                    const filter_enage_comment_val =
                        filter_enage_comment &&
                        filter_enage_comment.length !== 0 &&
                        filter_enage_comment[0].split("-")[0];
                    const filter_enage_like_val =
                        filter_enage_like &&
                        filter_enage_like.length !== 0 &&
                        filter_enage_like[0].split("-")[0];
                    const filter_enage_reactions_val =
                        filter_enage_reactions &&
                        filter_enage_reactions.length !== 0 &&
                        filter_enage_reactions[0].split("-")[0];
                    const filter_enage_share_val =
                        filter_enage_share &&
                        filter_enage_share.length !== 0 &&
                        filter_enage_share[0].split("-")[0];
                    const filter_enage_view_val =
                        filter_enage_view &&
                        filter_enage_view.length !== 0 &&
                        filter_enage_view[0].split("-")[0];

                    const engage_text = (
                        <div className="engage_text">
                            <div className="p-engage-field">
                                {filter_enage_like && filter_enage_like.length !== 0 && (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.renderTooltip("Like")}
                                    >
                                        <div className="p-engage-data">
                                            <img
                                                src={likes}
                                                width="25px"
                                                height="25px"
                                                alt="Likes"
                                            ></img>
                                            <span>{filter_enage_like_val}</span>
                                        </div>
                                    </OverlayTrigger>
                                )}
                                {filter_enage_comment && filter_enage_comment.length !== 0 && (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.renderTooltip("Comment")}>
                                        <div className="p-engage-data">
                                            <img
                                                src={comments}
                                                width="25px"
                                                height="25px"
                                                alt="Comments"></img>
                                            <span>{filter_enage_comment_val}</span>
                                        </div>
                                    </OverlayTrigger>
                                )}
                                {filter_enage_share && filter_enage_share.length !== 0 && (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.renderTooltip("Share")}>
                                        <div className="p-engage-data">
                                            <img
                                                src={share}
                                                width="25px"
                                                height="25px"
                                                alt="Share"></img>
                                            <span>{filter_enage_share_val}</span>
                                        </div>
                                    </OverlayTrigger>
                                )}
                                {filter_enage_reactions &&
                                    filter_enage_reactions.length !== 0 && (
                                        <OverlayTrigger
                                            placement="left"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={this.renderTooltip("Reaction")}>
                                            <div className="p-engage-data">
                                                <img
                                                    src={reactions}
                                                    width="25px"
                                                    height="25px"
                                                    alt="Reactions"></img>
                                                <span>{filter_enage_reactions_val}</span>
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                {filter_enage_view && filter_enage_view.length !== 0 && (
                                    <OverlayTrigger
                                        placement="left"
                                        delay={{ show: 250, hide: 400 }}
                                        overlay={this.renderTooltip("View")}>
                                        <div className="p-engage-data">
                                            <img
                                                src={view}
                                                width="25px"
                                                height="25px"
                                                alt="Views"></img>
                                            <span>{filter_enage_view_val}</span>
                                        </div>
                                    </OverlayTrigger>
                                )}
                            </div>
                        </div>
                    );

                    const fb = (
                        <div className="pro-small-txt">
                            {home_all_product_data.product_fb_ads.text !== "" && (
                                <div className="t-block-discription">
                                    {" "}
                                    {home_all_product_data.product_fb_ads.text}{" "}
                                </div>
                            )}
                            {home_all_product_data.product_fb_ads.document.length !== 0 && (
                                <>{fb_doc1}</>
                            )}
                            {home_all_product_data.product_fb_ads.image.length !== 0 && (
                                <div>{fb_image1} </div>
                            )}
                            {home_all_product_data.product_fb_ads.video.length !== 0 && (
                                <>{fb_video1}</>
                            )}
                        </div>
                    );
                    const engage_f = (
                        <div className="pro-small-txt">
                            {home_all_product_data.product_engagement.text !== "" &&
                                // <div className="t-block-discription"> {home_all_product_data.product_engagement.text} </div>}
                                engage_text}
                            {home_all_product_data.product_engagement.document.length !== 0 && (
                                <>{engage_doc1}</>
                            )}
                            {home_all_product_data.product_engagement.image.length !== 0 && (
                                <div>{engage_image1}</div>
                            )}
                            {home_all_product_data.product_engagement.video.length !== 0 && (
                                <>{engage_video1}</>
                            )}
                        </div>
                    );
                    const analytics = (
                        <div className="t-product-analytics">
                            <div>
                                <span>Source:</span>{" "}
                                {home_all_product_data.product_analytics_source.toString()}
                            </div>
                            <div>
                                <span>Orders:</span>{" "}
                                {home_all_product_data.product_orders_count.toString()}
                            </div>
                            <div>
                                <span>Reviews:</span>{" "}
                                {home_all_product_data.product_reviews.toString()}
                            </div>
                            <div>
                                <div className="pro-rating-group">
                                    <ReactStars
                                        count={5}
                                        size={35}
                                        edit={false}
                                        value={home_all_product_data.product_rating.toString()}
                                        half={true}
                                        emptyIcon={<i className="far fa-star"></i>}
                                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                                        fullIcon={<i className="fa fa-star"></i>}
                                        color2={"#ffd700"} />
                                    <div className="pro-rating-value">
                                        {home_all_product_data.product_rating.toString()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                    var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                    ];
                    function dateFormat(d) {
                        var t = new Date(d);
                        return t.getDate() + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear();
                    }
                    function dateFormat2(d) {
                        var t = new Date(d);
                        return t.getDate() - 1 + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear();
                    }
                    var dd = dateFormat(new Date());
                    var dd_yest = dateFormat2(new Date());
                    var date_ver =
                        home_all_product_data.product_display_date &&
                        home_all_product_data.product_display_date.toString() &&
                        home_all_product_data.product_display_date.slice(0, -5);
                    var date_api =
                        home_all_product_data.product_display_date &&
                        home_all_product_data.product_display_date.toString();
                    var final_date =
                        dd === date_api
                            ? "Today"
                            : dd_yest === date_api
                                ? "Yesterday"
                                : date_ver;
                    var f_main_image = this.state.loaded_env && home_all_product_data.product_main_image !== "" &&
                        (typeof (home_all_product_data.product_main_image) === "string" && (home_all_product_data.product_main_image.includes("http") ? home_all_product_data.product_main_image :
                            this.state.env.CDN_URL + home_all_product_data.product_main_image.replace("images", "thumb_images")));
                    var f_main_image_onclick = this.state.loaded_env && home_all_product_data.product_main_image !== "" &&
                        (typeof (home_all_product_data.product_main_image) === "string" && home_all_product_data.product_main_image.includes("http") ? home_all_product_data.product_main_image :
                            this.state.env.CDN_URL + home_all_product_data.product_main_image);
                    function titleCase(str) {
                        var splitStr = str.toLowerCase().split(' ');
                        for (var i = 0; i < splitStr.length; i++) {
                            // You do not need to check if i is larger than splitStr length, as your for does that for you
                            // Assign it back to the array
                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                        }
                        // Directly return the joined string
                        return splitStr.join(' ');
                    }

                    let tempName = titleCase(home_all_product_data.product_name);
                    return (
                        <div className="table-row hide-on-desktop" key={i}>
                            <div className="table-cell" style={{ width: "20%" }} valign="middle">
                                <div className="product-cell">
                                    <div className="cell-date-holder">
                                        <span className="date-in-vertical">
                                            {final_date && final_date.toString()}
                                        </span>
                                    </div>
                                    <div className="t-pro-info-container">
                                        <div className="t-product-img-container">
                                            <img
                                                src={f_main_image}
                                                alt="NoImage"
                                                onClick={() =>
                                                    this.handleLinkClick(f_main_image_onclick)}></img>
                                        </div>
                                        <div className="t-product-title">
                                            {home_all_product_data.product_name && tempName}
                                        </div>

                                    </div>
                                    <div
                                        className="pro-img-viewmore-btn"
                                        onClick={() => this.toggle(home_all_product_data._id, "product_images")}>
                                        All Images
                                    </div>

                                    {/* mobile view  */}
                                    <div class="mobile-view_">
                                        <div className="mob-profit">
                                            <span>Profit:</span>{" "}
                                            <span>
                                                {home_all_product_data.product_profit_margin}
                                                <div className="base-price">
                                                    {(home_all_product_data.product_profit_margin !== home_all_product_data.base_product_profit_margin) && home_all_product_data.base_product_profit_margin}</div>
                                            </span>
                                        </div>
                                        <div className="mob-view-details">
                                            <Button className="btn-home"
                                                onClick={() =>
                                                    this.handleSinglePageRouter(home_all_product_data._id)
                                                }>{" "}View Details{" "}
                                            </Button>
                                        </div>
                                    </div>
                                    {/* mobile view  */}

                                </div>
                            </div>
                            <div className="table-cell" style={{ width: "11%" }} valign="middle">
                                <div className="profit-cost-inner">
                                    <div>
                                        <span>Price:</span>{" "}
                                        <span>
                                            {home_all_product_data.product_price}
                                            <div className="base-price">
                                                {(home_all_product_data.product_price !== home_all_product_data.base_product_price) && home_all_product_data.base_product_price}</div>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Sale price:</span>{" "}
                                        <span>
                                            {home_all_product_data.product_sale_price}
                                            <div className="base-price">
                                                {(home_all_product_data.product_sale_price !== home_all_product_data.base_product_sale_price) && home_all_product_data.base_product_sale_price}</div>
                                        </span>
                                    </div>
                                    <div>
                                        <span>Profit:</span>{" "}
                                        <span>
                                            {home_all_product_data.product_profit_margin}
                                            <div className="base-price">
                                                {(home_all_product_data.product_profit_margin !== home_all_product_data.base_product_profit_margin) && home_all_product_data.base_product_profit_margin}</div>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="table-cell" style={{ width: "11%" }} valign="middle">{analytics}</div>
                            <div className="table-cell" style={{ width: "11%" }} valign="middle">
                                {" "}
                                <div className="t-pro-links">{links}</div>
                            </div>
                            <div className="table-cell" style={{ width: "8%" }} valign="middle">{get_id_youtube1}</div>
                            <div className="table-cell" style={{ width: "10%" }} valign="middle">{fb}</div>
                            <div className="table-cell" style={{ width: "10%" }} valign="middle">{engage_f}</div>
                            <div className="table-cell" style={{ width: "10%" }} valign="middle">
                                <div className="pro-small-txt">
                                    <div className="block_discription">
                                        {home_all_product_data.product_interest}
                                    </div>
                                </div>
                            </div>
                            <div className="table-cell table-action-buttons" style={{ width: "9%" }}
                                valign="middle"
                            >
                                <Button className="btn-home"
                                    onClick={() =>
                                        this.handleSinglePageRouter(home_all_product_data._id)
                                    }>{" "}View Details{" "}
                                </Button>
                            </div>
                        </div>
                    );
                })
                : this.state.loaded &&
                this.state.home_all_product_data &&
                this.state.home_all_product_data.length === 0 && (
                    <div className="table-row">
                        <div className="table-cell" colSpan="9" style={{ width: "100%" }}>No Products</div>
                    </div>
                );

        return (this.state.loaded_val && this.props.load_hl_lo) ?
            <div className="install-page">
                <div className="iinstall-page-inner">
                    <div className="install-page-header">
                        <span className="image-in-ip">
                            <span className="country-data">
                                <span className="cou-name">{country_value && country_value.Country_Name}</span>
                                {country_value && <span className="cou-flag"><img src={this.state.env.CDN_URL + country_value.Country_Flag}></img></span>}
                            </span>
                            <div className="image-in-ip-1">
                                <img src={imageInstall} alt="" className="img-fluid" />
                            </div>
                        </span>
                        <span className="ip-head-txt">
                            <h1 className="ip-video-txt">Handpicked Dropshipping unsaturated Winning Products by Experts</h1>
                            <p className="ip-video-pre mb-0">Delivering tens of hundreds of products daily!</p>
                        </span>
                        <span className="ip-btn-group">
                            <button className="app-btn btn btn-primary mr-2" onClick={this.handleShowSignUp}> Sign Up </button>
                            <button className="app-btn btn btn-primary" onClick={this.handleShowSignIn}> Log In </button>
                        </span>

                        {/* <Modal show={this.state.showSignUpModal} onHide={this.handleCloseSignUp}>
                            <form onSubmit={this.signUp} className="signup-btn">
                                <Modal.Header closeButton>
                                    <Modal.Title>Sign Up!</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form.Group controlId="formBasicEmail" role="form">
                                        <Form.Label>Email-ID</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email-id" onChange={(e) => this.handleOnChange("email", e)} />
                                        <span className="err-val-msg">{this.state.fieldsErrors.email}</span>
                                    </Form.Group>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="primary" type="submit">
                                        SignUp
                                    </Button>
                                    <Button variant="secondary" onClick={this.handleCloseSignUp}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </Modal> */}
                        <SignUpModal showSignUpModal={this.state.showSignUpModal}
                            handleCloseSignUp={this.handleCloseSignUp} />
                        <Modal show={this.state.showSignInModal} onHide={this.handleCloseSignIn}>
                            <form onSubmit={this.signIn} className="signup-btn">
                                <Modal.Header closeButton>
                                    <Modal.Title>Sign In!</Modal.Title>
                                </Modal.Header>

                                <Modal.Body>
                                    <Form.Group controlId="formBasicEmail" role="form">
                                        <Form.Label>Email-ID</Form.Label>
                                        <Form.Control type="email" placeholder="Enter email-id" onChange={(e) => this.handleOnChange("signin_email", e)} value={this.state.signin_email} />
                                        <span className="err-val-msg">{this.state.fieldsErrors.signin_email}</span>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword" role="form">
                                        <Form.Label>Password</Form.Label>
                                        <Form.Control type="password" placeholder="Enter password" onChange={(e) => this.handleOnChange("signin_password", e)} value={this.state.signin_password} />
                                        <span className="err-val-msg">{this.state.fieldsErrors.signin_password}</span>
                                        <span className="forgot-pwd" onClick={() => this.handleForgotPwd()}>Forgot password?</span>

                                    </Form.Group>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="primary" type="submit">
                                        LogIn
                                    </Button>
                                    <Button variant="secondary" onClick={this.handleCloseSignIn}>
                                        Close
                                    </Button>
                                </Modal.Footer>
                            </form>
                        </Modal>

                        {/* <div>
                            <SweetAlert
                                show={this.state.loaded_signup && this.state.showSignUpSuccess && this.state.sign_up && this.state.sign_up.message}
                                title="Success"
                                text={this.state.sign_up && this.state.sign_up.message}
                                onConfirm={() => this.setState({ loaded_signup: false, showSignUpSuccess: false })}
                            />
                        </div>

                        <div>
                            <SweetAlert
                                show={this.state.loaded_signin && this.state.showSignInSuccess && this.state.sign_in && this.state.sign_in.message}
                                title="Sorry!"
                                text={this.state.sign_in && this.state.sign_in.message}
                                onConfirm={() => this.setState({ loaded_signin: false, showSignInSuccess: false })}
                            />
                        </div> */}
                    </div>
                    <p className="in-alert-msg"> These are 7 days delayed products.
                    <span className="show-reg-popup" onClick={this.handleShowSignUp}>{" "}Sign Up</span> Free for daily new products.</p>
                    <div className="table-container">
                        <Table bordered responsive className="pro-data-table in-data-table home-table">
                            <div className="table-row table-thead hide-on-desktop">
                                <div className="table-heading-cell" style={{ width: "20%" }} align="center">
                                    Product{" "}
                                </div>
                                <div className="table-heading-cell" style={{ width: "11%" }} align="center">Profits</div>
                                <div className="table-heading-cell" style={{ width: "11%" }} align="center">Analytics</div>
                                <div className="table-heading-cell" style={{ width: "11%" }} align="center">Links</div>
                                <div className="table-heading-cell" style={{ width: "8%" }} align="center">Youtube</div>
                                <div className="table-heading-cell" style={{ width: "10%" }} align="center">FB Ads</div>
                                <div className="table-heading-cell" style={{ width: "10%" }} align="center">Engagement </div>
                                <div className="table-heading-cell" style={{ width: "10%" }} align="center">Interest</div>
                                <div className="table-heading-cell" style={{ width: "9%" }} align="center">Action</div>
                            </div>
                            {/* <tbody>{all_data}</tbody> */}
                            {this.state.loaded && this.state.home_all_product_data &&
                                this.state.home_all_product_data.length !== 0 ?
                                <Slider {...settings}>
                                    {all_data}

                                </Slider> : <SkeletonTheme color="#c5cfe0" highlightColor="#b1bac9">
                                    <Skeleton count={10} height={190} /></SkeletonTheme>}
                        </Table>
                    </div>
                    <div className="ip-video-section">
                    <div class="container--video">
                    <iframe width="100%" class="video--"  src="https://www.youtube.com/embed/wJnFpn_er1g" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                        {/* <span className="ip-video-container"> */}
                            {/* <iframe width="100%" class="y-video"  src="https://www.youtube.com/embed/wJnFpn_er1g" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                            {/* <Player>
                        <source src="https://www.youtube.com/embed/wJnFpn_er1g" />
                    </Player> */}
                        {/* </span> */}
                    </div>
                   
                    <div className="ip-mpl-section mt-5">
                        <h2 className="ip-video-txt">Use Cases</h2>
                        <div className="mpl-holder">
                            <div className="mpl-item">
                                <span className="mpl-img-holder">
                                    <img src={ebayimg} alt="" className="img-fluid" />
                                </span>
                            </div>
                            <div className="mpl-item">
                                <span className="mpl-img-holder">
                                    <img src={amazonimg} alt="" className="img-fluid" />
                                </span>
                            </div>
                            <div className="mpl-item">
                                <span className="mpl-img-holder">
                                    <img src={etsyimg} alt="" className="img-fluid" />
                                </span>
                            </div>
                            <div className="mpl-item">
                                <span className="mpl-img-holder">
                                    <img src={flipkartimg} alt="" className="img-fluid" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="ip-platform-section pt-0">
                        <div className="platform-holder">
                            <div className="platform-item">
                                <span className="pl-img-holder">
                                    <img src={shopifyimg} alt="" className="img-fluid" />
                                </span>
                                <button className="btn btn-primary mt-2" onClick={() => {
                                    this.handleLinkClick("https://apps.shopify.com/winning-products?surface_detail=winning+product&surface_inter_position=1&surface_intra_position=4&surface_type=search")
                                }}>Use Shopify App</button>
                            </div>
                            <div className="platform-item">
                                <span className="pl-img-holder">
                                    <img src={magentoimg} alt="" className="img-fluid" />
                                </span>
                            </div>
                            <div className="platform-item">
                                <span className="pl-img-holder">
                                    <img src={wooimg} alt="" className="img-fluid" />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="ip-clientsays-section pt-2 mb-5">
                        <h2 className="ip-video-txt mb-4">Merchant's Feedbacks</h2>
                        <div className="cs-slider-holder">
                            <ul className="user-icon-group">
                                <li className="user-icon-list-item u1">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u1} alt="" />
                                    </span>
                                </li>
                                <li className="user-icon-list-item u2">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u2} alt="" />
                                    </span>
                                </li>
                                <li className="user-icon-list-item u3">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u3} alt="" />
                                    </span>
                                </li>
                                <li className="user-icon-list-item u4">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u4} alt="" />
                                    </span>
                                </li>
                                <li className="user-icon-list-item u5">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u5} alt="" />
                                    </span>
                                </li>
                                <li className="user-icon-list-item u6">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u6} alt="" />
                                    </span>
                                </li>
                                <li className="user-icon-list-item u7">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u7} alt="" />
                                    </span>
                                </li>
                                <li className="user-icon-list-item u8">
                                    <span className="u-icon-holder">
                                        <img className="img-fluid" src={u8} alt="" />
                                    </span>
                                </li>
                            </ul>
                            <Slider {...Hsettings}>
                                <div className="cs-slide-item">
                                    <span className="cs-txt-holder">
                                        <span className="cs-qoute">❝</span>
                                        <div className="cs-rating-group">
                                            <ReactStars
                                                count={5}
                                                size={35}
                                                edit={false}
                                                half={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                color2={"#f6ab36"}
                                            />
                                        </div>
                                        <p>Good app, easy to use, really helpful when doing product research. Support team is there anytime you need. Thank you support</p>
                                        <h6>Polo Pools</h6>
                                    </span>
                                </div>
                                <div className="cs-slide-item">
                                    <span className="cs-txt-holder">
                                        <span className="cs-qoute">❝</span>
                                        <div className="cs-rating-group">
                                            <ReactStars
                                                count={5}
                                                size={35}
                                                edit={false}
                                                half={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                color2={"#f6ab36"}
                                            />
                                        </div>
                                        <p>Good app. Everyday Give me winning product. I really like the app. Highly recommended.</p>
                                        <h6>Total Beauties Now</h6>
                                    </span>
                                </div>
                                <div className="cs-slide-item">
                                    <span className="cs-txt-holder">
                                        <span className="cs-qoute">❝</span>
                                        <div className="cs-rating-group">
                                            <ReactStars
                                                count={5}
                                                size={35}
                                                edit={false}
                                                half={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                color2={"#f6ab36"}
                                            />
                                        </div>
                                        <p>Amazing app, Giving winning products with Analytics. Very Fast and great support team.Highly Recommended</p>
                                        <h6>Beauty Products</h6>
                                    </span>
                                </div>
                                <div className="cs-slide-item">
                                    <span className="cs-txt-holder">
                                        <span className="cs-qoute">❝</span>
                                        <div className="cs-rating-group">
                                            <ReactStars
                                                count={5}
                                                size={35}
                                                edit={false}
                                                half={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                color2={"#f6ab36"}
                                            />
                                        </div>
                                        <p>Highly recommended. The best app to find winning products. Gives all details for each product with profit margin . so we can easily select product. Directly add to Shopify with one click. Great App with daily updates and support. I will use it for my other 3 stores.</p>
                                        <h6>Search Apps</h6>
                                    </span>
                                </div>
                                <div className="cs-slide-item">
                                    <span className="cs-txt-holder">
                                        <span className="cs-qoute">❝</span>
                                        <div className="cs-rating-group">
                                            <ReactStars
                                                count={5}
                                                size={35}
                                                edit={false}
                                                half={true}
                                                emptyIcon={<i className="far fa-star"></i>}
                                                halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                fullIcon={<i className="fa fa-star"></i>}
                                                color2={"#f6ab36"}
                                            />
                                        </div>
                                        <p>Great App! Very easy to setup. This does help increase sales. and the customer service is AMAZING. They reply to you requests very very fast. I highly recommend.</p>
                                        <h6>The Signature Collection </h6>
                                    </span>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
                <Modal
                    show={this.state.show_youtube}
                    onHide={this.handleClose}
                    dialogClassName="extra-content-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    size="lg"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Youtube Video</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="product-youtube-video-modal">
                            <YouTube
                                videoId={this.state.display_youtube_video_id}
                                opts={opts}
                                onReady={this._onReady}
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() =>
                                this.handleLinkClick(
                                    this.state.youtube_url && this.state.youtube_url
                                )
                            }
                        >
                            See in full screen
            </Button>
                    </Modal.Footer>
                </Modal>
                <AllImages
                    show={this.state.modalShow}
                    onHide={() => this.toggle()}
                    product_image_id={this.state.product_image_id}
                    all_product_data={this.props.home_all_product_data}
                    CDN_URL={this.props.env.CDN_URL}
                    modal_for={this.state.modal_for}
                />
            </div>
            : <div className="home-page-loader">
                <Loader
                    loaded={false}
                    lines={10}
                    length={5}
                    width={5}
                    radius={10}
                    corners={1}
                    rotate={0}
                    direction={1}
                    color="#1f3893"
                    speed={1}
                    trail={40}
                    shadow={false}
                    hwaccel={false}
                    className="spinner"
                    zIndex={2e9}
                    top="50%"
                    left="50%"
                    scale={1.0}
                    loadedClassName="loadedContent"
                />
            </div>
    }
};

const mapStateToProps = (state) => {
    return {
        store_data: state.InstallReducer.store_data,
        home_all_product_data: state.AllReducer.home_all_product_data.data,
        sign_up: state.AllReducer.sign_up,
        sign_in: state.AllReducer.sign_in,
        env: state.AllReducer.env.data,
        load_env: state.AllReducer.env.load_env,
        on_refresh_data: state.InstallReducer.on_refresh_data,
        hide_link_logout: state.AllReducer.hide_link_logout.message,
        load_hl_lo: state.AllReducer.hide_link_logout.load_hl_lo
    }
};
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
        InstallAction: bindActionCreators(InstallAction, dispatch)
    }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));