import React, { Component } from "react";
import NavbarHeader from "./NavbarHeader";
import { Button, InputGroup, FormControl, FormGroup, FormLabel, Tooltip, Modal } from "react-bootstrap";
import Header from "./Header";
import Select from "react-select";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AllAction from "../Action/AllAction";
import S3FileUpload from 'react-s3';
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

// var supplier = require("../img/supplier.png");

class Supplier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_supplier_reg: false,
      submit: false,
      s_link: "",
      s_address: "",
      s_cat: "",
      s_email: "",
      formValid: false,
      s_mob: "",
      supplier_product: "",
      trend_service: "",
      s_state: "",
      s_country: "",
      s_city: "",
      product_catalog: "",
      primary_business: [
        { label: "Buying Agent", value: "Buying Agent" },
        { label: "Dealer / Reseller", value: "Dealer / Reseller" },
        { label: "Distributor", value: "Distributor" },
        { label: "Manufacturer/ OEM", value: "Manufacturer/ OEM" },
        { label: "Not Known", value: "Not Known" },
        { label: "Retailer", value: "Retailer" },
        { label: "Service Provider", value: "Service Provider" }
      ],
      selected_primary_business: "",
      fieldsErrors: {
        s_link: "",
        s_address: "",
        s_cat: "",
        s_email: "",
        supplier_product: "",
        s_mob: "",
        s_state: "",
        s_country: "",
        s_city: "",
      },
      get_categories: [],
      selected_cat: [],
      tags: [],
      tag: ''
    };
    this.config_aws_images = {}
  }
  componentWillMount() {
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const env_data = {
      secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
    };
    this.props.load_env !== true && this.props.action.AllAction.GetEnvAction(env_data);
    const data_cat = {
      accessToken,
      shop
    };
    this.props.load_cat !== true && this.props.action.AllAction.getCategoryAction(data_cat);
  }
  handleChangeTag = (tags) => {
    this.setState({ tags })
  }

  handleChangeInputTag = (tag) => {
    this.setState({ tag })
  }
  handleClose = () => {
    this.setState({
      show_supplier_reg: false,
    });
  };
  onChangeCat = (value, key) => {
    this.setState({
      [key]: value
    }, () => {
      this.setState({
        fieldsErrors: { [key]: "" }
      })
    })
  };

  handleOnChange = (key, e) => {
    this.setState(
      {
        [key]: e.target.value
      },
      () => {
        this.setState({
          fieldsErrors: { [key]: "" }
        });
      }
    );
  };
  onChangeHandler = event => {
    this.setState({
      product_catalog: event.target.files[0],
    }, () => {
      const filename = this.state.product_catalog && this.state.product_catalog.name;
      var date = new Date();
      var timestamp = date.getTime().toString();
      var ext = filename.split('.').pop();
      var file_timestamp = timestamp.concat("." + ext);
      var file_upload = new File([this.state.product_catalog], file_timestamp, { type: this.state.product_catalog.type });
      var config_aws_images = {
        bucketName: this.props.env.S3_BUCKET,
        dirName: 'secure/appmixo/winning_product/supplier/catalog/' + new Date().getFullYear() + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate(), /* optional */
        region: 'eu-west-1',
        accessKeyId: this.props.env.AWS_ACCESS_KEY,
        secretAccessKey: this.props.env.AWS_SECRET_ACCESS_KEY,
      }
      S3FileUpload
        .uploadFile(file_upload, config_aws_images)
        .then(data => {
          var dir = config_aws_images.dirName + "/" + file_timestamp;
          this.setState({
            product_catalog: dir
          })
        })
        .catch(err => console.log(err))
    })
  }

  handleRegSupplier = () => {
    const {
      s_link,
      s_country,
      s_state,
      s_city,
      s_cat,
      selected_cat,
      s_email,
      supplier_product,
      s_mob,
      selected_primary_business,
      product_catalog,
      tags
    } = this.state;

    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    this.setState({
      submit: true
    });

    const data_supplier = {
      accessToken,
      shop,
      store_link: s_link,
      supplier_product,
      store_category: selected_cat.length !== 0 ? selected_cat : [],
      tags: tags.length !== 0 ? tags : [],
      supplier_email: s_email,
      supplier_country: s_country,
      supplier_state: s_state,
      supplier_city: s_city,
      supplier_mobile: s_mob,
      supplier_product_catalog: product_catalog ? product_catalog : "",
      supplier_primary_business: selected_primary_business.value ? selected_primary_business.value : "Not Known"
    };
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,15})+$/;
    var regex = /^\+(?:[0-9]-?){6,14}[0-9]$/;
    if (supplier_product === "") {
      this.setState({
        fieldsErrors: {
          supplier_product: "* Supplier of product is Required!"
        },
        formValid: false
      });
    }
    // else if (s_cat === "") {
    //   this.setState({
    //     fieldsErrors: { s_cat: "* Category is Required!" },
    //     formValid: false
    //   });
    // }
    else if (selected_cat.length === 0) {
      this.setState({
        fieldsErrors: { selected_cat: "* Category is Required!" },
        formValid: false
      });
    }
    else if (s_email === "") {
      this.setState({
        fieldsErrors: { s_email: "* Email is Required!" },
        formValid: false
      });
    } else if (!s_email.match(mailformat)) {
      this.setState({
        fieldsErrors: { s_email: "You have entered an invalid email address!" },
        formValid: false
      });
    }
    else if (s_country === "") {
      this.setState({
        fieldsErrors: { s_country: "* Select country!" },
        formValid: false
      });
    } else if (s_state === "") {
      this.setState({
        fieldsErrors: { s_state: "* State is Required!" },
        formValid: false
      });
    } else if (s_city === "") {
      this.setState({
        fieldsErrors: { s_city: "* City is Required!" },
        formValid: false
      });
    }
    else if (s_mob === "") {
      this.setState({
        fieldsErrors: { s_mob: "* Mobile no. is Required!" },
        formValid: false
      });
    } else if (!regex.test(s_mob)) {
      this.setState({
        fieldsErrors: { s_mob: "* You've entered wrong mobile number!" },
        formValid: false
      });
    } else {
      this.setState(
        {
          formValid: true
        },
        () => {
          this.props.action.AllAction.SaveSuppliergAction(data_supplier);
          // this.props.onHideCat();
        }
      );
    }
  };

  handleShow = () => {
    this.setState({
      show_supplier_reg: true,
      s_link: "",
      s_address: "",
      s_cat: "",
      s_email: "",
      s_mob: "",
      supplier_product: "",
      trend_service: "",
      s_state: "",
      s_country: "",
      s_city: "",
      product_catalog: "",
      selected_primary_business: "",
      fieldsErrors: {
        s_link: "",
        s_address: "",
        s_cat: "",
        s_email: "",
        supplier_product: "",
        s_mob: "",
        s_state: "",
        s_country: "",
        s_city: "",
      },
      selected_cat: [],
      tags: [],
      tag: ''
    });
  };
  renderTooltip = props => {
    return <Tooltip {...props}>{props}</Tooltip>;
  };
  render() {
    const img_dir = "secure/appmixo/winning_product/static/";
    var env_path =
      this.props.env &&
      this.props.env.CDN_URL !== "" &&
      this.props.env.CDN_URL + img_dir;
    var supplier = env_path + "supplier.jpg";
    const selectedOptionsStyles = {
      color: "#fff",
      backgroundColor: "#1dc7ea"
    };
    const optionsListStyles = {
      backgroundColor: "#fff",
      color: "#1dc7ea"
    };
    var inputProps = {
      className: 'react-tagsinput-input',
      placeholder: 'Add upto 4 tags'
    }
    return (
      <div className="root-page">
        <NavbarHeader />
        <div className="main-container sup-holder">
          <Header header_name="Winning Products" history={this.props.history} />
          <div className="section-head ">
            <h4 className="section-title">Supplier</h4>
            <div className="influ-info">
              {/* <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={this.renderTooltip("Comming soon")}
              > */}
              <button className="find-influ btn btn-primary mt-0 mr-3" onClick={() => this.props.history && this.props.history.push('/suppliers/list')}>
                Find Supplier near you
                </button>
              {/* </OverlayTrigger> */}
              {this.props.load_cat && <button
                onClick={() => this.handleShow()}
                className="btn btn-primary">
                Register As Supplier
              </button>}
            </div>
          </div>
          <div className="influ-txt-container mb-5">
            <h1 className="influ-head" style={{ color: "#1f3893" }}>
              Let's choose or become{" "}
              <strong style={{ color: "#f6ab36" }}>Product Supplier</strong>
            </h1>
            <p className="influ-pre">Get your right match</p>
          </div>
          {this.props.load_env && (
            <div className="about-sup">
              <div className="ab-left">
                <h4 className="ab-title">
                  Benefits of Dropshipping From Multiple Suppliers
                </h4>
                <p className="ab-pre">
                  When it comes to dropshipping, don’t feel like you need to
                  rely on just one supplier to source your products. There are
                  numerous benefits that come with selecting multiple
                  dropshipping suppliers for your business. Let’s take a look at
                  them below.
                </p>
                <ul className="sup-list-group">
                  <li className="sup-list-item">
                    <div className="il-txt-holder">
                      <h3 className="il-title">More Products Available</h3>
                      <p className="il-details">
                        When you’re finding dropshipping suppliers for your
                        business, you need to be thinking about the products
                        that the suppliers are offering. One of the key, clear
                        benefits that come with selecting multiple suppliers for
                        your business is that they’ll likely be able to offer
                        you a wider variety of products for your store. It’s
                        always good to have more options – it’ll make it easier
                        to find products that your customer loves if you have
                        more choice.
                      </p>
                    </div>
                  </li>
                  <li className="sup-list-item">
                    <div className="il-txt-holder">
                      <h3 className="il-title">More Business Relationships</h3>
                      <p className="il-details">
                        As a dropshipper, relationships with your suppliers are
                        so important. After all, without them, you won’t have
                        products to deliver to your customers. This means that
                        it’s in your best interest to have a positive, fruitful
                        relationship with your suppliers.
                      </p>
                      <p className="il-details">
                        If you decide to use multiple dropshipping suppliers to
                        source your products from, you’ve got the potential to
                        start and grow numerous business relationships. This can
                        end up being a great asset for you as your business
                        grows and scales.
                      </p>
                    </div>
                  </li>
                  <li className="sup-list-item">
                    <div className="il-txt-holder">
                      <h3 className="il-title">Assess Your Product Quality</h3>
                      <p className="il-details">
                        When you’re selecting suppliers to help you run your
                        business you’ll want to find ones which can provide you
                        with high quality products. This means that working with
                        multiple dropshipping suppliers, especially as you’re
                        starting your business, will help you to better
                        understand the quality of the products that you receive
                        through your test orders. If you can compare products
                        from different dropshipping suppliers during your
                        selection process, you’ll be much more educated on which
                        ones are best suited for your dropshipping store.{" "}
                      </p>
                    </div>
                  </li>
                  <li className="sup-list-item">
                    <div className="il-txt-holder">
                      <h3 className="il-title">
                        Understand Your Customer’s Experience
                      </h3>
                      <p className="il-details">
                        As a dropshipper, it’s important that you try to
                        understand your customer’s experience with your store.
                        This is especially true when it comes to the delivery of
                        your products. Understanding your customer experience
                        will help you to identify ways where your dropshipping
                        suppliers can improve the service that they provide for
                        your store. This is potentially even more important if
                        you’re drop shipping from multiple suppliers, as the
                        service that they provide may vary slightly.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="ab-right">
                <img src={supplier} className="img-fluid" />
              </div>
            </div>
          )}
        </div>
        <Modal
          show={this.state.show_supplier_reg}
          onHide={this.handleClose}
          dialogClassName="extra-content-modal"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Register As Supplier</Modal.Title>
          </Modal.Header>
          {this.state.show_supplier_reg === true && this.props.load_cat &&
            this.state.formValid === false && (
              <>
                <Modal.Body>
                  <div>
                    <form>
                      <div className="supplier-first-portion">
                        <h4>About Your Organization</h4>
                        <FormGroup>
                          <FormLabel>Business Name/URL</FormLabel>
                          <FormControl
                            type="text"
                            placeholder="Enter business name/URL"
                            onChange={e => this.handleOnChange("s_link", e)}
                          ></FormControl>
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Supplier of Products *</FormLabel>
                          <FormControl
                            as="textarea"
                            placeholder="Tell us what products your company supplies in e.g. Wooden Furniture, Heavy Machinery, Cellphone Cases etc."
                            onChange={e =>
                              this.handleOnChange("supplier_product", e)
                            }
                          ></FormControl>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.supplier_product}
                          </span>
                        </FormGroup>
                        {/* <FormGroup>
                          <FormLabel>
                            Category *
                            <span>
                              {" "}
                              ( Add more than 1 category separated by comma ( ,
                              ) )
                            </span>
                          </FormLabel>
                          <FormControl
                            as="textarea"
                            placeholder="Enter category"
                            onChange={e => this.handleOnChange("s_cat", e)}
                          ></FormControl>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.s_cat}
                          </span>
                        </FormGroup>
                        <FormGroup> */}
                        <FormGroup>
                          <FormLabel>Category *</FormLabel>
                          <div className="input-cat-supplier">
                            <Select
                              options={this.props.get_categories}
                              onChange={e => this.onChangeCat(e, "selected_cat")}
                              isMulti={true}
                            />
                          </div>
                          <span style={{ color: "red" }}>{this.state.fieldsErrors.selected_cat}</span>
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Tags</FormLabel>
                          <TagsInput
                            value={this.state.tags.length !== 0 ? this.state.tags : []}
                            onChange={this.handleChangeTag}
                            inputValue={this.state.tag}
                            onChangeInput={this.handleChangeInputTag}
                            maxTags={4}
                            onlyUnique={true}
                            inputProps={inputProps}
                          />
                          <span className="hint">hit 'Enter' to add new tags</span>
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>Product Catalog</FormLabel>
                          <FormControl type="file" multiple={false} onChange={(e) => this.onChangeHandler(e)} accept=".pdf"></FormControl>
                        </FormGroup>
                        <FormLabel>Your Primary Business</FormLabel>
                        <InputGroup>
                          <div className="container">
                            <Select
                              options={this.state.primary_business}
                              onChange={e =>
                                this.onChangeCat(e, "selected_primary_business")
                              }
                              value={this.state.selected_primary_business}
                              defaultValue={this.state.primary_business[4]}
                              autoFocus={false}
                            />
                          </div>
                        </InputGroup>
                      </div>
                      <div className="supplier-second-portion">
                        <h4>Your Contact Details</h4>
                        <FormGroup>
                          <FormLabel>Email *</FormLabel>
                          <FormControl
                            type="email"
                            placeholder="Enter email"
                            onChange={e => this.handleOnChange("s_email", e)}
                          ></FormControl>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.s_email}
                          </span>
                        </FormGroup>
                        <div>
                          <FormLabel>Location *</FormLabel>
                          <FormGroup>
                            <div className="is-address">
                              <div className="is-address-inner">
                                <FormControl
                                  as="select"
                                  componentClass="select"
                                  onChange={e => this.handleOnChange("s_country", e)}>
                                  <option value="Afganistan">Afghanistan</option>
                                  <option value="Albania">Albania</option>
                                  <option value="Algeria">Algeria</option>
                                  <option value="American Samoa">American Samoa</option>
                                  <option value="Andorra">Andorra</option>
                                  <option value="Angola">Angola</option>
                                  <option value="Anguilla">Anguilla</option>
                                  <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                                  <option value="Argentina">Argentina</option>
                                  <option value="Armenia">Armenia</option>
                                  <option value="Aruba">Aruba</option>
                                  <option value="Australia">Australia</option>
                                  <option value="Austria">Austria</option>
                                  <option value="Azerbaijan">Azerbaijan</option>
                                  <option value="Bahamas">Bahamas</option>
                                  <option value="Bahrain">Bahrain</option>
                                  <option value="Bangladesh">Bangladesh</option>
                                  <option value="Barbados">Barbados</option>
                                  <option value="Belarus">Belarus</option>
                                  <option value="Belgium">Belgium</option>
                                  <option value="Belize">Belize</option>
                                  <option value="Benin">Benin</option>
                                  <option value="Bermuda">Bermuda</option>
                                  <option value="Bhutan">Bhutan</option>
                                  <option value="Bolivia">Bolivia</option>
                                  <option value="Bonaire">Bonaire</option>
                                  <option value="Bosnia & Herzegovina">Bosnia & Herzegovina</option>
                                  <option value="Botswana">Botswana</option>
                                  <option value="Brazil">Brazil</option>
                                  <option value="British Indian Ocean Ter">British Indian Ocean Ter</option>
                                  <option value="Brunei">Brunei</option>
                                  <option value="Bulgaria">Bulgaria</option>
                                  <option value="Burkina Faso">Burkina Faso</option>
                                  <option value="Burundi">Burundi</option>
                                  <option value="Cambodia">Cambodia</option>
                                  <option value="Cameroon">Cameroon</option>
                                  <option value="Canada">Canada</option>
                                  <option value="Canary Islands">Canary Islands</option>
                                  <option value="Cape Verde">Cape Verde</option>
                                  <option value="Cayman Islands">Cayman Islands</option>
                                  <option value="Central African Republic">Central African Republic</option>
                                  <option value="Chad">Chad</option>
                                  <option value="Channel Islands">Channel Islands</option>
                                  <option value="Chile">Chile</option>
                                  <option value="China">China</option>
                                  <option value="Christmas Island">Christmas Island</option>
                                  <option value="Cocos Island">Cocos Island</option>
                                  <option value="Colombia">Colombia</option>
                                  <option value="Comoros">Comoros</option>
                                  <option value="Congo">Congo</option>
                                  <option value="Cook Islands">Cook Islands</option>
                                  <option value="Costa Rica">Costa Rica</option>
                                  <option value="Cote DIvoire">Cote DIvoire</option>
                                  <option value="Croatia">Croatia</option>
                                  <option value="Cuba">Cuba</option>
                                  <option value="Curaco">Curacao</option>
                                  <option value="Cyprus">Cyprus</option>
                                  <option value="Czech Republic">Czech Republic</option>
                                  <option value="Denmark">Denmark</option>
                                  <option value="Djibouti">Djibouti</option>
                                  <option value="Dominica">Dominica</option>
                                  <option value="Dominican Republic">Dominican Republic</option>
                                  <option value="East Timor">East Timor</option>
                                  <option value="Ecuador">Ecuador</option>
                                  <option value="Egypt">Egypt</option>
                                  <option value="El Salvador">El Salvador</option>
                                  <option value="Equatorial Guinea">Equatorial Guinea</option>
                                  <option value="Eritrea">Eritrea</option>
                                  <option value="Estonia">Estonia</option>
                                  <option value="Ethiopia">Ethiopia</option>
                                  <option value="Falkland Islands">Falkland Islands</option>
                                  <option value="Faroe Islands">Faroe Islands</option>
                                  <option value="Fiji">Fiji</option>
                                  <option value="Finland">Finland</option>
                                  <option value="France">France</option>
                                  <option value="French Guiana">French Guiana</option>
                                  <option value="French Polynesia">French Polynesia</option>
                                  <option value="French Southern Ter">French Southern Ter</option>
                                  <option value="Gabon">Gabon</option>
                                  <option value="Gambia">Gambia</option>
                                  <option value="Georgia">Georgia</option>
                                  <option value="Germany">Germany</option>
                                  <option value="Ghana">Ghana</option>
                                  <option value="Gibraltar">Gibraltar</option>
                                  <option value="Great Britain">Great Britain</option>
                                  <option value="Greece">Greece</option>
                                  <option value="Greenland">Greenland</option>
                                  <option value="Grenada">Grenada</option>
                                  <option value="Guadeloupe">Guadeloupe</option>
                                  <option value="Guam">Guam</option>
                                  <option value="Guatemala">Guatemala</option>
                                  <option value="Guinea">Guinea</option>
                                  <option value="Guyana">Guyana</option>
                                  <option value="Haiti">Haiti</option>
                                  <option value="Hawaii">Hawaii</option>
                                  <option value="Honduras">Honduras</option>
                                  <option value="Hong Kong">Hong Kong</option>
                                  <option value="Hungary">Hungary</option>
                                  <option value="Iceland">Iceland</option>
                                  <option value="Indonesia">Indonesia</option>
                                  <option value="India">India</option>
                                  <option value="Iran">Iran</option>
                                  <option value="Iraq">Iraq</option>
                                  <option value="Ireland">Ireland</option>
                                  <option value="Isle of Man">Isle of Man</option>
                                  <option value="Israel">Israel</option>
                                  <option value="Italy">Italy</option>
                                  <option value="Jamaica">Jamaica</option>
                                  <option value="Japan">Japan</option>
                                  <option value="Jordan">Jordan</option>
                                  <option value="Kazakhstan">Kazakhstan</option>
                                  <option value="Kenya">Kenya</option>
                                  <option value="Kiribati">Kiribati</option>
                                  <option value="Korea North">Korea North</option>
                                  <option value="Korea Sout">Korea South</option>
                                  <option value="Kuwait">Kuwait</option>
                                  <option value="Kyrgyzstan">Kyrgyzstan</option>
                                  <option value="Laos">Laos</option>
                                  <option value="Latvia">Latvia</option>
                                  <option value="Lebanon">Lebanon</option>
                                  <option value="Lesotho">Lesotho</option>
                                  <option value="Liberia">Liberia</option>
                                  <option value="Libya">Libya</option>
                                  <option value="Liechtenstein">Liechtenstein</option>
                                  <option value="Lithuania">Lithuania</option>
                                  <option value="Luxembourg">Luxembourg</option>
                                  <option value="Macau">Macau</option>
                                  <option value="Macedonia">Macedonia</option>
                                  <option value="Madagascar">Madagascar</option>
                                  <option value="Malaysia">Malaysia</option>
                                  <option value="Malawi">Malawi</option>
                                  <option value="Maldives">Maldives</option>
                                  <option value="Mali">Mali</option>
                                  <option value="Malta">Malta</option>
                                  <option value="Marshall Islands">Marshall Islands</option>
                                  <option value="Martinique">Martinique</option>
                                  <option value="Mauritania">Mauritania</option>
                                  <option value="Mauritius">Mauritius</option>
                                  <option value="Mayotte">Mayotte</option>
                                  <option value="Mexico">Mexico</option>
                                  <option value="Midway Islands">Midway Islands</option>
                                  <option value="Moldova">Moldova</option>
                                  <option value="Monaco">Monaco</option>
                                  <option value="Mongolia">Mongolia</option>
                                  <option value="Montserrat">Montserrat</option>
                                  <option value="Morocco">Morocco</option>
                                  <option value="Mozambique">Mozambique</option>
                                  <option value="Myanmar">Myanmar</option>
                                  <option value="Nambia">Nambia</option>
                                  <option value="Nauru">Nauru</option>
                                  <option value="Nepal">Nepal</option>
                                  <option value="Netherland Antilles">Netherland Antilles</option>
                                  <option value="Netherlands">Netherlands (Holland, Europe)</option>
                                  <option value="Nevis">Nevis</option>
                                  <option value="New Caledonia">New Caledonia</option>
                                  <option value="New Zealand">New Zealand</option>
                                  <option value="Nicaragua">Nicaragua</option>
                                  <option value="Niger">Niger</option>
                                  <option value="Nigeria">Nigeria</option>
                                  <option value="Niue">Niue</option>
                                  <option value="Norfolk Island">Norfolk Island</option>
                                  <option value="Norway">Norway</option>
                                  <option value="Oman">Oman</option>
                                  <option value="Pakistan">Pakistan</option>
                                  <option value="Palau Island">Palau Island</option>
                                  <option value="Palestine">Palestine</option>
                                  <option value="Panama">Panama</option>
                                  <option value="Papua New Guinea">Papua New Guinea</option>
                                  <option value="Paraguay">Paraguay</option>
                                  <option value="Peru">Peru</option>
                                  <option value="Phillipines">Philippines</option>
                                  <option value="Pitcairn Island">Pitcairn Island</option>
                                  <option value="Poland">Poland</option>
                                  <option value="Portugal">Portugal</option>
                                  <option value="Puerto Rico">Puerto Rico</option>
                                  <option value="Qatar">Qatar</option>
                                  <option value="Republic of Montenegro">Republic of Montenegro</option>
                                  <option value="Republic of Serbia">Republic of Serbia</option>
                                  <option value="Reunion">Reunion</option>
                                  <option value="Romania">Romania</option>
                                  <option value="Russia">Russia</option>
                                  <option value="Rwanda">Rwanda</option>
                                  <option value="St Barthelemy">St Barthelemy</option>
                                  <option value="St Eustatius">St Eustatius</option>
                                  <option value="St Helena">St Helena</option>
                                  <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                                  <option value="St Lucia">St Lucia</option>
                                  <option value="St Maarten">St Maarten</option>
                                  <option value="St Pierre & Miquelon">St Pierre & Miquelon</option>
                                  <option value="St Vincent & Grenadines">St Vincent & Grenadines</option>
                                  <option value="Saipan">Saipan</option>
                                  <option value="Samoa">Samoa</option>
                                  <option value="Samoa American">Samoa American</option>
                                  <option value="San Marino">San Marino</option>
                                  <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                                  <option value="Saudi Arabia">Saudi Arabia</option>
                                  <option value="Senegal">Senegal</option>
                                  <option value="Seychelles">Seychelles</option>
                                  <option value="Sierra Leone">Sierra Leone</option>
                                  <option value="Singapore">Singapore</option>
                                  <option value="Slovakia">Slovakia</option>
                                  <option value="Slovenia">Slovenia</option>
                                  <option value="Solomon Islands">Solomon Islands</option>
                                  <option value="Somalia">Somalia</option>
                                  <option value="South Africa">South Africa</option>
                                  <option value="Spain">Spain</option>
                                  <option value="Sri Lanka">Sri Lanka</option>
                                  <option value="Sudan">Sudan</option>
                                  <option value="Suriname">Suriname</option>
                                  <option value="Swaziland">Swaziland</option>
                                  <option value="Sweden">Sweden</option>
                                  <option value="Switzerland">Switzerland</option>
                                  <option value="Syria">Syria</option>
                                  <option value="Tahiti">Tahiti</option>
                                  <option value="Taiwan">Taiwan</option>
                                  <option value="Tajikistan">Tajikistan</option>
                                  <option value="Tanzania">Tanzania</option>
                                  <option value="Thailand">Thailand</option>
                                  <option value="Togo">Togo</option>
                                  <option value="Tokelau">Tokelau</option>
                                  <option value="Tonga">Tonga</option>
                                  <option value="Trinidad & Tobago">Trinidad&Tobago</option>
                                  <option value="Tunisia">Tunisia</option>
                                  <option value="Turkey">Turkey</option>
                                  <option value="Turkmenistan">Turkmenistan</option>
                                  <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                                  <option value="Tuvalu">Tuvalu</option>
                                  <option value="Uganda">Uganda</option>
                                  <option value="United Kingdom">United Kingdom</option>
                                  <option value="Ukraine">Ukraine</option>
                                  <option value="United Arab Erimates">United Arab Emirates</option>
                                  <option value="United States of America">United States of America</option>
                                  <option value="Uraguay">Uruguay</option>
                                  <option value="Uzbekistan">Uzbekistan</option>
                                  <option value="Vanuatu">Vanuatu</option>
                                  <option value="Vatican City State">Vatican City State</option>
                                  <option value="Venezuela">Venezuela</option>
                                  <option value="Vietnam">Vietnam</option>
                                  <option value="Virgin Islands (Brit)">Virgin Islands (Brit)</option>
                                  <option value="Virgin Islands (USA)">Virgin Islands (USA)</option>
                                  <option value="Wake Island">Wake Island</option>
                                  <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                                  <option value="Yemen">Yemen</option>
                                  <option value="Zaire">Zaire</option>
                                  <option value="Zambia">Zambia</option>
                                  <option value="Zimbabwe">Zimbabwe</option>
                                </FormControl>
                                <span style={{ color: "red" }}>
                                  {this.state.fieldsErrors.s_country}
                                </span>
                              </div>
                              <div className="is-address-inner">
                                <FormControl
                                  type="text"
                                  placeholder="Enter state"
                                  onChange={e => this.handleOnChange("s_state", e)}
                                ></FormControl>
                                <span style={{ color: "red" }}>
                                  {this.state.fieldsErrors.s_state}
                                </span>
                              </div>
                              <div className="is-address-inner">
                                <FormControl
                                  type="text"
                                  placeholder="Enter city"
                                  onChange={e => this.handleOnChange("s_city", e)}
                                ></FormControl>
                                <span style={{ color: "red" }}>
                                  {this.state.fieldsErrors.s_city}
                                </span>
                              </div>
                            </div>
                          </FormGroup>
                        </div>
                        <FormGroup>
                          <FormLabel>Phone Number with Country code *<span>{" "}(e.g. +1-1119996666)</span></FormLabel>
                          <FormControl
                            type="text"
                            placeholder="e.g. +1-1119996666"
                            onChange={e => this.handleOnChange("s_mob", e)}
                          ></FormControl>
                          <span style={{ color: "red" }}>
                            {this.state.fieldsErrors.s_mob}
                          </span>
                        </FormGroup>
                      </div>
                    </form>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={() => this.handleRegSupplier()}>Save</Button>
                  <Button onClick={this.handleClose} variant="danger">
                    Close
                  </Button>
                </Modal.Footer>
              </>
            )}
          {this.state.submit === true && this.state.formValid === true && (
            <>
              <Modal.Body>
                <div>
                  Thank you for your registration. We will contact you soon.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button onClick={this.handleClose} variant="danger">
                  Close
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>
      </div >
    );
  }
}

const mapStateToProps = state => {
  return {
    env: state.AllReducer.env.data,
    load_env: state.AllReducer.env.load_env,
    get_categories: state.AllReducer.get_categories.data,
    load_cat: state.AllReducer.get_categories.load_cat,

  };
};
const mapDispatchToProps = dispatch => ({
  action: {
    AllAction: bindActionCreators(AllAction, dispatch)
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(Supplier);