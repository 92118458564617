import React, { Component } from 'react';

class PrivacyPolicy extends Component {
    render() {
        return (
            <div class="privacy-policy-container" style={{
                width: "90%", maxWidth: "1170px", margin: "0 auto", background: "#fff", boxShadow: "0 6px 60px rgba(0, 0, 0, .2)", padding: "20px",
                borderTop: "5px solid #222",     lineHeight: "25px",     textAlign: "left"
            }}>
                <h1 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }}>Privacy Policy</h1>
                <p>Winning prodcuts App is owned And operated By AppMixo™ Lab LLP, which provides the SERVICE.</p>
                <p>This page is used to inform application visitors regarding our policies with the collection, use, and disclosure of Personal Information if anyone decided to use our Service, the Winning prodcuts App.</p>
                <p>If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.</p>
                <p>We understand that you value your privacy, and we want to help make your experience with our Services satisfying and safe. This Privacy Policy may be updated from time to time. For example, we may update this Privacy Policy to reflect changes to our information collection and use practices.</p>
                <hr style={{ margin: "40px 0" }} />
                < h2 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }}> Information Collection and Use</h2>
                <p>For a better experience while using our Service, collection and use of information in this Policy, we refer to our collection and use of “Personal Information.” “Personal Information,” as used in this Policy, is personally identifiable information, which is information that directly identifies an individual, such as first and last name, mailing address, email address, IP addresses, demographics, passwords or other online contact information, or telephone number. We collect Personal Information, as well as non-personally identifiable information.  The information that we collect will be used to contact or identify you.</p>
                <hr style={{
                    margin: "40px 0"
                }} />
                < h2 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }}> Log Data</h2>
                <p>We want to inform you that whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer’s Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.</p>
                <hr style={{
                    margin: "40px 0"
                }} />
                < h2 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }}> Service Providers</h2>
                <p>We may employ third-party companies and individuals due to the following reasons:</p>
                <ul style={{listStyle:"disc", paddingLeft: "21px"}}>
                    <li>To facilitate our Service;</li>
                    <li>To provide the Service on our behalf;</li>
                    <li>To perform Service-related services; or</li>
                    <li>To assist us in analyzing how our Service is used.</li>
                </ul>
                <p>We want to inform our Service users that these third parties have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>
                <hr style={{
                    margin: "40px 0"
                }} />
                < h2 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }}> Security</h2>
                <p>We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
                <hr style={{
                    margin: "40px 0"
                }} />
                < h2 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }}> Links to Other Sites</h2>
                <p>Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                <p>Children’s Privacy</p>
                <p>Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.</p>
                <hr style={{
                    margin: "40px 0"
                }} />
                < h2 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }}> Changes to This Privacy Policy</h2>
                <p>We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>
                <hr style={{
                    margin: "40px 0"
                }} />
                < h2 style={{ marginTop: "0", fontWeight: "700", fontSize: "28px", letterSpacing: ".5px", margin:"0 0 10px 0" }} > Contact Us</h2>
                <p >If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.</p>
            </div>
        );
    }
}

export default PrivacyPolicy;