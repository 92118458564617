import React, { Component } from 'react';
import { Button, Modal, FormGroup, FormLabel, FormControl } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import * as AllAction from '../Action/AllAction';

class ChangePwd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            current_pwd: "",
            new_pwd: "",
            confirm_pwd: "",
            fieldsErrors: {
                current_pwd: "",
                new_pwd: "",
                confirm_pwd: ""
            }
        }
    }
    componentDidUpdate(prevProps) {
        if (this.props.show !== prevProps.show) {
            if (this.props.show === false) {
                this.setState({
                    current_pwd: "",
                    new_pwd: "",
                    confirm_pwd: "",
                    fieldsErrors: {
                        current_pwd: "",
                        new_pwd: "",
                        confirm_pwd: ""
                    }
                })
            }
        }
    }
    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value,
        }, () => {
            this.setState({
                fieldsErrors: { [key]: "" },
            })
        })
    };
    submitChangePwd = () => {
        const { current_pwd, new_pwd, confirm_pwd } = this.state;
        if (current_pwd === "") {
            this.setState({
                fieldsErrors: { current_pwd: "Enter your current passsword!" }
            })
        }
        else if (new_pwd === "") {
            this.setState({
                fieldsErrors: { new_pwd: "Enter new passsword!" }
            })
        }
        else if (new_pwd.length < 8) {
            this.setState({
                fieldsErrors: { new_pwd: "Password length is too short provide at least 8 characters!" }
            })
        }
        else if (confirm_pwd === "") {
            this.setState({
                fieldsErrors: { confirm_pwd: "Enter new password again!" }
            })
        }
        else if (confirm_pwd !== new_pwd) {
            this.setState({
                fieldsErrors: { confirm_pwd: "Password did not match. Please try again..!" }
            })
        }
        else {
            const accessToken = localStorage.getItem("token_wp");
            const shop = localStorage.getItem("shop_wp");
            var data = {
                accessToken, shop, old_password: current_pwd, new_password: new_pwd, confirm_password: confirm_pwd
            }
            this.props.action.AllAction.changePasswordAction(data);
            this.props.onHide();
        }       
    }
    render() {
        return (
            <div>
                <Modal show={this.props.show} onHide={this.props.onHide} className="change-pwd-modal">
                    <Modal.Header closeButton>
                        <Modal.Title>Change Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormGroup >
                            <FormLabel>Current password</FormLabel>
                            <FormControl type="password" placeholder="Enter current password"
                                onChange={(e) => this.handleOnChange("current_pwd", e)} />
                            <span className="err-val-msg">{this.state.fieldsErrors.current_pwd}</span>
                        </FormGroup>
                        <FormGroup >
                            <FormLabel>New password</FormLabel>
                            <FormControl type="password" placeholder="Enter new password"
                                onChange={(e) => this.handleOnChange("new_pwd", e)} />
                            <span className="err-val-msg">{this.state.fieldsErrors.new_pwd}</span>
                        </FormGroup>
                        <FormGroup >
                            <FormLabel>Confirm password</FormLabel>
                            <FormControl type="password" placeholder="Enter new password"
                                onChange={(e) => this.handleOnChange("confirm_pwd", e)} />
                            <span className="err-val-msg">{this.state.fieldsErrors.confirm_pwd}</span>
                        </FormGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={() => this.submitChangePwd()}>Submit</Button>
                        <Button variant="danger" onClick={this.props.onHide}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

    const mapDispatchToProps = (dispatch) => ({
        action: {
            AllAction: bindActionCreators(AllAction, dispatch),
        }
    })
    export default connect(null,mapDispatchToProps)(ChangePwd);