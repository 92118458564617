import { INSTALL_IN_STORE, MATCH_STORE, ON_REFRESH, FAILED } from "../Action/installAction"
const INITIAL_STATE = {
    store_data: [],
    store_match: [],
    on_refresh_data: [],
    load_token: false
}
const handleGetInstall = (state = INITIAL_STATE, action) => {

    switch (action.type) {
        case INSTALL_IN_STORE:
            {
                return Object.assign({}, state, { store_data: action.data, load_token: true })
            }

        case ON_REFRESH:
            {
                return Object.assign({}, state, { on_refresh_data: action.data })
            }
        case MATCH_STORE:
            {
                const localshop = localStorage.getItem("shop_wp");
                const localtoken = localStorage.getItem("token_wp");
                if (state.store_match.token === localtoken && state.store_match.shop === localshop) {
                    return Object.assign({}, state, { store_match: { match: true, shop: localshop, token: localtoken } })
                }
                else {
                    return Object.assign({}, state, { store_match: { match: false, shop: state.store_match.token, token: state.store_match.token } })
                }
            }
        case FAILED:
            {
                return Object.assign({}, state, { error_msg: action.data.error_msg });
            }

        default:
            return state;
    }
}
export default handleGetInstall