import React, { Component } from 'react';
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from 'react-draft-wysiwyg';
import { Modal, Button, FormLabel, FormControl, FormGroup } from "react-bootstrap";
import { Carousel } from 'react-responsive-carousel';
import { stateToHTML } from "draft-js-export-html";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { FaSpinner } from "react-icons/fa";
import { MdDone } from "react-icons/md";
import * as AllAction from '../Action/AllAction';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class AddToShopify extends Component {
    constructor(props) {
        super(props);
        this.state = {
            product_description: EditorState.createEmpty(),
            product_name: "",
            product_main_image: "",
            product_images: [],
            compare_price: "",
            quantity: 1,
            edit_price: false,
            product_sale_price: "",
            formValid: false,
            fieldsErrors: { compare_price: '', quantity: "", product_name: "", product_sale_price: "" },
            add_clicked: false,
            res_add_to_shopify: {},
            is_added: false,
            money_format: "$",
            loaded: false
        };
    }
    componentWillMount() {
        const { _id, product_name, product_main_image, product_description, product_images, base_product_sale_price, product_sale_price } = this.props.shopify_p_data
        const { money_format, rateToUSD } = this.props.shopify_MF
        var saleprice = base_product_sale_price.replace(/[^\d.]/g, '');
        var sp = rateToUSD ? (saleprice * rateToUSD) : saleprice
        function titleCase(str) {
            var splitStr = str.toLowerCase().split(' ');
            for (var i = 0; i < splitStr.length; i++) {
                // You do not need to check if i is larger than splitStr length, as your for does that for you
                // Assign it back to the array
                splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
            }
            // Directly return the joined string
            return splitStr.join(' ');
        }

        let tempName = product_name && titleCase(product_name);
        product_description && this.setState({
            product_description: EditorState.createWithContent(convertFromRaw(JSON.parse(product_description)))
        })
        var f_main_image = product_main_image !== "" && (typeof product_main_image === "string" && product_main_image.includes("http") ? product_main_image :
            this.props.CDN_URL + product_main_image);
        const other_images = product_images && product_images.length !== 0 && product_images.map((product_images, i) => {
            return product_images.includes("http") ? product_images : this.props.CDN_URL + product_images
        })
        this.setState({
            _id, product_name: tempName,
            product_main_image: f_main_image,
            product_images: other_images,
            p_sp: base_product_sale_price,
            product_sale_price: parseFloat(sp).toFixed(2),
            money_format
        })
    };
    componentDidUpdate(prevProps) {
        if (this.props.res_add_to_shopify !== (this.state.res_add_to_shopify)) {
            this.setState({
                res_add_to_shopify: this.props.res_add_to_shopify
            }, () => {
                if (prevProps.res_add_to_shopify.id !== this.state.res_add_to_shopify.id) {
                    if (this.state.res_add_to_shopify.id) {
                        this.setState({
                            is_added: true,
                            loaded: true
                        })
                    }
                    else if (this.state.res_add_to_shopify.message) {
                        this.setState({
                            is_added: false,
                            err_msg: this.state.res_add_to_shopify.message,
                            loaded: true
                        })
                    }
                    else {
                        this.setState({
                            is_added: false,
                            loaded: true
                        })
                    }
                }
                else {
                    this.setState({
                        loaded: false
                    })
                }
            })
        }
    }

    handleLinkClick = (link) => {
        window.open(link, "_blank")
    };

    onEditorStateChange = (product_description) => {
        this.setState({
            product_description,
        });
    };

    onSave = val => {
        var product_name = val
        if (val === "") {
            product_name = this.props.shopify_p_data && this.props.shopify_p_data.product_name
        }
        this.setState({
            product_name: product_name,
        })
    };

    onSave_sale_price = val => {
        var product_saleprice = val
        if (val === "") {
            var saleprice = this.props.shopify_p_data && this.props.shopify_p_data.product_sale_price.replace(/[^\d.]/g, '');
            product_saleprice = parseFloat(saleprice)
        }
        this.setState({
            product_sale_price: product_saleprice,
            edit_price: true
        })
    };

    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value,
        }, () => {
            this.setState({
                fieldsErrors: { [key]: "" },
                formValid: true
            })
        })
    };

    addToShopifyEvent = () => {
        var product_name1 = this.state.product_name;
        var product_saleprice1 = this.state.product_sale_price
        if (product_name1 === "") {
            this.setState({
                product_name: this.props.shopify_p_data && this.props.shopify_p_data.product_name
            })
            product_name1 = this.props.shopify_p_data && this.props.shopify_p_data.product_name
        }
        if (product_saleprice1 === "" || product_saleprice1 === 0) {
            var saleprice = this.props.shopify_p_data && this.props.shopify_p_data.product_sale_price.replace(/[^\d.]/g, '');
            var product_saleprice = parseFloat(saleprice)
            this.setState({
                product_sale_price: product_saleprice
            })
            product_saleprice1 = product_saleprice
        }
        this.setState({
            add_clicked: true
        })

        const { _id, product_main_image, product_description, product_images, compare_price, quantity } = this.state;
        var editorContentHtml = stateToHTML(product_description.getCurrentContent())
        var compare_price_d = compare_price.replace(/[^\d.]/g, '');
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        var order_no_f = this.props.shopify_p_data && this.props.shopify_p_data.order_no
        var pshop_f = this.props.shopify_p_data && this.props.shopify_p_data.shop
        const data = {
            shop, token: accessToken, product_id: _id, product_name: product_name1, product_main_image, product_other_images: product_images,
            product_description: editorContentHtml, product_price: product_saleprice1,
            compare_price: compare_price_d, quantity, order_no: order_no_f, pshop: pshop_f
        }
        if (data.product_name === "") {
            this.setState({
                formValid: false,
                add_clicked: false,
                fieldsErrors: {
                    product_name: "Product name must be not empty."
                }
            })
        }
        if (data.compare_price && data.compare_price.toString().length > 0 && data.compare_price <= data.product_price) {
            this.setState({
                formValid: false,
                add_clicked: false,
                fieldsErrors: {
                    compare_price: "Compare price must be greater than product price."
                }
            })
        }
        else if (data.product_price === "" || data.product_price === 0) {
            this.setState({
                formValid: false,
                add_clicked: false,
                fieldsErrors: {
                    product_sale_price: "Please enter product price and value must be greater than 0."
                }
            })
        }
        else if (data.quantity < 1) {
            this.setState({
                formValid: false,
                add_clicked: false,
                fieldsErrors: {
                    quantity: "Quantity must be greater than 1."
                }
            })
        }
        else {
            this.setState({
                formValid: true
            }, () => {
                this.props.action.AllAction.AddToShopifyAction(data)
            })
        }
    };

    handleOnClickItem = (val) => {
        var image = this.state.product_images && this.state.product_images.length !== 0 && this.state.product_images[val]
        this.handleLinkClick(image);
    };
    redirectToShopify = (id) => {
        var shop = localStorage.getItem("shop_wp")
        window.open("http://" + shop + "/admin/products/" + id, "_blank")
    }

    render() {
        const product_images = this.state.product_images && this.state.product_images.length !== 0 && this.state.product_images.map((image, i) => {
            return <div key={i} className="image-ats"><img src={typeof image === 'string' && (image.replace("images", "thumb_images"))} width="100px" height="100px" alt="NoImage" /></div>
        })
        return (
            <div>
                <Modal {...this.props} dialogClassName="add-to-shopify-content-modal"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered size="lg" className="add-to-shopify-modal">
                    <Modal.Header closeButton></Modal.Header>
                    <Modal.Body>
                        <div className="shopify-modal-container">
                            <div className="grid product-details">
                                <div className="product-images-container">
                                    <div className="product-gallery">
                                        <div className="product-image">
                                            <img className="active"
                                                src={typeof (this.state.product_main_image) === 'string' && (this.state.product_main_image
                                                    // .replace("images", "thumb_images")
                                                )}
                                                alt="NoImage" onClick={() => this.handleLinkClick(this.state.product_main_image)} />
                                        </div>
                                        <ul className="product-image-list">
                                            <Carousel centerMode centerSlidePercentage={50} emulateTouch onClickItem={this.handleOnClickItem} >
                                                {product_images}
                                            </Carousel>
                                        </ul>
                                    </div>
                                </div>
                                <div className="product-details-container">
                                    <form>
                                        {/* <div className="ats-title">
                                            <FormGroup>
                                                <div className="shopify-fields">
                                                    <FormControl type="text" defaultValue={this.state.product_name} onChange={(e) => this.handleOnChange("product_name", e)} ></FormControl>
                                                    <span className="validation-msg" style={{ color: "red" }}>{this.state.fieldsErrors.product_name}</span>
                                                </div>
                                            </FormGroup> */}
                                        {/* <EdiText
                                                type='text'
                                                value={this.state.product_name}
                                                onSave={this.onSave}
                                                submitOnEnter={true}
                                                validationMessage="Please enter product title."
                                                validation={val => val.length > 0}
                                            /> */}
                                        {/* </div> */}
                                        <div className="shopify-field-p-title">
                                            <FormGroup>
                                                <div className="shopify-fields-holder">
                                                    <FormControl type="text" defaultValue={this.state.product_name} value={this.state.product_name}
                                                        onChange={(e) => this.handleOnChange("product_name", e)} ></FormControl>
                                                    <span className="validation-msg" style={{ color: "red" }}>{this.state.fieldsErrors.product_name}</span>
                                                </div>
                                            </FormGroup>
                                        </div>
                                        <h4 className="pro-sub-title">Main feature</h4>
                                        <div>
                                            <Editor
                                                editorState={this.state.product_description}
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                readOnly={false}
                                                onEditorStateChange={this.onEditorStateChange}
                                                link={false}
                                            />
                                        </div>
                                        <div className="ats-filds-container">
                                            <div className="shopify-fields">
                                                <FormGroup>
                                                    <FormLabel >Compare at price:</FormLabel >
                                                    <div className="shopify-fields-holder">
                                                        <div>{this.state.money_format}</div>
                                                        <FormControl type="number" value={this.state.compare_price} onChange={(e) => this.handleOnChange("compare_price", e)} ></FormControl>
                                                        <span className="validation-msg" style={{ color: "red" }}>{this.state.fieldsErrors.compare_price}</span>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="shopify-fields">
                                                <FormGroup>
                                                    <FormLabel >Price:</FormLabel >
                                                    <div className="shopify-fields-holder">
                                                        <div className={(this.state.money_format !== "$" || this.state.money_format !== "") && "ats-sign"}>{this.state.money_format}</div>
                                                        {/* <EdiText
                                                            type='number'
                                                            value={this.state.product_sale_price}
                                                            onSave={this.onSave_sale_price}
                                                            step="0.1"
                                                            validationMessage="Please enter product price and value must be greater than 0."
                                                            validation={val => val.length > 0 && val > 0}
                                                            submitOnEnter={true}
                                                        /> */}
                                                        {/* <div className="shopify-fields">
                                                            <FormControl type="number" defaultValue={this.state.product_sale_price} onChange={(e) => this.handleOnChange("product_sale_price", e)} ></FormControl>
                                                            <span className="validation-msg" style={{ color: "red" }}>{this.state.fieldsErrors.product_sale_price}</span>
                                                        </div> */}
                                                        <span>
                                                            <FormControl type="number" defaultValue={this.state.product_sale_price}
                                                                onChange={(e) => this.handleOnChange("product_sale_price", e)}
                                                                value={this.state.product_sale_price} ></FormControl>
                                                            {this.state.money_format !== "$" && <div className="ats-bp">{(this.state.p_sp)}</div>}
                                                        </span>
                                                        <span className="validation-msg" style={{ color: "red" }}>{this.state.fieldsErrors.product_sale_price}</span>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                            <div className="shopify-fields">
                                                <FormGroup>
                                                    <FormLabel>Quantity:</FormLabel>
                                                    <div className="shopify-fields-holder">
                                                        <FormControl type="number" min="1" value={this.state.quantity} onChange={(e) => this.handleOnChange("quantity", e)} ></FormControl>
                                                        <span className="validation-msg" style={{ color: "red" }}>{this.state.fieldsErrors.quantity}</span>
                                                    </div>
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div></div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.add_clicked && this.state.formValid && !this.state.loaded && !this.state.is_added &&
                            <span className="ats-icon"><FaSpinner className="icon-spin" color="#6c757d" height="25px" width="25px" /></span>}
                        {this.state.add_clicked && this.state.formValid && this.state.loaded && this.state.is_added
                            && (this.state.res_add_to_shopify.id === this.props.res_add_to_shopify.id) &&
                            <><span className="view-product" onClick={() => this.redirectToShopify(this.state.res_add_to_shopify.id)}>View product</span>
                                <span className="ats-icon"><MdDone color="#228b22" height="25px" width="25px" /></span></>}
                        {this.state.add_clicked && this.state.formValid && !this.state.loaded && this.state.is_added === false && (this.state.res_add_to_shopify.id === this.props.res_add_to_shopify.id) && <span>{this.state.err_msg === "" ? "Error while add product in shopify!" : this.state.err_msg}</span>}
                        <Button variant="primary" onClick={() => this.addToShopifyEvent()} disabled={this.state.add_clicked && !this.state.formValid}>
                            Add To Shopify
                     </Button>
                        <Button variant="danger" className="ats-close" onClick={() => this.props.onHide()}>
                            Close
                     </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        all_product_data: state.AllReducer.all_product_data.data,
        total_record: state.AllReducer.all_product_data.total_records,
        store_data: state.InstallReducer.store_data,
        get_categories: state.AllReducer.get_categories.data,
        res_add_to_shopify: state.AllReducer.res_add_to_shopify,
        env: state.AllReducer.env.data
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch),
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(AddToShopify);