import React, { Component } from 'react';
import NavbarHeader from "../NavbarHeader";
import Header from "../Header";
import "./Messages.scss";
import firebase from "../../firebase.js";
import { Button } from "react-bootstrap";
import { BsDot } from "react-icons/bs";
import shave from 'shave';
import moment from 'moment';
import * as AllAction from '../../Action/AllAction';
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";

class Messages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentUserDetails: [],
            roomList: null,
            cloneRoomList: null,
            activeChatRoom: null,
            currentMessages: [],
            messageReceiver: null,
            activeChatRoomName: null,
            firebaseCredentials: null,
            isPropsExecuted: false,
            isMenuOpen: false
        }
    }

    async componentDidMount() {
        const currentUser = localStorage.getItem("shop_wp");
        if (currentUser) {
            await this.getCurrentUser(currentUser);
        }

        // this.getUsers();
        // this.createUser();
        // this.createRoom();
        // this.createChat();

    }

    componentWillUnmount() {
        this.setUserOnlineOffline(this.state.currentUserDetails.key, false);
    }

    async getCurrentUser(currentUser) {
        // console.log(currentUser)
        const ref = firebase.database().ref("users");
        let that = this;
        // console.log(ref);
        ref.orderByChild("email").equalTo(currentUser).once("value", async function (snapshot) {
            if (snapshot.val()) {
                // console.log('current user exist')
                snapshot.forEach(async function (data) {
                    const result = data.val();
                    result.key = data.key;

                    const { userData } = that.props.location;
                    if (userData && userData.username) {
                        //update username in firebase
                        firebase.database().ref('users/' + result.key).update({ username: userData.username });
                    }

                    const { username } = that.props.location;
                    if (username) {
                        firebase.database().ref('users/' + result.key).update({ username: username });
                    }

                    that.setState({
                        currentUserDetails: result
                    },
                        async () => {
                            that.setUserOnlineOffline(that.state.currentUserDetails.key, true);
                            const { userData } = that.props.location;
                            if (userData) {
                                await that.checkOtherUser(userData);
                            }
                            else {
                                that.getUserChatRooms();
                            }
                        });

                });
            }
            else {
                // console.log("user not found")
                let result = {
                    email: currentUser,
                    firstName: localStorage.getItem("user_firstname"),
                    lastName: localStorage.getItem("user_lastname"),
                    isActive: true,
                    isOnline: true,
                    picture: localStorage.getItem("user_image") ? localStorage.getItem("user_image") : null,
                    userType: localStorage.getItem("user_type") ? localStorage.getItem("user_type") : 'shop_user'
                }

                const { userData } = that.props.location;
                if (userData) {
                    result.username = userData.username;
                    // result.userType = checkData.userType;
                }

                const { username } = that.props.location;
                if (username) {
                    result.username = username;
                }

                let res = await that.createUser(result)
                if (res) {
                    let currentUserDetails = result;
                    currentUserDetails.key = res.key;
                    that.setState({
                        currentUserDetails: currentUserDetails
                    },
                        async () => {
                            that.setUserOnlineOffline(that.state.currentUserDetails.key, true);
                            const { userData } = that.props.location;
                            if (userData) {
                                await that.checkOtherUser(userData);
                            }
                            else {
                                that.getUserChatRooms();
                            }
                        });
                }

            }
        });
    }

    async checkOtherUser(otherUser) {
        // console.log('checking other user')
        const ref = firebase.database().ref("users");
        let that = this;
        ref.orderByChild("email").equalTo(otherUser.shop).once("value", async function (snapshot) {
            if (snapshot.val()) {
                //check if room exist or not
                // console.log('user exist');
                if (that.state.currentUserDetails.key) {
                    let users = [];
                    let otherUserID = null;
                    snapshot.forEach(function (data) {
                        otherUserID = data.key;
                        // console.log(data.val())
                        if (!data.val().username) {
                            //update username
                            firebase.database().ref('users/' + otherUserID).update({ username: otherUser.store_link });
                        }
                    });
                    let currentUserID = that.state.currentUserDetails.key;
                    users.push(currentUserID);
                    users.push(otherUserID);
                    try {
                        let isRoomExist = await that.isRoomExist(users);
                        //if room exist
                        if (isRoomExist !== undefined) {
                            if (isRoomExist === true) {
                                // console.log('room exist')
                                that.getUserChatRooms();
                            }
                            else {
                                // console.log('room not exist')
                                that.createRoom(users);
                                that.getUserChatRooms();
                            }
                        }
                        else {
                            that.getUserChatRooms();
                        }

                    } catch (error) {
                        console.log(error);
                    }

                }
                else {
                    console.log('current user details not found')
                }
            }
            else {
                // console.log("other user not found")
                let result = {
                    email: otherUser.shop,
                    firstName: otherUser.first_name ? otherUser.first_name : null,
                    lastName: otherUser.last_name ? otherUser.last_name : null,
                    isActive: true,
                    isOnline: false,
                    picture: otherUser.image ? otherUser.image : null,
                    userType: otherUser.userType ? otherUser.userType : null,
                    username: otherUser.store_link
                }

                let res = await that.createUser(result)
                if (res) {
                    if (that.state.currentUserDetails.key) {
                        let otherUserID = res.key;
                        let currentUserID = that.state.currentUserDetails.key;
                        that.createRoom([otherUserID, currentUserID]);
                        that.getUserChatRooms();
                    }
                    else {
                        console.log('current user details not found')
                    }

                }

            }
        });
    }

    getUsers() {
        const users = firebase.database().ref("users");

        users.on('value', (snapshot) => {
            let userList = snapshot.val();
            // console.log(userList)
        })
    }

    getUserById(element, callback) {
        const currentUser = this.state.currentUserDetails;
        let userid = element.users.filter((data) => data !== currentUser.key);

        const users = firebase.database().ref("users/" + userid);

        users.on('value', (snapshot) => {
            if (snapshot.val()) {
                let user = snapshot.val();
                user.key = snapshot.key;
                user.roomId = element.key;
                user.lastMessage = element.lastMessage ? element.lastMessage : null;
                user.lastMessageTimeStamp = element.lastMessageTimeStamp ? element.lastMessageTimeStamp : null;
                user.createdAt = element.createdAt ? element.createdAt : null;
                callback(user)
                // return user;
            }
        })
    }

    async createUser(data) {
        return firebase.database().ref('users').push(data);
    }

    createRoom(users) {
        firebase.database().ref('rooms').push({
            createdAt: new Date().getTime(),
            users: users
        });
    }

    createChat() {
        firebase.database().ref('chat/-M1nxvVze8c8ZtpT1M8R').push({
            isRead: true,
            message: "no I'm working as a software engineer!!",
            meassageType: "text",
            receiverID: "-M1nPjzU4XOLb5ZSIpeA",
            senderID: "-M1iLuYDfFUudDe4B-56",
            timestamp: new Date().getTime()
        });
    }

    removeByAttr(arr, attr, value) {
        var i = arr.length;
        while (i--) {
            if (arr[i]
                && arr[i].hasOwnProperty(attr)
                && (arguments.length > 2 && arr[i][attr] === value)) {

                arr.splice(i, 1);

            }
        }
        return arr;
    }

    findWithAttr(array, attr, value) {
        for (var i = 0; i < array.length; i += 1) {
            if (array[i][attr] === value) {
                return i;
            }
        }
        return -1;
    }

    getUserChatRooms() {
        const rooms = firebase.database().ref("rooms");
        const currentUser = this.state.currentUserDetails;
        let that = this;

        rooms
            .on('value', function (snapshot) {
                let otherUser = [];
                snapshot.forEach(function (data) {
                    let result = data.val();
                    result.key = data.key;
                    // console.log(result)

                    otherUser.push(result);
                });

                let filter = currentUser.key;
                let filteredResult = otherUser.filter((item) => {
                    return (item.users.indexOf(filter) >= 0);
                });

                let roomList = [];
                that.setState({
                    roomList: null,
                    cloneRoomList: null
                })

                filteredResult.forEach((element) => {

                    that.getUserById(element, function (room) {

                        if (room) {
                            let filteredResult1 = roomList.filter((item) => {
                                return (item.key === room.key);
                            });
                            if (filteredResult1 && filteredResult1.length) {
                                filteredResult1.forEach(element => {
                                    roomList = that.removeByAttr(roomList, 'username', element.username);
                                });
                            }

                            that.countUnReadMessage(room.roomId, function (unReadMessages) {
                                let filteredResult2 = roomList.filter((item) => {
                                    return (item.key === room.key);
                                });
                                if (filteredResult2 && filteredResult2.length) {
                                    filteredResult2.forEach(element => {
                                        roomList = that.removeByAttr(roomList, 'username', element.username);
                                    });
                                }

                                room.unReadMessages = unReadMessages;

                                roomList.push(room)
                                roomList.sort(function (x, y) {
                                    if (x.lastMessageTimeStamp && y.lastMessageTimeStamp) {
                                        return y.lastMessageTimeStamp - x.lastMessageTimeStamp;
                                    }
                                    else if (x.lastMessageTimeStamp && (!y.lastMessageTimeStamp || y.lastMessageTimeStamp === null)) {
                                        return y.createdAt - x.lastMessageTimeStamp;
                                    }
                                    else if ((!x.lastMessageTimeStamp || x.lastMessageTimeStamp === null) && y.lastMessageTimeStamp) {
                                        return y.lastMessageTimeStamp - x.createdAt;
                                    }
                                    else if (x.lastMessageTimeStamp === null && y.lastMessageTimeStamp === null) {
                                        return y.createdAt - x.createdAt;
                                    }
                                })
                                // console.log(roomList)
                                that.setState({
                                    roomList: roomList,
                                    cloneRoomList: roomList
                                }, () => {

                                    const { userData } = that.props.location;
                                    if (userData && that.state.isPropsExecuted === false) {
                                        let filteredResult = that.state.roomList.filter((item) => {
                                            return (item.email === userData.shop);
                                        });

                                        if (filteredResult.length > 0) {
                                            that.handleActiveChat(filteredResult[0]);
                                            that.setState({ isPropsExecuted: true })
                                        }
                                    }
                                })

                                shave('.lst-message', 30);
                            })

                        }
                    })
                });

            })

    }

    async isRoomExist(users) {
        let promise = new Promise((resolve, reject) => {
            const rooms = firebase.database().ref("rooms");
            let isExist = null;
            rooms
                .on('value', function (snapshot) {
                    let otherRooms = [];
                    snapshot.forEach(function (data) {
                        let result = data.val();
                        result.key = data.key;
                        otherRooms.push(result);
                    });

                    const filter1 = users[0]
                    let filteredResult1 = otherRooms.filter((item) => {
                        return (item.users.indexOf(filter1) >= 0);
                    });

                    const filter2 = users[1]
                    let filteredResult2 = filteredResult1.filter((item) => {
                        return (item.users.indexOf(filter2) >= 0);
                    });

                    filteredResult2.length > 0 ? isExist = true : isExist = false;
                    resolve(isExist)
                })
        });

        let result = await promise;
        return result
    }

    handleActiveChat = (element) => {
        if (element.unReadMessages && element.unReadMessages > 0) {
            this.setAllReadMessages(element.roomId)
        }
        this.setState({
            activeChatRoom: element.roomId,
            messageReceiver: element.key,
            activeChatRoomName: element.username ? element.username : element.email
        },
            () => {
                this.getRoomMessages(this.state.activeChatRoom)
            })
    }

    countUnReadMessage(roomID, callback) {

        const message = firebase.database().ref("chat/" + roomID);
        const currentUser = this.state.currentUserDetails.key;
        message.orderByChild("isRead").equalTo(false).on('value', (snapshot) => {
            let messages = [];
            snapshot.forEach(function (data) {
                let result = data.val();
                result.key = data.key;
                if (result.senderID !== currentUser) {
                    messages.push(result);
                }
            });

            callback(messages.length)
        })
    }

    setAllReadMessages = (roomID) => {
        let that = this;
        const message = firebase.database().ref("chat/" + roomID);
        const currentUser = this.state.currentUserDetails.key;
        message.orderByChild("isRead").equalTo(false).once('value', (snapshot) => {
            let messages = [];
            snapshot.forEach(function (data) {
                let result = data.val();
                result.key = data.key;
                if (result.senderID != currentUser) {
                    messages.push(result);
                }
            });

            if (messages.length > 0) {
                messages.forEach(element => {
                    that.readMessage(element.key, roomID);
                });
            }
        })
    }

    readMessage = (key, roomID) => {
        firebase.database().ref('chat/' + roomID + '/' + key).update({ isRead: true });
        this.decreaseUserUnreadMessages(this.state.currentUserDetails.key);
    }

    increaseUserUnreadMessages = (key) => {
        firebase.database().ref('users/' + key).once("value", async function (snapshot) {
            if (snapshot.val()) {
                let totalUnRead = snapshot.val().overAllUnreadMessage ? snapshot.val().overAllUnreadMessage : 1;
                firebase.database().ref('users/' + key).update({ overAllUnreadMessage: totalUnRead + 1 });
            }
        });
    }

    decreaseUserUnreadMessages = (key) => {
        firebase.database().ref('users/' + key).once("value", async function (snapshot) {
            if (snapshot.val() && snapshot.val().overAllUnreadMessage) {
                let totalUnRead = snapshot.val().overAllUnreadMessage;
                firebase.database().ref('users/' + key).update({ overAllUnreadMessage: totalUnRead - 1 });
            }
        });
    }

    setUserOnlineOffline = (key, status) => {
        firebase.database().ref('users/' + key).update({ isOnline: status });
    }

    getRoomMessages(roomId) {
        const message = firebase.database().ref("chat/" + roomId);

        message.on('value', (snapshot) => {
            if (this.state.activeChatRoom === roomId) {
                let messages = [];
                snapshot.forEach(function (data) {
                    let result = data.val();
                    result.key = data.key;
                    messages.push(result);
                });

                this.setState({
                    currentMessages: messages
                })

                this.handleScrollDown();
            }

        })
    }

    handleOnChange = (key, e) => {
        this.setState({
            [key]: e.target.value,
        })
    };

    sendMessage = (e) => {
        e.preventDefault();

        if (this.state.message) {
            let timestamp = new Date().getTime();

            let result = {
                isRead: false,
                message: this.state.message,
                meassageType: "text",
                receiverID: this.state.messageReceiver,
                senderID: this.state.currentUserDetails.key,
                timestamp: timestamp
            }

            firebase.database().ref('chat/' + this.state.activeChatRoom).push(result);

            this.increaseUserUnreadMessages(this.state.messageReceiver);
            this.sendEmailIfReceiverOffline(result);

            let lstMessage = {
                lastMessage: this.state.message,
                lastMessageTimeStamp: timestamp
            }
            firebase.database().ref('rooms/' + this.state.activeChatRoom).update(lstMessage);

            this.setState({
                message: null
            },
                () => {
                    document.getElementById('sendmsg').value = ''
                })


        }
    }

    sendEmailIfReceiverOffline = (data) => {
        console.log('sendEmailIfReceiverOffline')
        let emailTimeout = null;
        let that = this;
        firebase.database().ref('users/' + data.receiverID).once("value", async function (snapshot) {
            if (snapshot.val()) {
                console.log(snapshot.val())
                let userData = snapshot.val();

                if (userData.isOnline === false) {
                    emailTimeout = setTimeout(() => {
                        console.log("send email")

                        let result = {
                            shop: localStorage.getItem("shop_wp"),
                            accessToken: localStorage.getItem("token_wp"),
                            toEmail: userData.email,
                            message: data.message,
                            senderEmail: localStorage.getItem("shop_wp")
                        }
                        console.log(result);
                        that.props.action.AllAction.sendOfflineUserEmail(result);
                    }, 60 * 10 * 1000);
                }
                else if (userData.isOnline === true) {

                    if (emailTimeout !== null) {
                        clearTimeout(emailTimeout);
                        console.log("clear timeout")
                    }
                }
            }
        });
    }

    handleScrollDown = () => {
        var elem = document.getElementById('messageGroup');
        if (elem) {
            elem.scrollTop = elem.scrollHeight;
        }
    }

    renderMessages = () => {

        if (this.state.currentMessages) {
            let i = 0;
            let messageCount = this.state.currentMessages.length;
            let tempMessages = [];

            while (i < messageCount) {
                let previous = this.state.currentMessages[i - 1];
                let current = this.state.currentMessages[i];
                let next = this.state.currentMessages[i + 1];
                let isMine = current.senderID === this.state.currentUserDetails.key;
                let currentMoment = moment(current.timestamp);
                let prevBySameAuthor = false;
                let nextBySameAuthor = false;
                let startsSequence = true;
                let endsSequence = true;
                let showTimestamp = true;

                if (previous) {
                    let previousMoment = moment(previous.timestamp);
                    let previousDuration = moment.duration(currentMoment.diff(previousMoment));
                    prevBySameAuthor = previous.author === current.author;

                    if (prevBySameAuthor && previousDuration.as('hours') < 1) {
                        startsSequence = false;
                    }

                    if (previousDuration.as('hours') < 1) {
                        showTimestamp = false;
                    }
                }

                if (next) {
                    let nextMoment = moment(next.timestamp);
                    let nextDuration = moment.duration(nextMoment.diff(currentMoment));
                    nextBySameAuthor = next.author === current.author;

                    if (nextBySameAuthor && nextDuration.as('hours') < 1) {
                        endsSequence = false;
                    }
                }
                const friendlyTimestamp = moment(current.timestamp).format('LLLL');

                tempMessages.push(

                    <div key={i}
                        className={[
                            'message',
                            `${isMine ? 'mine' : ''}`,
                            `${startsSequence ? 'start' : ''}`,
                            `${endsSequence ? 'end' : ''}`
                        ].join(' ')}>
                        {
                            showTimestamp &&
                            <div className="timestamp">
                                {friendlyTimestamp}
                            </div>
                        }

                        <div className="bubble-container">
                            <div className="bubble" title={friendlyTimestamp}>
                                {current.message}
                            </div>
                        </div>
                    </div>
                );

                // Proceed to the next message.
                i += 1;
            }
            return tempMessages;
        }

    }

    handleToggleMenu = () => {
        this.setState(prevState => {
            return {
                isMenuOpen: !prevState.isMenuOpen
            }
        })
    }

    async filterByValue(array, string) {
        // return array.filter(o =>
        //     Object.keys(o).some(k => o[k].toLowerCase().includes(string.toLowerCase())));
        return array.filter(element => element.username.toLowerCase().includes(string.toLowerCase()));
    }

    handleSearchRoom = async (event) => {
        if (event.target.value.length >= 3) {
            let result = await this.filterByValue(this.state.cloneRoomList, event.target.value);
            this.setState({
                roomList: result
            })
        }
        else {
            // this.getUserChatRooms();
            this.setState({
                roomList: this.state.cloneRoomList
            })
        }

    }

    render() {

        // setTimeout(() => {
        //     shave('.lst-message', 30);    
        // }, 100);

        // setInterval(() => {
        //     var elem = document.getElementById('messageGroup');            
        //     if (elem) {
        //         elem.scrollTop = elem.scrollHeight;
        //     }
        // }, 1000);

        return (
            <div className="root-page winning-dashboard">
                <NavbarHeader />
                <div className="main-container">
                    <Header
                        header_name="Winning Products"
                        history={this.props.history}
                    />
                    <div className="page-container">
                        <div>
                            {/* <h4 className="section-title">Messages</h4> */}

                            <div className="App">
                                <div className="messenger">

                                    <div className="scrollable sidebar">
                                        {this.state.isMenuOpen ?
                                            <div className="conversation-list">

                                                <div className="toolbar">
                                                    <div className="left-items">{[<i key="add" className={`toolbar-button ion-ios-cog`} />]}</div>
                                                    <div className="msg-header">

                                                        <span className="toggle-btn"
                                                            onClick={() => this.handleToggleMenu()} >
                                                            <MdClose />
                                                        </span>

                                                        <h1 className="toolbar-title">Messenger</h1>

                                                    </div>

                                                    <div className="right-items">{[<i key="add" className={`toolbar-button ion-ios-add-circle-outline`} />]}</div>
                                                </div>

                                                <div className="conversation-search search-bar">
                                                    <input
                                                        type="search"
                                                        className="conversation-search-input"
                                                        placeholder="Search"
                                                        onChange={this.handleSearchRoom}
                                                    />
                                                </div>
                                                <div className="conversation-list-group">
                                                    {
                                                        this.state.roomList ? (
                                                            this.state.roomList.map(conversation =>
                                                                <div className="conversation-list-item" key={conversation.email} onClick={() => this.handleActiveChat(conversation)}>
                                                                    <img className="conversation-photo" src={conversation.picture ? conversation.picture : "https://upload.wikimedia.org/wikipedia/commons/thumb/1/12/User_icon_2.svg/220px-User_icon_2.svg.png"} alt="conversation" />
                                                                    <div className="conversation-info">
                                                                        <h5 className="conversation-title">
                                                                            {conversation.username ? conversation.username : conversation.email}
                                                                            {conversation.isOnline ? <BsDot className="user-online" /> : <BsDot className="user-offline" />}
                                                                            {conversation.unReadMessages > 0 ? <label className="unread-message-count">+{conversation.unReadMessages}</label> : null}
                                                                        </h5>
                                                                        <div>
                                                                            <p className="conversation-snippet lst-message">{conversation.lastMessage ? conversation.lastMessage : null}</p>
                                                                            <p className=" conversation-snippet roomlist-time">{conversation.lastMessageTimeStamp ? new Date(conversation.lastMessageTimeStamp).toLocaleString() : null}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        ) : (null)
                                                    }
                                                </div>
                                            </div>
                                            : <div><FiMenu onClick={() => this.handleToggleMenu()} /></div>
                                        }</div>

                                    {
                                        this.state.activeChatRoom ?
                                            (
                                                <div className="scrollable content message-form-top">

                                                    <div className="message-list">

                                                        <div className="toolbar">
                                                            <div className="left-items"></div>
                                                            <h1 className="toolbar-title">{this.state.activeChatRoomName}</h1>
                                                            <div className="right-items">{[
                                                                <i key="info" className={`toolbar-button ion-ios-information-circle-outline`} />,
                                                                <i key="video" className={`toolbar-button ion-ios-videocam`} />,
                                                                <i key="phone" className={`toolbar-button ion-ios-call`} />
                                                            ]}
                                                            </div>
                                                        </div>

                                                        {
                                                            this.state.currentMessages && this.state.currentMessages.length ? (
                                                                <div className="message-list-container" id="messageGroup">{this.renderMessages()}</div>
                                                            ) : (
                                                                    <ul>
                                                                        <li><h1>No Current Message</h1></li>
                                                                    </ul>
                                                                )
                                                        }


                                                        <div className="compose">
                                                            <form onSubmit={this.sendMessage}>
                                                                <input
                                                                    type="text"
                                                                    autoComplete="off"
                                                                    className="compose-input"
                                                                    id="sendmsg"
                                                                    placeholder="Type a message, @name"
                                                                    onChange={(e) => this.handleOnChange("message", e)}
                                                                />

                                                                <div className="toolbar">
                                                                    <div className="right-items">
                                                                        <Button type="submit" variant="primary">Send</Button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>

                                            ) : (null)

                                    }


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        get_firebase_credentials: state.AllReducer.get_firebase_credentials,
        send_offline_user_email: state.AllReducer.send_offline_user_email
    }
};
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch)
    }
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Messages));

// export default (Messages);