import * as authService from "../services/authService";
import 'font-awesome/css/font-awesome.min.css';
import swal from 'sweetalert';
export const PRODUCT_DATA = "PRODUCT_DATA";
export const GET_CATEGORY = "GET_CATEGORY";
export const ADD_TO_SHOPIFY = "ADD_TO_SHOPIFY";
export const GET_SUPPORT = "GET_SUPPORT";
export const SIGN_UP = "SIGN_UP";
export const SIGN_IN = "SIGN_IN";
export const GET_FIREBASE_CREDENTIALS = "GET_FIREBASE_CREDENTIALS";
export const SEND_OFFLINE_USER_EMAIL = "SEND_OFFLINE_USER_EMAIL";
export const GET_USERNAME = "GET_USERNAME";
export const CHECK_USERNAME = "CHECK_USERNAME";
export const SINGLE_PRODUCT_DATA = "SINGLE_PRODUCT_DATA";
export const GET_SHOP = "GET_SHOP";
export const SAVE_SEO = "SAVE_SEO";
export const SAVE_MARKETING = "SAVE_MARKETING";
export const SAVE_INFLUENCER = "SAVE_INFLUENCER";
export const SAVE_SUPPLIER = "SAVE_SUPPLIER";
export const GET_ENV = "GET_ENV";
export const GET_SERVICE = "GET_SERVICE";
export const PLACE_ORDER = "PLACE_ORDER";
export const GET_ORDER = "GET_ORDER";
export const PLAN_UPDRADE = "PLAN_UPDRADE";
export const PLAN_DOWNGRADE = "PLAN_DOWNGRADE";
export const PRODUCTS_BY_JOB = "PRODUCTS_BY_JOB";
export const HOME_ALL_PRODUCT = "HOME_ALL_PRODUCT";
export const HOME_SINGLE_PRODUCT = "HOME_SINGLE_PRODUCT";
export const GET_INFLUENCER = "GET_INFLUENCER";
export const GET_SUPPLIER = "GET_SUPPLIER";
export const GET_ALL_IMAGES = "GET_ALL_IMAGES";
export const GET_COUNTRY = "GET_COUNTRY";
export const GET_SUB_PLAN = "GET_SUB_PLAN";
export const USER_PAY_TYPE = "USER_PAY_TYPE";
export const IS_ANY_DONE_JOBS = "IS_ANY_DONE_JOBS";
export const SHOPIFY_MONEY_FORMATE = "SHOPIFY_MONEY_FORMATE";
export const PRODUCTS_BY_CAT = "PRODUCTS_BY_CAT";
export const PRODUCTS_BY_CAT_REMOVE = "PRODUCTS_BY_CAT_REMOVE";
export const GET_SHOP_SUB = "GET_SHOP_SUB";
export const GET_SUP_PLAN_GOLD = "GET_SUP_PLAN_GOLD";
export const GET_SUP_PLAN_SILVER = "GET_SUP_PLAN_SILVER";
export const STRIPE_LOADED = "STRIPE_LOADED";
export const GET_ALL_SUB = "GET_ALL_SUB";
export const HIDE_LINK = "HIDE_LINK";
export const HIDE_ATS = "HIDE_ATS";
export const HIDE_LINK_LOGOUT = "HIDE_LINK_LOGOUT";
export const IS_REF_BTN = "IS_REF_BTN";
export const GET_STRIPE_CUS_MSG = "GET_STRIPE_CUS_MSG";
export const STRIPE_LOADED_ORDER = "STRIPE_LOADED_ORDER";
export const GET_REF_CODE = "GET_REF_CODE";
export const GENERATE_REF_CODE = "GENERATE_REF_CODE";
export const CARD_DETAILS = "CARD_DETAILS";
export const FAILED = "FAILED";

export const getCountryAction = () => {
  return dispatch => {
    authService
      .getCountry()
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_COUNTRY,
            data: response.data
          });
          let data = {
            'Country_Name': response.data.data.Country_Name, 'Country_Code': response.data.data.Country_Code,
            'Country_Flag': response.data.data.Country_Flag, 'Currency_Symbol': response.data.data.Currency_Symbol
          }
          localStorage.setItem("country_wp", JSON.stringify(data))
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const getAllProduct = data => {
  return dispatch => {
    authService
      .getProducts(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: PRODUCT_DATA,
            data: response.data,
            reqData: data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const getCategoryAction = data => {
  return dispatch => {
    authService
      .getCategory(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_CATEGORY,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};


export const signUpAction = data => {
  return dispatch => {
    authService
      .signUp(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SIGN_UP,
            data: response.data
          });
          if (response.data.isValid === false) {
            swal({
              text: response.data.message,
              icon: "warning",
              dangerMode: true,
            })
          }
          else {
            swal({
              text: "Thank you, You've signed up successfully.",
              icon: "success",
              dangerMode: false,
            })

          }
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const signInAction = (data, history) => {
  return dispatch => {
    authService
      .signIn(data)
      .then(response => {
        if (response.status === 200) {
          if (response.data) {
            if (response.data.isValid) {
              const asyncLocalStorage = {
                setItem: function () {
                  return Promise.resolve().then(function () {
                    localStorage.setItem("shop_wp", response.data.data.shop);
                    localStorage.setItem("token_wp", response.data.data.accessToken);
                    localStorage.setItem("user_from", response.data.data.user_from);
                    localStorage.setItem("user_email", response.data.data.email);
                  });
                }
              };
              asyncLocalStorage.setItem()
                .then(() => {
                  history.push("/products")
                })
            }
            else {
              swal({
                text: response.data.message,
                icon: "warning",
                dangerMode: true,
              })
            }
          }
          dispatch({
            type: SIGN_IN,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const getFirebaseCredentials = data => {
  return dispatch => {
    authService
      .getFirebaseCredentials(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_FIREBASE_CREDENTIALS,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const sendOfflineUserEmail = data => {
  return dispatch => {
    authService
      .sendOfflineUserEmail(data)
      .then(response => {
        if (response.status === 200) {
          console.log(response)
          dispatch({
            type: SEND_OFFLINE_USER_EMAIL,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const getUsername = data => {
  return dispatch => {
    authService
      .getUsername(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_USERNAME,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const checkUsername = data => {
  return dispatch => {
    authService
      .checkUsername(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: CHECK_USERNAME,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};


export const getSupportAction = data => {
  return dispatch => {
    authService
      .getSupport(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_SUPPORT,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const AddToShopifyAction = data => {
  return dispatch => {
    authService
      .addToShopify(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: ADD_TO_SHOPIFY,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const GetSingleProductAction = data => {
  return dispatch => {
    authService
      .singleProduct(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SINGLE_PRODUCT_DATA,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const GetShopDetailsAction = data => {
  return dispatch => {
    authService
      .getShop(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_SHOP,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const GetEnvAction = data => {
  return dispatch => {
    authService
      .getEnv(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_ENV,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const SaveSeoAction = data => {
  return dispatch => {
    authService
      .saveSeo(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SAVE_SEO,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};

export const SaveMarketingAction = data => {
  return dispatch => {
    authService
      .saveMarketing(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SAVE_MARKETING,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const SaveInfluencerAction = data => {
  return dispatch => {
    authService
      .saveInfluencer(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SAVE_INFLUENCER,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const SaveSuppliergAction = data => {
  return dispatch => {
    authService
      .saveSupplier(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SAVE_SUPPLIER,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const GetServiceAction = data => {
  return dispatch => {
    authService
      .getServices(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_SERVICE,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const PlaceOrderAction = (data, history) => {

  return dispatch => {
    dispatch({
      type: STRIPE_LOADED_ORDER,
      data: false
    });
    const wrapper = document.createElement('div');
    wrapper.innerHTML = "<i class='fa fa-spinner fa-spin' style='font-size:30px'></i>";
    var send_time = new Date();

    swal({
      title: 'Loading..',
      allowEscapeKey: false,
      allowOutsideClick: false,
      content: wrapper,
      buttons: false
    })
    var url = window.location.href
    authService
      .placeOrder(data)
      .then(response => {

        /********* send email if more than 5 sec to response *******/

        var receive_time = new Date();
        var difference = (receive_time - send_time) / 1000;
        if (difference > 5) {
          var email_data = { ...data, difference, "req_from": "niche", url }
          authService
            .sendEmailOfResponseTime(email_data)
            .then(response => {
              if (response.status === 200) { }
            })
            .catch(error => {
              if (error) {
                dispatch({ type: FAILED, data: { error_msg: error } });
              }
            });
        }

        /*************************/

        if (response.status === 200) {
          dispatch({
            type: PLACE_ORDER,
            data: response.data
          });
          if (response.data.checkoutSessionId) {
            authService.publicKey()
              .then(response1 => {
                if (response1.status === 200) {
                  dispatch({
                    type: STRIPE_LOADED_ORDER,
                    data: true
                  })
                  var stripe = window.Stripe(response1.data.publicKey);
                  stripe.redirectToCheckout({ sessionId: response.data.checkoutSessionId }).then(function (result) {
                  }).catch(function (err) {
                    console.log(err);
                  });
                }
              })
              .catch(error => {
                if (error) {
                  dispatch({ type: FAILED, data: { error_msg: "error" } });
                }
              });
          } else {
            dispatch({
              type: STRIPE_LOADED_ORDER,
              data: true
            });
            if (response.data.success) {
              const accessToken = localStorage.getItem("token_wp");
              const shop = localStorage.getItem("shop_wp");
              const get_order = {
                accessToken, shop, page: "1"
              }
              swal(response.data.message).then(() => {
                authService
                  .getOrder(get_order)
                  .then(response => {
                    if (response.status === 200) {
                      dispatch({
                        type: GET_ORDER,
                        data: response.data,
                        reqData: get_order
                      });
                    }
                    history.push("/products#jobs" + new Date().getTime());
                    // window.location.reload();
                  })
                  .catch(error => {
                    if (error) {
                      dispatch({ type: FAILED, data: { error_msg: error } });
                    }
                  });
              });
            } else {
              dispatch({
                type: STRIPE_LOADED,
                data: true
              });
              swal(response.data.message)
              var receive_time = new Date();
              var difference = (receive_time - send_time) / 1000;
              var email_data = { ...data, difference, "req_from": "niche", "err_res": true, url, "err": response.data.message }
              authService
                .sendEmailOfResponseTime(email_data)
                .then(response => {
                  if (response.status === 200) { }
                })
                .catch(error => {
                  if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                  }
                });

            }
          }
        }
      })
      .catch(error => {

        /********* send email if no response *******/
        var receive_time = new Date();
        var difference = (receive_time - send_time) / 1000;
        if (difference > 5) {
          var email_data = { ...data, difference, "req_from": "niche", "no_res": true, url }
          authService
            .sendEmailOfResponseTime(email_data)
            .then(response => {
              if (response.status === 200) { }
            })
            .catch(error => {
              if (error) {
                dispatch({ type: FAILED, data: { error_msg: error } });
              }
            });
        }
        /*************************/

        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const PlaceOrderShopifyAction = data => {
  return dispatch => {
    authService
      .placeOrderShopify(data)
      .then(response => {
        if (response.status === 200) {
          response.data && response.data.redirect_url && window.open(response.data.redirect_url, "_self")
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const GetOrderAction = data => {
  return dispatch => {
    authService
      .getOrder(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_ORDER,
            data: response.data,
            reqData: data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const PlanUpdradeAction = (data) => {

  return dispatch => {
    dispatch({
      type: STRIPE_LOADED,
      data: false
    })
    var url = window.location.href
    var send_time = new Date();
    authService
      .planUpgrade(data)
      .then(response => {


        /********* send email if more than 5 sec to response *******/
        var receive_time = new Date();
        var difference = (receive_time - send_time) / 1000;
        if (difference > 5) {
          var email_data = { ...data, difference, "req_from": "wp", url }
          authService
            .sendEmailOfResponseTime(email_data)
            .then(response => {
              if (response.status === 200) { }
            })
            .catch(error => {

              if (error) {
                dispatch({ type: FAILED, data: { error_msg: error } });
              }
            });
        }

        /**************/

        if (response.status === 200) {
          dispatch({
            type: PLAN_UPDRADE,
            data: response.data
          });
          if (response.data.stripe_updated) {

            dispatch({
              type: STRIPE_LOADED,
              data: true
            });

            swal(response.data.message);

            var data_sub_shop = {
              accessToken: data.accessToken,
              shop: data.shop
            }
            authService
              .getShopSubscription(data_sub_shop)
              .then(response => {
                if (response.status === 200) {
                  dispatch({
                    type: GET_SHOP_SUB,
                    data: response.data
                  });
                }
              }).then(() => {
                var data_shop = {
                  accessToken: data.accessToken,
                  shop: data.shop,
                  plan_type: data.plan_type
                }
                authService
                  .getShop(data_shop)
                  .then(response => {
                    if (response.status === 200) {
                      dispatch({
                        type: GET_SHOP,
                        data: response.data
                      });
                    }
                  })
              })
              .catch(error => {
                if (error) {
                  dispatch({ type: FAILED, data: { error_msg: error } });
                }
              });
          }
          else {
            // response.data && response.data.redirect_url && window.open(response.data.redirect_url, "_self")
            if (response.data.checkoutSessionId) {
              authService.publicKey()
                .then(response1 => {
                  if (response1.status === 200) {
                    var stripe = window.Stripe(response1.data.publicKey);
                    stripe.redirectToCheckout({ sessionId: response.data.checkoutSessionId }).then(function (result) {
                    }).catch(function (err) {
                      console.log(err);
                    });
                  }
                })
                .catch(error => {
                  if (error) {
                    dispatch({ type: FAILED, data: { error_msg: "error" } });
                  }
                });
            } else {
              dispatch({
                type: STRIPE_LOADED,
                data: true
              });
              swal(response.data.message)

              /************************Err email**********************/
              var receive_time = new Date();
              var difference = (receive_time - send_time) / 1000;
              var email_data = { ...data, difference, "req_from": "wp", "err_res": true, url, "err": response.data.message }
              authService
                .sendEmailOfResponseTime(email_data)
                .then(response => {
                  if (response.status === 200) { }
                })
                .catch(error => {
                  if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                  }
                });

              /************************End Err email**********************/

            }
          }
        }

      })
      .catch(error => {

        /********* send email if no API response *******/
        var receive_time = new Date();
        var difference = (receive_time - send_time) / 1000;
        var email_data = { ...data, difference, "req_from": "wp", "no_res": true, url }
        authService
          .sendEmailOfResponseTime(email_data)
          .then(response => {
            if (response.status === 200) { }
          })
          .catch(error => {
            if (error) {
              dispatch({ type: FAILED, data: { error_msg: error } });
            }
          });
        /**************/

        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const setStripeLoadedOrder = () => {
  return dispatch => {
    dispatch({
      type: STRIPE_LOADED,
      data: false
    });
  }
}
export const planUpgradeShopifyToStripe = (data) => {

  return dispatch => {
    dispatch({
      type: STRIPE_LOADED,
      data: false
    })
    var url = window.location.href
    var send_time = new Date();
    authService
      .planUpgradeShopifyToStripe(data)
      .then(response => {

        /********* send email if more than 5 sec to response *******/
        var receive_time = new Date();
        var difference = (receive_time - send_time) / 1000;
        if (difference > 5) {
          var email_data = { ...data, difference, "req_from": "wp", url }
          authService
            .sendEmailOfResponseTime(email_data)
            .then(response => {
              if (response.status === 200) { }
            })
            .catch(error => {

              if (error) {
                dispatch({ type: FAILED, data: { error_msg: error } });
              }
            });
        }
        /**************/

        if (response.status === 200) {
          dispatch({
            type: PLAN_UPDRADE,
            data: response.data
          });
          if (response.data.stripe_updated) {
            dispatch({
              type: STRIPE_LOADED,
              data: true
            });
            swal(response.data.message);
            var data_sub_shop = {
              accessToken: data.accessToken,
              shop: data.shop
            }
            authService
              .getShopSubscription(data_sub_shop)
              .then(response => {
                if (response.status === 200) {
                  dispatch({
                    type: GET_SHOP_SUB,
                    data: response.data
                  });
                }
              }).then(() => {
                var data_shop = {
                  accessToken: data.accessToken,
                  shop: data.shop,
                  plan_type: data.plan_type
                }
                authService
                  .getShop(data_shop)
                  .then(response => {
                    if (response.status === 200) {
                      dispatch({
                        type: GET_SHOP,
                        data: response.data
                      });
                    }
                  })
              })
              .catch(error => {
                if (error) {
                  dispatch({ type: FAILED, data: { error_msg: error } });
                }
              });
          }
          else {

            // response.data && response.data.redirect_url && window.open(response.data.redirect_url, "_self")
            if (response.data.checkoutSessionId) {

              authService.publicKey()
                .then(response1 => {
                  if (response1.status === 200) {
                    var stripe = window.Stripe(response1.data.publicKey);
                    stripe.redirectToCheckout({ sessionId: response.data.checkoutSessionId }).then(function (result) {
                    }).catch(function (err) {
                      console.log(err);
                    });
                  }
                })
                .catch(error => {
                  if (error) {
                    dispatch({ type: FAILED, data: { error_msg: "error" } });
                  }
                });

            } else {
              dispatch({
                type: STRIPE_LOADED,
                data: true
              });
              swal(response.data.message)

              /************************Err email**********************/
              var receive_time = new Date();
              var difference = (receive_time - send_time) / 1000;
              var email_data = { ...data, difference, "req_from": "wp", "err_res": true, url, "err": response.data.message }
              authService
                .sendEmailOfResponseTime(email_data)
                .then(response => {
                  if (response.status === 200) { }
                })
                .catch(error => {
                  if (error) {
                    dispatch({ type: FAILED, data: { error_msg: error } });
                  }
                });

              /************************End Err email**********************/
            }
          }
        }

      })
      .catch(error => {

        /********* send email if no response *******/
        var receive_time = new Date();
        var difference = (receive_time - send_time) / 1000;
        if (difference > 5) {
          var email_data = { ...data, difference, "req_from": "wp", "no_res": true, url }
          authService
            .sendEmailOfResponseTime(email_data)
            .then(response => {
              if (response.status === 200) { }
            })
            .catch(error => {

              if (error) {
                dispatch({ type: FAILED, data: { error_msg: error } });
              }
            });
        }
        /**************/

        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const PlanUpdradeShopifyAction = (data) => {
  return dispatch => {
    authService
      .planUpgradeShopify(data)
      .then(response => {
        if (response.status === 200) {
          response.data && response.data.redirect_url && window.open(response.data.redirect_url, "_self")
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const PlanDowngradeAction = data => {
  return dispatch => {
    authService
      .planDowngrade(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: PLAN_DOWNGRADE,
            data: response.data
          });
          dispatch({
            type: STRIPE_LOADED,
            data: true
          });
          swal(response.data.message)
        }
      }).then(() => {
        authService
          .getShop(data)
          .then(response => {
            if (response.status === 200) {
              dispatch({
                type: GET_SHOP,
                data: response.data
              });
            }
          })
          .catch(error => {
            if (error) {
              dispatch({ type: FAILED, data: { error_msg: "error" } });
            }
          });
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const ProductsByJobAction = data => {
  return dispatch => {
    authService
      .productsByJob(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: PRODUCTS_BY_JOB,
            data: response.data,
            reqData: data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const HomeAllProductAction = data => {
  return dispatch => {
    authService
      .homeAllProducts(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: HOME_ALL_PRODUCT,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const HomeSingleProductAction = data => {
  return dispatch => {
    authService
      .homeProductById(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: HOME_SINGLE_PRODUCT,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const GetInfluencerAction = data => {
  return dispatch => {
    authService
      .getInfluencer(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_INFLUENCER,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const GetSupplier = data => {
  return dispatch => {
    authService
      .getSupplier(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_SUPPLIER,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const getSubscriptionPlanAction = (data, data_for) => {
  return dispatch => {
    authService
      .getSubscriptionPlan(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: data_for === "sup_gold" ?
              GET_SUP_PLAN_GOLD : data_for === "sup_silver" ?
                GET_SUP_PLAN_SILVER : GET_SUB_PLAN,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const getUserPayTypeAction = data => {
  return dispatch => {
    authService
      .getUserPayType(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: USER_PAY_TYPE,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const isAnyDoneJobsAction = data => {
  return dispatch => {
    authService
      .isAnyDoneJobs(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: IS_ANY_DONE_JOBS,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const getShopoifyMFAction = data => {
  return dispatch => {
    authService
      .getShopoifyMF(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SHOPIFY_MONEY_FORMATE,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const proByCatAction = (data1, history) => {
  return dispatch => {
    const accessToken = localStorage.getItem("token_wp");
    const shop = localStorage.getItem("shop_wp");
    const data = {
      page: '1',
      sort: '',
      search: {
        by_product: "", by_category: data1._id, by_interest: ""
      },
      accessToken,
      shop
    };
    authService
      .getProducts(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: PRODUCT_DATA,
            data: response.data,
            reqData: data
          });
        }
      }).then(() => {
        dispatch({
          type: PRODUCTS_BY_CAT,
          data: data1
        })
        history.push("/products")
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: "error" } });
        }
      });
  };
};
export const proByCatRemoveAction = () => {
  return dispatch => {
    dispatch({
      type: PRODUCTS_BY_CAT_REMOVE,
      data: ""
    });
  }
};
export const getShopSubscriptionAction = data => {
  return dispatch => {
    authService
      .getShopSubscription(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_SHOP_SUB,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const changePasswordAction = data => {
  return dispatch => {
    authService
      .changePassword(data)
      .then(response => {
        if (response.status === 200) {
          swal({
            text: response.data.message,
            icon: response.data.success === true ? "success" : "warning",
            dangerMode: response.data.success === true ? false : true,
          })
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const updateForgotPasswordAction = data => {

  return dispatch => {
    const wrapper = document.createElement('div');
    wrapper.innerHTML = "<i class='fa fa-spinner fa-spin' style='font-size:30px'></i>";
    swal({
      title: 'Loading..',
      allowEscapeKey: false,
      allowOutsideClick: false,
      content: wrapper,
      buttons: false
    })
    authService
      .updateForgotPassword(data)
      .then(response => {
        if (response.status === 200) {
          if (response.data.expired === true) {
            swal({
              text: 'The Link You Followed Has Expired !',
              icon: 'warning',
              buttons: {
                cancel: "Cancel",
                try_again: "Try again !",
              },
            }).then((value) => {
              switch (value) {
                case "cancel":
                  break;
                case "try_again":
                  window.open("/winningproducts", "_self")
                  break;
                default:
                  break;
              }
            });
          }
          else {
            if (response.data.success) {
              swal({
                text: response.data.message,
                icon: 'success',
                dangerMode: false,
                button: 'Login !'
              }).then(() => {
                // window.open("/winningproducts", "_self")
                if (response.data.data) {
                  const asyncLocalStorage = {
                    setItem: function () {
                      return Promise.resolve().then(function () {
                        localStorage.setItem("shop_wp", response.data.data.shop);
                        localStorage.setItem("token_wp", response.data.data.accessToken);
                        localStorage.setItem("user_from", response.data.data.user_from);
                        localStorage.setItem("user_email", response.data.data.email);
                      });
                    }
                  };
                  asyncLocalStorage.setItem()
                    .then(() => {
                      window.open("/winningproducts/products", "_self")
                    });
                }
              });
            }
            else {
              swal({
                text: 'Something went wrong !',
                icon: 'warning',
                buttons: {
                  cancel: "Cancel",
                  try_again: "Try again !",
                },
              }).then((value) => {
                switch (value) {
                  case "cancel":
                    break;
                  case "try_again":
                    window.open("/winningproducts", "_self")
                    break;
                  default:
                    break;
                }
              });
            }
          }
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const forgotPasswordAction = () => {
  return dispatch => {
    swal("Enter your Email-ID to change the password !", {
      content: {
        element: "input",
        attributes: {
          placeholder: "Type your email-id",
          type: "email",
        },
      },
      button: "Submit"
    })
      .then((value) => {
        var pattern = /^[a-zA-Z0-9\-_]+(\.[a-zA-Z0-9\-_]+)*@[a-z0-9]+(\-[a-z0-9]+)*(\.[a-z0-9]+(\-[a-z0-9]+)*)*\.[a-z]{2,15}$/;
        if (value !== undefined && value !== null) {
          if (value === "") {
            swal("Email-Id can't be blank. Please try again !")
          }
          else if (value !== "" && pattern.test(value) === false) {
            swal("You've entered invalid Email-ID. Please try again!");
          }
          else {
            const wrapper = document.createElement('div');
            wrapper.innerHTML = "<i class='fa fa-spinner fa-spin' style='font-size:30px'></i>";
            swal({
              title: 'Loading..',
              allowEscapeKey: false,
              allowOutsideClick: false,
              content: wrapper,
              buttons: false
            })

            var data = { username: value }
            authService
              .forgotPassword(data)
              .then(response => {
                if (response.status === 200) {
                  swal({
                    text: response.data.message,
                    icon: response.data.success === true ? "success" : "warning",
                    dangerMode: response.data.success === true ? false : true,
                  })
                }
              })
              .catch(error => {
                if (error) {
                  dispatch({ type: FAILED, data: { error_msg: error } });
                }
              });
          }
        }
      });
  };
};
export const getAllSubAction = data => {
  return dispatch => {
    authService
      .getAllSub(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_ALL_SUB,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const hideLinkAction = data => {
  return dispatch => {
    authService
      .hideLink(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: HIDE_LINK,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const hideATSAction = data => {
  return dispatch => {
    authService
      .hideATS(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: HIDE_ATS,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const hideLinkLogoutAction = data => {
  return dispatch => {
    authService
      .hideLinkLogout(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: HIDE_LINK_LOGOUT,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const signupSetPasswordAction = data => {
  return dispatch => {
    authService
      .setsSignupPass(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: SIGN_UP,
            data: response.data
          });
          if (response.data.expired === true) {
            swal({
              text: 'The Link You Followed Has Expired !',
              icon: 'warning',
              buttons: {
                cancel: "Cancel",
                try_again: "Try again !",
              },
            }).then((value) => {
              switch (value) {
                case "cancel":
                  break;
                case "try_again":
                  window.open("/winningproducts", "_self")
                  break;
                default:
                  break;
              }
            });
          }
          else {
            if (response.data.isValid) {
              swal({
                text: response.data.message,
                icon: 'success',
                dangerMode: false,
                button: 'Login !'
              }).then(() => {
                // window.open("/winningproducts", "_self")
                if (response.data.data) {
                  const asyncLocalStorage = {
                    setItem: function () {
                      return Promise.resolve().then(function () {
                        localStorage.setItem("shop_wp", response.data.data.shop);
                        localStorage.setItem("token_wp", response.data.data.accessToken);
                        localStorage.setItem("user_from", response.data.data.user_from);
                        localStorage.setItem("user_email", response.data.data.email);
                      });
                    }
                  };
                  asyncLocalStorage.setItem()
                    .then(() => {
                      window.open("/winningproducts/products", "_self")
                    });
                }
              });
            }
            else {
              swal({
                text: response.data.message,
                icon: 'warning',
                buttons: {
                  cancel: "Cancel",
                  try_again: "Try again !",
                },
              }).then((value) => {
                switch (value) {
                  case "cancel":
                    break;
                  case "try_again":
                    window.open("/winningproducts", "_self")
                    break;
                  default:
                    break;
                }
              });
            }
          }
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const sendSignupEmailAction = data => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = "<i class='fa fa-spinner fa-spin' style='font-size:30px'></i>";
  swal({
    title: 'Loading..',
    allowEscapeKey: false,
    allowOutsideClick: false,
    content: wrapper,
    buttons: false
  })
  return dispatch => {
    authService
      .sendSignupemail(data)
      .then(response => {
        swal({
          text: response.data.message,
          icon: response.data.success === true ? "success" : "warning",
          dangerMode: response.data.success === true ? false : true,
        })
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const submitFriendEmailsAction = data => {
  const wrapper = document.createElement('div');
  wrapper.innerHTML = "<i class='fa fa-spinner fa-spin' style='font-size:30px'></i>";
  swal({
    title: 'Loading..',
    allowEscapeKey: false,
    allowOutsideClick: false,
    content: wrapper,
    buttons: false
  })
  return dispatch => {
    authService
      .submitFriendEmails(data)
      .then(response => {
        swal({
          text: response.data.message,
          icon: response.data.success === true ? "success" : "warning",
          dangerMode: response.data.success === true ? false : true,
        })
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const handleRefCodeSubmit = () => {
  return dispatch => {
    swal("Enter referral code", {
      content: {
        element: "input",
        attributes: {
          placeholder: "Enter referral code here",
          type: "text",
        },
      },
      button: "Submit"
    })
      .then((value) => {

        if (value !== undefined && value !== null) {
          if (value === "") {
            swal("Referral code can't be blank. Please try again !")
          }
          else {
            const wrapper = document.createElement('div');
            wrapper.innerHTML = "<i class='fa fa-spinner fa-spin' style='font-size:30px'></i>";
            swal({
              title: 'Loading..',
              allowEscapeKey: false,
              allowOutsideClick: false,
              content: wrapper,
              buttons: false
            })
            const accessToken = localStorage.getItem("token_wp");
            const shop = localStorage.getItem("shop_wp");
            const user_from = localStorage.getItem("user_from");
            var send_data = { referral_code: value.toLowerCase(), accessToken, shop, user_from }
            authService
              .addRefCode(send_data)
              .then(response => {
                if (response.status === 200) {
                  swal({
                    text: response.data.message,
                    icon: response.data.success === true ? "success" : "warning",
                    dangerMode: response.data.success === true ? false : true,
                  })
                  if (response.data.success) {
                    authService
                      .isDisplayRefBtn(send_data)
                      .then(response => {
                        if (response.status === 200) {
                          dispatch({
                            type: IS_REF_BTN,
                            data: response.data
                          })
                        }
                      })
                      .catch(error => {
                        if (error) {
                          dispatch({ type: FAILED, data: { error_msg: error } });
                        }
                      });
                  }
                }
              })
              .catch(error => {
                if (error) {
                  dispatch({ type: FAILED, data: { error_msg: error } });
                }
              });
          }
        }
      });
  };
};

export const isDisplayRefBtnAction = data => {
  return dispatch => {
    authService
      .isDisplayRefBtn(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: IS_REF_BTN,
            data: response.data
          })
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const getStripCustomerMessagesAction = data => {
  return dispatch => {
    authService
      .getStripCustomerMessages(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_STRIPE_CUS_MSG,
            data: response.data
          })
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const generateRefCodeAction = data => {
  return dispatch => {
    authService
      .generateRefCode(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GENERATE_REF_CODE,
            data: response.data
          })
          authService.getRefCode(data)
            .then(response => {
              if (response.status === 200) {
                dispatch({
                  type: GET_REF_CODE,
                  data: response.data
                })
              }
            })
            .catch(error => {
              if (error) {
                dispatch({ type: FAILED, data: { error_msg: error } });
              }
            });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};

export const getRefCodeAction = data => {
  return dispatch => {
    authService
      .getRefCode(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: GET_REF_CODE,
            data: response.data
          })
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};
export const getCardDeailsByGetShopApi = (data) => {
  return dispatch => {
    authService
      .getCustomerCardDeails(data)
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: CARD_DETAILS,
            data: response.data
          });
        }
      })
      .catch(error => {
        if (error) {
          dispatch({ type: FAILED, data: { error_msg: error } });
        }
      });
  };
};