import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { convertFromRaw, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { TiArrowBack } from "react-icons/ti";
import { Carousel } from "react-responsive-carousel";
import * as InstallAction from "../Action/installAction";
import * as AllAction from "../Action/AllAction";
import YouTube from "react-youtube";
import ReactStars from "react-rating-stars-component";
import Loader from "react-loader";
import { MdDateRange } from "react-icons/md";
import SignUpModal from './SignUpModal';
import swal from "sweetalert";
var youtube_icon = require("../img/yt.png");
const pdf_icon2 = require("../img/pdf-image.png");
const fb_ads2 = require("../img/fb-ads.png");
const comments = require("../img/Comment-89.png");
const likes = require("../img/Like-18.png");
const reactions = require("../img/Smiles.png");
const share = require("../img/Share-91.png");
var view = require("../img/view-42.png");
var imageInstall = require("../img/app-logo.png");

class HomeSingleProduct extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      home_all_product_data: [],
      home_single_product: [],
      loaded: false,
      loaded_env: false,
      showSignUpModal: false
    };
  }

  componentDidMount() {
    const env_data = {
      secret: "9K061Pu832RM9bC48Zm92RM9bC48Zm9T587WNQ"
    };
    this.props.action.AllAction.GetEnvAction(env_data);
    const id = window.location.href.slice(-24);
    const data = {
      product_id: id,
    };
    this.props.action.AllAction.HomeSingleProductAction(data);
    const hide_link_logout = {
      tag: "link_hide",
      key: "link_hide"
    };
    this.props.load_hl_lo !== true && this.props.action.AllAction.hideLinkLogoutAction(hide_link_logout);

  }

  componentDidUpdate(prevProps) {
    if (this.props.home_single_product !== this.state.home_single_product) {
      this.setState(
        {
          home_single_product: this.props.home_single_product
        },
        () => {
          const id = window.location.href.slice(-24);
          if ((this.props.home_single_product && this.props.home_single_product.length !== 0) &&
            (id === this.props.home_single_product[0]._id)) {
            this.setState({
              loaded: true
            });
          }
          if (this.state.home_single_product.length === 0) {
            this.setState({
              loaded: false
            });
          }
          var product_description =
            this.state.home_single_product &&
            this.state.home_single_product.length !== 0 &&
            this.state.home_single_product[0].product_description;
          product_description
            ? this.setState({
              editorState: EditorState.createWithContent(
                convertFromRaw(JSON.parse(product_description))
              )
            })
            : this.setState({
              editorState: EditorState.createEmpty()
            });
        }
      );
    }
    if (prevProps.env !== this.props.env) {
      this.setState(
        {
          env: this.props.env
        },
        () => {
          this.setState({
            loaded_env: true
          });
        }
      );
    }
  }

  handleRoute = route => {
    this.props.history.push(route);
  };

  handleLinkClick = link => {
    window.open(link, "_blank");
  }; handleLinkClick = (link, is_show) => {
    if (this.props.load_hl_lo && this.props.hide_link_logout && this.props.hide_link_logout.is_hide_status === true
      && is_show === 'show') {
      this.handleSwal();
    }
    else {
      window.open(link, "_blank");
    }
  };
  handleSwal = () => {
    this.setState({
      showSignUpModal: true
    })
  }
  handleCloseSignUp = () => this.setState({
    showSignUpModal: false
  });
  renderTooltip = props => {
    return <Tooltip {...props}>{props}</Tooltip>;
  };

  handleOnClickItem = val => {
    const { home_single_product, loaded_env } = this.state;
    var image =
      loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_images &&
      home_single_product[0].product_images.length !== 0 &&
      home_single_product[0].product_images[val];
    this.handleLinkClick(image.includes("http") ? image : this.state.env.CDN_URL + image);
  };

  render() {
    const { home_single_product } = this.state;

    function titleCase(str) {
      var splitStr = str.toLowerCase().split(' ');
      for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
      }
      // Directly return the joined string
      return splitStr.join(' ');
    }

    let tempName = home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_name && titleCase(home_single_product[0].product_name);
    const opts = {
      height: "220",
      width: "170",
      playerVars: {
        autoplay: 0,
        controls: 0,
        forceSSL: 0,
        origin: "https://" + window.location.hostname
      }
    };
    const product_name =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_name &&
      tempName;
    // const main_image =
    //   this.state.loaded_env &&
    //   home_single_product &&
    //   home_single_product.length !== 0 &&
    //   (typeof (home_single_product[0].product_main_image) === "string" && (home_single_product[0].product_main_image.includes("http") ? this.props.home_single_product[0].product_main_image :
    //     this.state.env.CDN_URL + this.props.home_single_product[0].product_main_image.replace("images", "thumb_images")));
    const main_image_onclick =
      this.state.loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      (typeof (home_single_product[0].product_main_image) === "string" && home_single_product[0].product_main_image.includes("http") ? this.props.home_single_product[0].product_main_image :
        this.state.env.CDN_URL + this.props.home_single_product[0].product_main_image);
    const product_images =
      this.state.loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_images &&
      home_single_product[0].product_images.length !== 0 &&
      home_single_product[0].product_images.map((image, i) => {
        return (
          <li className="image-item" key={i}>
            <img src={(typeof image === "string" && (image.includes("http") ? image : this.state.env.CDN_URL + image.replace("images", "thumb_images")))} width="100px" height="100px" alt="NoImage" />
          </li>
        );
      });

    const pnc = (
      <ol className="list-unstyled vertical-data">
        <li>
          <b>Price:</b>{" "}
          <span>
            {home_single_product &&
              home_single_product.length !== 0 &&
              home_single_product[0].product_price}
            <div className="base-price">{home_single_product &&
              home_single_product.length !== 0 &&
              (home_single_product[0].product_price !== home_single_product[0].base_product_price) &&
              home_single_product[0].base_product_price}</div>
          </span>
        </li>
        <li>
          <b>Sale Price:</b>{" "}
          <span>
            {home_single_product &&
              home_single_product.length !== 0 &&
              home_single_product[0].product_sale_price}
            <div className="base-price">{home_single_product &&
              home_single_product.length !== 0 &&
              (home_single_product[0].product_sale_price !== home_single_product[0].base_product_sale_price) &&
              home_single_product[0].base_product_sale_price}</div>
          </span>
        </li>
        <li>
          <b>Profit:</b>{" "}
          <span>
            {home_single_product &&
              home_single_product.length !== 0 &&
              home_single_product[0].product_profit_margin}
            <div className="base-price">{home_single_product &&
              home_single_product.length !== 0 &&
              (home_single_product[0].product_profit_margin !== home_single_product[0].base_product_profit_margin) &&
              home_single_product[0].base_product_profit_margin}</div>
          </span>
        </li>
      </ol>
    );
    const analytics = (
      <ol className="list-unstyled vertical-data">
        <li>
          <b>Source:</b>{" "}
          <span>
            {home_single_product &&
              home_single_product.length !== 0 &&
              home_single_product[0].product_analytics_source}
          </span>
        </li>
        <li>
          <b>Orders:</b>{" "}
          <span>
            {home_single_product &&
              home_single_product.length !== 0 &&
              home_single_product[0].product_orders_count}
          </span>
        </li>
        <li>
          <b>Reviews:</b>{" "}
          <span>
            {home_single_product &&
              home_single_product.length !== 0 &&
              home_single_product[0].product_reviews}
          </span>
        </li>
        <li className="li-product-rating">
          {" "}
          {home_single_product && home_single_product.length !== 0 && (
            <span>
              <div className="rating-flex">
                <ReactStars
                  count={5}
                  size={35}
                  edit={false}
                  value={
                    home_single_product[0].product_rating &&
                    home_single_product[0].product_rating.toString()
                  }
                  half={true}
                  emptyIcon={<i className="far fa-star"></i>}
                  halfIcon={<i className="fa fa-star-half-alt"></i>}
                  fullIcon={<i className="fa fa-star"></i>}
                  color2={"#ffd700"}
                />
              </div>{" "}
              <span className="s-rating-counter">
                {home_single_product[0].product_rating}
              </span>
            </span>
          )}
        </li>
      </ol>
    );
    const links =
      this.state.loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_link &&
      home_single_product[0].product_link.length !== 0 &&
      home_single_product[0].product_link.map(product_link => {
        return (
          <OverlayTrigger
            placement="auto"
            delay={{ show: 250, hide: 400 }}
            overlay={this.renderTooltip(product_link.title)}
          >
            <div className="t-link-icon">
              <img
                src={(typeof (product_link.icon) === "string" && (product_link.icon.includes("http") ? product_link.icon :
                  this.state.env.CDN_URL + product_link.icon.replace("images", "thumb_images")))}
                height="50px"
                width="50px"
                alt={product_link.title}
                onClick={() => this.handleLinkClick(product_link.link_name, "show")}
              ></img>
            </div>
          </OverlayTrigger>
        );
      });

    const all_youtube_video =
      (home_single_product &&
        home_single_product.length !== 0 &&
        home_single_product[0].product_youtube_video &&
        home_single_product[0].product_youtube_video.length !== 0) ?
        home_single_product[0].product_youtube_video.map(
          (product_youtube_video, i) => {
            var index = product_youtube_video.indexOf("=");
            var arr = [
              product_youtube_video.slice(0, index),
              product_youtube_video.slice(index + 1)
            ];
            return (<>
              {this.props.load_hl_lo && this.props.hide_link_logout && this.props.hide_link_logout.is_hide_status === true ?
                <div className="yt-single" onClick={() =>
                  this.handleLinkClick(
                    product_youtube_video && product_youtube_video.toString(), "show"
                  )}> <img src={youtube_icon} alt="" className="img-yt" /></div>
                : <div key={i} className="s-video-container">
                  <YouTube videoId={arr[1]} opts={opts} onReady={this._onReady} />
                  <div
                    onClick={() =>
                      this.handleLinkClick(
                        product_youtube_video && product_youtube_video.toString(), "show"
                      )
                    }
                    className="link-name"
                  >
                    See in full screen
              </div>
                  <div></div>
                </div>}
            </>
            );
          }
        ) : "";
    const fb_ads_documents =
      this.state.loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_fb_ads &&
      home_single_product[0].product_fb_ads.document &&
      home_single_product[0].product_fb_ads.document.length !== 0 &&
      home_single_product[0].product_fb_ads.document.map((product_fb_doc, i) => {
        return (
          <div
            key={i}
            className="single-page-doc"
            onClick={() => this.handleLinkClick(
              product_fb_doc.includes("http") ? product_fb_doc : this.state.env.CDN_URL + product_fb_doc,
              "show")}>
            <img src={pdf_icon2.toString()} alt="NoImage"></img>
          </div>
        );
      });

    const fb_ads_images =
      this.state.loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_fb_ads &&
      home_single_product[0].product_fb_ads.image &&
      home_single_product[0].product_fb_ads.image.length !== 0 &&
      home_single_product[0].product_fb_ads.image.map((product_fb_images, i) => {
        return (
          <div
            key={i}
            className="image-item"
            onClick={() => this.handleLinkClick(product_fb_images.includes("http") ? product_fb_images : this.state.env.CDN_URL + product_fb_images,
              "show")}
          >
            <img src={(typeof product_fb_images === "string" && (product_fb_images.includes("http") ?
              product_fb_images : this.state.env.CDN_URL + product_fb_images.replace("images", "thumb_images")))} alt="NoImage"></img>
          </div>
        );
      });

    const fb_ads_videos =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_fb_ads &&
      home_single_product[0].product_fb_ads.video &&
      home_single_product[0].product_fb_ads.video.length !== 0 &&
      home_single_product[0].product_fb_ads.video.map((video, i) => {
        return (
          <div
            key={i}
            className="single-page-video"
            onClick={() => this.handleLinkClick(video.toString(), "show")}
          >
            <img
              src={fb_ads2.toString()}
              width="130px"
              height="90px"
              alt="NoImage"
            ></img>
          </div>
        );
      });
    const fb_ads = (
      <div>
        {home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_fb_ads &&
          home_single_product[0].product_fb_ads.text !== "" && (
            <div className="s-block-discription">
              {home_single_product[0].product_fb_ads.text}
            </div>
          )}
        {this.state.loaded_env && home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_fb_ads &&
          home_single_product[0].product_fb_ads.document &&
          home_single_product[0].product_fb_ads.document.length !== 0 && (
            <div className="image-list-icon">{fb_ads_documents} </div>
          )}
        {this.state.loaded_env && home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_fb_ads &&
          home_single_product[0].product_fb_ads.image &&
          home_single_product[0].product_fb_ads.image.length !== 0 && (
            <div className="image-list">{fb_ads_images} </div>
          )}
        {home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_fb_ads &&
          home_single_product[0].product_fb_ads.video &&
          home_single_product[0].product_fb_ads.video.length !== 0 && (
            <div className="image-list-icon">{fb_ads_videos} </div>
          )}
      </div>
    );

    const engage_documents =
      this.state.loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_engagement &&
      home_single_product[0].product_engagement.document &&
      home_single_product[0].product_engagement.document.length !== 0 &&
      home_single_product[0].product_engagement.document.map((product_fb_doc, i) => {
        return (
          <div
            key={i}
            className="single-page-doc"
            onClick={() => this.handleLinkClick(product_fb_doc.includes("http") ? product_fb_doc : this.state.env.CDN_URL + product_fb_doc)}
          >
            <img src={pdf_icon2} alt="NoImage"></img>
          </div>
        );
      });

    const engage_images =
      this.state.loaded_env &&
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_engagement &&
      home_single_product[0].product_engagement.image &&
      home_single_product[0].product_engagement.image.length !== 0 &&
      home_single_product[0].product_engagement.image.map((product_engage_images, i) => {
        return (
          <div
            key={i}
            className="image-item"
            onClick={() => this.handleLinkClick(product_engage_images.includes("http") ? product_engage_images : this.state.env.CDN_URL + product_engage_images)}
          >
            <img src={(typeof product_engage_images === "string" && (product_engage_images.includes("http") ?
              product_engage_images : this.state.env.CDN_URL + product_engage_images.replace("images", "thumb_images")))} alt="NoImage"></img>
          </div>
        );
      });
    const engage_videos =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_engagement &&
      home_single_product[0].product_engagement.video &&
      home_single_product[0].product_engagement.video.length !== 0 &&
      home_single_product[0].product_engagement.video.map((video, i) => {
        return (
          <div
            key={i}
            className="single-page-video"
            onClick={() => this.handleLinkClick(video.toString())}
          >
            <img src={fb_ads2} width="130px" height="90px" alt="NoImage"></img>
          </div>
        );
      });
    const engage_txt =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_engagement &&
      home_single_product[0].product_engagement.text !== "" &&
      home_single_product[0].product_engagement.text.trim();
    const engage = engage_txt && engage_txt.split(",");
    const filter_enage_like =
      engage &&
      engage.length !== 0 &&
      engage.filter(engage => {
        return (
          engage.includes("like") ||
          engage.includes("likes") ||
          engage.includes("LIKES") ||
          engage.includes("LIKE") ||
          engage.includes("Likes") ||
          engage.includes("Like")
        );
      });
    const filter_enage_comment =
      engage &&
      engage.length !== 0 &&
      engage.filter(engage => {
        return (
          engage.includes("comment") ||
          engage.includes("comments") ||
          engage.includes("COMMENT") ||
          engage.includes("COMMENTS") ||
          engage.includes("Comments") ||
          engage.includes("Comment")
        );
      });
    const filter_enage_reactions =
      engage &&
      engage.length !== 0 &&
      engage.filter(engage => {
        return (
          engage.includes("reaction") ||
          engage.includes("reactions") ||
          engage.includes("REACTIONS") ||
          engage.includes("REACTION") ||
          engage.includes("Reaction") ||
          engage.includes("Reactions")
        );
      });
    const filter_enage_share =
      engage &&
      engage.length !== 0 &&
      engage.filter(engage => {
        return (
          engage.includes("share") ||
          engage.includes("shares") ||
          engage.includes("SHARE") ||
          engage.includes("SHARES") ||
          engage.includes("Shares") ||
          engage.includes("Share")
        );
      });
    const filter_enage_view =
      engage &&
      engage.length !== 0 &&
      engage.filter(engage => {
        return (
          engage.includes("view") ||
          engage.includes("views") ||
          engage.includes("VIEW") ||
          engage.includes("VIWES") ||
          engage.includes("Views") ||
          engage.includes("View")
        );
      });
    const filter_enage_comment_val =
      filter_enage_comment &&
      filter_enage_comment.length !== 0 &&
      filter_enage_comment[0].split("-")[0];
    const filter_enage_like_val =
      filter_enage_like &&
      filter_enage_like.length !== 0 &&
      filter_enage_like[0].split("-")[0];
    const filter_enage_reactions_val =
      filter_enage_reactions &&
      filter_enage_reactions.length !== 0 &&
      filter_enage_reactions[0].split("-")[0];
    const filter_enage_share_val =
      filter_enage_share &&
      filter_enage_share.length !== 0 &&
      filter_enage_share[0].split("-")[0];
    const filter_enage_view_val =
      filter_enage_view &&
      filter_enage_view.length !== 0 &&
      filter_enage_view[0].split("-")[0];

    const engage_text = (
      <div className="engage_text">
        <div className="engage-field">
          {filter_enage_like && filter_enage_like.length !== 0 && (
            <div className="engage-list-item">
              <div className="engege-txt">Like</div>
              <div className="engage-data">
                <img src={likes} width="25px" height="25px" alt="Likes"></img>
                <span>{filter_enage_like_val}</span>
              </div>
            </div>
          )}
          {filter_enage_comment && filter_enage_comment.length !== 0 && (
            <div className="engage-list-item">
              <div className="engege-txt">Comment</div>
              <div className="engage-data">
                <img
                  src={comments}
                  width="25px"
                  height="25px"
                  alt="Comments"
                ></img>
                <span>{filter_enage_comment_val}</span>
              </div>
            </div>
          )}
          {filter_enage_share && filter_enage_share.length !== 0 && (
            <div className="engage-list-item">
              <div className="engege-txt">Share</div>
              <div className="engage-data">
                <img src={share} width="25px" height="25px" alt="Share"></img>
                <span>{filter_enage_share_val}</span>
              </div>
            </div>
          )}
          {filter_enage_reactions && filter_enage_reactions.length !== 0 && (
            <div className="engage-list-item">
              <div className="engege-txt">Reaction</div>
              <div className="engage-data">
                <img
                  src={reactions}
                  width="25px"
                  height="25px"
                  alt="Reactions"
                ></img>
                <span>{filter_enage_reactions_val}</span>
              </div>
            </div>
          )}
          {filter_enage_view && filter_enage_view.length !== 0 && (
            <div className="engage-list-item">
              <div className="engege-txt">View</div>
              <div className="engage-data">
                <img src={view} width="25px" height="25px" alt="Views"></img>
                <span>{filter_enage_view_val}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    );

    const engagement = (
      <div>
        {home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_engagement &&
          home_single_product[0].product_engagement.text !== "" &&
          engage_text}
        {this.state.loaded_env && home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_engagement &&
          home_single_product[0].product_engagement.document &&
          home_single_product[0].product_engagement.document.length !== 0 && (
            <div className="image-list-icon">{engage_documents} </div>
          )}
        {this.state.loaded_env && home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_engagement &&
          home_single_product[0].product_engagement.image &&
          home_single_product[0].product_engagement.image.length !== 0 && (
            <div className="image-list">{engage_images} </div>
          )}
        {home_single_product &&
          home_single_product.length !== 0 &&
          home_single_product[0].product_engagement &&
          home_single_product[0].product_engagement.video &&
          home_single_product[0].product_engagement.video.length !== 0 && (
            <div className="image-list-icon">{engage_videos} </div>
          )}
      </div>
    );
    const interest = home_single_product && home_single_product.length !== 0 && (
      <div className="s-block-discription">
        {home_single_product[0] && home_single_product[0].product_interest}
      </div>
    );

    var monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    function dateFormat(d) {
      var t = new Date(d);
      return t.getDate() + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear();
    }
    function dateFormat2(d) {
      var t = new Date(d);
      return t.getDate() - 1 + '-' + monthShortNames[t.getMonth()] + '-' + t.getFullYear();
    }
    var dd = dateFormat(new Date());
    var dd_yest = dateFormat2(new Date());
    var date_ver =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_display_date &&
      home_single_product[0].product_display_date.toString() &&
      home_single_product[0].product_display_date.slice(0, -5);
    var date_api =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_display_date &&
      home_single_product[0].product_display_date.toString();
    var final_date =
      dd === date_api
        ? "Today"
        : dd_yest === date_api
          ? "Yesterday"
          : date_ver;
    const display_date = home_single_product && home_single_product.length !== 0 && (
      <span className="date-value">{final_date && final_date.toString()}</span>
    );
    const conversion =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_conversions;
    const daily_people_reach =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0] &&
      home_single_product[0].product_daily_people_reach;
    const product_cat =
      home_single_product &&
      home_single_product.length !== 0 &&
      home_single_product[0].product_category &&
      home_single_product[0].product_category.length !== 0 &&
      home_single_product[0].product_category.map((product_category, key) => {
        return (
          <div className="p-cat-container">
            <span className="p-cat-item">{product_category.title}</span>
          </div>
        );
      });
    let country_value = JSON.parse(localStorage.getItem("country_wp"));


    const adScript = `<script async="" src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- sareeo mediam rectangular box -->
<ins class="adsbygoogle" style="display:inline-block;width:300px;height:250px" data-ad-client="ca-pub-4162229383187756" data-ad-slot="2447726427"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>
  <script async="" src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<!-- sareeo mediam rectangular box -->
<ins class="adsbygoogle" style="display:inline-block;width:300px;height:250px" data-ad-client="ca-pub-4162229383187756" data-ad-slot="2447726427"></ins>
<script>
(adsbygoogle = window.adsbygoogle || []).push({});
</script>`


    return (
      <div className="root-page">
        <div className="main-container-home">
          <div className="ms-logo">
            <div className="country-data">
              <span className="cou-name-vd">{country_value && country_value.Country_Name}</span>
              {country_value && this.state.loaded_env && <span className="cou-flag">
                <img src={this.state.env.CDN_URL + country_value.Country_Flag}></img></span>}
            </div>
            <img src={imageInstall} alt="" className="logo-vd" />
          </div>
          {this.state.loaded == false && <Loader
            loaded={this.state.loaded && this.state.loaded_env}
            lines={10}
            length={5}
            width={5}
            radius={10}
            corners={1}
            rotate={0}
            direction={1}
            color="#1f3893"
            speed={1}
            trail={40}
            shadow={false}
            hwaccel={false}
            className="spinner"
            zIndex={2e9}
            top="50%"
            left="50%"
            scale={1.0}
            loadedClassName="loadedContent"
          />}
          <SignUpModal showSignUpModal={this.state.showSignUpModal}
            handleCloseSignUp={this.handleCloseSignUp} />
          {this.state.loaded && (
            <div>
              {home_single_product && home_single_product.length !== 0 && home_single_product !== "Unauthorised request !" ? (
                <main>
                  <div className="container">
                    <div className="container-home-single-products">
                      <div className="grid second-nav">
                        <div className="column-xs-12">
                          <nav>
                            <ol className="breadcrumb-list">
                              <li
                                className="breadcrumb-item"
                                onClick={() => this.handleRoute("/")}
                              >
                                Products
                            </li>
                              <li className="breadcrumb-item active">
                                {product_name}
                              </li>
                              <li className="date-container">
                                <span className="date-titile">Listed date:</span>
                                {display_date}{" "}
                                <MdDateRange size={20} color="#1f3893" />
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>


                      {/* ad script tag */}
                      <center dangerouslySetInnerHTML={{ __html: adScript }} ></center>
                      {/* ad script tag */}


                      <div className="grid product-details">
                        <div className="product-images-container">
                          <div className="product-gallery">
                            <div className="product-image">
                              <img
                                className="active"
                                src={main_image_onclick}
                                alt="NoImage"
                                onClick={() => this.handleLinkClick(main_image_onclick)}
                              />
                            </div>
                            <ul className="product-image-list">
                              {/* {product_images} */}
                              <Carousel
                                centerSlidePercentage={40}
                                centerMode
                                emulateTouch
                                onClickItem={this.handleOnClickItem}
                              >
                                {product_images}
                              </Carousel>
                            </ul>
                          </div>
                        </div>
                        <div className="product-details-container">
                          <h2 className="pro-title">{product_name}</h2>
                          <div className="pro-cat">
                            {product_cat && product_cat}
                          </div>
                          <h4 className="pro-sub-title">Main feature</h4>
                          <div className="pro-description">
                            <Editor
                              className="disable-from-table"
                              editorState={this.state.editorState}
                              readOnly={true}
                            />
                          </div>
                          <div className="grid products-related-boxes">
                            <Card className="products-info-box">
                              <Card.Header>Profits and costs</Card.Header>
                              <Card.Body>
                                <Card.Text>{pnc}</Card.Text>
                              </Card.Body>
                            </Card>
                            <Card className="products-info-box">
                              <Card.Header>Analytics</Card.Header>
                              <Card.Body>
                                <Card.Text>{analytics}</Card.Text>
                              </Card.Body>
                            </Card>
                            <Card className="products-info-box">
                              <Card.Header>Links</Card.Header>
                              <Card.Body>
                                <Card.Text>
                                  <ol className="list-unstyled vertical-data link-card">
                                    {links}
                                  </ol>
                                </Card.Text>
                              </Card.Body>
                            </Card>
                          </div>
                        </div>
                      </div>

                      {(all_youtube_video !== "") && <div className="grid products-video-row">
                        <h6 className="s-pro-title">Youtube videos</h6>
                        <div className="youtube-videos">{all_youtube_video}</div>
                      </div>}
                      <div className="grid products-fb-content">
                        <h6 className="s-pro-title">Fb ads</h6>
                        <div className="fbads-n-other">
                          <div className="s-pro-block-content">{fb_ads}</div>
                          <div className="other-info">
                            <div className="other-info-list-item">
                              <div className="other-info-txt">
                                Daily People Reach
                            </div>
                              <div className="other-info-data">
                                <span>
                                  {daily_people_reach &&
                                    daily_people_reach.replace(/\s/g, "")}
                                </span>
                              </div>
                            </div>
                            <div className="other-info-list-item">
                              <div className="other-info-txt">Conversions</div>
                              <div className="other-info-data">
                                <span>
                                  {conversion && conversion.replace(/\s/g, "")}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {(engagement !== "" || engagement !== []) && <div className="grid products-engage-content">
                        <h6 className="s-pro-title">Engagement</h6>
                        <div className="s-pro-block-content">{engagement}</div>
                      </div>}
                      <div className="grid products-interest-txt">
                        <h6 className="s-pro-title">Interests</h6>
                        <div className="s-pro-block-content">{interest}</div>
                      </div>
                      <div class="footer-sp">
                        <div className="back-btn" onClick={() => this.handleRoute("/")}>
                          <span>
                            <TiArrowBack />
                          </span>
                          <span>Back</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              ) : (
                  <main>
                    <h4>Something went wrong!</h4>
                  </main>
                )}
              <footer>
                <div className="grid">
                  <div className="column-xs-12"></div>
                </div>
              </footer>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    home_all_product_data: state.AllReducer.home_all_product_data.data,
    home_single_product: state.AllReducer.home_single_product,
    env: state.AllReducer.env.data,
    load_env: state.AllReducer.env.load_env,
    hide_link_logout: state.AllReducer.hide_link_logout.message,
    load_hl_lo: state.AllReducer.hide_link_logout.load_hl_lo
  };
};
const mapDispatchToProps = dispatch => ({
  action: {
    AllAction: bindActionCreators(AllAction, dispatch),
    InstallAction: bindActionCreators(InstallAction, dispatch)
  }
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HomeSingleProduct)
);