import React, { Component } from 'react';
import { Table, Button, Modal } from "react-bootstrap";
import NavbarHeader from './NavbarHeader';
import Header from './Header';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as AllAction from '../Action/AllAction';
import Loader from "react-loader";
import moment from 'moment';
import { FaEdit } from "react-icons/fa";
import { GoVerified } from "react-icons/go";
import { IoMdCloseCircle } from "react-icons/io";

class BillPayments extends Component {

    componentWillMount() {
        const accessToken = localStorage.getItem("token_wp");
        const shop = localStorage.getItem("shop_wp");
        const data = {
            accessToken, shop
        }
        this.props.action.AllAction.getShopSubscriptionAction(data);
        this.props.action.AllAction.getStripCustomerMessagesAction(data);

    }

    handleShow = (sub_tag) => {
        if (sub_tag === "supplier")
            this.props.history.push("/supplier-plans")
        if (sub_tag === "winning_products") {
            this.props.history.push("/account")
        }
    };

    render() {
        var { get_stripe_cus_msg, load_scm } = this.props

        var sub_plan = load_scm &&
            this.props.load_shop_sub && this.props.get_shop_sub && this.props.get_shop_sub.plan_data && this.props.get_shop_sub.plan_data.length !== 0 &&
            this.props.get_shop_sub.plan_data.map((plan_data, i) => {
                var plan_name = load_scm && get_stripe_cus_msg !== null && get_stripe_cus_msg !== undefined && this.props.get_shop_sub &&
                    this.props.get_shop_sub.temp_plan_data && this.props.get_shop_sub.temp_plan_data.length !== 0 ?
                    this.props.get_shop_sub.temp_plan_data.filter((temp_plan_data) => {
                        return plan_data.subscriptionItemID == temp_plan_data.subscriptionItemID
                    }) : ""
                var plan_class = plan_name === "" ? "plan-text" : "plan-text-dec"
                return <tr>
                    <td>{plan_data.sub_name}</td>
                    <td><div className={plan_class}>{plan_name === "" ? plan_data.plan_name : (plan_name && plan_name.length !== 0 && plan_name[0].plan_name)}</div></td>
                    <td>{plan_data.amount}</td>
                    <td>
                        <FaEdit size={25} color="#1f3893" onClick={() => this.handleShow(plan_data.sub_tag)} className="pdf-sup" />
                    </td>
                </tr>
            })
        var is_card_no = this.props.load_shop_sub && this.props.stripe_customer_cards && this.props.stripe_customer_cards.length !== 0 &&
            this.props.stripe_customer_cards[0].cardDetails && this.props.stripe_customer_cards[0].cardDetails.length !== 0 &&
            this.props.stripe_customer_cards[0].cardDetails[0] && this.props.stripe_customer_cards[0].cardDetails[0].last4 ? true : false;
        var msg_no = is_card_no ? this.props.load_shop_sub && this.props.stripe_customer_cards && this.props.stripe_customer_cards.length !== 0 &&
            this.props.stripe_customer_cards[0].cardDetails && this.props.stripe_customer_cards[0].cardDetails.length !== 0 &&
            this.props.stripe_customer_cards[0].cardDetails[0] && this.props.stripe_customer_cards[0].cardDetails[0].last4 : "";
        return (
            <div className="root-page">
                <NavbarHeader />
                <div className="main-container">
                    <Header header_name="Winning Products" history={this.props.history} CDN_URL={this.props.env && this.props.env.CDN_URL} />
                    <div>
                        <h4 className="section-title">Subscriptions</h4>
                        <div className="sub-plan-container">
                            {this.props.load_shop_sub && this.props.next_billing_date &&
                                <div className="next-date"> Your account is billed monthly and the next payment is due on{" "}
                                    <span className="due-date">{moment(this.props.next_billing_date).format("MMM DD, YYYY")}</span>.</div>}
                            {this.props.load_shop_sub &&
                                this.props.get_shop_sub &&
                                this.props.get_shop_sub.plan_data &&
                                this.props.get_shop_sub.plan_data.length !== 0 &&
                                load_scm ?
                                <Table bordered responsive className="pro-data-table">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "30%" }}>Product</th>
                                            <th style={{ width: "30%" }}>Plan</th>
                                            <th style={{ width: "30%" }}>Amount</th>
                                            <th style={{ width: "10%" }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {sub_plan && sub_plan}
                                    </tbody>
                                </Table> : this.props.load_shop_sub && <h4>No any subscribed plan.</h4>}
                            {this.props.load_shop_sub && is_card_no && < div className="card-no">
                                <span className="green-mark">
                                    {load_scm && Array.isArray(get_stripe_cus_msg) && get_stripe_cus_msg.length !== 0 ?
                                        <IoMdCloseCircle color="red" /> :
                                        <GoVerified color="green" />}
                                </span>You are paying via card ending with <span className="msg-no">{msg_no}</span>.</div>}
                            {this.props.load_shop_sub && load_scm && get_stripe_cus_msg !== null && get_stripe_cus_msg !== undefined && Array.isArray(get_stripe_cus_msg) && get_stripe_cus_msg.length !== 0
                                && <div className="card-no"><span className="red-mark">
                                    <IoMdCloseCircle color="red" />
                                </span>{get_stripe_cus_msg[0].displayMessage}</div>}
                        </div>
                        <Loader
                            loaded={this.props.load_shop_sub && load_scm}
                            lines={10}
                            length={5}
                            width={5}
                            radius={10}
                            corners={1}
                            rotate={0}
                            direction={1}
                            color="#1f3893"
                            speed={1}
                            trail={40}
                            shadow={false}
                            hwaccel={false}
                            className="spinner"
                            zIndex={2e9}
                            top="50%"
                            left="50%"
                            scale={1.0}
                            loadedClassName="loadedContent"
                        />
                    </div>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        get_shop_sub: state.AllReducer.get_shop_sub.message,
        load_shop_sub: state.AllReducer.get_shop_sub.load_shop_sub,
        next_billing_date: state.AllReducer.get_shop_sub.next_billing_date,
        stripe_customer_cards: state.AllReducer.get_shop_sub.stripe_customer_cards,
        get_stripe_cus_msg: state.AllReducer.get_stripe_cus_msg.message,
        load_scm: state.AllReducer.get_stripe_cus_msg.load_scm
    }
}
const mapDispatchToProps = (dispatch) => ({
    action: {
        AllAction: bindActionCreators(AllAction, dispatch)
    }
})
export default connect(mapStateToProps, mapDispatchToProps)(BillPayments);